import _ from 'lodash';
import React from 'react';

import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import CustomInput from '../../forms/CustomInput';
import ImageUpload from '../../forms/ImageUpload';
import CustomSelectCreate from '../../forms/CustomSelectCreate';
import CustomRichTextarea from '../../forms/CustomRichTextarea';

import ListingsForm from './listings';

import {
  getCategories,
  searchItem,
  searchVariant,
  searchMake,
  searchFamily,
  getItem,
  postItem,
  deleteItemImage,
} from '../../../actions';

import componentOptions from '../../../utils/categoryDefinitions.json';
import renderDropdown from '../../forms/CustomDropdown';
import customToggle from '../../forms/CustomToggle';

import ItemForm from '../../ui/FormPage';
import Loader from '../../Loader';

/************ IMPORTANT CODE STARTS HERE **************/
const FieldPrefixContext = React.createContext();
const FieldPrefix = ({ prefix, children }) => (
  <FieldPrefixContext.Provider value={prefix}>
    {children}
  </FieldPrefixContext.Provider>
);
const PrefixedField = ({ name, ...props }) => (
  <FieldPrefixContext.Consumer>
    {(prefix) => <Field name={`${prefix}.${name}`} {...props} />}
  </FieldPrefixContext.Consumer>
);
/************* IMPORTANT CODE ENDS HERE ***************/

function groupVariantsByType(variants) {
  if (!variants?.length) return {};
  return variants.reduce((acc, variant) => {
    const { variantType } = variant;
    if (!acc[variantType]) {
      acc[variantType] = [];
    }
    acc[variantType].push({
      value: variant._id,
      label: variant.variant,
      variantType: variant.variantType,
    });
    return acc;
  }, {});
}

const EditItem = (props) => {
  let { id } = useParams();

  function handleMakeSearch(searchTerm) {
    props.searchMake(searchTerm);
  }

  function handleItemSearch(searchTerm) {
    props.searchItem(searchTerm);
  }

  function handleFamilySearch(searchTerm) {
    //  how to get makeId from form field

    props.searchFamily(searchTerm);
  }

  function handleCategorySearch(searchTerm) {
    props.getCategories(searchTerm);
  }

  function handleItemPropertySearch(variant, variantType) {
    // lowercase variant
    variant = variant.toLowerCase();
    props.searchVariant(variant, variantType);
  }

  const onSubmit = async (values) => {
    return await props.postItem(values);
  };

  // on mount state
  const isMountedRef = React.useRef(false);

  React.useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      props.getCategories();
      if (id && !props?.items[id]) {
        props.getItem(id, false);
      }
    }
  }, [props, id]);

  const {
    items,
    listId,
    buildId,
    makeOptions,
    itemName,
    categoryOptions,
    familyOptions,
  } = props;

  const item = items && items[id] ? items[id] : null;
  // convert _make and _category to vanilla options

  // const for each listing, convert _athlete to vanilla option
  const listings = !_.isEmpty(item?.listings)
    ? item.listings.map((listing) => {
        return {
          ...listing,
          _athlete: listing._athlete
            ? {
                value: listing._athlete._id,
                label: listing._athlete.name,
              }
            : '',
        };
      })
    : [];

  function generateVariantOptions(componentOptions, item, props) {
    const parentCategory = componentOptions.find((category) =>
      category.options.find((option) => option.label === item?._category?.label)
    );
    const variantTypes = parentCategory?.attributes.map((attribute) => {
      return {
        label: attribute?.label,
        value: attribute?.value,
      };
    });

    const variantOptions = variantTypes?.length
      ? variantTypes.map((variantType) => {
          const existingOptions = item?.variants?.length
            ? item.variants.filter(
                (variant) => variant.variantType === variantType
              )
            : [];

          // match variantType and return options
          const searchOptions = props.variantOptions?.length
            ? props.variantOptions.filter((variant) => {
                return variant?.variantType === variantType.value
                  ? variant.options
                  : null;
              })
            : [];

          const mergedOptions = _.uniqBy(
            [...existingOptions, ...searchOptions],
            'label'
          );

          return {
            label: variantType.label,
            name: variantType.value,
            options: mergedOptions,
          };
        })
      : [];

    const variantOptionsObject = variantOptions.reduce((obj, variant) => {
      obj[variant.name] = {
        label: variant.label,
        name: variant.name,
        options: variant.options,
      };
      return obj;
    }, {});

    return variantOptionsObject;
  }

  const initVariantOptionsObject = item
    ? groupVariantsByType(item.variants)
    : {};

  const initialValues = item
    ? {
        listId,
        buildId,
        ...item,
        _id: id,
        _make: item._make
          ? {
              value: item._make._id,
              label: item._make.name,
            }
          : '',
        _family: item._family
          ? {
              value: item._family._id,
              label: item._family.name,
            }
          : '',
        _category: item._category
          ? {
              value: item._category._id,
              label: item._category.name,
            }
          : '',
        materials: item.materials
          ? {
              value: item.materials._id,
              label: item.materials.name,
            }
          : '',
        variants: initVariantOptionsObject,
        listings: listings?.length ? listings : [],
        status: item.status === 'active' ? true : false,
      }
    : !id
    ? {
        id: '_new_',
        listId,
        buildId,
        _make: '',
        name: itemName,
        _category: '',
        variants: initVariantOptionsObject,
        listings: [],
        status: false,
      }
    : {};

  const handleDeleteImage = async (imageFile) => {
    await props.deleteItemImage(id, imageFile);
  };

  const renderForm = ({ handleSubmit, form, submitting, pristine, values }) => {
    const variantOptions = !_.isEmpty(
      generateVariantOptions(componentOptions, values, props)
    )
      ? generateVariantOptions(componentOptions, values, props)
      : {};
    const toggleLabel = values.status ? 'Verified' : 'Pending';
    return (
      <form onSubmit={handleSubmit} className="space-y-2 w-full">
        {isAdmin && (
          <div className="flex flex-col my-4">
            <Field
              name="images"
              component={ImageUpload}
              handleDeleteImage={handleDeleteImage}
              label="Images"
              labelStyle="pl-4 sm:pl-0"
              desiredAspectRatio={1}
            />
          </div>
        )}
        <div className="flex flex-col px-4 sm:px-0">
          <Field
            name="_make"
            component={CustomSelectCreate}
            options={makeOptions}
            defaultOptions={makeOptions}
            searchAction={handleMakeSearch}
            isCreatable={true}
            isMulti={false}
            placeholder="Make"
            label="Make"
          ></Field>
        </div>
        <div className="flex flex-col px-4 sm:px-0">
          <Field
            name="name"
            component={CustomInput}
            searchAction={handleItemSearch}
            isCreatable={true}
            placeholder="Model"
            label="Model"
          />
        </div>
        {isAdmin && (
          <div className="flex flex-col px-4 sm:px-0">
            <Field
              name="sku"
              component={CustomInput}
              searchAction={handleItemSearch}
              isCreatable={true}
              placeholder="SKU"
              label="SKU"
            />
          </div>
        )}
        {isAdmin && (
          <div className="flex flex-col px-4 sm:px-0">
            <Field
              name="_family"
              component={CustomSelectCreate}
              options={familyOptions}
              defaultOptions={familyOptions}
              searchAction={handleFamilySearch}
              isCreatable={true}
              isClearable={true}
              placeholder=""
              label="Family"
            />
          </div>
        )}
        <div className="flex flex-col px-4 sm:px-0">
          {values._category &&
            values._category.__isNew__ && [
              <Field
                name="createCategory"
                placeholder="Category"
                label="Category"
                component={renderDropdown}
                options={categoryOptions}
                defaultValue={categoryOptions[0]}
              />,
            ]}
        </div>
        <div className="flex flex-col px-4 sm:px-0">
          <Field
            name="_category"
            component={CustomSelectCreate}
            options={categoryOptions}
            defaultOptions={categoryOptions}
            searchAction={handleCategorySearch}
            isCreatable={false}
            placeholder={
              values._category && values._category.__isNew__
                ? 'Subcategory'
                : 'Category'
            }
            label={
              values._category && values._category.__isNew__
                ? 'Subcategory'
                : 'Category'
            }
          />
        </div>
        {isAdmin && (
          <div className="flex flex-col px-4 sm:px-0">
            <Field
              name="description"
              component={CustomRichTextarea}
              isCreatable={true}
              placeholder="Tell me something about this item"
              label="Description"
            />
          </div>
        )}
        {isAdmin && (
          <FieldPrefix prefix="variants">
            {!_.isEmpty(variantOptions)
              ? Object.keys(variantOptions).map((variant) => (
                  // is exact variant in variantOptions
                  <div key={variant} className="flex flex-col px-4 sm:px-0">
                    <PrefixedField
                      name={variant}
                      component={CustomSelectCreate}
                      type="text"
                      label={variantOptions[variant].label}
                      variantType={variantOptions[variant].label}
                      searchAction={(value) =>
                        handleItemPropertySearch(
                          value,
                          variantOptions[variant].name
                        )
                      }
                      options={variantOptions[variant]?.options}
                      defaultOptions={
                        initVariantOptionsObject[variant]?.options
                      }
                      placeholder={`Enter ${variantOptions[variant].name}`}
                      isMulti
                      // isCreatable false if there is one option and isn't an error
                      isCreatable={true}
                    />
                  </div>
                ))
              : null}
          </FieldPrefix>
        )}
        {!isAdmin && (
          <div className="flex flex-col px-4 sm:px-0">
            <Field
              name="note"
              component={CustomRichTextarea}
              isCreatable={true}
              placeholder="Please share any additional information that you may have on this item that can aid us in verifying it. A link to the product page or contact info of the manufacturer will be helpful."
              label="Notes"
            />
          </div>
        )}
        {isAdmin && (
          <div className="flex flex-col py-4">
            <h2 className="ml-4 sm:ml-0">Listings</h2>
            <ListingsForm />
          </div>
        )}
        <Field name="listId" component={CustomInput} type="hidden" />
        <Field name="buildId" component={CustomInput} type="hidden" />
        <div className="flex flex-row px-4 sm:px-0 justify-between gap-4">
          <div className="flex flex-row w-48">
            {isAdmin && (
              <Field
                status="active"
                name="status"
                component={customToggle}
                // set label to current value of input
                label={toggleLabel}
              />
            )}
          </div>
          <button
            className={`btn btn-primary ${submitting ? 'loading' : ''}`}
            type="submit"
            disabled={submitting || pristine}
          >
            {submitting ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
    );
  };

  if (_.isEmpty(item) && initialValues?.id !== '_new_') {
    return <Loader />;
  }

  const isAdmin = props?.isAdmin;

  return (
    <div className="flex flex-col w-full bg-base-100 items-start sm:px-0 sm:mt-5 pb-28 overflow-y-visible">
      <ItemForm>
        <div className="px-4 sm:px-0 py-1 mt-4">
          <h1>{item ? 'Edit' : isAdmin ? 'Add' : 'Suggest'} Item</h1>
          {!isAdmin && (
            <p className="text-sm">
              Thanks for taking the time to suggest an item. The more details
              you can provide the better. We review all item additions and will
              complete with available information.
            </p>
          )}
        </div>
        <Form
          onSubmit={onSubmit}
          render={renderForm}
          initialValues={initialValues}
          initialValuesEqual={() => true}
          mutators={{
            ...arrayMutators,
          }}
          validate={(formValues) => {
            const errors = {};

            if (!formValues._make) {
              errors._make = 'Please enter a make for your item';
            }

            if (!formValues.name) {
              errors.name = 'Please enter a model name for your item';
            }

            if (!formValues._category) {
              errors._category = 'Please enter a category for your item';
            }

            return errors;
          }}
        />
      </ItemForm>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const getVanillaOptions = (options) => {
    return options.map((option) => {
      return {
        value: option._id,
        label: option.name?.length ? option.name : option.label,
      };
    });
  };

  const items = state.items?.items ? state.items.items : null;

  const itemName = new URLSearchParams(window.location.search).get('name');

  const makeOptions = state.search.makes?.length
    ? getVanillaOptions(state.search.makes)
    : [];
  const familyOptions = state.search.family?.length
    ? getVanillaOptions(state.search.family)
    : [];
  const categoryOptions = state.search.category?.length
    ? state.search.category
    : componentOptions;
  const variantOptions = !_.isEmpty(state.search.variant)
    ? Object.keys(state.search.variant).map((variant) => {
        // if object key of variant is error, skip
        if (Object.keys(state.search.variant[variant]).includes('error')) {
          return null;
        }

        return {
          variantType: variant,
          options: _.uniqBy(
            state.search.variant[variant].map((option) => {
              return {
                value: option._id,
                label: option.variant.toLowerCase(),
              };
            }),
            'label'
          ),
        };
      })
    : [];

  const isAdmin = state?.auth?.permissions?.admin;

  return {
    isAdmin,
    items,
    itemName,
    makeOptions,
    familyOptions,
    categoryOptions,
    variantOptions,
  };
}

export default connect(mapStateToProps, {
  getCategories,
  searchItem,
  searchVariant,
  searchMake,
  searchFamily,
  deleteItemImage,
  getItem,
  postItem,
})(EditItem);

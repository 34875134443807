import { ADD_MODAL, HIDE_MODAL, DELETE_MODAL } from './types';

export const showModal = (modal, property) => {
  return { type: ADD_MODAL, payload: modal, property };
};

export const hideModal = () => {
  return { type: HIDE_MODAL };
};

export const deleteModal = () => {
  return { type: DELETE_MODAL };
};

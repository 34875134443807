import React from 'react';
import { Link } from 'react-router-dom';
import RecordEvent from '../../analytics/pageview';

const NewUser = ({ profile }) => {
  const name = profile?.name || 'there';

  return (
    <div className="h-screen flex items-center justify-center p-5">
      <div>
        <h1 className="text-4xl font-bold my-2">Welcome, {name}!</h1>
        <h2 className="text-2xl font-bold">It's nice to meet you.</h2>
        <p className="py-4">
          <Link className="btn btn-primary" to="/profile/edit/">
            Create your profile
          </Link>
          <a className="btn btn-primary mx-3 btn-outline" href="/api/logout">
            Log out
          </a>
        </p>
      </div>
      <RecordEvent
        hitType="pageview"
        page={window.location.pathname}
        title="Onboarding - New User"
      />
    </div>
  );
};

export default NewUser;

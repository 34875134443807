import React from 'react';

import { Link, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import { getList } from '../../../../../actions';

import ListItems from '../../../../lists/listItems';

import APArrowLeftIcon from '../../../../ui/ArrowLeftIcon';

const ListView = (props) => {
  const { listId } = useParams();
  const id = props.athleteId;

  // onmount state
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true);
      props.getList(listId);
    }
  }, [mounted, props, listId]);

  const { lists } = props;

  function renderBackButton() {
    return (
      <div className="flex justify-between flex-row">
        <div className="flex flex-row">
          <Link
            to={`/athlete/${id}/lists`}
            className="btn btn-square btn-ghost"
          >
            <APArrowLeftIcon />
          </Link>
        </div>
      </div>
    );
  }

  function renderListTitle(listId) {
    if (!lists || !lists[listId]) {
      return <>Loading...</>;
    }

    const { name, description } = props.lists[listId];

    return (
      <div>
        <h3 className="font-bold underline underline-offset-8 my-4">{name}</h3>
        <p>{description}</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      {renderBackButton()}
      <div className="py-4">{renderListTitle(listId)}</div>
      <div className="grid grid-cols-3 sm:grid-cols-5 gap-4">
        <ListItems
          lists={lists}
          listId={listId}
          athleteId={props.auth.athlete}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    lists: state.lists,
  };
};

export default connect(mapStateToProps, { getList })(ListView);

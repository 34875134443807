import React from 'react';

import { fetchProfileStats } from '../../../../actions/profile/company';
import { connect } from 'react-redux';

import Loader from '../../../Loader';

import { UserGroupIcon, BoltIcon } from '@heroicons/react/24/outline';

const Stats = ({ fetchProfileStats, stats, loading }) => {
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    if (!mounted) {
      fetchProfileStats();
      setMounted(true);
    }
  }, [fetchProfileStats, mounted]);

  const profileViews =
    stats?.profileViewsCount > 0 ? stats?.profileViewsCount : 0;
  const followers = stats?.followers > 0 ? stats?.followers : 0;

  return (
    <div className="flex flex-col items-center justify-center mt-4">
      {!stats && loading?.stats && (
        <Loader type="table" className="mt-4 max-w-xl" />
      )}
      {stats && (
        <div className="stats shadow flex max-w-xl">
          <div className="stat">
            <div className="stat-figure text-secondary">
              <UserGroupIcon className="h-8 w-8" />
            </div>
            <div className="stat-title">Followers</div>
            <div className="stat-value text-secondary">{followers}</div>
            <div className="stat-desc whitespace-break-spaces">
              Number of accounts following you on AP
            </div>
          </div>
          <div className="stat">
            <div className="stat-figure text-secondary">
              <BoltIcon className="h-8 w-8" />
            </div>
            <div className="stat-title">Profile Views</div>
            <div className="stat-value text-secondary">{profileViews}</div>
            <div className="stat-desc whitespace-break-spaces">
              Unique views of your profile over the past year
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const alias = state.auth?.profile?.alias;
  return {
    stats: state.athlete?.[alias]?.stats,
    loading: state.loader?.elements,
  };
};

export default connect(mapStateToProps, { fetchProfileStats })(Stats);

import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Page from '../ui/Page';
import BuildItemEdit from './items/edit';
import EditBuild from './edit';
import ViewBuild from './view';

import Header from '../header';

const BuildRouter = ({ builds, lists }) => {
  return (
    <Page className="flex h-screen">
      <Header />
      <Routes basename="/build">
        <Route path="/edit" element={<EditBuild />} />
        <Route path="/edit/:id" element={<EditBuild />} />
        <Route
          path="/:id"
          element={<ViewBuild builds={builds} lists={lists} />}
        />
        <Route path="/:buildId/item/edit" element={<BuildItemEdit />} />
        <Route path="/:buildId/item/edit/:itemId" element={<BuildItemEdit />} />
      </Routes>
    </Page>
  );
};

export default BuildRouter;

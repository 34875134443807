// RelatedBuilds.js

import React, { useEffect } from 'react';
import Masonry from 'react-masonry-css';
import BuildCard from '../card';
import { connect } from 'react-redux';
import Loader from '../../Loader';

import { getRelatedBuilds } from '../../../actions';

const RelatedBuilds = ({
  loading,
  lists,
  getRelatedBuilds,
  builds,
  buildId,
}) => {
  const [page, setPage] = React.useState(1);
  const [mount, setMount] = React.useState(false);
  const [mountedId, setMountedId] = React.useState(null);

  useEffect(() => {
    if ((!mount || mountedId !== buildId) && buildId) {
      getRelatedBuilds(buildId, 1);
      setMount(true);
      setMountedId(buildId);
      setPage(1);
    }
  }, [mount, getRelatedBuilds, buildId, page, mountedId, setMountedId]);

  const relatedBuilds = builds[buildId]?.relatedBuilds;

  useEffect(() => {
    function handleScroll() {
      if (
        window.innerHeight +
          document.getElementById('main-content').scrollTop +
          400 <
        document.getElementById('scroll-surface').offsetHeight
      )
        return;

      // if page of current type is equal to total pages, or loader is true, or total pages is 0, return
      if (
        loading ||
        !builds[buildId]?.totalRelatedBuildsPages ||
        page >= builds[buildId]?.totalRelatedBuildsPages ||
        builds[buildId]?.totalRelatedBuildsPages === 0 ||
        mountedId !== buildId
      )
        return;

      getRelatedBuilds(buildId, page + 1);

      setPage(page + 1);
    }
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, [page, setPage, builds, buildId, getRelatedBuilds, loading, mountedId]);

  return relatedBuilds?.length > 0 ? (
    <div
      className={`flex flex-col transition-opacity duration-500 ease-linear opacity-100`}
    >
      <h2 className="text-center mt-8 mb-5">Discover more builds like this</h2>
      <Masonry
        breakpointCols={{ 640: 2, 1280: 3, default: 5 }}
        className="masonry-grid gap-4 md:gap-8 lg:gap-8 px-4 md:px-0"
        columnClassName="masonry-grid_column"
      >
        {relatedBuilds.map((build) => (
          <BuildCard
            key={build._id}
            build={build}
            lists={lists}
            noAvatar
            noReactions
            noCarousel
          />
        ))}
      </Masonry>
      {loading && <Loader type="builds" />}
    </div>
  ) : (
    <div className={`flex flex-col`}></div>
  );
};

const mapStateToProps = (state) => {
  const loading =
    state.loader && state.loader.builds ? state.loader.builds : false;

  return {
    loading,
  };
};

export default connect(mapStateToProps, { getRelatedBuilds })(RelatedBuilds);

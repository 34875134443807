import React from "react";

import Identity from "../../../identity";

import { Form, Field } from "react-final-form";
import CustomInput from "../../../forms/CustomInput";

import { connect } from "react-redux";
import { addStravaEmail } from "../../../../actions";

const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

const StravaAddEmail = ({addStravaEmail, stravaId}) => {

    const onSubmit = async (formValues) => {
        addStravaEmail(formValues);
    }

    const renderForm = () => {
        return (
            <Form
                initialValues={{ email: '', stravaId }}
                onSubmit={onSubmit}
                validate={(formValues) => {
                    const errors = {};
                    if (formValues.email && !validateEmail(formValues.email)) {
                        errors.email = 'Please enter a valid email address';
                    }
                    return errors;
                }}
                render={({ handleSubmit, submitting, pristine }) => (
                    <form onSubmit={handleSubmit} className="ui form error">
                        <div className="flex flex-col sm:flex-row items-start justify-center gap-0">
                            <div className="flex flex-col items-center justify-center gap-0 w-64">
                                <Field
                                    name="email"
                                    component={CustomInput}
                                    placeholder="Enter your email address"
                                    className="border border-white rounded-none shadow-sm focus:outline-none focus:ring-white focus:border-white dark:focus:ring-yellow-700 dark:focus:border-yellow-700 dark:border-black text-center"
                                />
                                <Field 
                                    name="stravaId"
                                    type="hidden"
                                    component={CustomInput}
                                />
                                <button
                                    className="btn btn-primary my-4 rounded-none"
                                    type="submit"
                                >
                                    Add Email
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            />
        )
    }


    return (
        <div className='h-screen flex flex-col items-center justify-center p-5 text-center gap-4 bg-amber-100 dark:bg-yellow-950'>
            <Identity />
            <h1 className="text-4xl font-bold uppercase">
                <span className='italic'>Next Step</span> Add Email
            </h1>
            <p className="text-xl font-bold">
                We need your email address to connect your Strava account.
            </p>
            {renderForm()}
            {/* Log Out */}
            <a href="/api/logout" className="underline underline-offset-8">
                Log Out
            </a>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        stravaId: state.auth.stravaId
    }
}

export default connect(mapStateToProps, { addStravaEmail })(StravaAddEmail);
import _ from 'lodash';
import React from 'react';
import Modal from '../../Modal';

import Loader from '../../Loader';

import { connect } from 'react-redux';

import { 
    showModal, 
    hideModal,
    getStravaActivities,
    postStravaActivityToBuild,
} from '../../../actions';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import ConnectStrava from '../../profile/connectStrava';

const AddActivity = (
    {
        modal,
        hideModal,
        stravaId,
        stravaActivities,
        getStravaActivities,
        postStravaActivityToBuild,
        buildId,
        buildActivity,
        athlete,
    }) => {

    const [mounted, setMounted] = React.useState(false);

    React.useEffect(() => {
        // on mount and upon sign-in verification, get athletes
        if (!mounted && stravaId && modal.visible && modal.modalType === 'ADD_ACTIVITY') {
            setMounted(true);
            getStravaActivities();
        }
    }, [getStravaActivities, mounted, stravaId, modal]);

    const handleAddActivity = (activityId) => {

        // get strava activitty
        const activity = stravaActivities.find(activity => activity.id === activityId);

        postStravaActivityToBuild(buildId, activity)

    }

    const convertMetersToMiles = (meters) => {
        return (meters * 0.000621371192).toFixed(2);
    }


    const renderActivities = () => {
        if (!_.isEmpty(stravaActivities) && !stravaActivities?.error) {
            return stravaActivities.map(activity => {
                // check if activity is already added to buildActivity
                const isAdded = buildActivity.find(buildActivity => buildActivity.id === activity.id);
                return (
                    <div 
                        key={activity.id} 
                        className="flex flex-row justify-between items-center border-b border-gray-200 py-2"
                        >
                        <div className="flex flex-row items-center justify-center">
                            <div>
                                <div className="text-base font-semibold">{activity.name}</div>
                                <div className="text-sm text-gray-500">{convertMetersToMiles(activity.distance)} miles</div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-center">
                            {!isAdded ? <button 
                                onClick={() => handleAddActivity(activity.id)}
                                className="btn btn-primary btn-sm"
                                >
                                Select
                            </button> : <CheckCircleIcon className="h-7 w-7 mr-7 text-yellow-500" />}
                        </div>
                    </div>
                )
            })
        } else if (stravaActivities?.error) {
            return (
                <div className="text-sm text-gray-500 mb-4">
                    <div className="mb-2">There was an error retrieving your Strava activities</div>
                    <div className="mb-2">Please try disconnecting and reconnecting your Strava account</div>
                    <ConnectStrava athlete={athlete} />
                </div>)
        } else {
            return <Loader type="placeholderList" />
        }
    }

    const renderContent = () => {
        return (
            <div className="flex flex-col">
                <div className="flex flex-row justify-between items-center mb-4">
                    <div className="text-xl font-semibold">Add Activity</div>
                    <button
                        onClick={() => hideModal()}
                        className="text-sm text-gray-500 hover:text-gray-700"
                        >
                        <XMarkIcon className="h-8 w-8" />
                    </button>
                </div>
                <div className="text-sm text-gray-500 mb-4">Select a public activity recorded on Strava to add to this build</div>
                {stravaId ? renderActivities() : (
                    <>
                        <div className="text-sm text-gray-500 mb-4">You must connect your Strava account to add activities</div>
                        <ConnectStrava athlete={athlete} />
                    </>
                )}
            </div>
            )
    }

    if (!modal.visible || modal.modalType !== 'ADD_ACTIVITY') {
        return null;
    }

    return (
        <Modal
            onDismiss={() => hideModal()}
            content={renderContent()}
        />
    );

}

const mapStateToProps = (state) => {

    const athlete = (
        state.auth.profile 
        && state.auth.athlete 
        && state.auth.profile._id === state.auth.athlete
    ) && state.auth.profile;

    return {
        modal: state.modal,
        stravaActivities: state.strava.activities,
        stravaId: state.auth?.stravaId,
        athlete,
    }
}

export default connect(
    mapStateToProps,
    {
        showModal,
        hideModal,
        getStravaActivities,
        postStravaActivityToBuild,
    }
)(AddActivity);

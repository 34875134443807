
import { Routes, Route } from "react-router-dom";
import Login from "./login";
import EmailSent from "./email";
import NoAccount from "./noaccount";
import AcceptInvite from "./invite";

import RecordEvent from "../analytics/pageview";

import Identity from "../identity";

function Auth() {
  return (
    <div className='h-screen flex flex-col items-center justify-center p-5 gap-4 dark:text-neutral bg-gradient-to-b from-yellow-300 to-yellow-200'>
        <Identity noDark/>
        <Routes basename="/login">
            <Route path="/" element={<Login />} />
            <Route path="/email_sent" element={<EmailSent />} />
            <Route path="/no_account" element={<NoAccount />} />
            <Route path="/invite/:inviteToken" element={<AcceptInvite />} />
        </Routes>
        <RecordEvent hitType='pageview' page={window.location.pathname} title='Onboarding - Login Routing' />
    </div>
    );
}

export default Auth;

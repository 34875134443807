import React from 'react';

// heroicon for default profile image
import { UserCircleIcon } from '@heroicons/react/24/solid';

const ProfileImage = ({athlete, className}) => {
    
    const opacity = athlete?.status !== 'verified' && athlete?.profileType !== 'athlete' ? 'opacity-20 grayscale' : '';
    const profileStyle = `${className} rounded-full ${opacity}`;

    if (!athlete || !athlete.profileImages || athlete.profileImages.length === 0) {
        return (<UserCircleIcon className={profileStyle} />);
    }
    const { REACT_APP_AWS_S3 } = process.env;
    const { imageFile } = athlete.profileImages[0];
    const imageUri = REACT_APP_AWS_S3 + '/' + imageFile;

    return (
        <div className="avatar bg-base-200 rounded-full">
            <div className={className}>
                <img src={imageUri} alt="profile" className={profileStyle} />
            </div>
        </div>
    );
}

export default ProfileImage;
import React from 'react';

import { connect } from 'react-redux';

import { Link, useParams } from 'react-router-dom';

import AddPostCollection from '../addCollection';

import postTypeCollectionDefinitions from '../../../../utils/postCollectionTypeDefinitions.json';

import { fixDate } from '../../../../utils/fixDate';

import {
  apeFetchManyPostCollections,
  apeFetchPostCollection,
  apeDeletePostCollection,
  apeDuplicatePostCollection,
  showModal,
  hideModal,
  addNotice,
} from '../../../../actions';

import {
  PlusCircleIcon,
  EllipsisVerticalIcon,
  LockClosedIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';
import history from '../../../../history';

const Collections = ({
  postCollections,
  fetchPostCollections,
  deletePostCollection,
  duplicatePostCollection,
  showModal,
  hideModal,
  addNotice,
  loading,
}) => {
  const { collectionType } = useParams();
  // if collectionType is not defined, redirect to the first collection type
  if (!collectionType) {
    history.push(
      `/ape-admin/posts/${postTypeCollectionDefinitions[0].type_value}`
    );
  }

  const [selectedPostCollection, setSelectedPostCollection] =
    React.useState(null);

  React.useEffect(() => {
    if (!collectionType) {
      return;
    }
    fetchPostCollections(collectionType);
  }, [fetchPostCollections, collectionType]);

  if (!postCollections || postCollections.length === 0) {
    return null;
  }
  const upcomingCollections = postCollections
    .filter((collection) => !collection.locked)
    .sort((a, b) => new Date(a.publishedAt) - new Date(b.publishedAt));
  // const  upcomingCollections.length > 0 ? upcomingCollections[0] : null;

  // mark the first collection as upNext
  postCollections.forEach((collection) => {
    if (
      upcomingCollections.length > 0 &&
      collection._id === upcomingCollections[0]._id
    ) {
      collection.upNext = true;
    }
  });

  const renderAddPostCollectionButton = () => {
    // for post type selected, get the collection definitions and render an add button
    return (
      <div className="flex flex-row justify-center items-center gap-2">
        {postTypeCollectionDefinitions.map((definition, i) => {
          if (definition.type_value !== collectionType) {
            return null;
          }
          return (
            <button
              key={i}
              className="btn btn-ghost btn-sm rounded-btn"
              onClick={() => {
                setSelectedPostCollection({
                  _id: '_isNew_',
                  type: definition.type_value,
                });
                showModal('APE_EDIT_POST_COLLECTION');
              }}
            >
              <PlusCircleIcon className="h-5 w-5" />
              <span className="ml-1">Add {definition.type_name}</span>
            </button>
          );
        })}
      </div>
    );
  };

  // create a menu that allows the user to toggle between different post collections types
  const renderMenu = () => {
    return (
      <div className="flex flex-row justify-center items-center gap-2">
        {postTypeCollectionDefinitions.map((definition, i) => {
          return (
            <Link
              key={i}
              className={`btn btn-sm rounded-btn ${
                collectionType === definition.type_value
                  ? `btn-primary`
                  : `btn-ghost`
              }`}
              to={`/ape-admin/posts/${definition.type_value}`}
            >
              {definition.type_name}
            </Link>
          );
        })}
      </div>
    );
  };

  const renderPCItemMenu = (postCollection) => {
    const locked = postCollection?.locked;
    return (
      <div className="flex flex-col items-center">
        <div className="dropdown dropdown-bottom dropdown-end hover:bg-gray-200 rounded-lg z-100 relative">
          <label tabIndex={0} className="cursor-pointer ">
            <EllipsisVerticalIcon className="h-5 w-5" />
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content z-40 menu p-2 shadow bg-base-100 rounded-box w-48 relative"
          >
            <li>
              {locked ? (
                <div className="text-secondary">
                  Edit
                  <LockClosedIcon className="h-4 w-4" />
                </div>
              ) : (
                <div
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedPostCollection(postCollection);
                    showModal('APE_EDIT_POST_COLLECTION');
                  }}
                >
                  Edit
                </div>
              )}
            </li>
            <li>
              <div
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  // are you sure?
                  window.confirm(
                    'Are you sure you want to duplicate this post collection?'
                  ) && duplicatePostCollection(postCollection._id);
                }}
              >
                Duplicate
              </div>
            </li>
            <li>
              <div
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  // are you sure?
                  window.confirm(
                    'Are you sure you want to delete this post?'
                  ) && deletePostCollection(postCollection._id);
                }}
              >
                Delete
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderPostCollectionTable = () => {
    return (
      <div className="flex flex-col w-screen items-center">
        <table className="container my-4 text-sm table-auto border-separate border-spacing-2">
          <thead>
            <tr className="text-left">
              <th className="table-cell">Title</th>
              <th className="hidden md:table-cell">Description</th>
              <th className="table-cell">Posts</th>
              {collectionType === 'email' && (
                <>
                  <th className="hidden md:table-cell">Delivered</th>
                  <th className="hidden md:table-cell">Opens</th>
                  <th className="hidden md:table-cell">Clicks</th>
                </>
              )}
              <th className="table-cell">Publish Date</th>
            </tr>
          </thead>
          <tbody>
            {postCollections.map((postCollection) => {
              const delivered =
                postCollection.deliveryData?.delivered > 0
                  ? postCollection.deliveryData?.delivered
                  : `—`;
              const unique_opens =
                postCollection.deliveryData?.unique_opens > 0
                  ? postCollection.deliveryData?.unique_opens
                  : `—`;
              const clicks =
                postCollection.deliveryData?.clicks > 0
                  ? postCollection.deliveryData?.clicks
                  : `—`;

              return (
                <tr
                  key={postCollection._id}
                  className="border-b border-gray-200 p-4"
                >
                  <td className="font-bold gap-2 items-center flow-root">
                    <Link
                      to={`/ape-admin/posts/edit/${postCollection._id}`}
                      className="inline-block"
                    >
                      {postCollection.title}
                    </Link>
                    {postCollection.upNext && collectionType === 'email' && (
                      <div className="badge badge-warning inline-block text-xs uppercase my-1 whitespace-nowrap mx-1">
                        Up Next
                      </div>
                    )}
                    {postCollection.locked && (
                      <CheckCircleIcon className="h-5 w-5 text-green-500 inline-block mx-1" />
                    )}
                  </td>
                  <td className="hidden md:table-cell">
                    {postCollection?.description}
                  </td>
                  <td>{postCollection?.postCount}</td>
                  {collectionType === 'email' && (
                    <td className="hidden md:table-cell">{delivered}</td>
                  )}
                  {collectionType === 'email' && (
                    <td className="hidden md:table-cell">{unique_opens}</td>
                  )}
                  {collectionType === 'email' && (
                    <td className="hidden md:table-cell">{clicks}</td>
                  )}
                  <td className="table-cell">
                    <div className="justify-between flex flex-row">
                      <div className="flex flex-col items-center gap-2 whitespace-nowrap">
                        {fixDate(postCollection.publishedAt)}
                      </div>
                      {renderPCItemMenu(postCollection)}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center gap-2">
      <h1 className="text-center">Post Collections</h1>
      {renderMenu()}
      {renderAddPostCollectionButton()}
      <div className="flex flex-col items-center justify-center">
        {loading?.postCollection && postCollections?.length === 0 ? (
          <div>Loading...</div>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <AddPostCollection
              postCollection={selectedPostCollection}
              onClose={() => setSelectedPostCollection(null)}
            />
            {renderPostCollectionTable()}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const postCollections = state.ape?.postCollections
    ? Object.values(state.ape.postCollections)
    : [];

  return {
    postCollections,
    loading: state.loader?.elements,
  };
};

export default connect(mapStateToProps, {
  fetchPostCollections: apeFetchManyPostCollections,
  fetchPostCollection: apeFetchPostCollection,
  deletePostCollection: apeDeletePostCollection,
  duplicatePostCollection: apeDuplicatePostCollection,
  showModal,
  hideModal,
  addNotice,
})(Collections);

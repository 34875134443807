import React from 'react';

const renderToggle = ({ input, label, meta, disabled, className }) => {
  const customClass = `toggle ${className}`;

  return (
    <div className="form-control w-full">
      <label className="label cursor-pointer" htmlFor={input.name}>
        {label}
        <input
          {...input}
          id={input.name}
          disabled={disabled}
          type="checkbox"
          className={customClass}
          onChange={(e) => input.onChange(e.target.checked)}
          checked={input.checked}
        />
      </label>
    </div>
  );
};

export default renderToggle;

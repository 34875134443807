import './forms.css';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import './CustomRichTextarea.css';

import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

function renderError({ error, touched }, label) {
  if (touched && error) {
    return (
      <>
        <div
          className={`absolute right-2 flex items-center text-red-500 ${
            label ? `top-8` : `top-2`
          }`}
        >
          <ExclamationTriangleIcon className="h-8 w-8 mr-1" />
        </div>
        <div className="text-white bg-red-400 w-fit flex-nowrap py-2 px-4 mt-2 rounded whitespace-nowrap">
          {error}
        </div>
      </>
    );
  }
}

const renderRichTextarea = ({ input, label, meta, placeholder, autoFocus }) => {
  const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
  return (
    <div className={`${className} min-h-40 relative`}>
      <label>{label}</label>
      <ReactQuill
        {...input}
        autoFocus={autoFocus}
        theme="bubble"
        onChange={(newValue, delta, source) => {
          if (source === 'user') {
            input.onChange(newValue);
          }
        }}
        //   placeholder text for darkmode
        className="h-32 border-none"
        placeholder={placeholder}
        onBlur={(range, source, quill) => {
          input.onBlur(quill.getHTML());
        }}
        modules={{
          toolbar: [
            ['bold', 'italic'],
            // [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
          ],
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: true,
          },
        }}
        formats={['bold', 'italic', 'list', 'bullet', 'blockquote', 'link']}
      />
      {renderError(meta, label)}
    </div>
  );
};

export default renderRichTextarea;

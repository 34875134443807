import React from 'react';
import './forms.css';

// import heroicon of back arrow
import {
  ExclamationTriangleIcon,
  CalendarIcon,
} from '@heroicons/react/24/solid';

// input is the destructured version of formProps.input

function renderError({ error, touched }, label) {
  if (touched && error) {
    return (
      <>
        <div
          className={`absolute right-4 flex items-center text-red-500 ${
            label ? `top-8` : `top-2`
          }`}
        >
          <ExclamationTriangleIcon className="h-8 w-8 mr-1" />
        </div>
        <div className="text-white bg-red-400 w-fit flex-nowrap py-2 px-4 mt-2 rounded whitespace-nowrap">
          {error}
        </div>
      </>
    );
  }
}

function renderCalIcon({ error, touched }) {
  if (!touched || !error) {
    return (
      <CalendarIcon
        className="h-8 w-8 absolute right-4 top-8"
        // onclick to focus on the input
        onClick={(e) => {
          e.target.previousSibling.focus();
        }}
      />
    );
  }
}

const renderDateInput = ({
  input,
  label,
  meta,
  disabled,
  autoFocus = false,
  // size = 'input',
  // className: customClass,
  onInput,
}) => {
  const className = `input input-bordered w-full  ${
    meta.error && meta.touched ? 'input-error' : ''
  }`;
  const divClassName = `relative w-full`;
  return (
    <div className={divClassName}>
      <label htmlFor={input.name}>{label}</label>
      <div className="flex flex-col items-center w-full">
        <input
          // onChange={formProps.input.onChange}
          // value={formProps.value}
          // is the same as
          {...input}
          id={input.name}
          autoComplete="off"
          disabled={disabled}
          className={className}
          autoFocus={autoFocus}
          onInput={onInput}
          type="date"
          // force safari to make the input full width
          style={{ width: '100%' }}
          onClick={(e) => e.target.focus()}
        />
      </div>
      {renderCalIcon(meta)}
      {renderError(meta, label)}
    </div>
  );
};

export default renderDateInput;

import './forms.css';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import Compressor from 'compressorjs';
import Veil from '../Veil';
import Loader from '../Loader';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import {
  EllipsisVerticalIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';

// import heroicon of back arrow
import {
  ExclamationTriangleIcon,
  TrashIcon,
  CameraIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
} from '@heroicons/react/24/solid';

function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <div className="relative cursor-default">
        <div
          className="btn btn-sm btn-square cursor-pointer absolute top-2 left-2 -space-x-5 flex flex-row z-10 touch-none"
          {...listeners}
        >
          <EllipsisVerticalIcon className="h-4 w-4" />
          <EllipsisVerticalIcon className="h-4 w-4" />
        </div>
        {props.children}
      </div>
    </div>
  );
}

const ImageUpload = ({
  input,
  label,
  meta,
  onChange,
  maxImages = 7,
  handleDeleteImage,
  desiredWidth = 2000,
  desiredHeight = 2000,
  labelStyle = '',
  previewSize = 'md',
  circular = false,
  submitting,
  desiredAspectRatio = null,
  verticalRatio = 4 / 5,
  horizontalRatio = 7 / 5,
  takeImageAspect = false,
  required = true, // if image is required, don't allow deletion of last image
  quality = 0.85,
}) => {
  const croppedThumbsClassName =
    previewSize === 'md'
      ? 'cropped_thumbnails relative w-36 h-36 aspect-square justify-center'
      : previewSize === 'sm'
      ? 'cropped_thumbnails relative w-36 h-36 aspect-square justify-center'
      : 'cropped_thumbnails relative w-36 h-36 aspect-square justify-center';

  const widthClassName =
    previewSize === 'md' ? 'w-36' : previewSize === 'sm' ? 'w-24' : 'w-36';

  // const heightClassName =
  //   previewSize === 'md' ? 'h-64' : previewSize === 'sm' ? 'h-32' : 'h-96';

  // const maxHeightClassName =
  //   previewSize === 'md'
  //     ? 'max-h-64'
  //     : previewSize === 'sm'
  //     ? 'max-h-32'
  //     : 'max-h-96';

  const [files, setFiles] = useState([]);

  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [aspect, setAspect] = React.useState(desiredAspectRatio);
  const [aspectOption, setAspectOption] = React.useState(4 / 3);
  const [fileQueue, setFileQueue] = React.useState([]);
  const [processing, setProcessing] = React.useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // get the files from the input if there are any on load
  useEffect(() => {
    if (input.value) {
      setFiles(input.value);
    }
    // reset zoom and cropped area when the input value changes
    setZoom(1);
    setCroppedArea(null);
  }, [input.value, desiredAspectRatio]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles) => {
      // merge the new files with the old files
      // const newFiles = [...files, ...acceptedFiles];

      // setFiles(newFiles);
      // input.onChange(newFiles);
      setFileQueue(acceptedFiles);
      // reset the processing count
      setProcessing(false);
      // setAspectOption to 3/4 for portrait images
      setAspect(1);
    },
    maxFiles: maxImages,
  });

  const deleteFile = (file) => {
    const newFiles = files.filter((f) => f.imageFile !== file.imageFile);
    setFiles(newFiles);
    input.onChange(newFiles);
    handleDeleteImage(file.imageFile);
  };

  const removeFile = (file) => {
    const newFiles = files.filter((f) => f.name !== file.name);
    const newFileQueue = fileQueue.filter((f) => f.name !== file.name);
    setFiles(newFiles);
    setFileQueue(newFileQueue);
    setProcessing(newFileQueue.length);
    input.onChange(newFiles);
  };

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = files.findIndex((f) => f.imageFile === active.id);
      const newIndex = files.findIndex((f) => f.imageFile === over.id);

      // set the imageOrder of the files to the new index
      const newFiles = arrayMove(files, oldIndex, newIndex).map((f, i) => {
        // preserve the blob file type
        if (f instanceof Blob) {
          // Create a new Blob instance with the same properties
          // const newBlob = new Blob([f], { type: f.type });
          const newBlob = new Blob([f], { type: f.type, ...f });
          // Preserve other properties as well
          for (const key in f) {
            if (f.hasOwnProperty(key)) {
              newBlob[key] = f[key];
            }
          }
          return newBlob;
        }
        return { ...f, imageOrder: i };
      });

      setFiles(newFiles);
      input.onChange(newFiles);
    }
  }

  const renderError = ({ error, touched }) => {
    if (touched && error) {
      return (
        <div className="flex">
          <div className="text-red-500 px-2">
            <ExclamationTriangleIcon className="h-8 w-8 mt-3" />
          </div>
          <div className="text-white bg-red-400 w-fit flex-nowrap py-2 px-4 mt-2 rounded whitespace-nowrap">
            {error}
          </div>
        </div>
      );
    }
  };

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const createImage = (url) =>
    new Promise(async (resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => {
        resolve(image);
      });
      image.addEventListener('error', reject);
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues
      image.src = url;
    });

  const getCroppedImg = async (
    imageSrc,
    pixelCrop,
    rotation = 0,
    imageName
  ) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea - 2;
    canvas.height = safeArea - 2;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate((rotation * Math.PI) / 180);
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image?.width * 0.5 - 1,
      safeArea / 2 - image?.height * 0.5 - 1
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width - 1;
    canvas.height = pixelCrop.height - 1;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
    );

    let file = await new Promise((resolve, reject) => {
      canvas.toBlob(
        async (file) => {
          file = new Compressor(file, {
            width: desiredWidth,
            height: desiredHeight,
            quality,
            success(result) {
              resolve(
                Object.assign(result, {
                  // _id: imageName,
                  imageFile: 'cropped_' + imageName,
                  name: 'cropped_' + imageName,
                  preview: URL.createObjectURL(result),
                  croppedImage: true,
                  imageOrder: 0,
                })
              );
            },
            error(err) {
              reject(err);
            },
          });
        },
        'image/jpeg',
        quality
      );
    });
    // add seconds delay to allow for image to load
    // await new Promise(resolve => setTimeout(resolve, 2000));

    return file;
  };

  // sort the files by imageOrder ascending from 0
  const sortedFiles =
    files &&
    files.sort((a, b) => {
      if (a.imageOrder && b.imageOrder) {
        return a.imageOrder - b.imageOrder;
      } else if (a.imageOrder) {
        return 1;
      } else if (b.imageOrder) {
        return -1;
      } else {
        return 0;
      }
    });

  const showDeleteButton = (required && files.length > 0) || !required;

  const thumbs =
    sortedFiles &&
    sortedFiles.map((file, index) => {
      if (file.croppedImage) {
        return (
          <SortableItem key={file.name} id={file.name}>
            <div key={file.name} className={croppedThumbsClassName}>
              {showDeleteButton && (
                <button
                  className="btn absolute top-2 right-2 btn-sm"
                  onClick={() => removeFile(file)}
                >
                  <TrashIcon className="h-4 w-4" />
                </button>
              )}
              {!submitting ? (
                <img
                  src={file.preview}
                  alt="new files"
                  className={
                    circular
                      ? 'rounded-full object-contain object-center'
                      : 'rounded-2xl object-contain object-center bg-gray-200 dark:bg-gray-900 animate-fade-in'
                  }
                  // Revoke data uri after image is loaded
                  // onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
              ) : (
                <Loader type="inline" />
              )}
            </div>
          </SortableItem>
        );
      } else if (file._id || (file.imageFile && !file.croppedImage)) {
        // show existing images on the build stored in AWS
        const { REACT_APP_AWS_S3 } = process.env;
        const imageUri = REACT_APP_AWS_S3 + '/' + file.imageFile;

        return (
          <SortableItem key={file.imageFile} id={file.imageFile}>
            <div key={imageUri} className={croppedThumbsClassName}>
              {showDeleteButton && (
                <button
                  className="btn absolute top-2 right-2 btn-sm"
                  onClick={() => deleteFile(file)}
                >
                  <TrashIcon className="h-4 w-4" />
                </button>
              )}
              {!submitting ? (
                <img
                  src={imageUri}
                  alt="existing file"
                  className={
                    circular
                      ? 'rounded-full object-contain object-center '
                      : 'rounded-2xl object-contain object-center bg-gray-200 dark:bg-gray-900 animate-fade-in'
                  }
                />
              ) : (
                <Loader type="inline" />
              )}
            </div>
          </SortableItem>
        );
      } else if (file.cropper && !file.croppedImage && fileQueue.length === 0) {
        return (
          <Veil key={file.name}>
            <div key={file.name} className="z-20">
              <Cropper
                image={file.cropper}
                crop={crop}
                zoom={zoom}
                aspect={aspect || aspectOption}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                onMediaLoaded={(mediaSize) => {
                  if (mediaSize.height >= mediaSize.width) {
                    setAspectOption(verticalRatio);
                  } else {
                    setAspectOption(horizontalRatio);
                  }
                }}
                className="cropperContainer"
              />
              <div className="reactEasyCrop_Controls">
                <div className="flex flex-row mb-14 gap-4">
                  <button
                    className="btn btn-warning shadow-lg"
                    disabled={submitting || !croppedArea}
                    onClick={async (event) => {
                      // on button click disable button and change text to loading
                      event.target.disabled = true;
                      event.target.innerText = 'saving...';

                      event.preventDefault();

                      const croppedImage = await getCroppedImg(
                        file.cropper,
                        croppedArea,
                        0,
                        file.name
                      );

                      // wait a few seconds to allow for image to load
                      // await new Promise(resolve => setTimeout(resolve, 1000));
                      const newFiles = [
                        ...files.filter((f) => f.name !== file.name),
                        croppedImage,
                      ];
                      // await new Promise(resolve => setTimeout(resolve, 1000));

                      setFiles(newFiles);
                      input.onChange(newFiles);
                    }}
                  >
                    Save
                  </button>
                  {!takeImageAspect && !desiredAspectRatio && (
                    <button
                      className="btn btn-accent shadow-lg"
                      onClick={() => {
                        if (aspect === 1) {
                          setAspect(aspectOption);
                        } else if (aspect !== 1) {
                          setAspect(1);
                        }
                      }}
                    >
                      {aspect === 1 ? (
                        <ArrowsPointingOutIcon className="h-6 w-6" />
                      ) : (
                        <ArrowsPointingInIcon className="h-6 w-6" />
                      )}
                    </button>
                  )}
                  {takeImageAspect && (
                    <button
                      className="btn btn-accent shadow-lg"
                      onClick={() => {
                        if (aspect === 1) {
                          // get the aspect ratio of the image
                          const image = new Image();
                          image.src = file.cropper;
                          image.onload = () => {
                            const width = image.width;
                            const height = image.height;
                            const ratio = width / height;
                            setAspect(ratio);
                          };
                        } else if (aspect !== 1) {
                          setAspect(1);
                        }
                      }}
                    >
                      {aspect === 1 ? (
                        <ArrowsPointingOutIcon className="h-6 w-6" />
                      ) : (
                        <ArrowsPointingInIcon className="h-6 w-6" />
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Veil>
        );
      }
      return <Loader key={index} active type="inline" />;
    });

  useEffect(() => {
    if (fileQueue && fileQueue.length > 0 && fileQueue.length !== processing) {
      setProcessing(fileQueue.length);

      fileQueue.forEach(async (file) => {
        if (!file.cropper && !file.croppedImage && !file._id) {
          const reader = new FileReader();
          reader.addEventListener('load', async () => {
            window.scrollTo(0, 0);

            // load new image into cropper
            const image = new Image();
            image.src = reader.result;
            image.onload = async () => {
              const width = image.width;
              const height = image.height;

              // if the loaded image is too large, resize it before loading into cropper
              if (image.width > desiredWidth || image.height > desiredHeight) {
                // resize image proportionally to max width or height of 4000
                const maxWidth = desiredWidth;
                const maxHeight = desiredHeight;
                let newWidth = width;
                let newHeight = height;
                if (width > height) {
                  if (width > maxWidth) {
                    newHeight *= maxWidth / width;
                    newWidth = maxWidth;
                  }
                } else {
                  if (height > maxHeight) {
                    newWidth *= maxHeight / height;
                    newHeight = maxHeight;
                  }
                }

                // resize image proportionally
                // make up file name with letters and numbers
                const imageName =
                  Math.random().toString(36).substring(2, 15) +
                  Math.random().toString(36).substring(2, 15);

                const resizedImage = await new Promise((resolve, reject) => {
                  new Compressor(file, {
                    width: newWidth,
                    height: newHeight,
                    quality,

                    success(result) {
                      const image = {
                        ...result,
                        cropper: URL.createObjectURL(result),
                        croppedImage: false,
                        name: imageName,
                      };
                      resolve(image);
                    },
                    error(err) {
                      reject(err);
                    },
                  });
                });
                // remove file from queue
                setFileQueue(fileQueue.filter((f) => f.name !== file.name));
                const newFiles = [
                  ...files.filter((f) => f.name !== file.name),
                  resizedImage,
                ];
                setFiles(newFiles);
                input.onChange(newFiles);
              } else {
                // resize image proportionally
                // make up file name with letters and numbers
                const imageName =
                  Math.random().toString(36).substring(2, 15) +
                  Math.random().toString(36).substring(2, 15);

                const prepImage = {
                  ...file,
                  cropper: URL.createObjectURL(file),
                  croppedImage: false,
                  name: imageName,
                };

                const newFiles = [
                  ...files.filter((f) => f.name !== file.name),
                  prepImage,
                ];

                // remove file from queue
                setFileQueue(fileQueue.filter((f) => f.name !== file.name));
                setFiles(newFiles);
                input.onChange(newFiles);

                // remove file from queue
                setFileQueue(fileQueue.filter((f) => f.name !== file.name));
              }
            };
          });
          reader.readAsDataURL(file);
        }
      });
    }
  }, [
    files,
    input,
    fileQueue,
    processing,
    quality,
    desiredWidth,
    desiredHeight,
  ]);

  // revoke data uris once the component is unmounted. Earlier and we get occasional broken images
  useEffect(() => {
    return () => {
      files && files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  return (
    <div>
      {renderError(meta)}
      <label className={labelStyle}>{label}</label>
      <div
        className={`grid grid-flow-col auto-cols-max items-center overflow-x-auto gap-4 w-full ${labelStyle}`}
      >
        {maxImages >= (files && files.length + 1) && (
          <div
            {...getRootProps({
              className: `dropzone p-5 ${widthClassName} h-[98%] flex  ${
                circular ? 'rounded-full' : 'rounded-2xl'
              } ${
                files?.length > 0
                  ? `scale-100 !w-24 !h-24`
                  : `scale-100 bg-gray-200 dark:bg-gray-800 aspect-square `
              } border border-gray-400 dark:border-gray-600 border-dashed border-solid items-center justify-center hover:cursor-pointer hover:bg-gray-400 dark:hover:bg-gray-700 transition-all duration-300 ease-in-out`,
            })}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the file here</p>
            ) : (
              <div
                className={`fixed w-full justify-center items-center text-center flex flex-col`}
              >
                {files?.length > 0 && (
                  <>
                    <PlusCircleIcon className="h-8 w-8 text-center" />
                  </>
                )}
                <div
                  className={`fixed w-full justify-center items-center text-center flex flex-col transition-transform duration-300  ${
                    files?.length > 0 ? `opacity-0` : `animate-fade-in `
                  }`}
                >
                  <CameraIcon className="h-7 w-7 text-center" />
                  {!circular && <span>Tap to select</span>}
                </div>
              </div>
            )}
          </div>
        )}
        <aside className="flex flex-wrap flex-row gap-4 items-center justify-center">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={sortedFiles.map((item) => item.imageFile)}
              strategy={horizontalListSortingStrategy}
            >
              {thumbs}
            </SortableContext>
          </DndContext>
        </aside>
      </div>
    </div>
  );
};

export default ImageUpload;

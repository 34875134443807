import './forms.css';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Field } from 'react-final-form';
import CreatableSelect from 'react-select/creatable';
// import heroicon of back arrow
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

const controlStyles = {
  base: 'border rounded-lg hover:cursor-pointer',
  focus: 'border-primary-600 ring-1 ring-primary-500',
  nonFocus: 'border-gray-300 hover:border-gray-400',
};
const placeholderStyles = 'text-gray-500 pl-1 py-0.5';
const selectInputStyles = 'pl-1 py-1 mt-0.5 mb-1';
const valueContainerStyles = 'p-1 gap-1';
const singleValueStyles = 'leading-7 ml-1 my-0.5';
const multiValueStyles =
  'bg-gray-100 dark:outline dark:outline-gray-700 dark:bg-gray-800 rounded items-center py-0.5 pl-2 pr-1 gap-1.5 capitalize';
const multiValueLabelStyles = 'leading-6 py-0.5';
const multiValueRemoveStyles =
  'border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md';
const indicatorsContainerStyles = 'p-1 gap-1';
const clearIndicatorStyles =
  'text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800';
const indicatorSeparatorStyles = 'bg-gray-300';
const dropdownIndicatorStyles =
  'p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black';
const menuStyles =
  'p-1 mt-2 border border-gray-200 bg-white rounded-lg z-20 shadow-md';
const groupHeadingStyles = 'ml-3 mt-2 mb-1 text-gray-500 text-sm';
const optionStyles = {
  base: 'hover:cursor-pointer px-3 py-2 rounded capitalize',
  focus: 'bg-gray-100 active:bg-gray-200',
  selected: "after:content-['✔'] after:ml-2 after:text-green-500 text-gray-500",
};
const noOptionsMessageStyles =
  'text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm';
const loadingMessageStyle = 'py-2 text-inherit';

// customer filter function to match all words in the string
const filterOption = (option, rawInput) => {
  // there is a data property on option

  // if empty string, return all options
  if (!rawInput) {
    return true;
  }
  // split input into words
  const words = rawInput.split(' ');
  // return true if all words are found in the option or termMatch property
  return words.every((word) =>
    option?.data?.termMatch
      ? option?.data?.termMatch.toLowerCase().includes(word.toLowerCase())
      : option.label.toLowerCase().includes(word.toLowerCase())
  );
};

const ReactSelectAdapter = ({
  input,
  onInputChange,
  defaultOptions,
  options,
  isLoading,
  isCreatable,
  isClearable,
  onFocus,
  isMulti,
  ...rest
}) => (
  <CreatableSelect
    {...input}
    {...rest}
    key={input.name}
    searchable
    filterOption={filterOption}
    isClearable={isClearable}
    defaultOptions={options}
    isLoading={isLoading}
    isMulti={isMulti}
    options={options}
    onInputChange={(value) => onInputChange(value)}
    classNamePrefix="react-select"
    // isValidNewOption is a function that returns true if input value has length > 0
    isValidNewOption={(inputValue) => inputValue.length > 0 && isCreatable}
    unstyled={true}
    onFocus={onFocus}
    styles={{
      input: (base) => ({
        ...base,
        'input:focus': {
          boxShadow: 'none',
        },
      }),
      // On mobile, the label will truncate automatically, so we want to
      // override that behaviour.
      multiValueLabel: (base) => ({
        ...base,
        whiteSpace: 'normal',
        overflow: 'visible',
      }),
      control: (base) => ({
        ...base,
        transition: 'none',
      }),
      option: (styles, { data }) => {
        return {
          ...styles,
          fontWeight: data.isFamily ? 'bold' : 'normal',
        };
      },
    }}
    classNames={{
      control: ({ isFocused }) =>
        clsx(
          isFocused ? controlStyles.focus : controlStyles.nonFocus,
          controlStyles.base
        ),
      placeholder: () => placeholderStyles,
      input: () => selectInputStyles,
      valueContainer: () => valueContainerStyles,
      singleValue: () => singleValueStyles,
      multiValue: () => multiValueStyles,
      multiValueLabel: () => multiValueLabelStyles,
      multiValueRemove: () => multiValueRemoveStyles,
      indicatorsContainer: () => indicatorsContainerStyles,
      clearIndicator: () => clearIndicatorStyles,
      indicatorSeparator: () => indicatorSeparatorStyles,
      dropdownIndicator: () => dropdownIndicatorStyles,
      menu: () => menuStyles,
      groupHeading: () => groupHeadingStyles,
      option: ({ isFocused, isSelected }) =>
        clsx(
          isFocused && optionStyles.focus,
          isSelected && optionStyles.selected,
          optionStyles.base
        ),
      noOptionsMessage: () => noOptionsMessageStyles,
      loadingMessage: () => loadingMessageStyle,
    }}
  />
);

// input is the destructured version of formProps.input
function renderError({ error, touched }) {
  if (touched && error) {
    return (
      <>
        <div className="absolute top-8 right-4 flex items-center text-red-500">
          <ExclamationTriangleIcon className="h-8 w-8 mr-8" />
        </div>
        <div className="text-white bg-red-400 w-fit flex-nowrap py-2 px-4 mt-2 rounded whitespace-nowrap">
          {error}
        </div>
      </>
    );
  }
}

// only set state if the value is different from the current state
const handleTermChange = (value, term, setTerm) => {
  if (value !== term) {
    setTerm(value);
  }
};
const RenderCustomSelect = ({
  input,
  label,
  meta,
  options = [],
  searchAction,
  isCreatable = 'false',
  isClearable = false,
  isMulti = false,
  placeholder,
  onChange,
  onFocus,
  className: classNameInput,
}) => {
  const [term, setTerm] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    // search term related to input item
    const search = () => searchAction(term, input.name);

    // debounce search
    if (term && term.length > 3 && !options.length && !inputValue === term) {
      search();
      setInputValue(term);
      setLoading(false);
    } else {
      const timeoutId = setTimeout(() => {
        // if term is not in options, search
        if (term && inputValue !== term) {
          search();
          setInputValue(term);
        }
        setLoading(false);
      }, 500);
      // react will run the return when component is re-reendered.
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [term, input, options, searchAction, inputValue]);

  // use effect to focus menu when results are loaded
  // could perhaps be removed
  // useEffect(() => {
  //   if (options.length > 0) {
  //     onFocus();
  //   }
  // }, [options, onFocus]);

  const className = `${meta.error && meta.touched ? 'input-error' : ''}`;
  return (
    <div className={`relative ${classNameInput}`}>
      <label>{label}</label>
      <Field
        {...input}
        style={{ height: '1.5em' }}
        autoComplete="off"
        component={ReactSelectAdapter}
        options={options}
        isLoading={loading}
        defaultValue={!isMulti && options[0]}
        onInputChange={(input) => handleTermChange(input, term, setTerm)}
        onChange={onChange || input.onChange}
        className={className}
        isCreatable={isCreatable}
        isClearable={isClearable}
        isMulti={isMulti}
        placeholder={placeholder}
        onFocus={() => onFocus && onFocus()}
      />
      {renderError(meta)}
    </div>
  );
};

export default RenderCustomSelect;

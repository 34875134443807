import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import Modal from '../../../Modal';

import { connect } from 'react-redux';

import { Form, Field } from 'react-final-form';
import CustomInput from '../../../forms/CustomInput';
import CustomSelectMulti from '../../../forms/CustomSelectMulti';

import {
  showModal,
  hideModal,
  addNotice,
  apeFetchDistributor,
  apeUpsertDistributor,
  searchMake,
} from '../../../../actions';

// check heroicon
import { XMarkIcon } from '@heroicons/react/24/outline';

const UpsertDistributorModal = ({
  modal,
  distributor,
  makes,
  distributorMakes,
  searchMake,
  hideModal,
  apeFetchDistributor,
  apeUpsertDistributor,
}) => {
  const [makeOptions, setMakeOptions] = useState(makes);
  const [mount, setMount] = useState(false);

  useEffect(() => {
    setMakeOptions(makes);
    setMount(true);
  }, [makes, distributor, mount, apeFetchDistributor]);

  const handleUpsertDistributor = async (formValues) => {
    console.log('formValues', formValues);
    return await apeUpsertDistributor(formValues);
  };

  if (!mount) {
    return null;
  }

  const renderForm = () => {
    const initialValues = {
      ...distributor,
      makes: distributorMakes,
    };
    return (
      <Form
        onSubmit={handleUpsertDistributor}
        initialValues={initialValues}
        initialValuesEqual={() => true} // prevent reinitializing form on every render
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = 'A name is required';
          }
          if (values.makes && values.makes.length === 0) {
            errors.makes = 'At least one make is required';
          }
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col justify-between items-center w-full gap-2">
              <Field
                name="name"
                component={CustomInput}
                placeholder="QBP"
                label="Distributor's name"
                className="w-full"
              />
              <div className="flex flex-col w-full">
                <Field
                  name="makes"
                  component={CustomSelectMulti}
                  label="Makes"
                  options={makeOptions}
                  defaultOptions={distributorMakes}
                  searchAction={searchMake}
                  placeholder="Search makes"
                  isMulti
                  isCreatable={false}
                />
              </div>

              <div className="flex flex-row justify-between items-center w-full gap-2">
                <button
                  className="btn btn-ghost btn-sm"
                  onClick={() => hideModal()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={submitting}
                  className="btn btn-primary btn-sm"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        )}
      />
    );
  };

  const renderContent = (props) => {
    return (
      <div className="flex flex-col min-h-screen">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <div className="text-xl font-bold">Add/Update Distributor</div>
            </div>
            <div className="flex flex-row justify-end">
              <button
                className="btn btn-square btn-ghost btn-sm"
                onClick={() => hideModal()}
              >
                <XMarkIcon className="h-6 w-6 text-gray-500" />
              </button>
            </div>
          </div>
          <div className="flex flex-col mt-4">
            <div className="flex flex-col mt-2">{renderForm()}</div>
          </div>
        </div>
      </div>
    );
  };

  if (!modal.visible || modal.modalType !== 'APE_ADD_DISTRIBUTOR') {
    return null;
  }

  return <Modal content={renderContent()} onDismiss={() => hideModal()} />;
};

const mapStateToProps = (state, ownProps) => {
  const isSignedIn = state.auth?.userId;
  const distributorId = ownProps?.distributorId;

  // get the current distributor
  const distributor =
    (state?.ape?.distributors && state.ape.distributors[distributorId]) || {};

  const makesResults = state?.search?.makes
    ? _.compact(
        Object.values(state.search.makes).map(
          (make) =>
            make?.name &&
            make?._id && {
              label: make.name,
              value: make?._id,
            }
        )
      )
    : [];

  // get athlete makes only if they exist and are not empty
  const distributorMakes =
    distributor?.makes && !_.some(distributor?.makes, (make) => _.isEmpty(make))
      ? distributor.makes.map((make) => {
          return {
            label: make._make?.name,
            value: make._make?._id,
          };
        })
      : [];
  const makes = _.uniqBy([...makesResults, ...distributorMakes], 'value');

  return {
    isSignedIn,
    athlete: state.auth.profile,
    distributor,
    distributorMakes,
    makes,
    modal: state.modal,
  };
};

export default connect(mapStateToProps, {
  apeFetchDistributor,
  apeUpsertDistributor,
  searchMake,
  showModal,
  hideModal,
  addNotice,
})(UpsertDistributorModal);

const fixDate = (date) => {
  const newDate = new Date(date.replace(/-/g, '/').replace(/T.+/, ''));

  return newDate.toDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

module.exports = {
  fixDate,
};

import React from 'react';
import { Helmet } from 'react-helmet';

const CustomHelmet = ({
  title = 'Active Projects',
  description = 'Equipping modern athletes with the gear and connections they need to succeed.',
  image = '',
  url = process.env.REACT_APP_URL,
}) => {
  const descInPlainText = description.replace(/(<([^>]+)>)/gi, '');
  const shortDescription = descInPlainText.substring(0, 160);

  const { REACT_APP_AWS_S3 } = process.env;
  const imageUri = image ? REACT_APP_AWS_S3 + '/' + image : '';

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={shortDescription} />
      <meta name="image" content={imageUri} />
      <link rel="canonical" href={url} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={shortDescription} />
      <meta property="og:image" content={imageUri} />
    </Helmet>
  );
};

export default CustomHelmet;

import React from 'react';
import DOMPurify from 'dompurify';

const PostHeadline = ({ children, className }) => {
  const cleanChildren = DOMPurify.sanitize(children, {
    ALLOWED_TAGS: ['b', 'i', 'em', 'strong'],
  });

  // replace <p></p> tags with <div></div> tags
  const replacePTags = cleanChildren
    .replace(/<p>/g, '<div>')
    .replace(/<\/p>/g, '</div>');

  return (
    <div
      className={`col-span-full flex flex-col w-full text-balance ${
        className ? className : 'text-center pb-1 px-4 text-3xl'
      }`}
      dangerouslySetInnerHTML={{ __html: replacePTags }}
    />
  );
};

export default PostHeadline;

import './index.css';

import React from 'react';

import ConnectStrava from '../profile/connectStrava';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import { showModal } from '../../actions';

import { XMarkIcon } from '@heroicons/react/24/outline';

const Drawer = ({ showModal, auth, isAdmin, isIdentity, isCompany }) => {
  const onProfileChangeClick = () => {
    showModal('SWITCH_ATHLETE');
  };

  const closeDrawer = () => {
    document.getElementById('app-drawer').checked = false;
  };

  const profileLink = auth?.profile?.alias
    ? `/${auth.profile.alias}`
    : `/athlete/${auth?.profile?._id}`;

  return (
    <div className="drawer-side z-30">
      <label
        htmlFor="app-drawer"
        aria-label="close sidebar"
        className="drawer-overlay h-screen"
      ></label>
      <ul className="menu p-4 w-64 bg-base-100 min-h-full text-base-content sm:pt-4 z-100 flex flex-col">
        <li className="my-0" onClick={closeDrawer}>
          <XMarkIcon className="h-14 w-14" />
        </li>
        <li className="text-xl font-bold text-left">
          <Link to={profileLink} onClick={closeDrawer}>
            {auth?.profile?.name}
          </Link>
        </li>

        {isCompany ? (
          <>
            <li>
              <Link to={profileLink} onClick={closeDrawer}>
                Our Builds
              </Link>
            </li>
            <li>
              <Link to={`${profileLink}/lists`} onClick={closeDrawer}>
                Our Lists
              </Link>
            </li>
            <li>
              <Link to={`/profile/dash/`} onClick={closeDrawer}>
                Manage Profile
              </Link>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link to={profileLink} onClick={closeDrawer}>
                My Builds
              </Link>
            </li>
            <li>
              <Link to={`${profileLink}/lists`} onClick={closeDrawer}>
                My Lists
              </Link>
            </li>
            <li>
              <Link to="/profile/edit/" onClick={closeDrawer}>
                Edit Profile
              </Link>
            </li>
          </>
        )}
        <li>
          <div onClick={onProfileChangeClick} className="font-semibold">
            Switch Profile
          </div>
        </li>
        {isAdmin && <span className="divider">Admin</span>}
        {isAdmin && (
          <li>
            <Link
              to="/ape-admin/users"
              onClick={closeDrawer}
              className="font-semibold"
            >
              Users
            </Link>
            <Link
              to="/ape-admin/items"
              onClick={closeDrawer}
              className="font-semibold"
            >
              Items
            </Link>
            <Link
              to="/ape-admin/posts"
              onClick={closeDrawer}
              className="font-semibold"
            >
              Posts
            </Link>
            <Link
              to="/ape-admin/stats"
              onClick={closeDrawer}
              className="font-semibold"
            >
              Stats
            </Link>
          </li>
        )}
        {isAdmin && <span className="divider"></span>}
        {isIdentity ? (
          <>
            <li>
              <ConnectStrava athlete={auth?.profile} className="btn-sm" />
            </li>
            <span className="divider"></span>
          </>
        ) : null}
        <li>
          <a href="/api/logout" className="font-semibold">
            Log out
          </a>
        </li>
        <li className="text-left w-full mt-auto mb-14 sm:mb-0">
          <div onClick={() => showModal('SUPPORT')}>Support</div>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  const isAdmin = state?.auth?.permissions?.admin;

  // ise the current user the identity of the profile
  const isIdentity = state?.auth?.profile?.users?.some(
    (user) => user.identity && user?._userId?._id === state?.auth?.userId
  );

  // is the current user a company
  const isCompany = state?.auth?.profile?.profileType === 'company';

  // console.log('isCompany', isCompany);

  return {
    auth: state.auth,
    isAdmin,
    isIdentity,
    isCompany,
  };
};

export default connect(mapStateToProps, { showModal })(Drawer);

import React from 'react';

import { HeartIcon as EmptyHeart } from '@heroicons/react/24/outline';
import { HeartIcon as SolidHeart } from '@heroicons/react/24/solid';

import { connect } from 'react-redux';
import { likeBuild, likeBuildItem } from '../../../actions';

const Heart = ({build, item, liked, likeBuild, likeBuildItem, isSignedIn}) => {

    const [loading, setLoading] = React.useState(false);

    const onHeartClick = async (build, item) => {
        const buildId = build ? build._id : null;
        const itemId = item ? item._id : null;
        isSignedIn && setLoading(true);
        build && !item && likeBuild(buildId);
        item && build && likeBuildItem(buildId, itemId);
    }

    React.useEffect(() => {
        setLoading(false);
    }, [liked]);

    return (
        !loading ?        
        (<button onClick={() => onHeartClick(build, item)} className="btn btn-square btn-ghost btn-sm">
            {liked ? (<SolidHeart className="h-6 w-6 text-red-600" />) : (<EmptyHeart className="h-6 w-6" />)}
        </button>)
        :
        (<button className="btn btn-square btn-ghost btn-sm">
            <svg className="animate-spin h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
            </svg>
        </button>)
    );
}

const mapStateToProps = (state, ownProps) => {
    const athlete = state.auth.athlete;
    const build = ownProps.build;
    const item = ownProps.item;

    const isSignedIn = state.auth?.userId;

    // is the build item liked by the current user; if just a build is passed, check if build is liked
    const liked = build && build.items && item ? build.items.find(i => i._id === item._id)._likes?.find(a => a._athlete === athlete) : build && !item ? build._likes?.find(a => a._athlete === athlete) : null;

    return { 
        liked,
        isSignedIn
    };
}

export default connect(mapStateToProps, { likeBuild, likeBuildItem })(Heart);
import React from 'react';

const Page = ({ children }) => {
  return (
    <>
      <div className="bg-amber-100 dark:bg-amber-900 text-base-content min-h-screen flex-1">
        <div id="scroll-surface" className="py-5 sm:px-10 lg:pt-5 space-y-2">
          {children}
        </div>
      </div>
      {/* Fade from bg-amber-100 to base bg */}
      <div className="w-full h-20 bg-gradient-to-b from-amber-100 to-base-100 dark:from-amber-900 dark:to-base-900 pointer-events-none z-50"></div>
    </>
  );
};

export default Page;

import React from 'react';
import DOMPurify from 'dompurify';

import './ReadMore.css';

const ReadMore = ({ children, maxCharacterCount = 100, className }) => {
  const cleanChildren = DOMPurify.sanitize(children, {
    ALLOWED_TAGS: [
      'b',
      'i',
      'em',
      'strong',
      'br',
      'ul',
      'li',
      'ol',
      'blockquote',
      'span',
    ],
  });

  // const text = cleanChildren;
  const [isTruncated, setIsTruncated] = React.useState(true);

  function toggleIsTruncated() {
    setIsTruncated(!isTruncated);
  }

  // if text is short enough, don't show the "read more" link
  if (!children || children.length <= maxCharacterCount) {
    return (
      <div className={`text-base-content read-more text-sm ${className}`}>
        <div dangerouslySetInnerHTML={{ __html: cleanChildren }} />
      </div>
    );
  }

  return (
    <div className={`text-base-content read-more text-sm ${className}`}>
      <div
        className={!isTruncated ? `line-camp-none` : `line-clamp-2`}
        dangerouslySetInnerHTML={{ __html: cleanChildren }}
      />
      {isTruncated ? (
        <div
          onClick={toggleIsTruncated}
          className={`cursor-pointer right-0 end-0 inline-flex text-secondary`}
        >
          {isTruncated ? 'Read More +' : ''}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ReadMore;

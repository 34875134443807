import React from 'react';
import DOMPurify from 'dompurify';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

const PostProgress = ({ post, step, isComplete, isAdmin }) => {
  const { description } = post;

  if (!step) {
    return null;
  }

  if (isComplete) {
    if (isAdmin) {
      return (
        <div className="flex flex-col w-full items-center col-span-full gap-4">
          <p>progress bar - hidden when complete</p>
        </div>
      );
    }
    return null;
  }

  // strip all html tags from description

  // strip all html tags from description
  const strippedDescription = DOMPurify.sanitize(description, {
    ALLOWED_TAGS: [],
  });

  return (
    <div className="flex flex-col w-full items-center col-span-full gap-4">
      <h3 className="text-2xl text-center">{strippedDescription}</h3>
      <ul className="steps sm:steps-horizontal steps-vertical">
        <li
          data-content={step.profile ? '✓' : '●'}
          className={`step ${step.profile ? 'step-brand' : 'step-primary'}`}
        >
          <Link to="/profile/edit">Create Profile</Link>
        </li>
        <li
          data-content={step.profileImage ? '✓' : '●'}
          className={`step ${
            step.profileImage ? 'step-brand' : 'step-primary'
          }`}
        >
          <Link to="/profile/edit">Add Profile Photo</Link>
        </li>
        <li
          data-content={step.build ? '✓' : '●'}
          className={`step ${step.build ? 'step-brand' : 'step-primary'}`}
        >
          <Link to="/build/edit">Add Build</Link>
        </li>
        <li
          data-content={step.buildItems ? '✓' : '●'}
          className={`step ${step.buildItems ? 'step-brand' : 'step-primary'}`}
        >
          Add Item to Build
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  let step = {
    profile: 0,
    profileImage: 0,
    build: 0,
    buildItems: 0,
  };

  if (!state?.auth?.profile?.step) {
    return step;
  }

  // are all steps completed?
  const isComplete = Object.values(state.auth.profile.step).every(
    (value) => value
  );

  // isAdmin
  const isAdmin = state?.auth?.permissions?.admin;

  return {
    step: state.auth.profile.step,
    isComplete,
    isAdmin,
  };
};

export default connect(mapStateToProps)(PostProgress);

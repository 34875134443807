import React from 'react';
import { Field, Form } from 'react-final-form';
import renderInput from '../../forms/CustomInput';

import { connect } from 'react-redux';
import { loginEmail, logOut } from '../../../actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faStrava } from '@fortawesome/free-brands-svg-icons';

import { Link } from 'react-router-dom';
import ProfileImage from '../../athlete/image';

import validateEmail from '../../../utils/validateEmail';

const Login = (props) => {
  const [signinMethod, setSigninMethod] = React.useState('oauth');

  const onSubmit = (formValues) => {
    props.loginEmail(formValues);
  };

  const handleSigninMethodToggle = (e) => {
    e.preventDefault();
    setSigninMethod(signinMethod === 'oauth' ? 'email' : 'oauth');
  };

  const renderMagicLinkForm = () => {
    return (
      <>
        <div className="flex flex-col items-center justify-center gap-2 sm:w-8/12">
          <div>Enter your email address to receive a link to sign-in.</div>
          <Form
            onSubmit={onSubmit}
            validate={(formValues) => {
              const errors = {};
              // validate email address
              if (!formValues.email || !validateEmail(formValues.email)) {
                errors.email = 'Please enter an email address';
              }
              return errors;
            }}
            render={({ handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col w-full max-w-lg"
              >
                <div className="flex sm:flex-row flex-col gap-2 items-start">
                  <Field
                    name="email"
                    component={renderInput}
                    placeholder="Enter your email address"
                    type="email"
                    className="dark:bg-white dark:text-black w-grow"
                  />
                  <button
                    className="btn btn-primary sm:shrink-0 w-full sm:w-auto"
                    type="submit"
                  >
                    Sign-in
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </>
    );
  };

  const renderOauthButtons = () => {
    return (
      <>
        <div className="flex flex-col sm:flex-row items-center justify-center gap-4 w-64">
          <a
            className="btn btn-primary flex-nowrap sm:w-fit w-full"
            href="/auth/google"
          >
            <FontAwesomeIcon icon={faGoogle} className="mr-2" /> Google
          </a>
          <a
            className="btn btn-primary flex-nowrap sm:w-fit w-full"
            href="/auth/strava"
          >
            <FontAwesomeIcon icon={faStrava} className="mr-2 w-5 h-5" /> Strava
          </a>
        </div>
      </>
    );
  };

  const profileLink = props.athlete?.alias
    ? `/${props.athlete?.alias}`
    : `/athlete/${props.athlete?.id}`;

  if (props.isSignedIn) {
    return (
      <div className="flex flex-col sm:flex-row items-center justify-center gap-4 w-64">
        <a className="btn btn-primary flex-nowrap" href="/api/logout">
          Log Out
        </a>
        <Link to={profileLink} className="btn btn-ghost">
          <ProfileImage
            athlete={props.athlete}
            className="w-10 h-10 mr-0 cursor-pointer m-0 p-0 self-center"
          />
          Return to Profile
        </Link>
      </div>
    );
  }

  return (
    <>
      {props.children ? (
        props.children
      ) : (
        <>
          <h1 className="text-4xl font-bold">Sign in</h1>
          <div className="text-center">
            Welcome! Login or request access by using your Google or Strava
            account.
          </div>
        </>
      )}
      {signinMethod === 'email' ? renderMagicLinkForm() : renderOauthButtons()}
      <div className="flex flex-col sm:flex-row items-center justify-center gap-4 w-64">
        <Link
          className="underline underline-offset-8 backdrop-blur-sm"
          onClick={handleSigninMethodToggle}
        >
          {signinMethod === 'oauth'
            ? 'Sign-in with your email'
            : 'Sign-in by another method'}
        </Link>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    isSignedIn: state.auth.userId && state.auth.permissions?.user,
    athlete: state.auth.profile,
  };
};

export default connect(mapStateToProps, { loginEmail, logOut })(Login);

import React from 'react';

const Build = ({ children }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="sm:px-0 w-screen sm:w-full flex flex-wrap lg:flex-row lg:mt-4 lg:items-start justify-between col-span-2 lg:col-span-3 gap-2">
        {children}
      </div>
    </div>
  );
};

export default Build;

import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Page from '../ui/Page';
import EditItem from './edit';
import ViewItem from './view';

import Header from '../header';

const ItemRouter = ({ lists }) => {
  return (
    <Page>
      <Header />
      <Routes basename="/item">
        <Route path="/:id" element={<ViewItem lists={lists} />} />
        <Route path="/edit/:id" element={<EditItem />} />
      </Routes>
    </Page>
  );
};

export default ItemRouter;

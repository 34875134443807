import React from 'react';
import { Link } from 'react-router-dom';

import ItemCard from '../../items/card';
import BuildListCard from '../../builds/listCard';
import AthleteLine from '../../athlete/line';

const ListCards = ({ lists, showProfiles, twoColumn }) => {
  return (
    <div
      className={`grid ${
        twoColumn ? `sm:grid-cols-2` : `sm:grid-cols-3`
      } grid-flow-row gap-0 my-0 sm:gap-8 items-end`}
    >
      {lists.map((list, index) => {
        // merge athlete and profiles
        const profiles =
          list?.profiles &&
          Array.isArray(list.profiles) &&
          list?.profiles?.length > 0
            ? [
                list._athlete,
                ...list.profiles.map((profile) => profile._profile),
              ]
            : list._athlete;

        return (
          <div className="w-full py-2 relative overflow-hidden" key={index}>
            <Link to={`/list/${list._id}`} state={{ backable: true }}>
              {list.items && list.items.length ? (
                <div className="flex items-end w-full relative -space-x-12 sm:-space-x-20">
                  {list.items.map((item, i) => {
                    // only show the first 8 items
                    if (list.items.indexOf(item) > 5) {
                      return null;
                    }
                    const zArray = [
                      'z-10',
                      '-z-10',
                      '-z-20',
                      '-z-40',
                      '-z-50',
                      '-z-60',
                      '-z-70',
                      '-z-80',
                    ];
                    const setZIndex = zArray[i];
                    const customItemClass = `w-[130px] ${setZIndex}`;
                    const customBuildClass = `w-[145px] shadow ${setZIndex}`;

                    const { _id, _item, _build } = item;

                    if (_item) {
                      return (
                        <div
                          className="w-fit relative"
                          // set left border shade on 2nd and more items in the list to create a stack effect
                          style={{
                            boxShadow: `-1px 0 0px 0px rgba(0, 0, 0, 0.1)`,
                          }}
                          key={_id || i}
                        >
                          <ItemCard
                            key={_id || i}
                            item={_item}
                            customClass={customItemClass}
                            imageOnly
                          />
                        </div>
                      );
                    }

                    if (_build) {
                      return (
                        <BuildListCard
                          key={_id || i}
                          build={_build}
                          customClass={customBuildClass}
                          imageOnly
                        />
                      );
                    }

                    return null;
                  })}
                </div>
              ) : (
                <p>No items in this list.</p>
              )}
              <div className="-space-y-1 mt-2 px-2 sm:px-0">
                <h2>{list.name}</h2>
                {showProfiles && (
                  <AthleteLine
                    athlete={list._athlete}
                    profiles={profiles}
                    noAvatar
                    noLink
                  />
                )}
                {!showProfiles && (
                  <div className="text-gray-500">{list.itemCount} items</div>
                )}
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default ListCards;

const parseLinks = (text) => {
  // regex to find urls
  // const urlRegex = /(https?:\/\/[^\s]+)/g;
  const urlRegex = /((https?:\/\/)?[^\s]+\.[^\s]+)/g;
  // split text into array of strings and urls
  const textArray = text.split(urlRegex);

  // map over array and return text or link
  return textArray.map((item, index) => {
    if (item?.match(urlRegex)) {
      // get domain name excluding www
      const domain = item
        .replace('https://', '')
        .replace('http://', '')
        .replace('www.', '')
        .split(/[/?#]/)[0];
      // add http prefix if missing http or https
      const href = item.startsWith('http') ? item : `http://${item}`;

      return (
        <a
          key={index}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-500 hover:underline"
        >
          {domain}
        </a>
      );
    }
    return null;
  });
};

export default parseLinks;

import { combineReducers } from 'redux';
import authReducer from './authReducer';
import athleteReducer from './athleteReducer';
import buildsReducer from './buildsReducer';
import itemsReducer from './itemsReducer';
import listsReducer from './listsReducer';
import postsReducer from './postsReducer';
import searchReducer from './searchReducer';
import loaderReducer from './loaderReducer';
import noticeReducer from './noticeReducer';
import modalReducer from './modalReducer';
import notificationsReducer from './notificationsReducer';
import stravaReducer from './stravaReducer';
import apeReducer from './apeReducer';
import swReducer from './swReducer';

export default combineReducers({
  auth: authReducer,
  athlete: athleteReducer,
  builds: buildsReducer,
  items: itemsReducer,
  lists: listsReducer,
  posts: postsReducer,
  search: searchReducer,
  loader: loaderReducer,
  notice: noticeReducer,
  notifications: notificationsReducer,
  modal: modalReducer,
  strava: stravaReducer,
  ape: apeReducer,
  sw: swReducer,
});

import React from 'react';

import { getMakeItems, showModal } from '../../../../actions';
import { connect } from 'react-redux';

import ProfileMenu from '../menu';
import ItemCard from '../../../items/card';
import FamilyItemsModal from '../../../items/family';

import Helmet from '../../../ui/Helmet';
import RecordEvent from '../../../analytics/pageview';

import Loader from '../../../Loader';

const AthleteItems = ({
  getMakeItems,
  athlete,
  myAthlete,
  items,
  lists,
  itemsMake,
  totalPages,
  showModal,
  loading,
  currentFilter,
}) => {
  const [mounted, setMounted] = React.useState(false);
  const [selectedFamily, setSelectedFamily] = React.useState(null);
  const [familyObject, setFamilyObject] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [filter] = React.useState(currentFilter);

  const alias = athlete?.alias;

  // get the first page of items, if not loaded
  React.useEffect(() => {
    if (!mounted && !loading && itemsMake !== alias) {
      getMakeItems(alias, 1, filter);
      setMounted(true);
    }
  }, [mounted, getMakeItems, alias, itemsMake, loading, filter, currentFilter]);

  // run this when the filter changes
  React.useEffect(() => {
    // if the filter has changed, reset the page to 1
    if (filter !== currentFilter && !loading) {
      getMakeItems(alias, 1, filter);
      setPage(1);
    }
  }, [mounted, loading, filter, alias, getMakeItems, currentFilter]);

  // get the next page of items if the user scrolls to the bottom of the page
  React.useEffect(() => {
    function handleScroll() {
      if (
        window.innerHeight +
          document.getElementById('main-content').scrollTop +
          400 <
        document.getElementById('scroll-surface').offsetHeight
      )
        return;

      if (page === totalPages || loading || totalPages === 0) return;

      getMakeItems(alias, page + 1, filter);

      setPage(page + 1);
    }
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, [page, setPage, totalPages, loading, alias, getMakeItems, filter]);

  const handleSelectFamily = (item) => {
    const familyItems = [
      item,
      ...item?.familyItems
        ?.filter((i) => i._id !== item._id)
        .sort((a, b) => {
          return a._category?.name?.localeCompare(b._category?.name);
        }),
    ];
    const familyId = item?._family?._id;
    const name = item?._family?.name;
    setFamilyObject({
      name,
      familyItems,
    });
    setSelectedFamily(familyId);
    showModal('FAMILY_ITEMS');
  };

  const renderItems = () => {
    if ((!items || items?.length === 0) && !loading) {
      return (
        <div className="flex flex-col items-center justify-center">
          No results
        </div>
      );
    }
    return (
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {Object.values(items).map((item, i) => {
          if (item?.familyItems?.length > 1) {
            return (
              <ItemCard
                key={i}
                item={item}
                lists={lists}
                labelBuilds={true}
                family
                onClickHandler={() => handleSelectFamily(item)}
              />
            );
          }
          return (
            <ItemCard key={i} item={item} lists={lists} labelBuilds={true} />
          );
        })}
      </div>
    );
  };

  // const onFilterToggleHandler = (filterOption) => {
  //   setFilter({
  //     ...filter,
  //     [filterOption]: !filter[filterOption],
  //   });
  // };

  // const renderFilterMenu = () => {
  //   return (
  //     <div className="form-control">
  //       <label className="label cursor-pointer justify-center gap-2">
  //         <span className="label-text">In Builds</span>
  //         <input
  //           type="checkbox"
  //           className="toggle"
  //           onChange={() => onFilterToggleHandler('inBuilds')}
  //           checked={filter.inBuilds}
  //           disabled={loading}
  //         />
  //       </label>
  //     </div>
  //   );
  // };

  return (
    <>
      <Helmet
        title={`${athlete?.name}'s items on Active Projects`}
        description={`View all of ${athlete?.name}'s bike products on Active Projects`}
        image={athlete?.avatar}
        url={`${process.env.REACT_APP_URL}/${athlete?.alias}/items/`}
      />
      <RecordEvent
        hitType="pageview"
        page={window.location.pathname}
        title={`Profile - ${athlete?.name} - items`}
      />
      <ProfileMenu currentTab="items" myAthlete={myAthlete} athlete={athlete} />
      {renderItems()}
      {selectedFamily && <FamilyItemsModal family={familyObject} />}
      {loading && <Loader type="items" />}
    </>
  );
};

function mapStateToProps(state) {
  const loading = state?.loader?.items;

  const items = state.items?.items ? state.items.items : null;
  const itemsMake = state.items?.make ? state.items.make : null;
  const totalPages = state.items?.totalPages ? state.items.totalPages : 0;
  const currentFilter = state.items?.filters
    ? state.items.filters
    : {
        inBuilds: false,
        forSale: false,
        inBrands: false,
      };

  return {
    loading,
    items,
    itemsMake,
    totalPages,
    currentFilter,
    lists: state.lists,
  };
}

export default connect(mapStateToProps, { getMakeItems, showModal })(
  AthleteItems
);

import './index.css';
import React from 'react';

import Loader from '../../Loader';

import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/solid';

const ItemImages = ({
  item,
  noCarousel,
  customClass = '',
  stack,
  badge,
  leftBadge,
}) => {
  const carouselRef = React.useRef(null);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const onScroll = () => {
    const scrollPosition = carouselRef.current.scrollLeft;
    const slideWidth = carouselRef.current.children[0].offsetWidth;
    const currentSlide = Math.round(scrollPosition / slideWidth);
    setCurrentSlide(currentSlide);
  };

  const { REACT_APP_AWS_S3 } = process.env;

  if (!item) {
    return (
      <Loader type="image" className="carousel-item w-full aspect-square" />
    );
  }

  const { name, description, images } = item;
  const renderImages =
    images && images.length > 0 && !noCarousel && !stack ? (
      Object.values(images).map((image) => {
        // convert object to array
        const { imageFile } = image;
        const imageUri = REACT_APP_AWS_S3 + '/' + imageFile;

        return (
          <div
            className={`carousel-item w-full aspect-square bg-gray-500`}
            key={imageFile}
          >
            {loading && (
              <Loader
                type="image"
                className="carousel-item w-full aspect-square"
              />
            )}
            <img
              key={imageFile}
              src={imageUri}
              alt={`${name} ${description}`}
              className="object-scale-down w-full animate-fade-in aspect-square bg-white opacity-90"
              style={{
                display: loading ? 'none' : 'block',
                width: '100%',
              }}
              onLoad={() => setLoading(false)}
            />
          </div>
        );
      })
    ) : images && images.length > 0 ? (
      <div
        className={`carousel-item w-full aspect-square bg-gray-500`}
        key={images[0].imageFile}
      >
        {loading && (
          <Loader
            type="image"
            className="carousel-item w-full aspect-square bg-blend-darken"
          />
        )}
        <img
          key={images[0].imageFile}
          src={REACT_APP_AWS_S3 + '/' + images[0].imageFile}
          alt={`${name} ${description}`}
          className={`object-scale-down w-full animate-fade-in aspect-square bg-white opacity-90`}
          style={{
            display: loading ? 'none' : 'block',
            width: '100%',
          }}
          onLoad={() => setLoading(false)}
        />
      </div>
    ) : (
      <div className="text-center bg-gray-200 grid w-full aspect-square">
        {/* <div className="card-body"></div> */}
      </div>
    );

  const renderDots =
    !noCarousel &&
    !stack &&
    images &&
    images.length > 1 &&
    Object.values(images).map((image, index) => {
      // conver object to array
      const { imageFile } = image;
      // on click, scroll to image
      const onClick = () => {
        const slideWidth = carouselRef.current.children[0].offsetWidth;
        carouselRef.current.scrollLeft = index * slideWidth;
      };
      return (
        <div
          className={`dot ${
            currentSlide === index ? 'active' : 'cursor-pointer'
          }`}
          key={imageFile}
          onClick={onClick}
        ></div>
      );
    });

  const onArrowClick = (direction) => {
    const slideWidth = carouselRef.current.children[0].offsetWidth;
    if (direction === 'left') {
      carouselRef.current.scrollLeft -= slideWidth;
    } else {
      carouselRef.current.scrollLeft += slideWidth;
    }
  };

  // left and right arrows
  const renderArrows = !noCarousel && !stack && (
    <div className="relative w-full">
      <div className="absolute m-2 hover:opacity-50 opacity-10 transition-opacity duration-200 inset-0 flex aspect-square items-center justify-between z-10">
        <div onClick={() => onArrowClick('left')}>
          <ArrowLeftCircleIcon className="h-6 w-6 text-gray-400 cursor-pointer" />
        </div>
        <div onClick={() => onArrowClick('right')}>
          <ArrowRightCircleIcon className="h-6 w-6 text-gray-400 cursor-pointer" />
        </div>
      </div>
    </div>
  );

  const carouselBlock =
    !noCarousel && !stack ? (
      <>
        {images?.length > 1 && renderArrows}
        <div
          className="carousel mx-auto w-full"
          ref={carouselRef}
          onScroll={onScroll}
        >
          {renderImages}
        </div>
      </>
    ) : stack === true ? (
      <div className="mx-auto w-full aspect-square flex">
        {badge && (
          <div className="absolute top-0 right-2 mt-2 z-20">
            <div className="badge badge-ghost font-extralight text-xs bg-base-100">
              {badge}
            </div>
          </div>
        )}
        {leftBadge && (
          <div className="absolute top-0 left-2 mt-2 z-20">
            <div className="badge badge-ghost border-none bg-brand font-extralight text-xs text-base-100">
              {leftBadge}
            </div>
          </div>
        )}
        <div
          className={`${customClass} translate-y-0 scale-100 z-10 absolute inset-0`}
        >
          {renderImages}
        </div>
        <div className="text-center bg-gray-200 opacity-50 aspect-square -translate-y-[5%] scale-95 absolute inset-0 z-0">
          <div className="card-body"></div>
        </div>
        <div className="text-center bg-gray-200 opacity-40 aspect-square -translate-y-[10%] scale-90 absolute inset-0 z-0">
          <div className="card-body"></div>
        </div>
      </div>
    ) : (
      <div className="mx-auto w-full aspect-square">
        {leftBadge && (
          <div className="absolute top-0 left-2 mt-2 z-20">
            <div className="badge badge-ghost border-none bg-brand font-extralight text-xs text-base-100">
              {leftBadge}
            </div>
          </div>
        )}
        <div className={`${customClass}`} ref={carouselRef}>
          {renderImages}
        </div>
      </div>
    );

  return (
    <>
      {carouselBlock}
      {images?.length > 1 && !noCarousel && !stack && (
        <div className="dots">{renderDots}</div>
      )}
    </>
  );
};

export default ItemImages;

import React, { useRef } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { showModal } from '../../../../actions';

import AddItem from '../add';
import Bookmark from '../../../reactions/bookmark';
import BuildItemOptions from '../options';
import FamilyItemsModal from '../../../items/family';

import Loader from '../../../Loader';
import categoryDefinitions from '../../../../utils/categoryDefinitions';

import {
  EllipsisHorizontalIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import GuideTooltip from '../../../ui/Guide';

const BuildItemsList = ({ build, showModal, isOwner, lists, isAdmin }) => {
  const [expanded, setExpanded] = React.useState([
    'Frame',
    'Parts',
    'Accessories',
    'Apparel',
    'Nutrition',
    'Camp',
    'Other',
  ]);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [selectedFamily, setSelectedFamily] = React.useState(null);
  const [familyObject, setFamilyObject] = React.useState(null);

  let showGuide = true;

  const handleExpand = (index) => {
    const newExpanded = [...expanded];
    if (newExpanded.includes(index)) {
      const indexToRemove = newExpanded.indexOf(index);
      newExpanded.splice(indexToRemove, 1);
    } else {
      newExpanded.push(index);
    }
    setExpanded(newExpanded);
  };

  const renderItemCompletion = (
    _variant,
    _family,
    familyItems,
    _item,
    status
  ) => {
    if ((!_variant || _variant?.length === 0) && (!_family || !_family?._id)) {
      return null;
    }

    // what percentage of _variant isComplete
    const completed =
      status !== 'family'
        ? (_variant?.filter(
            (variant) => variant.isComplete && variant.count > 0
          )?.length || 0) + 7
        : status === 'family'
        ? (_variant?.filter(
            (variant) => variant.isComplete && variant.count > 0
          )?.length || 0) + 7
        : 7;

    let total =
      _item && _variant?.length > 0
        ? _variant?.filter((variant) =>
            // if item is selected, count distinct variantTypes iin variants
            _item?.variants?.some(
              (availableVariant) =>
                availableVariant.variantType === variant.variantType
            )
          ).length + 7
        : // if familyitems // total number of variantTypes in familyItems
        // are there variants in familyItems?
        familyItems?.length > 0 &&
          familyItems?.some((familyItem) => familyItem?.variants?.length > 0)
        ? familyItems?.reduce((acc, familyItem) => {
            if (!familyItem?.variants?.length > 0) {
              return acc;
            }
            const { variants } = familyItem;
            const variantTypes = variants
              ?.map((variant) => variant.variantType)
              .filter(
                (variantType, index, self) =>
                  self.indexOf(variantType) === index
              );
            return acc + variantTypes.length;
          }, 0) + 7
        : 7;
    // if item is selected, add 1 to total for each variantType
    if (!_item) {
      total = total + 7;
    }
    //  if familyItems have no variants, add 1 to total for each familyItem

    let percentage = Math.round((completed / total) * 100);
    const isComplete = percentage === 100;

    if (isComplete) {
      return (
        <div className="flex">
          <div
            className="radial-progress text-secondary-content dark:text-accent"
            style={{
              '--value': percentage,
              '--size': '1.3rem',
              '--thickness': '1.5px',
            }}
          >
            <EllipsisHorizontalIcon className="h-4 w-4 text-neutral dark:text-secondary" />
          </div>
        </div>
      );
    } else {
      const showThisGuide = showGuide;
      showGuide = false;

      return (
        <div className="flex">
          <div
            className="radial-progress text-warning"
            style={{
              '--value': percentage,
              '--size': '1.3rem',
              '--thickness': '1.5px',
            }}
          >
            {showThisGuide ? (
              <GuideTooltip
                message="Tap to select your specific model"
                placement="tooltip-right"
              >
                <EllipsisHorizontalIcon className="h-4 w-4 text-neutral dark:text-secondary" />
              </GuideTooltip>
            ) : (
              <EllipsisHorizontalIcon className="h-4 w-4 text-neutral dark:text-secondary" />
            )}
          </div>
        </div>
      );
    }
  };

  const renderItemsList = () => {
    if (!build?._id) {
      return <Loader type="placeholderList" height="h-4" />;
    }

    const { items } = build;

    if (!items || items?.length === 0) {
      return null;
    }

    const categorizedItems = categoryDefinitions.map(
      (categoryDefinition, index) => {
        const { label, options } = categoryDefinition;

        const filteredItems = items.filter((item) => {
          return item?.category?._parent?.name === label;
        });

        if (filteredItems.length === 0) {
          return null;
        }

        return (
          <div key={index} className="flex flex-col mb-4">
            {label !== 'Frame' ? (
              <div
                className="flex flex-row gap-4 items-center cursor-pointer text-gray-500 text-sm"
                onClick={() => handleExpand(label)}
              >
                <h3 className="text-start">{label}</h3>
                <div className="flex flex-row items-center">
                  <ChevronDownIcon
                    className={`h-5 w-5 mr-1 transform ease-in duration-200 ${
                      expanded.includes(label) ? `rotate-0` : `rotate-180`
                    }`}
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-row gap-4 items-center text-gray-500 text-sm">
                <h3 className="text-start">{label}</h3>
              </div>
            )}
            {expanded.includes(label) && (
              <>
                <div className="flex flex-row gap-2 items-center">
                  <div className="w-full mt-0">
                    {renderItems(filteredItems, options, label)}
                  </div>
                </div>
              </>
            )}
          </div>
        );
      }
    );

    // render any items that don't have a category parent or have a status of pending
    const filteredItems = items.filter((item) => {
      if (!item?._item?.name && !item?._family?.name) {
        return false;
      }
      return !item?.category?._parent || item?.status === 'Pending';
    });

    const otherItems =
      filteredItems.length > 0 ? (
        <div className="flex flex-col mb-4">
          <div
            className="flex flex-row gap-4 items-center cursor-pointer text-gray-500 text-sm"
            onClick={() => handleExpand('Other')}
          >
            <h3 className="text-start">Pending Verification</h3>
            <div className="flex flex-row items-center">
              <ChevronDownIcon
                className={`h-5 w-5 mr-1 transform ease-in duration-200 ${
                  expanded.includes('Other') ? `rotate-0` : `rotate-180`
                }`}
              />
            </div>
          </div>
          {expanded.includes('Other') && (
            <>
              <div className="flex flex-row gap-2 items-center">
                <div className="w-full mt-0">
                  {renderItems(filteredItems, null, 'Other')}
                </div>
              </div>
            </>
          )}
        </div>
      ) : null;

    return (
      <>
        {categorizedItems}
        {otherItems}
      </>
    );
  };

  const renderBuildItemVariants = (_variant) => {
    if (!_variant || _variant?.length === 0) {
      return null;
    }

    return (
      <span className="">
        {_variant.map((variant, index) => {
          const { variant: variantName } = variant;
          return (
            <span key={index} className="capitalize">
              {variantName}{' '}
            </span>
          );
        })}
      </span>
    );
  };

  const renderItems = (items, options, parent) => {
    if (!items || items?.length === 0) {
      return null;
    }

    const handleSelectItem = (buildItemId) => {
      setSelectedItem(buildItemId);
      showModal('BUILD_ITEM_OPTIONS');
    };

    const handleSelectFamily = (familyId, categoryId) => {
      // get familyItems that belong to familyId and categoryId
      const familyItems = items.find(
        // (item) => item._family?._id === familyId
        (item) =>
          item._family?._id === familyId && item._category?._id === categoryId
      )?.familyItems;
      setFamilyObject({
        // ...items.find((item) => item._family?._id === familyId)?._family,
        ...items.find(
          (item) =>
            item._family?._id === familyId && item._category?._id === categoryId
        )?._family,
        familyItems,
      });
      setSelectedFamily(familyId);
      showModal('FAMILY_ITEMS');
    };

    return items.map((item, index) => {
      if (!item || (!item._item && !item._family)) {
        return null;
      }

      // _variant is the buildItem's selected variants and if completed
      const { _item, _variant, _family, familyItems } = item;
      const { _id: buildItemId } = item;

      // variants are all possible variations on the item
      let itemId, _make, _category, name, status;

      if (_item) {
        // { _id: itemId, _make, _category, name, status } = _item;
        itemId = _item._id;
        _make = _item._make;
        _category = _item._category;
        name = _item.name;
        status = _item.status;
      } else if (_family) {
        // { _id: itemId, _make, _category, name, status } = _family;
        itemId = _family?._id;
        _make = familyItems?.length > 0 ? familyItems[0]?._make : null;
        _category = familyItems?.length > 0 ? familyItems[0]?._category : null;
        name = _family.name;
        status = 'family';
      }

      // is this the first incomplete item?
      const isComplete = _variant?.every((variant) => variant.isComplete);
      const firstIncomplete = index === 0 && !isComplete;

      const make = _make ? _make.name : '';
      const category = _category ? _category.name : '';

      return (
        <div
          key={buildItemId}
          className={`flex flex-row border-y-accent-300 dark:border-y-white border-x-transparent ${
            index > 0 && `border-t`
          } pl-0 pr-0 justify-between py-1 gap-2  min-h-[40px]`}
        >
          {(isOwner || isAdmin) && (
            <div className="flex flex-row gap-2 items-center">
              <div
                onClick={() => handleSelectItem(buildItemId)}
                className="cursor-pointer"
              >
                {renderItemCompletion(
                  _variant,
                  _family,
                  familyItems,
                  _item,
                  status,
                  firstIncomplete
                )}
              </div>
            </div>
          )}
          <div className="flex items-center flex-grow flex-wrap whitespace-normal">
            {status !== 'family' ? (
              <Link
                to={`/item/${itemId}`}
                className="text-sm align-middle text-pretty"
                state={{ backable: true }}
              >
                {parent !== 'Frame' ? (
                  <span className="capitalize pr-1"> {category}:</span>
                ) : null}
                {make} {name} {renderBuildItemVariants(_variant)}
              </Link>
            ) : (
              <div
                className="text-sm align-middle hover:cursor-pointer"
                onClick={() => handleSelectFamily(itemId, _category?._id)}
              >
                {parent !== 'Frame' ? (
                  <span className="capitalize pr-1"> {category}:</span>
                ) : null}
                {make} {name} {renderBuildItemVariants(_variant)}{' '}
              </div>
            )}
            {status === 'pending' && (
              <div className="tooltip" data-tip="Item is pending verification">
                <InformationCircleIcon className="h-4 w-4 text-base-300 ml-1" />
              </div>
            )}
          </div>
          <div className="translate-x-1 gap-2 flex-row flex items-center z-20 relative">
            {_item?._id && <Bookmark item={_item} lists={lists} />}
          </div>
        </div>
      );
    });
  };

  const inputRef = useRef(null);

  const handleFocus = () => {
    // relatedBuilds?.length === 0 && setOpenAddItemMenu(true);
    // setOpenAddItemMenu(true);

    // is the device a mobile device?
    const isMobile = window.innerWidth < 768;
    const block = isMobile ? 'start' : 'center';
    const behavior = isMobile ? 'smooth' : 'smooth';

    setTimeout(() => {
      inputRef.current &&
        inputRef.current.scrollIntoView({
          behavior,
          block,
        });
    }, 150);
  };

  const isItems = build?.items?.length > 0;
  // is there a frame in the build?
  const isFrame = build?.items?.some(
    (item) =>
      item._item?._category?._parent?.name === 'Frame' ||
      item?.category?._parent?.name === 'Frame'
  );

  return (
    <div className="mb-14">
      <div className="flex flex-row gap-2 items-center mb-4 z-0">
        <div className="w-full mt-0">{renderItemsList()}</div>
      </div>
      {(isOwner || isAdmin) && (!isItems || !isFrame) ? (
        <GuideTooltip
          message={`Add ${!isFrame ? 'a frame' : 'an item'} to your build`}
        >
          <AddItem
            refProp={inputRef}
            onFocus={handleFocus}
            placeholder={`Add ${!isFrame ? 'a frame' : 'an item'}`}
          />
        </GuideTooltip>
      ) : (
        <AddItem
          refProp={inputRef}
          onFocus={handleFocus}
          placeholder="Add an item"
        />
      )}

      {(isOwner || isAdmin) && selectedItem && (
        <BuildItemOptions build={build} buildItemId={selectedItem} />
      )}
      {selectedFamily && <FamilyItemsModal family={familyObject} />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  showModal,
})(BuildItemsList);

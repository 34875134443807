import React from 'react';

const NotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            {/* <h1 className="text-4xl font-bold">404</h1> */}
            {/* <h2 className="text-2xl">Page not found</h2> */}
        </div>
    );
}

export default NotFound;
import React from 'react';

import Page from '../../ui/Page';
import Header from '../../header';
import GlassUp from '../../landing/glassup';
import Loader from '../../Loader';
import Helmet from '../../ui/Helmet';

import PostCard from '../../posts/card';
import PostCarousel from '../../posts/carousel';
import PostHeadline from '../../posts/headline';
import PostProgress from '../../posts/progress';

import { Link } from 'react-router-dom';

import RecordEvent from '../../analytics/pageview';

import { connect } from 'react-redux';

import { fetchDiscoverFeed } from '../../../actions';

import { NewspaperIcon } from '@heroicons/react/24/outline';

const Posts = ({ fetchDiscoverFeed, posts, isSignedIn, loading }) => {
  React.useEffect(() => {
    fetchDiscoverFeed();
  }, [fetchDiscoverFeed]);

  const renderPosts = (posts) => {
    return posts.map((post, index) => {
      return post.format === 'carousel' ? (
        <PostCarousel key={index} post={post} />
      ) : post.format === 'headline' ? (
        <PostHeadline key={index}>{post.description}</PostHeadline>
      ) : post.format === 'progress' ? (
        <PostProgress key={index} post={post} />
      ) : (
        <PostCard key={index} post={post} />
      );
    });
  };
  return (
    <Page>
      <Helmet
        title="Active Projects Discover Feed"
        description="Discover new builds and follow your favorite athletes on Active Projects"
        url={`${process.env.REACT_APP_URL}/feed`}
      />
      <Header />
      <GlassUp>
        <div>
          <div className="tabs flex justify-center my-6">
            <Link
              className={`tab text-xl tab-active font-bold`}
              to={`/discover`}
            >
              Discover
            </Link>
            <Link className={`tab text-xl`} to={`/feed`}>
              Builds
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 space-y-16">
          {!loading && !posts?.length && (
            <div className="flex justify-center items-center w-full h-96 col-span-full gap-2">
              <NewspaperIcon className="h-24 w-24 text-gray-400" />
              <div className="text-center text-gray-400 text-lg">
                No posts yet
              </div>
            </div>
          )}
          {renderPosts(posts)}
        </div>
        {loading && <Loader type="builds" />}
        <RecordEvent
          hitType="pageview"
          page={window.location.pathname}
          title="Discovery Feed"
        />
      </GlassUp>
    </Page>
  );
};

const mapStateToProps = (state) => {
  const loading =
    state.loader && state.loader.posts ? state.loader.posts : false;

  return {
    loading,
    isSignedIn: state.auth?.userId,
    posts: state?.posts?.posts ? Object.values(state.posts.posts) : null,
  };
};

export default connect(mapStateToProps, { fetchDiscoverFeed })(Posts);

import React from 'react';
import Modal from '../../Modal';

import { connect } from 'react-redux';

import { Form, Field } from 'react-final-form';
import CustomInput from '../../forms/CustomInput';

import {
  getAuthAthletes,
  postNewAthlete,
  switchAthlete,
  addAdminToAthlete,
  removeAdminFromAthlete,
  getAthlete,
  showModal,
  hideModal,
} from '../../../actions';

import AthleteLine from '../../athlete/line';

// check heroicon
import {
  PlusCircleIcon,
  XMarkIcon,
  UserMinusIcon,
  StarIcon,
} from '@heroicons/react/24/outline';

import Circle from '../../ui/Circle';

const SwitchAthlete = ({
  isSignedIn,
  athlete: currentAthlete,
  athleteList,
  modal,
  getAuthAthletes,
  postNewAthlete,
  switchAthlete,
  addAdminToAthlete,
  removeAdminFromAthlete,
  userId,
  hideModal,
}) => {
  const [mounted, setMounted] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);

  React.useEffect(() => {
    // on mount and upon sign-in verification, get athletes
    if (isSignedIn && !mounted && !athleteList) {
      setMounted(true);
      isSignedIn && getAuthAthletes();
    }
  }, [getAuthAthletes, mounted, athleteList, isSignedIn]);

  // don't render if no athletes
  if (!currentAthlete) {
    return null;
  }

  const renderAthletes = () => {
    if (athleteList) {
      return athleteList.map((athlete) => {
        const isCurrentProfile =
          currentAthlete._id === athlete._id ? true : false;
        const isIdentity = athlete.users.find(
          (user) => user?.identity && user?._userId?._id === userId
        );
        return (
          <div
            key={athlete._id}
            className="flex flex-row justify-between items-center border-b border-gray-200 py-2 w-full"
          >
            <div className="flex flex-row items-center w-full overflow-x-scroll">
              {isCurrentProfile && (
                <span className="-ml-1 z-10 fixed">
                  <Circle className="h-11 w-11 border-1 stroke-yellow-500 drop-shadow-lg" />
                </span>
              )}
              <AthleteLine athlete={athlete} noLink />
            </div>
            <div className="flex flex-row gap-4">
              {!isCurrentProfile && (
                <>
                  {!isIdentity ? (
                    <button
                      className="btn btn-ghost btn-sm"
                      onClick={() => handleRemoveProfile(athlete._id)}
                    >
                      <UserMinusIcon className="h-5 w-5" />
                    </button>
                  ) : (
                    <StarIcon className="h-5 w-5 m-2 mr-3" />
                  )}

                  <button
                    className="btn btn-sm"
                    onClick={() => handleSwitch(athlete._id)}
                  >
                    Switch
                  </button>
                </>
              )}
            </div>
          </div>
        );
      });
    }
  };

  const handleAddProfileSubmit = async (values) => {
    await postNewAthlete(values);
    setShowForm(false);
    hideModal();
  };

  const renderAddProfileForm = () => {
    const initialValues = {
      name: '',
      newAcct: '_new_',
    };
    return (
      <Form
        onSubmit={handleAddProfileSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-row justify-between items-center w-full">
              <Field
                name="name"
                component={CustomInput}
                placeholder="Name for new profile"
                size="input input-sm"
                autoFocus
              />
              <Field name="newAcct" component="input" type="hidden" />
              <button
                type="submit"
                disabled={submitting || pristine}
                className="ml-2 btn btn-primary btn-sm"
              >
                Next
              </button>
            </div>
          </form>
        )}
      />
    );
  };

  const renderAddAthlete = () => {
    return (
      <div className="flex flex-row justify-between items-center border-b border-gray-200 py-2 w-full">
        {showForm ? (
          renderAddProfileForm()
        ) : (
          <div
            className="flex flex-row items-center justify-center cursor-pointer"
            onClick={handleAddProfile}
          >
            <div className="w-9">
              <PlusCircleIcon className="h-7 w-7 text-gray-500" />
            </div>
            <div>
              <div className="text-base">Add Profile</div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleAddProfile = async () => {
    setShowForm(true);
  };

  const handleSwitch = (id) => {
    switchAthlete(id);
    document.getElementById('app-drawer').checked = false;
    hideModal();
  };

  const handleRemoveProfile = (id) => {
    removeAdminFromAthlete(id, true);
    getAthlete(currentAthlete._id);
    showModal();
  };

  const renderContent = () => {
    return (
      <div className="flex flex-col w-full">
        <div className="flex flex-col w-full">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <div className="text-xl font-bold">Switch Profile</div>
              <div className="text-sm">Select a profile or add a new one.</div>
            </div>
            <div className="flex flex-row justify-end">
              <button
                className="btn btn-square btn-ghost btn-sm"
                onClick={() => hideModal()}
              >
                <XMarkIcon className="h-6 w-6 text-gray-500" />
              </button>
            </div>
          </div>
          <div className="flex flex-col mt-4 w-full">
            <div className="flex flex-col mt-2 w-full">
              {renderAthletes()}
              {renderAddAthlete()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!athleteList || !modal.visible || modal.modalType !== 'SWITCH_ATHLETE') {
    return null;
  }

  return <Modal content={renderContent()} onDismiss={() => hideModal()} />;
};

const mapStateToProps = (state) => {
  // sort athletes by name
  const athletes =
    state?.auth?.athleteList &&
    Object.values(state.auth.athleteList).sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else {
        return 1;
      }
    });

  const isSignedIn = state.auth?.userId;

  return {
    isSignedIn,
    athlete: state.auth.profile,
    athleteList: athletes,
    userId: state.auth.userId,
    modal: state.modal,
  };
};

export default connect(mapStateToProps, {
  getAuthAthletes,
  postNewAthlete,
  switchAthlete,
  addAdminToAthlete,
  removeAdminFromAthlete,
  showModal,
  hideModal,
})(SwitchAthlete);

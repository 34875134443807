import React from 'react';

import { connect } from 'react-redux';

import { apeFetchAnalytics, apeFetchStats } from '../../../actions';

import { Link } from 'react-router-dom';

import Loader from '../../Loader';

import { InformationCircleIcon } from '@heroicons/react/24/solid';

// format number to comma separated 1,000s
const formatNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const ApeStats = ({
  apeFetchStats,
  apeFetchAnalytics,
  stats,
  analytics,
  loading,
}) => {
  const [mounted, setMounted] = React.useState(null);

  React.useEffect(() => {
    // on mount, get the stats
    if (!mounted) {
      setMounted(true);
      apeFetchStats();
      apeFetchAnalytics();
    }
  }, [apeFetchStats, apeFetchAnalytics, mounted]);

  // ANALYTICS ANALYTICS ANALYTICS
  const renderAnalytics = () => {
    if (loading?.analytics) {
      return <Loader type="table" className="mt-5" />;
    }

    if (analytics) {
      const { users, cities, builds } = analytics;

      const renderUsers = () => {
        const { metricHeaders, rows } = users;

        // combine each metric with its value
        const userMetrics = metricHeaders.map((header, index) => {
          return {
            name: header.name,
            value: rows[0].metricValues[index].value,
          };
        });

        return (
          <div className="stats shadow">
            <div className="stat place-items-center">
              <div className="stat-title">Active Visitors</div>
              <div className="stat-value">
                {Math.floor(
                  userMetrics.find((m) => m.name === 'activeUsers').value
                )}
              </div>
              <div className="stat-desc">Last 30 days</div>
            </div>

            <div className="stat place-items-center">
              <div className="stat-title">New Visitors</div>
              <div className="stat-value">
                {Math.floor(
                  userMetrics.find((m) => m.name === 'newUsers').value
                )}
              </div>
              <div className="stat-desc">Last 30 days</div>
            </div>
          </div>
        );
      };

      const renderCities = () => {
        const { rows } = cities;

        // take the top 5 cities
        rows.splice(6);

        return (
          <div className="card bg-base-100 shadow w-6/6">
            <div className="card-body py-4">
              <div className="stat-title">Active Visitors by City</div>
              <table className="table w-full">
                <tbody>
                  {rows.map((row, index) => {
                    // ignore empty rows
                    if (row.dimensionValues[0].value === '(not set)') {
                      return null;
                    }
                    const reviseValue = Math.floor(row.metricValues[0].value);
                    return (
                      <tr key={index}>
                        <td className="py-2">{reviseValue}</td>
                        <td className="py-2">
                          <span className=" line-clamp-1">
                            {row.dimensionValues[0].value}{' '}
                            {row.dimensionValues[1].value}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="stat-desc">Last 30 days</div>
            </div>
          </div>
        );
      };

      const renderBuilds = () => {
        const { rows } = builds;

        // filter out builds with a dimension value in list: ['(not set)', 'undefined', 'Active Projects']
        const filteredRows = rows.filter(
          (row) =>
            !['Build - undefined', 'Active Projects'].includes(
              row.dimensionValues[0].value
            )
        );
        filteredRows.splice(6);

        return (
          <div className="card bg-base-100 shadow w-6/6">
            <div className="card-body py-4">
              <div className="stat-title">Most viewed builds</div>
              <table className="table w-full">
                <tbody>
                  {filteredRows.map((row, index) => {
                    // ignore empty rows
                    if (row.dimensionValues[0].value === '(not set)') {
                      return null;
                    }
                    const reviseValue = Math.floor(row.metricValues[0].value);
                    return (
                      <tr key={index}>
                        <td className="py-2">{reviseValue}</td>
                        <td className="py-2">
                          <Link
                            to={row.dimensionValues[1].value}
                            className="underline-offset-4 hover:underline"
                          >
                            <span className=" line-clamp-1">
                              {row.dimensionValues[0].value}
                            </span>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="stat-desc">Last 30 days</div>
            </div>
          </div>
        );
      };

      return (
        <div className="overflow-x-auto gap-8 flex flex-col items-center">
          <div className="flex flex-col sm:flex-row gap-12 items-center sm:items-start">
            <div>{renderUsers()}</div>
          </div>
          <div className="flex flex-col sm:flex-row gap-12 items-center sm:items-start">
            <div>{renderCities()}</div>
            <div>{renderBuilds()}</div>
          </div>
        </div>
      );
    }
  };

  // STATS STATS STATS
  const renderStats = () => {
    if (loading?.stats) {
      return <Loader type="table" className="mt-5" />;
    }

    if (stats) {
      const { profiles, builds, makes, items } = stats;

      // render profile stats
      const renderProfiles = () => {
        const { total, companies, events, groups, athletes } = profiles;

        return (
          <div className="stats shadow stats-vertical sm:stats-horizontal">
            <div className="stat place-items-center">
              <div className="stat-title">Total Profiles</div>
              <div className="stat-value">{total}</div>
            </div>

            <div className="stat place-items-center">
              <div className="stat-title">Athletes</div>
              <div className="stat-value text-secondary">{athletes.total}</div>
              <div className="stat-desc text-secondary">
                {athletes.verified} verified
              </div>
            </div>

            <div className="stat place-items-center">
              <div className="stat-title">Companies</div>
              <div className="stat-value text-secondary">{companies.total}</div>
              <div className="stat-desc text-secondary">
                {companies.verified} verified
              </div>
            </div>

            <div className="stat place-items-center">
              <div className="stat-title">Events</div>
              <div className="stat-value text-secondary">{events.total}</div>
              <div className="stat-desc text-secondary">
                {events.verified} verified
              </div>
            </div>

            <div className="stat place-items-center">
              <div className="stat-title">Groups</div>
              <div className="stat-value text-secondary">{groups.total}</div>
              <div className="stat-desc text-secondary">
                {groups.verified} verified
              </div>
            </div>
          </div>
        );
      };

      const renderBuilds = () => {
        const { total, withItems } = builds;

        return (
          <div className="stats shadow stats-vertical sm:stats-horizontal">
            <div className="stat place-items-center">
              <div className="stat-title">Total Builds</div>
              <div className="stat-value">{total}</div>
            </div>

            <div className="stat place-items-center">
              <div className="stat-title">with Items</div>
              <div className="stat-value text-secondary">{withItems}</div>
            </div>
          </div>
        );
      };

      const renderMakes = () => {
        const { total, mostItems, mostBuilds } = makes;

        return (
          <div className="flex flex-row gap-4">
            <div>
              <div className="stats shadow">
                <div className="stat place-items-center">
                  <div className="stat-title">Total Makes</div>
                  <div className="stat-value">{total}</div>
                </div>
              </div>
            </div>

            <div role="tablist" className="tabs tabs-lifted">
              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                checked
                className="tab border-transparent  whitespace-nowrap overflow-ellipsis"
                aria-label="Items"
              />
              <div
                role="tabpanel"
                className="tab-content bg-base-100 rounded-box shadow p-6 py-4"
              >
                <div className="stat-title">Makes on most items</div>
                {mostItems.map((make) => {
                  const listingCompletion = Math.round(
                    (make.listings / make.items) * 100
                  );
                  // add a completion percentage to the make as tooltip
                  return (
                    <div className="flex flex-row gap-4" key={make._id}>
                      <div
                        className="whitespace-nowrap flex-nowrap flex  tooltip items-center -ml-2 gap-2"
                        data-tip={listingCompletion + '% listings coverage'}
                      >
                        <InformationCircleIcon
                          className={`w-5 h-5 ${
                            listingCompletion < 50
                              ? 'text-red-500'
                              : listingCompletion < 90
                              ? 'text-yellow-500'
                              : 'text-green-500'
                          }`}
                        />
                        <div>{make.items}x</div>
                      </div>
                      <div>{make.name}</div>
                    </div>
                  );
                })}
              </div>
              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab border-transparent whitespace-nowrap overflow-ellipsis"
                aria-label="Builds"
              />
              <div
                role="tabpanel"
                className="tab-content bg-base-100 rounded-box shadow p-6 py-4"
              >
                <div className="stat-title">Makes on most builds</div>
                {mostBuilds.map((make) => {
                  return (
                    <div className="flex flex-row gap-4" key={make._id}>
                      <div>{make.builds}x</div>

                      <div>{make.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      };

      const renderItems = () => {
        const { total, mostBuilds, withListings } = items;

        return (
          <div className="flex flex-row gap-4">
            <div>
              <div className="stats shadow stats-vertical">
                <div className="stat place-items-center">
                  <div className="stat-title">Total Items</div>
                  <div className="stat-value">{formatNumber(total)}</div>
                </div>
                <div className="stat place-items-center">
                  <div className="stat-title">with Listings</div>
                  <div className="stat-value">{formatNumber(withListings)}</div>
                </div>
              </div>
            </div>

            <div className="card bg-base-100 shadow w-4/6">
              <div className="card-body py-4">
                <div className="stat-title">Items on most builds</div>
                {mostBuilds.map((item) => {
                  return (
                    <div key={item._id} className="flex flex-row gap-4">
                      <div>{item.builds}x</div>
                      <div>
                        {item.make}
                        {` `}
                        {item.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      };

      return (
        <div className="overflow-x-auto gap-12 flex flex-col items-center">
          <div className="flex flex-row gap-12">
            <div>{renderProfiles()}</div>
            <div>{renderBuilds()}</div>
          </div>
          <div className="flex flex-col sm:flex-row gap-12">
            <div>{renderMakes()}</div>
            <div>{renderItems()}</div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="items-center flex flex-col">
      <h1>Ape these figures</h1>
      <div className="flex  gap-8 flex-col w-full pt-4">
        <div className="w-full">{renderAnalytics()}</div>
        <div className="w-full">{renderStats()}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stats: state.ape.stats,
    analytics: state.ape.analytics,
    loading: state.loader?.elements,
  };
};

export default connect(mapStateToProps, { apeFetchStats, apeFetchAnalytics })(
  ApeStats
);

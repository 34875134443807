import React from 'react';

import { connect } from 'react-redux';
import {
  apeFetchPosts,
  apeDeletePost,
  apeReorderPosts,
  apeSendTestEmail,
  showModal,
  addNotice,
} from '../../../../actions';

import { useParams, Link } from 'react-router-dom';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import AddPostModal from '../addPost';
import Loader from '../../../Loader';

import PostCard from '../../../posts/card';
import PostCarousel from '../../../posts/carousel';
import PostHeadline from '../../../posts/headline';
import PostParagraph from '../../../posts/paragraph';
import PostProgress from '../../../posts/progress';
import PostImages from '../../../posts/image';

import { fixDate } from '../../../../utils/fixDate';

import {
  PlusCircleIcon,
  EllipsisVerticalIcon,
  LockClosedIcon,
} from '@heroicons/react/24/outline';

// import banana svg
import { ReactComponent as BananaIcon } from '../../../../assets/icons/banana.svg';

// props and children are passed to the SortableItem component
function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    // <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
    //   {props.children}
    // </tr>
    <div ref={setNodeRef} style={style} {...attributes}>
      <div
        className="absolute top-2 -left-9 h-16 w-16 -space-x-3 flex flex-row z-10 touch-none"
        {...listeners}
      >
        <EllipsisVerticalIcon className="h-5 w-5" />
        <EllipsisVerticalIcon className="h-5 w-5" />
      </div>
      {props.children}
    </div>
  );
}

const PostsAdmin = ({
  apeFetchPosts,
  apeDeletePost,
  apeReorderPosts,
  apeSendTestEmail,
  showModal,
  userId,
  posts: nahPosts,
  postsCollection,
  addNotice,
  loading,
}) => {
  const [mounted, setMounted] = React.useState(null);
  const [selectedPost, setSelectedPost] = React.useState(null);

  // initial state from posts.order
  const [items, setItems] = React.useState(null);

  // isSortable
  const [isSortable, setIsSortable] = React.useState(false);

  const [disableSendTestEmail, setDisableSendTestEmail] = React.useState(false);

  const { id } = useParams();

  const posts = postsCollection ? postsCollection[id]?.posts : null;

  const postsCollectionType = postsCollection
    ? postsCollection[id]?.type
    : null;

  const isLocked = postsCollection ? postsCollection[id]?.locked : null;

  React.useEffect(() => {
    // on mount, get the athlete
    if (!mounted && id) {
      setMounted(true);
      apeFetchPosts(id);
    }
  }, [apeFetchPosts, mounted, id]);

  React.useEffect(() => {
    if (posts && !items) {
      setItems(posts);
    }
  }, [mounted, items, posts, apeReorderPosts, loading]);

  // if SelectedPost changes, setItems agin
  React.useEffect(() => {
    if (selectedPost) {
      setItems(posts);
    }
  }, [selectedPost, posts]);

  // if length of posts changes, setItems again
  React.useEffect(() => {
    if (posts?.length !== items?.length) {
      setItems(posts);
    }
  }, [posts, items]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleShowNewPostModal = async (e) => {
    e.preventDefault();
    setSelectedPost(null);
    showModal('APE_EDIT_POST');
  };

  const handleDeletePost = async (e, postId) => {
    e.preventDefault();
    setSelectedPost(postId);

    // are you sure?
    window.confirm('Are you sure you want to delete this post?') &&
      (await apeDeletePost({ postId, postCollectionId: id }));

    // unset selected post
    setSelectedPost(null);
  };

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(
          items.find((item) => item._id === active.id)
        );
        const newIndex = items.indexOf(
          items.find((item) => item._id === over.id)
        );

        const newItems = arrayMove(items, oldIndex, newIndex);

        const reorderedItems = newItems.map((item, index) => {
          return {
            ...item,
            order: index,
          };
        });

        apeReorderPosts({
          postCollectionId: id,
          posts: reorderedItems,
        });

        return newItems;
      });
    }
  }

  const handleTestEmail = async (e) => {
    // are you sure?
    setDisableSendTestEmail(true);
    setTimeout(() => {
      setDisableSendTestEmail(false);
    }, 5000);
    await apeSendTestEmail({
      postCollectionId: id,
      userId,
    });
  };

  const renderStats = () => {
    if (postsCollectionType === 'email' && isLocked) {
      const { delivered, unique_opens, clicks } =
        postsCollection[id]?.deliveryData;
      // return delivered, unique_opens, and clicks in a table
      return (
        <div className="flex flex-row items-center justify-center gap-2">
          <div className="flex flex-row mt-2">
            <BananaIcon className="h-8 w-8 fill-yellow-600" />
          </div>
          <table className="table-auto text-center my-4 text-sm border-separate border-spacing-2">
            <thead>
              <tr>
                <th>Delivered</th>
                <th>Unique Opens</th>
                <th>Clicks</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{delivered || 0}</td>
                <td>{unique_opens || 0}</td>
                <td>{clicks || 0}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  };

  const renderAddPostButton = () => {
    if (isLocked) {
      return null;
    }

    return (
      <div className="flex sm:flex-row flex-col justify-center items-center gap-2">
        {posts?.length > 1 && (
          <button
            className={
              isSortable
                ? 'btn btn-sm rounded-btn btn-primary'
                : 'btn btn-ghost btn-sm rounded-btn'
            }
            onClick={() => setIsSortable(!isSortable)}
          >
            <span className="ml-1">
              {isSortable ? 'End Sorting' : 'Sort Posts'}
            </span>
          </button>
        )}
        {postsCollectionType === 'email' && (
          <button
            className={
              disableSendTestEmail
                ? 'btn btn-ghost btn-sm rounded-btn'
                : 'btn btn-ghost btn-sm rounded-btn'
            }
            disabled={disableSendTestEmail}
            onClick={() => handleTestEmail()}
          >
            <span className="ml-1">Send Test Email</span>
          </button>
        )}
        <button
          className="btn btn-ghost btn-sm rounded-btn"
          onClick={handleShowNewPostModal}
        >
          <PlusCircleIcon className="h-5 w-5" />
          <span className="ml-1">Add Post</span>
        </button>
      </div>
    );
  };

  const renderMenu = (post) => {
    return (
      <div className="flex flex-col items-center">
        <div className="dropdown dropdown-bottom dropdown-end hover:bg-gray-200 rounded-lg z-30 relative">
          <label tabIndex={0} className="cursor-pointer ">
            <EllipsisVerticalIcon className="h-5 w-5" />
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content z-40 menu p-2 shadow bg-base-100 rounded-box w-48 relative"
          >
            <li>
              <div
                className="cursor-pointer"
                disabled={isLocked}
                onClick={(e) => {
                  if (isLocked) {
                    return;
                  }
                  e.preventDefault();
                  setSelectedPost(post);
                  showModal('APE_EDIT_POST', post);
                }}
              >
                {isLocked ? (
                  <div className="text-secondary flex flex-row gap-2">
                    Edit
                    <LockClosedIcon className="h-4 w-4" />
                  </div>
                ) : (
                  'Edit'
                )}
              </div>
            </li>
            <li>
              <div
                className="cursor-pointer"
                onClick={(e) => {
                  if (isLocked) {
                    return;
                  }
                  handleDeletePost(e, post._id);
                }}
              >
                {isLocked ? (
                  <div className="text-secondary flex flex-row gap-2">
                    Delete
                    <LockClosedIcon className="h-4 w-4" />
                  </div>
                ) : (
                  'Delete'
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderPost = (post) => {
    return post.format === 'carousel' ? (
      <PostCarousel key={post._id} post={post} mode="edit" />
    ) : post.format === 'headline' ? (
      <PostHeadline
        key={post._id}
        mode="edit"
        postCollectionType={postsCollectionType}
        // if email, use a larger font size for the first headline
        className={`${
          postsCollectionType === 'email'
            ? 'text-3xl text-left'
            : 'text-3xl text-center px-4'
        }`}
      >
        {post.description}
      </PostHeadline>
    ) : post.format === 'paragraph' ? (
      <PostParagraph key={post._id} mode="edit">
        {post.description}
      </PostParagraph>
    ) : post.format === 'progress' ? (
      <PostProgress key={post._id} post={post} mode="edit" />
    ) : post.format === 'card' ? (
      <PostCard
        key={post._id}
        post={post}
        mode="edit"
        postCollectionType={postsCollectionType}
      />
    ) : post.format === 'image' ? (
      <PostImages key={post._id} post={post} mode="edit" />
    ) : null;
  };

  const renderItem = (item) => {
    return (
      <div className="flex flex-row items-start justify-between w-full">
        <div
          className={`${
            isSortable
              ? `h-24 overflow-y-hidden shadow-md bg-white dark:bg-black pt-7 items-center`
              : `overflow-hidden`
          } ${postsCollectionType === 'email' ? `sm:w-[600px]` : `w-full`}
          `}
        >
          {renderPost(item)}
        </div>
        <div className={`relative h-4 w-3 pt-2 translate-x-1.5`}>
          {renderMenu(item)}
        </div>
      </div>
    );
  };

  const renderPosts = () => {
    if (loading?.posts) {
      return <Loader type="table" />;
    }

    if (items?.length > 0 && posts?.length > 0) {
      return (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={items.map((item) => item._id)}
            strategy={verticalListSortingStrategy}
          >
            <div
              className={`w-[90vw] py-10 mx-auto   border-1 border-black  rounded space-y-4 ${
                isSortable ? `` : `bg-white dark:bg-black shadow`
              } ${
                postsCollectionType === 'email' ? `sm:w-[600px]` : ` w-full`
              }`}
            >
              {items.map((item) => {
                return (
                  <div className="relative scale-95 " key={item._id}>
                    {isSortable ? (
                      <SortableItem id={item._id}>
                        {renderItem(item)}
                      </SortableItem>
                    ) : (
                      renderItem(item)
                    )}
                  </div>
                );
              })}
            </div>
          </SortableContext>
        </DndContext>
      );
    } else {
      return (
        <div className="flex flex-row items-center justify-center font-extralight h-[30vh] w-[90vw] py-10 mx-auto bg-white dark:bg-black overflow-hidden border-1 border-black shadow rounded space-y-8">
          A blank canvas. A world of possibility.
        </div>
      );
    }
  };

  if (loading?.posts) {
    return <Loader />;
  }

  if (!postsCollection) {
    return (
      <div className="flex flex-col items-center gap-2">
        <h1 className="text-center">No posts found</h1>
        <Link to="/ape-admin/posts">Back to Posts</Link>
      </div>
    );
  }

  return (
    <div
      className="flex flex-col items-center gap-2 w-full"
      id="posts-admin-container"
    >
      <div className="text-center text-sm text-gray-500 px-0">
        <Link to={`/ape-admin/posts/${postsCollection[id]?.type}`}>
          {postsCollection ? postsCollection[id]?.type : null} posts
        </Link>
      </div>
      <h1 className="text-center">
        {postsCollection ? postsCollection[id]?.title : null}
      </h1>
      <div className="text-center text-sm text-gray-500 px-0">
        {postsCollection ? postsCollection[id]?.description : null}
      </div>
      <div className="text-center text-sm text-gray-500 px-0">
        Publish:{' '}
        {postsCollection ? fixDate(postsCollection[id]?.publishedAt) : null}
      </div>

      {renderAddPostButton()}
      {renderStats()}

      {renderPosts()}
      <AddPostModal
        post={selectedPost}
        onClose={() => setSelectedPost(null)}
        postsCollectionId={id}
        postsCollectionType={postsCollectionType}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const posts = state.ape?.posts ? Object.values(state.ape.posts) : null;

  const orderedPosts = posts?.map((post) => {
    if (!post?.order) {
      post.order = posts.indexOf(post);
    }
    return post;
  });

  // sort by order
  orderedPosts?.sort((a, b) => a.order - b.order);

  const userId = state.auth?.userId;

  return {
    userId,
    posts: orderedPosts,
    postsCollection: state.ape?.postCollections,
    loading: state.loader?.elements,
  };
};

export default connect(mapStateToProps, {
  apeFetchPosts,
  apeDeletePost,
  apeReorderPosts,
  apeSendTestEmail,
  showModal,
  addNotice,
})(PostsAdmin);

import { ADD_MODAL, HIDE_MODAL, DELETE_MODAL } from '../actions/types';

const INITIAL_STATE = {
  modal: null,
  visible: false,
  property: null,
};

const modalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_MODAL:
      return {
        ...state,
        modalType: action.payload,
        property: action.property,
        visible: true,
      };
    case HIDE_MODAL:
      return { ...state, visible: false };
    case DELETE_MODAL:
      return { ...state, modalType: null, visible: false, property: null };
    default:
      return state;
  }
};

export default modalReducer;

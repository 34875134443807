import './index.css';
import React from 'react';

import Loader from '../../Loader';

import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/solid';

const PostImages = ({
  post,
  noCarousel,
  customClass = '',
  badge,
  leftBadge,
}) => {
  const carouselRef = React.useRef(null);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const onScroll = () => {
    const scrollPosition = carouselRef.current.scrollLeft;
    const slideWidth = carouselRef.current.children[0].offsetWidth;
    const currentSlide = Math.round(scrollPosition / slideWidth);
    setCurrentSlide(currentSlide);
  };

  const { REACT_APP_AWS_S3 } = process.env;

  if (!post) {
    return <Loader type="image" className="carousel-item w-full" />;
  }

  const { name, description, images } = post;
  const renderImages =
    images && images.length > 0 && !noCarousel ? (
      Object.values(images).map((image) => {
        // convert object to array
        const { imageFile } = image;
        const imageUri = REACT_APP_AWS_S3 + '/' + imageFile;

        return (
          <div className={`carousel-item w-full bg-gray-500`} key={imageFile}>
            {loading && (
              <Loader type="image" className="carousel-item w-full" />
            )}
            <img
              key={imageFile}
              src={imageUri}
              alt={`${name} ${description}`}
              className="object-scale-down w-full animate-fade-in bg-white opacity-90"
              style={{
                display: loading ? 'none' : 'block',
                width: '100%',
              }}
              onLoad={() => setLoading(false)}
            />
          </div>
        );
      })
    ) : images && images.length > 0 ? (
      <div
        className={`carousel-item w-full bg-gray-500`}
        key={images[0].imageFile}
      >
        {loading && (
          <Loader
            type="image"
            className="carousel-item w-full bg-blend-darken"
          />
        )}
        <img
          key={images[0].imageFile}
          src={REACT_APP_AWS_S3 + '/' + images[0].imageFile}
          alt={`${name} ${description}`}
          className={`object-scale-down w-full animate-fade-in bg-white opacity-90`}
          style={{
            display: loading ? 'none' : 'block',
            width: '100%',
          }}
          onLoad={() => setLoading(false)}
        />
      </div>
    ) : (
      <div className="text-center bg-gray-200 grid w-full">
        {/* <div className="card-body"></div> */}
      </div>
    );

  const renderDots =
    !noCarousel &&
    images &&
    images.length > 1 &&
    Object.values(images).map((image, index) => {
      // conver object to array
      const { imageFile } = image;
      // on click, scroll to image
      const onClick = () => {
        const slideWidth = carouselRef.current.children[0].offsetWidth;
        carouselRef.current.scrollLeft = index * slideWidth;
      };
      return (
        <div
          className={`dot ${
            currentSlide === index ? 'active' : 'cursor-pointer'
          }`}
          key={imageFile}
          onClick={onClick}
        ></div>
      );
    });

  const onArrowClick = (direction) => {
    const slideWidth = carouselRef.current.children[0].offsetWidth;
    if (direction === 'left') {
      carouselRef.current.scrollLeft -= slideWidth;
    } else {
      carouselRef.current.scrollLeft += slideWidth;
    }
  };

  // left and right arrows
  const renderArrows = !noCarousel && (
    <div className="relative w-full">
      <div className="absolute m-2 hover:opacity-50 opacity-10 transition-opacity duration-200 inset-0 flex items-center justify-between z-10">
        <div onClick={() => onArrowClick('left')}>
          <ArrowLeftCircleIcon className="h-6 w-6 text-gray-400 cursor-pointer" />
        </div>
        <div onClick={() => onArrowClick('right')}>
          <ArrowRightCircleIcon className="h-6 w-6 text-gray-400 cursor-pointer" />
        </div>
      </div>
    </div>
  );

  const carouselBlock = !noCarousel ? (
    <>
      {images?.length > 1 && renderArrows}
      <div
        className="carousel mx-auto w-full"
        ref={carouselRef}
        onScroll={onScroll}
      >
        {renderImages}
      </div>
    </>
  ) : (
    <div className="mx-auto w-full">
      {leftBadge && (
        <div className="absolute top-0 left-2 mt-2 z-20">
          <div className="badge badge-ghost border-none bg-brand font-extralight text-xs text-base-100">
            {leftBadge}
          </div>
        </div>
      )}
      <div className={`${customClass}`} ref={carouselRef}>
        {renderImages}
      </div>
    </div>
  );

  return (
    <>
      {carouselBlock}
      {images?.length > 1 && !noCarousel && (
        <div className="dots">{renderDots}</div>
      )}
    </>
  );
};

export default PostImages;

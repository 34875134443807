import React from 'react';
import Modal from '../../../Modal';

import { connect } from 'react-redux';

import { Form, Field } from 'react-final-form';
import CustomInput from '../../../forms/CustomInput';
import CustomRichTextarea from '../../../forms/CustomRichTextarea';
import CustomDropdown from '../../../forms/CustomDropdown';
import CustomSelectMulti from '../../../forms/CustomSelectMulti';
import ImageUpload from '../../../forms/ImageUpload';

import {
  showModal,
  hideModal,
  addNotice,
  apeUpdatePost,
  searchSubjects,
  searchCollections,
  clearResultsOfType,
} from '../../../../actions';

import { XMarkIcon } from '@heroicons/react/24/outline';

const EditPostModal = ({
  modal,
  post,
  postsCollectionId,
  hideModal,
  subjectOptions,
  collectionOptions,
  apeUpdatePost,
  searchSubjects,
  searchCollections,
  onClose,
  clearResultsOfType,
  postsCollectionType,
}) => {
  // if post is provided, format postSubject and postCollection
  const [initPost, setInitPost] = React.useState(null);
  const [mount, setMount] = React.useState(false);
  const [subjectIdState, setSubjectIdState] = React.useState(null);

  const formatOptions =
    postsCollectionType === 'feed'
      ? [
          { value: 'card', label: 'Card' },
          { value: 'carousel', label: 'Carousel' },
          { value: 'headline', label: 'Headline' },
          { value: 'progress', label: 'Progress' },
        ]
      : postsCollectionType === 'email'
      ? [
          { value: 'card', label: 'Card' },
          // { value: 'build_card', label: 'Build Card' },
          { value: 'headline', label: 'Headline' },
          { value: 'paragraph', label: 'Paragraph' },
          { value: 'image', label: 'Image' },
        ]
      : [
          { value: 'card', label: 'Card' },
          { value: 'carousel', label: 'Carousel' },
          { value: 'headline', label: 'Headline' },
          { value: 'paragraph', label: 'Paragraph' },
          { value: 'progress', label: 'Progress' },
        ];

  const handleUpdatePost = async (values) => {
    // console.log('values', values);
    const response = await apeUpdatePost({
      ...values,
      postId: post?._id,
      postCollectionId: postsCollectionId,
    });

    setInitPost(null);
    onClose();
    clearResultsOfType('collection');

    return response;
  };

  const handleDeleteImage = async (imageFile) => {
    // await this.props.deleteBuildImage(this.props.match.params.id, imageFile);
    console.log('delete image', imageFile);
  };

  const yesHideModal = () => {
    setInitPost(null);
    onClose();
    clearResultsOfType('collection');
    hideModal();
  };

  const handleHideModal = () => {
    // are you sure?
    window.confirm('Are you sure you want to close this modal?') &&
      yesHideModal();
  };

  // function to format defaultValues coming from props
  const formatPostOptions = (options) => {
    if (!options || !options.length || !Array.isArray(options) || !options[0]) {
      return [];
    }
    return options.map((option) => {
      const key = Object.keys(option)[0];
      const value = { [key]: option[key] };
      const label = option[key]?.name;
      return {
        value,
        label,
      };
    });
  };

  React.useEffect(() => {
    if (!mount && post) {
      const postSubject = formatPostOptions(post.postSubject);
      const postCollection = formatPostOptions(post.postCollection);

      setInitPost({
        ...post,
        postSubject,
        postCollection,
        format: post.format || 'card',
        _id: post._id || '_isNew_',
      });

      setMount(true);
    } else if (!mount && !post) {
      setInitPost(null);
    }
  }, [post, mount]);

  React.useEffect(() => {
    if (
      (post && !subjectIdState) ||
      (post && subjectIdState !== post.postSubject[0]._id)
    ) {
      // search collections for subject
      const subjectId = post?.postSubject?.length
        ? post?.postSubject[0]?._athlete?._id ||
          post?.postSubject[0]?._list?._id
        : null;
      searchCollections(subjectId);
      setSubjectIdState(subjectId);
    } else if (!post) {
      setSubjectIdState(null);
    }
  }, [post, searchCollections, subjectIdState]);

  // unmount on hideModal
  React.useEffect(() => {
    if (!modal.visible) {
      setMount(false);
      setInitPost(null);
    }
  }, [modal.visible]);

  // return if modal is not visible
  if (!modal.visible || modal.modalType !== 'APE_EDIT_POST') {
    return null;
  }

  const renderPostForm = () => {
    const initialValues = initPost
      ? initPost
      : {
          _id: '_isNew_', // this is a new post
          format: 'card',
        };
    return (
      <Form
        onSubmit={handleUpdatePost}
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};

          const simpleFormat = ['headline', 'progress', 'paragraph'].includes(
            values?.format
          );

          const imageFormat = ['image'].includes(values.format);

          if (!values.title && !simpleFormat && !imageFormat) {
            errors.title = 'Required';
          }
          if (!values.postSubject && !simpleFormat && !imageFormat) {
            errors.postSubject = 'Required';
          }
          if (!values.postCollection && !simpleFormat && !imageFormat) {
            errors.postCollection = 'Required';
          }
          if (!values.category && !simpleFormat && !imageFormat) {
            errors.category = 'Required';
          }
          // strip html tags from description
          const strippedDescription = values.description?.replace(
            /(<([^>]+)>)/gi,
            ''
          );
          if (!strippedDescription && (simpleFormat || imageFormat)) {
            errors.description = 'Required';
          }
          if (!values.images && imageFormat) {
            errors.images = 'Required';
          }
          return errors;
        }}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          simpleFormat,
        }) => (
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            {/* Format Dropdown */}
            <Field
              name="format"
              component={CustomDropdown}
              label="Format"
              disabled={values._id !== '_isNew_'}
              options={formatOptions}
            />
            {/* Post Subject Profile and List CustomSelect no create */}
            {!['headline', 'progress', 'paragraph', 'image'].includes(
              values.format
            ) && (
              <Field
                name="postSubject"
                component={CustomSelectMulti}
                label="Featured Profile or List"
                options={subjectOptions}
                isCreatable={false}
                isMulti={false}
                searchAction={searchSubjects}
                onChange={(value) => {
                  if (value?.value && Object.keys(value.value).length) {
                    // get value from object on first property
                    const subjectId = Object.values(value.value)[0];
                    searchCollections(subjectId);
                  }
                }}
              />
            )}
            {/* Post Collection Build and Items CustomSelectMulti Array */}
            {!['headline', 'progress', 'paragraph', 'image'].includes(
              values.format
            ) && (
              <Field
                name="postCollection"
                component={CustomSelectMulti}
                label="Collection"
                options={collectionOptions}
                isCreatable={false}
                isMulti={true}
                searchAction={() => {}}
                minOptions={1}
              />
            )}
            {/* Category Custom Input */}
            {!['headline', 'progress', 'paragraph', 'image'].includes(
              values.format
            ) && (
              <Field
                name="category"
                component={CustomInput}
                // if email, change label to Button
                label={postsCollectionType === 'email' ? 'Button' : 'Category'}
                type="text"
                placeholder={
                  postsCollectionType === 'email'
                    ? 'View Build'
                    : 'e.g. Athlete Spotlight'
                }
              />
            )}
            {/* Title Custom Input */}
            {!['headline', 'progress', 'paragraph', 'image'].includes(
              values.format
            ) && (
              <Field
                name="title"
                component={CustomInput}
                label="Title"
                type="text"
                placeholder="Headline title text"
              />
            )}

            {/* Image Custom Input */}
            {['image'].includes(values.format) && (
              <Field
                name="images"
                component={ImageUpload}
                label="Image"
                handleDeleteImage={handleDeleteImage}
                submitting={submitting}
                maxImages={1}
                takeImageAspect
              />
            )}

            {/* Description Custom Input */}

            <Field
              name="description"
              component={CustomRichTextarea}
              label={`${
                values.format.includes('image')
                  ? 'Image alternative text'
                  : values.format.includes('headline')
                  ? 'Headline'
                  : values.format.includes('paragraph')
                  ? 'Paragraph'
                  : 'Description'
              }`}
              placeholder="Tell a story about the post"
            />

            {/* Submit Button */}
            <button
              type="submit"
              className="btn btn-primary"
              disabled={submitting || pristine}
            >
              {submitting
                ? 'Posting...'
                : values._id === '_isNew_'
                ? 'Add Post'
                : 'Update Post'}
            </button>
          </form>
        )}
      />
    );
  };

  const renderContent = () => {
    // new post or edit post
    const title = post ? 'Edit Post' : 'Add Post';

    return (
      <div className="flex flex-col">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <div className="text-xl font-bold">{title}</div>
            </div>
            <div className="flex flex-row justify-end">
              <button
                className="btn btn-square btn-ghost btn-sm"
                onClick={() => handleHideModal()}
              >
                <XMarkIcon className="h-6 w-6 text-gray-500" />
              </button>
            </div>
          </div>
          <div className="flex flex-col mt-4">
            <div className="flex flex-col mt-2">{renderPostForm()}</div>
          </div>
        </div>
      </div>
    );
  };

  if (!modal.visible || modal.modalType !== 'APE_EDIT_POST') {
    return null;
  }

  return (
    <Modal content={renderContent()} onDismiss={() => handleHideModal()} />
  );
};

const mapStateToProps = (state) => {
  const getStructuredVanillaOptions = (options, key) => {
    // if no options, return empty array
    if (!Array.isArray(options)) {
      return [];
    }
    return options?.map((option) => {
      // strip plural and add _ prefix to key
      const property = `_${key.replace(/s$/, '')}`;

      const value = { [property]: option._id };
      return {
        value,
        label: option.name?.length ? option.name : option.label,
      };
    });
  };

  const resultsByCategory = (results) => {
    if (!results) {
      return [];
    }
    // get keys of result object
    const keys = Object.keys(results);

    // map over keys and return as array of objects with key as category
    return keys.map((key) => {
      const customKey = key === 'athletes' ? 'profile' : key;
      return {
        label: customKey,
        value: results[key],
        options: getStructuredVanillaOptions(results[key], key),
      };
    });
  };

  const subjectOptions = resultsByCategory(state.search.subject);

  const collectionsBySubject = (subject) => {
    if (!subject) {
      return [];
    }
    // get keys of result object
    const keys = Object.keys(subject);

    if (!keys.length) {
      return [];
    }

    // map over keys and return as array of objects with key as category
    return keys.map((key) => {
      return {
        label: key,
        value: subject[key],
        options: getStructuredVanillaOptions(subject[key], key),
      };
    });
  };

  const collectionOptions = collectionsBySubject(state.search.collection);

  return {
    modal: state.modal,
    subjectOptions,
    collectionOptions,
  };
};

export default connect(mapStateToProps, {
  showModal,
  hideModal,
  addNotice,
  apeUpdatePost,
  searchSubjects,
  searchCollections,
  clearResultsOfType,
})(EditPostModal);

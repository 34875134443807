import axios from 'axios';

import {
  APE_FETCH_DISTRIBUTOR,
  APE_FETCH_DISTRIBUTORS,
  APE_DELETE_DISTRIBUTOR,
  APE_UPDATE_DISTRIBUTOR,
} from '../types';

import { addNotice } from '../notices';
import { hideModal } from '../modal';
import { setLoader, setLoaderElement } from '../loader';

export const apeFetchDistributors = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setLoaderElement('distributors'));

    try {
      const res = await axios.get('/api/ape/distributors');

      dispatch({
        type: APE_FETCH_DISTRIBUTORS,
        payload: res.data,
      });
    } catch (error) {
      dispatch(
        addNotice({ type: 'error', notice: error.message, time: new Date() })
      );
    }

    dispatch(setLoader(false));
  };
};

export const apeFetchDistributor = (id) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setLoaderElement('distributor'));

    try {
      const res = await axios.get(`/api/ape/distributors/${id}`);

      dispatch({
        type: APE_FETCH_DISTRIBUTOR,
        payload: res.data,
      });
    } catch (error) {
      dispatch(
        addNotice({ type: 'error', notice: error.message, time: new Date() })
      );
    }

    dispatch(setLoader(false));
  };
};

export const apeUpsertDistributor = (distributor) => async (dispatch) => {
  dispatch(setLoader(true));

  try {
    const res = await axios.put('/api/ape/distributors', distributor);

    dispatch({
      type: APE_UPDATE_DISTRIBUTOR,
      payload: res.data,
    });
    dispatch(hideModal());
    dispatch(
      addNotice({
        type: 'positive',
        notice: 'Distributor saved',
        time: new Date(),
      })
    );
  } catch (error) {
    dispatch(
      addNotice({ type: 'error', notice: error.message, time: new Date() })
    );
  }

  dispatch(setLoader(false));
};

export const apeDeleteDistributor = (id) => {
  return async (dispatch) => {
    dispatch(setLoader(true));

    try {
      const res = await axios.delete(`/api/ape/distributors/${id}`);

      dispatch({
        type: APE_DELETE_DISTRIBUTOR,
        payload: res.data,
      });

      dispatch(addNotice({ type: 'success', notice: 'Distributor deleted' }));
    } catch (error) {
      dispatch(
        addNotice({ type: 'error', notice: error.message, time: new Date() })
      );
    }

    dispatch(setLoader(false));
  };
};

import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import { searchLocations } from '../../../../actions';
import { Link } from 'react-router-dom';
import { MapPinIcon } from '@heroicons/react/24/outline';

const FindLocal = ({ local, searchLocations, make, profileGeo }) => {
  const [location, setLocation] = useState({});
  const [mount, setMount] = useState(false);
  const [localLoaded, setLocalLoaded] = useState(false);
  const [noLocal, setNoLocal] = useState(false);
  const [searching, setSearching] = useState(false);

  const reportError = (error) => {
    console.error(error);
    setNoLocal(true);
    setSearching(false);
  };

  useEffect(() => {
    if (!profileGeo && !mount) {
      const getLocation = (navigator) => {
        navigator.geolocation.getCurrentPosition((position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        }, reportError);
      };
      if (!location.lat && !mount) {
        getLocation(navigator);
      }
    }
    setMount(true);
  }, [location, mount, localLoaded, profileGeo]);

  useEffect(() => {
    if (profileGeo && !mount) {
      setLocation({
        lat: profileGeo[1],
        lng: profileGeo[0],
      });
      setMount(true);
    }
  }, [profileGeo, mount]);

  const getLocation = (navigator) => {
    setSearching(true);
    navigator.geolocation.getCurrentPosition((position) => {
      setLocalLoaded(false);
      setLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      setSearching(false);
    }, reportError);
  };

  useEffect(() => {
    if (location.lat && !localLoaded) {
      // object to string
      const geo = `${location.lat},${location.lng}`;
      const criteria = { make };
      searchLocations(geo, criteria);
      setLocalLoaded(true);
    }
  }, [location, localLoaded, searchLocations, make]);

  // convert distance to miles
  const toMiles = (distance) => {
    return (distance * 0.000621371192).toFixed(0);
  };

  return (
    <div>
      <div className="flex flex-row justify-between">
        <h2>Find Local</h2>
        <button
          onClick={() => {
            getLocation(navigator);
          }}
          className={`btn btn-brand btn-xs gap-1 ${
            searching ? `animate-pulse` : ``
          }`}
        >
          <MapPinIcon
            className={`h-4 w-4 ${searching ? `animate-pulse` : ``}`}
          />
          {searching ? 'Searching...' : 'Search Near Me'}
        </button>
      </div>
      {local?.length > 0 ? (
        <>
          <small>
            These locations may stock or are able to special order this item.
          </small>
          <ul>
            {local.map(({ name: brand, locations, alias, dist }, index) => (
              <li key={index} className="my-3">
                <ul className="grid grid-cols-1 gap-4">
                  {locations.map(
                    ({ name, dist, email, address, phone }, index) => (
                      <li
                        className="border p-3 rounded-md shadow-md grid grid-cols-2 gap-x-6"
                        key={index}
                      >
                        <h4>
                          <Link to={`/${alias}`} className="link">
                            {brand} {name}
                          </Link>
                        </h4>
                        <div className="text-sm mt-0.5">
                          {dist && toMiles(dist)} miles
                        </div>
                        <div className="text-sm">{address}</div>
                        <div className="flex flex-col text-sm">
                          <div>{email}</div>
                          <div>{phone}</div>
                        </div>
                      </li>
                    )
                  )}
                </ul>
              </li>
            ))}
          </ul>
        </>
      ) : noLocal ? (
        <p>Geolocation deactivated</p>
      ) : !localLoaded ? (
        <p>Loading...</p>
      ) : (
        <p>No local places found</p>
      )}
      <small>
        <i>
          Own or manage a local bike shop that carries this make? Please reach
          out to us to get started.
        </i>
      </small>
    </div>
  );
};

const mapStateToProps = (state) => {
  const profileGeo = state.auth?.profile?.locationGeo?.coordinates;

  return {
    local: state.search.local,
    profileGeo,
  };
};

export default connect(mapStateToProps, { searchLocations })(FindLocal);

import React from "react";
// import { TransitionablePortal, Segment, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { addNotice, hideNotice, deleteNotice } from "../actions";

class Notice extends React.Component {

    componentDidMount() {
        this.expireNotice();
    }

    expireNotice() {
        if (!this.props.notice) {
            return;
        }
        setTimeout(() => {
            if (!this.props.notice) {
                return;
            }
            const { time } = this.props.notice;
            const timeElapsed = new Date() - time;
      
            if (timeElapsed>4500) {
                if (this.props.visible) {
                    this.props.hideNotice();
                    setTimeout(() => {
                        this.props.deleteNotice();
                    }, 500);
                }
            }
        }, 5000);
    }

    render() {
        if (!this.props.notice?.notice) {
            return;
        } 

        this.expireNotice();

        const { notice, type } = this.props.notice;
        const { visible } = this.props;

        const alertType = (type) => {
            switch (type) {
                case 'success':
                    return 'alert-success';
                case 'error':
                    return 'alert-error';
                default:
                    return 'alert-info';
            }
        }

        return (
        <div className="toast toast-center sm:toast-top min-w-fit whitespace-nowrap sm:top-5 z-50">
            <div className={`alert shadow-xl ${alertType(type)} ${visible ? '' : 'animate-fade-down sm:animate-fade-up'}`} role="alert">
                <div>
                    <span>{notice}</span>
                </div>
            </div>
        </div>
    );
    }

};


const mapStateToProps = (state) => {
    return { 
        notice: state.notice.notice,
        visible: state.notice.visible
    };
};

export default connect(
    mapStateToProps, 
    {addNotice,hideNotice,deleteNotice} // refers to the two action creators
)(Notice);
import _ from 'lodash';
import React from 'react';

import { Route, Routes, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { getAthlete } from '../../actions';

import AthleteBuilds from './view/builds';
import AthleteLists from './view/lists';
import AthleteItems from './view/items';

import NotFound from '../404';

import Page from '../ui/Page';
import Loader from '../Loader';

import AthleteHeader from './header';
import Header from '../header';

import Helmet from '../ui/Helmet';

const AthleteRouter = (props) => {
  const { alias } = useParams();

  // on mount, get the athlete
  const [mounted, setMounted] = React.useState(null);

  React.useEffect(() => {
    if (!mounted || alias !== mounted) {
      setMounted(alias);
      props.getAthlete(alias);
    }
  }, [mounted, props, alias]);

  const { auth, loader } = props;

  const athlete = props.athlete[alias];
  const id = athlete ? athlete._id : null;

  // is this the logged in user?
  const myAthlete = auth && auth.profile && auth.profile._id === id;

  if (
    (loader && loader.page) ||
    _.isEmpty(props.athlete) ||
    (!props.athlete?.undefined && !props.athlete[alias])
  ) {
    return <Loader />;
  }
  if (props.athlete?.undefined) {
    return <NotFound />;
  }

  // get athlete type and convert to title case
  const athleteType =
    athlete?.profileType !== 'athlete' ? athlete?.profileType : 'cyclist';

  return (
    <Page className="flex h-screen">
      <Helmet
        title={`${athlete?.name}, a ${athleteType} on Active Projects`}
        description={`View ${athlete?.name}'s builds, lists, and items on Active Projects`}
        url={`${process.env.REACT_APP_URL}/${alias}/`}
      />
      <Header />
      <AthleteHeader athlete={athlete} auth={auth} loader={loader} />
      <Routes basename="/:alias">
        <Route
          path="/*"
          element={
            <AthleteBuilds
              athleteId={id}
              myAthlete={myAthlete}
              athlete={athlete}
            />
          }
        />
        <Route
          path="/lists/*"
          element={
            <AthleteLists
              athleteId={id}
              myAthlete={myAthlete}
              athlete={athlete}
            />
          }
        />
        <Route
          path="/items/*"
          element={
            <AthleteItems
              athleteId={id}
              myAthlete={myAthlete}
              athlete={athlete}
            />
          }
        />
      </Routes>
    </Page>
  );
};

function mapStateToProps(state) {
  const isSignedIn = state.auth?.userId;

  return {
    athlete: state.athlete,
    auth: state.auth,
    isSignedIn,
    loader: state.loader,
  };
}

export default connect(mapStateToProps, { getAthlete })(AthleteRouter);

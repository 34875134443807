import React from 'react';

// heroicon for default profile image
import { UserCircleIcon } from '@heroicons/react/24/solid';

const ProfileImages = ({ profiles, className }) => {
  if (!profiles || profiles.length === 0) {
    return (
      <div className="avatar placeholder">
        <div className="bg-accent text-neutral-content rounded-full text-xs items-center inset-0 w-7">
          <UserCircleIcon className={className} />
        </div>
      </div>
    );
  }

  // countr profiles
  const profileCount = profiles?.length;

  // for each profile, render image
  const { REACT_APP_AWS_S3 } = process.env;

  // max 3 profiles
  const lineProfiles = profiles.slice(0, 3);

  const renderImages = () => {
    return lineProfiles.map((profile, index) => {
      const opacity =
        profile?.status === 'hidden' ? 'opacity-20' : 'opacity-90';
      const bgopacity =
        profile?.status === 'hidden' ? 'bg-base-200 opacity-80' : '';

      let imageElement = <></>;
      if (
        profileCount > 3 &&
        (index === profileCount + 2 || index === lineProfiles.length - 1)
      ) {
        return (
          <div className="avatar placeholder" key={index}>
            <div className="bg-accent text-neutral-content rounded-full text-xs items-center inset-0 w-7">
              {profileCount - index}
            </div>
          </div>
        );
      } else if (profileCount > 0 && profile?.profileImages?.length > 0) {
        const { imageFile } = profile.profileImages[0];
        const imageUri = REACT_APP_AWS_S3 + '/' + imageFile;
        imageElement = (
          <img src={imageUri} alt={profile.name} className={`${opacity}`} />
        );
        return (
          <div className="avatar" key={index}>
            <div className={`bg-gray-500 ${className}`}>{imageElement}</div>
          </div>
        );
      } else {
        return (
          <div className="avatar placeholder" key={index}>
            <div className={`bg-accent text-neutral-content rounded-full`}>
              <div className={bgopacity}>
                <UserCircleIcon className={className} />
              </div>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div className="avatar-group -space-x-4 min-w-fit">{renderImages()}</div>
  );
};

export default ProfileImages;

import { SET_LOADER, SET_LOADER_ELEMENT } from './types';

export const setLoader = (type, bool) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: { [type]: bool },
  });
};

export const setLoaderElement = (element, bool) => async (dispatch) => {
  dispatch({
    type: SET_LOADER_ELEMENT,
    payload: { [element]: bool },
  });
};

import React from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';

import APArrowLeftIcon from './ArrowLeftIcon';

const BuildForm = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleBackButton = () => {
    const { state } = location;
    const skipEdit = state ? state.skipEdit : false;
    if (skipEdit) {
      navigate(-3);
    } else {
      navigate(-1);
    }
  };

  const { state } = location;
  const backable = state ? state.backable : false;

  const backButton = () => (
    <div className="flex flex-initial sm:w-18">
      {backable && (
        <div className="px-2 m-1 w-full sm:px-0 sm:my-3 sm:ml-0">
          <Link
            className="btn btn-square btn-ghost"
            onClick={() => handleBackButton()}
          >
            <APArrowLeftIcon />
          </Link>
        </div>
      )}
    </div>
  );

  return (
    <div className="flex flex-col items-start justify-center w-screen sm:w-full sm:justify-start sm:flex-row mt-1 sm:-mt-1">
      {backButton()}
      <div className="w-full sm:w-10/12 bg-base-100 overflow-y-visible">
        {children}
      </div>
    </div>
  );
};

export default BuildForm;

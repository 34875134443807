import React from 'react';

import {
  fetchProfileLocations,
  deleteProfileLocation,
  showModal,
  addNotice,
} from '../../../../actions/';

import { connect } from 'react-redux';

import Loader from '../../../Loader';

import UpsertLocationModal from './add';
import {
  PlusCircleIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';

const Locations = ({
  locations,
  loading,
  showModal,
  fetchProfileLocations,
  deleteProfileLocation,
}) => {
  const [mounted, setMounted] = React.useState(false);
  const [selectedLocation, setSelectedLocation] = React.useState(null);

  const handleShowModal = (e) => {
    e.preventDefault();
    setSelectedLocation(null);
    showModal('UPSERT_LOCATION');
  };

  const handleEditClick = (e, locationId) => {
    e.preventDefault();
    console.log('locationId', locationId);
    setSelectedLocation(locationId);
    showModal('UPSERT_LOCATION');
  };

  const handleDeleteClick = (e, locationId) => {
    e.preventDefault();
    window.confirm('Are you sure you want to delete this location?') &&
      deleteProfileLocation(locationId);
  };

  React.useEffect(() => {
    if (!mounted) {
      fetchProfileLocations();
      setMounted(true);
    }
  }, [fetchProfileLocations, mounted]);

  const renderMenu = () => {
    return (
      <div className="flex flex-row gap-4 justify-center mt-6">
        <div className="flex flex-row items-center justify-center">
          <button
            className="btn btn-ghost btn-sm rounded-btn"
            onClick={handleShowModal}
          >
            <PlusCircleIcon className="h-5 w-5" />
            Add Location
          </button>
        </div>
      </div>
    );
  };

  const renderEditMenu = (locationId) => {
    return (
      <div className="flex flex-col items-center">
        <div className="dropdown dropdown-bottom dropdown-end hover:bg-gray-200 rounded-lg">
          <label tabIndex={0} className="cursor-pointer ">
            <EllipsisVerticalIcon className="h-5 w-5" />
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-48"
          >
            <li>
              <div
                className="text-base cursor-pointer"
                onClick={(e) => handleEditClick(e, locationId)}
              >
                Edit
              </div>
            </li>
            <li>
              <div
                className="text-base cursor-pointer"
                onClick={(e) => handleDeleteClick(e, locationId)}
              >
                Delete
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div>
      {loading?.locations && <Loader />}
      {renderMenu()}
      <div className="w-full flex flex-row items-center justify-center ">
        <div className="w-full md:w-3/6 max-w-md">
          {locations
            ? locations?.map((location) => (
                <div
                  key={location._id}
                  className="flex flex-col p-4 border border-gray-200 rounded-md mt-4 w-full"
                >
                  <div className="flex flex-row justify-between">
                    <div className="text-lg font-bold">{location.name}</div>
                    {renderEditMenu(location._id)}
                  </div>
                  <div className="flex flex-col">
                    <div>{location.address}</div>
                    <div>
                      {location?.phone ? location.phone : 'No phone'} |{' '}
                      {location?.email ? location.email : 'No email'}
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>

      <UpsertLocationModal locationId={selectedLocation} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const alias = state.auth?.profile?.alias;
  return {
    locations: state.athlete?.[alias]?.locations,
    loading: state.loader?.elements,
  };
};

export default connect(mapStateToProps, {
  fetchProfileLocations,
  deleteProfileLocation,
  showModal,
  addNotice,
})(Locations);

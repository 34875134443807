import React from 'react';

import { BookmarkIcon as EmptyBookmark } from '@heroicons/react/24/outline';
import { BookmarkIcon as SolidBookmark } from '@heroicons/react/24/solid';

import { connect } from 'react-redux';
import { addListItem, addListBuildItem } from '../../../actions';

const Bookmark = ({item, build, lists, saved, addListItem, addListBuildItem, right, isSignedIn}) => {

    const [loading, setLoading] = React.useState(false);
    const itemId = item ? item._id : null;
    const buildId = build ? build._id : null;

    React.useEffect(() => {
        setLoading(false);
    }, [saved]);

    // if list is _new_


    const onBookmarkClick = async (itemId, buildId, listId) => {
        isSignedIn && setLoading(true);
        itemId && listId && await addListItem(listId, itemId);
        buildId && listId && await addListBuildItem(listId, buildId);
    }

    const renderAddToList = () => {

        // render an add to list dropdown
        return (
            <div className={`dropdown dropdown-hover z-10 ${right ? `dropdown-right` : `dropdown-left`} h-8`}>
                <div>   
                    <label type="button" tabIndex={0} className="btn btn-square btn-ghost btn-sm m-0">
                        {saved ? <SolidBookmark className="h-6 w-6" /> : <EmptyBookmark className="h-6 w-6" />}
                    </label>
                </div>
                <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-200 rounded-box w-52 z-100">
                    {lists && Object.values(lists).map((list) => {
                        // is item in this list
                        const inList = list && list.items && itemId ? list.items.find(i => i._item?.hasOwnProperty('_id') && i._item._id === itemId) :
                            list && list.items && buildId ? list.items.find(i => i._build?.hasOwnProperty('_id') && i._build._id === buildId) : null;
                        // if item is in list add checkmark
                        const checkmark = inList ? <span className="ml-2">✓</span> : null;
                        return (
                            <li key={list._id}>
                                <div onClick={() => onBookmarkClick(itemId, buildId, list._id)} >{checkmark} {list.name}</div>
                            </li>
                        );
                    })
                    }
                </ul>
            </div>
        );
    }
    
    return (
        !loading ? renderAddToList() :
        (<button className="btn btn-square btn-ghost btn-sm m-0">
            <svg className="animate-spin h-6 w-6 text-slate-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
            </svg>
        </button>)
    );
}

const mapStateToProps = (state, ownProps) => {
    const item = ownProps.item ? ownProps.item : {};
    const build = ownProps.build ? ownProps.build : {};

    // is item in any of the user's lists
    const lists = ownProps.lists ? ownProps.lists : {};
    let saved;

    // return id of _item and list it is in if it is in a list else return null
    if (item && item._id && lists && lists.length>0) {
        saved = lists && lists.length>0 && lists.filter(
            list => list.hasOwnProperty('items') && list.items.find(
                i => i._item && i._item.hasOwnProperty('_id') && i._item._id === item._id)
            ).length;
    } else if (build && build._id && lists && lists.length>0) {
        saved = lists && lists.length>0 && lists.filter(
            list => list.hasOwnProperty('items') && list.items.find(
                i => i._build && i._build.hasOwnProperty('_id') && i._build._id === build._id)
            ).length;
    }

    const isSignedIn = state.auth?.userId;

    return { 
        saved,
        isSignedIn
     };
}

export default connect(mapStateToProps, { addListItem, addListBuildItem })(Bookmark);
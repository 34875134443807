import { 
    SET_LOADER,
    SET_LOADER_ELEMENT
} from '../actions/types';

const INITIAL_STATE = {
    pusher: true,
    page: true,
    form: false,
    search: false,
    builds: false,
    like: false,
    elements: {}
}

const loaderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADER:
            return { ...state, ...action.payload };
        case SET_LOADER_ELEMENT:
            return { ...state, elements: { ...state.elements, ...action.payload } };
        default:
            return state;
    }
};

export default loaderReducer;
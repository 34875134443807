import React from 'react';

import { ArrowUpRightIcon } from '@heroicons/react/24/outline';

import { connect } from 'react-redux';
import { addNotice } from '../../../actions';

const { REACT_APP_URL } = process.env;

const Share = ({build, item, addNotice}) => {

    const onShareClick = async (build, item) => {

        let url = REACT_APP_URL;
        let title = '';
        let text = 'Check out this item on Active Projects';

        if (item) {
            url += '/item/' + item._id;
            title = item.name;
        } else if (build) {
            url += '/build/' + build._id;
            title = build.name;
        }

        if (navigator.share) {
            navigator
              .share({
                title,
                text,
                url
            });
          } else {
            navigator.clipboard.writeText(url);
            addNotice({notice: 'Link copied to clipboard', type: 'success', time: new Date()});
          }
    }
    
    return (<button onClick={() => onShareClick(build, item)} className="btn btn-square btn-ghost btn-sm">
            <ArrowUpRightIcon className="h-6 w-6" />
        </button>);
}

const mapStateToProps = (state, ownProps) => {
    const build = ownProps.build;
    const item = ownProps.item;

    return { build, item };
}

export default connect(mapStateToProps, { addNotice } )(Share);
import React from 'react';

import RecordEvent from '../../analytics/pageview';

import { ArrowLeftIcon } from '@heroicons/react/24/solid';

import { connect } from 'react-redux';
import { disconnectStrava } from '../../../actions';

const Waitlist = ({
  profile = { name: 'there' },
  stravaId,
  disconnectStrava,
  onClickHandler,
}) => {
  const { name } = profile;
  return (
    <div className="h-screen flex flex-col items-center justify-center p-5 dark:text-neutral bg-gradient-to-b from-yellow-300 to-yellow-200">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-16 h-16"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>

        <h1 className="text-4xl font-bold my-4">Hello, {name}!</h1>
        <h2 className="text-2xl font-bold my-2">It's nice to see you.</h2>
        <p className="py-4">
          At this time, Active Projects is in a closed beta. You have been added
          to the waitlist. We will notify you when you can sign in. Thank you
          for your interest.
        </p>
        {/* {name && name !== 'there' && ( */}
        <a
          className="underline underline-offset-8 mx-0 dark:bg-white dark:hover:bg-white mr-8"
          href="/api/logout"
        >
          Log out
        </a>
        {/* )} */}
        {stravaId && (
          <span
            className="text-gray-500 text-sm hover:underline hover:underline-offset-8 cursor-pointer"
            onClick={disconnectStrava}
          >
            Disconnect Strava
          </span>
        )}
        {onClickHandler && (
          <div
            className="btn btn-ghost btn-square -ml-2"
            onClick={() => onClickHandler('')}
          >
            <ArrowLeftIcon className="h-6 w-6" />
          </div>
        )}
      </div>
      <RecordEvent
        hitType="pageview"
        page={window.location.pathname}
        title="Onboarding - Joined Waitlist"
      />
    </div>
  );
};

export default connect(null, { disconnectStrava })(Waitlist);

import _ from 'lodash';
import React from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { clearNotifications, deleteNotifications } from '../../actions';

import { BellIcon, BellAlertIcon, TrashIcon } from '@heroicons/react/24/outline';

const Notifications = (props) => {

    const handleNotificationsClear = () => {
        // wait 5 seconds before clearing notifications
        setTimeout(async () => {
            await props.clearNotifications();
        }, 5000);
    };

    const handleNotificationsDelete = () => {
        // are you sure?
        window.confirm('Are you sure you want to delete all notifications?') && props.deleteNotifications();
    };

    if (!props.isSignedIn || !props.notifications?.notices?.length === 0) {
        return null;
    }

    const { notices, count } = props.notifications;

    return (
        <div className={`dropdown ${props.className} z-20`}>
            <label tabIndex={0} className="m-0 p-0 top-0 self-center cursor-pointer indicator">
                {count>0 ? (<div className="indicator" onClick={handleNotificationsClear}>
                    <span className="indicator-item badge badge-warning badge-sm">{count}</span> 
                    <BellAlertIcon className="h-10 w-10" />
                </div>) : (<BellIcon className={`h-10 w-10 ${_.isEmpty(notices) && `text-gray-400`}`} />)}
            </label>
            {notices?.length >0 && (
            <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-200 rounded-box w-64 text-sm">
                {notices && notices.map((n, i) => {
                    return (
                        <li key={i}>
                            <Link 
                                to={`${process.env.REACT_APP_URL}/${n.uri}`}
                                className={`${!n.seen ? 'bg-yellow-300 dark:bg-yellow-800 bg-opacity-50 font-semibold' : 'transition ease-in-out bg-base-200 duration-1000'}`}
                                state={{ backable: true }}
                                >{n.notice}</Link>
                        </li>
                    );
                })}
                <li>
                    <Link className='btn btn-ghost' onClick={handleNotificationsDelete}>
                        <TrashIcon className="h-4 w-4" /> Clear all 
                    </Link>
                </li>
            </ul>)}
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    const athlete = (state.auth && state.auth.profile) ? state.auth.profile : {
        name: state.auth.name,
        email: state.auth.email,
        id: '_new_'
    };

    const isSignedIn = state.auth?.userId;

    return {
        auth: state.auth,
        isSignedIn,
        athlete,
        notifications: state.notifications
    }
}

export default connect(mapStateToProps, { clearNotifications, deleteNotifications })(Notifications);
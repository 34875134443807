import React from 'react';
import { connect } from 'react-redux';

import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import CustomInput from '../../../forms/CustomInput';
import CustomSelectCreate from '../../../forms/CustomSelectCreate';
import { searchProfiles } from '../../../../actions';

import { XMarkIcon, PlusIcon } from '@heroicons/react/24/outline';

const ListingsForm = ({ athleteOptions, searchProfiles }) => {
  function handleAthleteSearch(searchTerm) {
    searchProfiles(searchTerm);
  }

  const initListing = {
    _id: '_new_',
    price: 0,
    _athlete: '',
    promoText: '',
    URI: '',
    SKU: '',
  };

  return (
    <FieldArray
      name="listings"
      validate={(values) => {
        const errors = [];

        // validate that price is not 0
        if (values) {
          values.forEach((listing, index) => {
            const listingErrors = {};
            if (listing.price <= 0) {
              listingErrors.price = 'Prices must be greater than 0';
            }
            if (listing._athlete === '') {
              listingErrors._athlete = 'A seller is required';
            }
            if (listing.URI === '' && listing.SKU === '') {
              listingErrors.URI = 'A SKU or link is required';
            }
            errors[index] = listingErrors;
          });
        }

        return errors;
      }}
    >
      {({ fields }) => (
        <div className="flex flex-col gap-4">
          {fields?.length > 0 ? (
            fields.map((name, index) => (
              <div key={name} className="flex flex-col sm:flex-row my-2">
                <div className="flex flex-col gap-2">
                  {/* first line of listing */}
                  <div className="flex flex-col md:flex-row justify-between gap-4">
                    <div className="flex flex-col px-4 sm:px-0 w-30">
                      <Field
                        name={`${name}._id`}
                        component={CustomInput}
                        type="hidden"
                      />

                      <Field
                        name={`${name}.name`}
                        component={CustomInput}
                        type="text"
                        label="Name (Optional)"
                      />
                    </div>
                    <div className="flex flex-col px-4 sm:px-0 w-72">
                      <Field
                        name={`${name}._athlete`}
                        component={CustomSelectCreate}
                        options={athleteOptions}
                        searchAction={handleAthleteSearch}
                        // deactivate create option for make if user is not admin
                        isCreatable={false}
                        placeholder="Seller"
                        label="Seller"
                      />
                    </div>
                    <div className="flex flex-col px-4 sm:px-0">
                      <Field
                        name={`${name}.price`}
                        component={CustomInput}
                        type="number"
                        label="Price"
                      />
                    </div>
                  </div>
                  {/* second line of listing */}
                  <div className="flex flex-col md:flex-row gap-4">
                    <div className="flex flex-col px-4 sm:px-0">
                      <Field
                        name={`${name}.URI`}
                        component={CustomInput}
                        type="text"
                        label="Link"
                        className="input-sm"
                      />
                    </div>
                    <div className="flex flex-col px-4 sm:px-0">
                      <Field
                        name={`${name}.SKU`}
                        component={CustomInput}
                        type="text"
                        label="SKU"
                        className="input-sm"
                      />
                    </div>
                    <div className="flex flex-col px-4 sm:px-0">
                      <Field
                        name={`${name}.MPN`}
                        component={CustomInput}
                        type="text"
                        label="MPN"
                        className="input-sm"
                      />
                    </div>
                    <div className="flex flex-col px-4 sm:px-0">
                      <Field
                        name={`${name}.GTIN`}
                        component={CustomInput}
                        type="text"
                        label="GTIN"
                        className="input-sm"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-row px-4 sm:px-0 sm:flex-col gap-1 items-center">
                  <button
                    type="button"
                    className="btn btn-ghost btn-square"
                    onClick={() => fields.remove(index)}
                    // disabled={fields.length - 1 === 0}
                  >
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
          <div className="flex flex-col my-2 px-4 sm:px-0">
            <button
              type="button"
              className={`btn btn-outline`}
              onClick={() =>
                fields.push({
                  ...initListing,
                })
              }
            >
              <PlusIcon className="h-6 w-6" /> Add Listing
            </button>
          </div>
        </div>
      )}
    </FieldArray>
  );
};

const mapStateToProps = (state) => {
  const getVanillaOptions = (options) => {
    return options.map((option) => {
      return {
        value: option._id,
        label: option.name?.length ? option.name : option.label,
      };
    });
  };

  const athleteOptions = state.search.profiles?.length
    ? getVanillaOptions(state.search.profiles)
    : [];

  return {
    athleteOptions,
  };
};

export default connect(mapStateToProps, { searchProfiles })(ListingsForm);

import { 
    FETCH_STRAVA_ACTIVITIES
} from '../actions/types';

const INITIAL_STATE = {};

const stravaReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_STRAVA_ACTIVITIES:
            return { ...state, activities: action.payload };
        default:
            return state;
    }
};

export default stravaReducer;
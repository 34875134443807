import React from 'react';
import ProfileImage from '../image';

const AthleteCard = ({ athlete }) => {
  // get following count
  // const following = athlete._following ? athlete._following.length : 0;

  // const services = athlete.services ? athlete.services : [];

  // const renderServices = () => {
  //   let serviceList = '';
  //   if (services && services.length > 0) {
  //     serviceList = services.map((service) => {
  //       return (
  //         <div key={service._id} className="text-sm">
  //           {service.label}
  //         </div>
  //       );
  //     });
  //   } else {
  //     serviceList = <></>;
  //   }

  //   return (
  //     <div className="flex flex-row justify-center items-center gap-2 mb-2">
  //       {serviceList}
  //     </div>
  //   );
  // };

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-header-title">
          <div className="flex flex-col items-center">
            <div className="flex text-sm font-medium text-gray-900 justify-center">
              <ProfileImage athlete={athlete} className="w-24" />
            </div>
            <div className="text-sm text-gray-500 text-center">
              <h2>{athlete.name}</h2>
              {/* {renderServices()} */}
              {<div className="capitalize">{athlete?.profileType}</div>}
              {athlete.location}
              {/* {following > 0 && (
                <p className="text-sm text-gray-500">{following} following</p>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AthleteCard;

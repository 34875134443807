import React from "react";
import { Link } from "react-router-dom";

const CheckEmailNotice = () => {

    const [reveal, setReveal] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => {
            setReveal(true);
        }, 5000);
    });

    return (
        <>
        <h1 className="text-2xl font-bold">Please check your email for a link to login.</h1>
        <div className="text-xl">
                If you do not see it, please check your spam folder.
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-center gap-4 w-64">
                <Link
                    to="/login" 
                    className={`link link-primary text-blue-600 hover:text-blue-800 ${reveal ? `animate-fade-in` : `invisible`}`}
                >
                    Try another method
                </Link>
        </div>
        </>
    );
}

export default CheckEmailNotice;
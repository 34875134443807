import React from 'react';

import { connect } from 'react-redux';
import {
  apeFetchInvites,
  apeDeleteInvite,
  showModal,
  addNotice,
} from '../../../actions';

import AddInviteModal from './add';
import AthleteLine from '../../athlete/line';

import {
  PlusCircleIcon,
  EllipsisVerticalIcon,
  ClockIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';
import Loader from '../../Loader';

import timeFromNow from '../../../utils/timeFrom';

const InviteAdmin = ({
  apeFetchInvites,
  apeDeleteInvite,
  showModal,
  invites,
  addNotice,
  loading,
}) => {
  const [mounted, setMounted] = React.useState(null);

  React.useEffect(() => {
    // on mount, get the athlete
    if (!mounted) {
      setMounted(true);
      apeFetchInvites();
    }
  }, [apeFetchInvites, mounted]);

  const handleShowModal = (e) => {
    e.preventDefault();
    showModal('APE_ADD_INVITE');
  };

  const renderAddInviteButton = () => {
    return (
      <div className="flex flex-row justify-center items-center">
        <button
          className="btn btn-ghost btn-sm rounded-btn"
          onClick={handleShowModal}
        >
          <PlusCircleIcon className="h-5 w-5" />
          <span className="ml-2">Add Invite</span>
        </button>
      </div>
    );
  };

  const onShareClick = async (inviteLink) => {
    let title = 'Active Projects';
    let text = 'You are invited to Active Projects';

    if (navigator.share) {
      navigator.share({
        title,
        text,
        url: inviteLink,
      });
    } else {
      navigator.clipboard.writeText(inviteLink);
      addNotice({
        notice: 'Link copied to clipboard',
        type: 'success',
        time: new Date(),
      });
    }
  };

  const onInviteDeleteClick = (invite) => {
    window.confirm('Are you sure you want to delete this invite?') &&
      apeDeleteInvite(invite._id);
  };

  const renderMenu = (invite) => {
    const inviteLink = `${process.env.REACT_APP_URL}/login/invite/${invite.token}`;

    if (invite?.accepted) {
      return null;
    }

    return (
      <div className="flex flex-col items-center">
        <div className="dropdown dropdown-bottom dropdown-end hover:bg-gray-200 rounded-lg">
          <label tabIndex={0} className="cursor-pointer ">
            <EllipsisVerticalIcon className="h-5 w-5" />
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-48"
          >
            {!invite?.accepted && (
              <li>
                <div
                  onClick={() => onShareClick(inviteLink)}
                  className="cursor-pointer"
                >
                  Share Invite
                </div>
              </li>
            )}
            {!invite?.accepted && (
              <li>
                <div
                  onClick={() => onInviteDeleteClick(invite)}
                  className="cursor-pointer"
                >
                  Delete
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  };

  const renderInvites = () => {
    if (loading?.invites) {
      return <Loader type="table" />;
    }

    // sort invites by accepted
    invites =
      invites &&
      invites.sort((a, b) => {
        if (a.accepted && !b.accepted) {
          return 1;
        } else if (!a.accepted && b.accepted) {
          return -1;
        } else {
          return 0;
        }
      });

    // render users in a table
    if (invites) {
      return (
        <table className="w-full table-compact md:table-auto text-sm">
          <thead>
            <tr className="text-left">
              <th>Name</th>
              <th className="hidden md:table-cell">Email</th>
              <th>Profile</th>
              <th>Status</th>
              <th className="w-4"></th>
            </tr>
          </thead>
          <tbody>
            {invites.map((invite) => {
              return (
                <tr
                  key={invite._id}
                  className="border-b border-gray-200 items-center"
                >
                  <td className="truncate whitespace-nowrap">
                    {invite?.name && <span>{invite?.name}</span>}
                  </td>
                  <td className="truncate overflow-hidden hidden md:table-cell">
                    {invite?.email && <span>{invite?.email}</span>}
                  </td>
                  <td className="text-clip table-cell">
                    <div className="flex flex-wrap md:flex-row items-center gap-1 md:gap-2 text-clip flex-0 py-1 md:py-0">
                      {invite?._profile?._id ? (
                        <>
                          <AthleteLine athlete={invite._profile} />{' '}
                          <span className="badge badge-outline text-xs">
                            {' '}
                            Invited
                          </span>
                        </>
                      ) : invite?.acceptedBy?._identity ? (
                        <>
                          <AthleteLine athlete={invite.acceptedBy._identity} />{' '}
                          <span className="badge badge-outline text-xs">
                            {' '}
                            Identity
                          </span>
                        </>
                      ) : (
                        'No Profile'
                      )}
                      {invite?.identity && (
                        <span className="badge badge-outline text-xs">
                          {' '}
                          Identity
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="flex flex-row items-center gap-1 mt-1">
                    {invite?.accepted ? (
                      <>
                        <CheckCircleIcon className="h-5 w-5 text-green-500" />{' '}
                        Accepted{' '}
                        <span className="hidden lg:flex">
                          {invite.acceptedAt
                            ? timeFromNow(invite.acceptedAt)
                            : 'N/A'}
                        </span>
                      </>
                    ) : (
                      <>
                        <ClockIcon className="h-5 w-5 text-yellow-500" />{' '}
                        Pending{' '}
                        <span className="hidden lg:flex">
                          as of{' '}
                          {invite.createdAt
                            ? timeFromNow(invite.createdAt)
                            : 'N/A'}
                        </span>
                      </>
                    )}
                  </td>
                  <td className="w-4">{renderMenu(invite)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-col items-center gap-2">
      <h1>Invite Management</h1>
      {renderAddInviteButton()}
      {renderInvites()}
      <AddInviteModal />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    invites: state.ape.invites,
    loading: state.loader?.elements,
  };
};

export default connect(mapStateToProps, {
  apeFetchInvites,
  apeDeleteInvite,
  addNotice,
  showModal,
})(InviteAdmin);

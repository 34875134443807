import React, { useState } from 'react';
import { connect } from 'react-redux';

import { useParams } from 'react-router-dom';

import SearchInput from '../../forms/SearchInput';
import { search } from '../../../actions';

import { Form, Field } from 'react-final-form';

const SearchBar = ({
  isSignedIn,
  isSearching,
  search,
  autoFocus,
  location,
  className = 'w-full px-4 sm:px-0',
}) => {
  const { term, type } = useParams();
  const [prevTerm, setPrevTerm] = useState(term);

  const initialValues = {
    search: term,
  };

  const onSubmit = (formValues) => {
    // prevent empty search
    if (
      !formValues.search ||
      formValues.search === term ||
      formValues.search.length < 3
    ) {
      return;
    }
    search(formValues.search, type);
  };

  const searchAction = async (term) => {
    if (isSearching || term === prevTerm) {
      return;
    }
    // set previous term
    setPrevTerm(term);
    await search(term, type);
  };

  return (
    isSignedIn && (
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={className}>
            <Field
              name={`search`}
              component={SearchInput}
              type="text"
              placeholder="Search"
              handleClick={handleSubmit}
              searchAction={searchAction}
              autoFocus={autoFocus}
              location={location}
            />
          </form>
        )}
      />
    )
  );
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state?.auth?.userId,
    isSearching: state?.loader?.search,
  };
};

export default connect(mapStateToProps, { search })(SearchBar);

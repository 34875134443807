import React from 'react';
import Modal from '../../../Modal';

import AthleteLine from '../../../athlete/line';
import timeFromNow from '../../../../utils/timeFrom';

import { connect } from 'react-redux';

import { showModal, hideModal } from '../../../../actions';

import { XMarkIcon, ClockIcon, CheckCircleIcon, ArrowDownCircleIcon } from '@heroicons/react/24/outline';

import { Link } from 'react-router-dom';

const HistoryModal = (
    {
        item,
        modal, 
        hideModal
    }) => {

    const renderHistoryTimeline = () => {

        const renderSwitch = (status) => {
            switch (status) {
                case 'pending':
                    return <ClockIcon className="h-5 w-5 text-gray-400" />
                case 'active':
                    return <CheckCircleIcon className="h-5 w-5 text-green-400" />
                default:
                    return <ClockIcon className="h-5 w-5 text-gray-400" />
            }
        }

        const renderAction = (status) => {
            switch (status) {
                case 'pending':
                    return <Link to={`/item/edit/${item._id}`} className="btn btn-ghost btn-sm rounded-btn" state={{ backable: true }}>Edit to Activate</Link>
                case 'active':
                    return <Link to={`/item/edit/${item._id}`} className="btn btn-ghost btn-sm rounded-btn" state={{ backable: true }}>Edit</Link>
                default:
                    return <Link to={`/item/edit/${item._id}`} className="btn btn-ghost btn-sm rounded-btn" state={{ backable: true }}>Edit</Link>
            }
        }


        return (
            <ul className="timeline timeline-vertical timeline-compact">
                {
                    item?.history?.map((history, index) => {
                        return (
                            <li key={index}>
                                {index>0 && <hr/>}
                                <div className="timeline-middle">
                                    {renderSwitch(history.status)}
                                </div>
                                <div className={`timeline-end ${history.note && `timeline-box`}`}>
                                    <div className="text-xs text-gray-500">{timeFromNow(history.updatedAt)}</div>
                                    <div className="text-sm text-gray-500 -my-1">
                                        <div dangerouslySetInnerHTML={{__html: history.note}}></div>
                                    </div>
                                    {history.note ?
                                        <AthleteLine athlete={history._athlete} />
                                        : (
                                            <div className="text-sm text-gray-500">
                                                <span className="font-bold">{history._athlete?.name}</span> updated this item
                                            </div>
                                        )
                                    }
                                </div>
                                <hr/>
                            </li>
                        )
                    })
                }
                <li>
                    <hr/>
                    <div className="timeline-middle">
                        <ArrowDownCircleIcon className="h-5 w-5 text-red-400" />
                    </div>
                    <div className="timeline-end">
                        {renderAction(item.status)}
                    </div>
                </li>
            </ul>
        )
    }


    const renderContent = () => {
        return (
            <div className="flex flex-col w-full">
                <div className="flex flex-col w-full">
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-col">
                            <div className="text-xl font-bold">
                            {item?._make?.name} {item?.name}
                            </div>
                        </div>
                        <div className="flex flex-row justify-end">
                            <button className="btn btn-square btn-ghost btn-sm" onClick={() => hideModal()}>
                                <XMarkIcon className="h-6 w-6 text-gray-500" />
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col mt-2 w-full">
                        {renderHistoryTimeline()}
                    </div>
                </div>
            </div>
        );
    }

    if (!modal.visible || modal.modalType !== 'ITEM_HISTORY' || !item) {
        return null;
    }

    return (
        <Modal
            content={renderContent()}
            onDismiss={() => hideModal()}
        />
    );

}

const mapStateToProps = (state, ownProps) => {

    const isSignedIn  = state.auth?.userId;

    return {
        isSignedIn,
        athlete: state.auth.profile,
        modal: state.modal
    }
}

export default connect(mapStateToProps, 
    { showModal, hideModal })
    (HistoryModal);
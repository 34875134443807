import React from 'react';
import Modal from '../../../Modal';

import { connect } from 'react-redux';

import { Form, Field } from 'react-final-form';

import CustomInput from '../../../forms/CustomInput';
import CustomDate from '../../../forms/CustomDate';

import postTypeCollectionDefinitions from '../../../../utils/postCollectionTypeDefinitions.json';

import {
  showModal,
  hideModal,
  addNotice,
  apeUpdatePostCollection,
} from '../../../../actions';

import { XMarkIcon, ClockIcon } from '@heroicons/react/24/outline';

const EditPostCollectionModal = ({
  modal,
  postCollection,
  hideModal,
  apeUpdatePostCollection,
  onClose,
}) => {
  const [initPostCollection, setInitPostCollection] = React.useState(null);
  const [mounted, setMounted] = React.useState(false);

  // get the post collection type definitions
  const { id, type } = postCollection || { id: null, type: null };
  const { type_name } = postTypeCollectionDefinitions?.find(
    (pc) => pc.type_value === type
  ) || {
    type_name: null,
    type_value: null,
    type_set_name: null,
    type_set_value: null,
  };

  const handleUpdatePostCollection = async (values) => {
    const data = {
      ...values,
      id: postCollection.id,
    };
    const response = await apeUpdatePostCollection(data);
    setInitPostCollection(null);
    onClose();

    return response;
  };

  const yesHideModal = () => {
    setInitPostCollection(null);
    onClose();
    hideModal();
  };

  const handleHideModal = () => {
    window.confirm('Are you sure you want to discard changes?') &&
      yesHideModal();
  };

  React.useEffect(() => {
    if (!mounted && postCollection) {
      const publishedAt = postCollection.publishedAt
        ? new Date(postCollection.publishedAt).toISOString().split('T')[0]
        : null;
      setInitPostCollection({
        ...postCollection,
        publishedAt,
      });
      setMounted(true);
    } else if (!mounted && !postCollection) {
      // setMounted(true);
      setInitPostCollection();
    }
  }, [mounted, postCollection, type]);

  // unmount on hidemodal
  React.useEffect(() => {
    if (!modal.visible || modal.modalType !== 'APE_EDIT_POST_COLLECTION') {
      setMounted(false);
      setInitPostCollection(null);
    }
  }, [modal.visible, modal.modalType]);

  const renderForm = () => {
    return (
      <Form
        onSubmit={handleUpdatePostCollection}
        initialValues={initPostCollection}
        render={({ handleSubmit, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} className="flex flex-col gap-2">
            <Field
              name="title"
              component={CustomInput}
              label="Title"
              placeholder="Title"
            />
            <Field
              name="description"
              component={CustomInput}
              label="Description"
              placeholder="Description"
            />
            <Field
              name="publishedAt"
              component={CustomDate}
              label="Published At"
            />
            <button
              type="submit"
              className="btn btn-primary"
              disabled={submitting || pristine}
            >
              {submitting ? (
                'Posting...'
              ) : // if publishedAt date (formatted) is in the future, show 'Schedule Post'
              values.publishedAt &&
                new Date().toISOString() <
                  new Date(values.publishedAt).toISOString() ? (
                <span className="gap-1 flex flex-row items-center">
                  <ClockIcon className="h-6 w-6" />
                  Schedule {type_name}
                </span>
              ) : id === '_isNew_' ? (
                `Add ${type_name}`
              ) : (
                `Update ${type_name}`
              )}
            </button>
          </form>
        )}
      />
    );
  };

  const renderContent = () => {
    // new post or edit post
    const title = id === '_isNew_' ? `Add ${type_name}` : `Edit ${type_name}`;

    return (
      <div className="flex flex-col">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <div className="text-xl font-bold">{title}</div>
            </div>
            <div className="flex flex-row justify-end">
              <button
                className="btn btn-square btn-ghost btn-sm"
                onClick={() => handleHideModal()}
              >
                <XMarkIcon className="h-6 w-6 text-gray-500" />
              </button>
            </div>
          </div>
          <div className="flex flex-col mt-4">
            <div className="flex flex-col mt-2">{renderForm()}</div>
          </div>
        </div>
      </div>
    );
  };

  if (!modal.visible || modal.modalType !== 'APE_EDIT_POST_COLLECTION') {
    return null;
  }

  return (
    <Modal content={renderContent()} onDismiss={() => handleHideModal()} />
  );
};

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
  };
};

export default connect(mapStateToProps, {
  showModal,
  hideModal,
  addNotice,
  apeUpdatePostCollection,
})(EditPostCollectionModal);

import React from 'react';
import Modal from '../../../Modal';
import { connect } from 'react-redux';

import {
  showModal,
  hideModal,
  addBuildItemVariant,
  deleteBuildItemVariant,
  deleteBuildItem,
  addBuildItemItem,
  deleteBuildItemItem,
} from '../../../../actions';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { TrashIcon } from '@heroicons/react/24/outline';

const BuildItemOptions = ({
  modal,
  hideModal,
  showModal,
  deleteBuildItem,
  addBuildItemVariant,
  deleteBuildItemVariant,
  addBuildItemItem,
  deleteBuildItemItem,
  build,
  buildItemId,
  loader,
}) => {
  const [mounted, setMounted] = React.useState(false);
  const item = build?.items?.find((item) => item._id === buildItemId);

  // get buildItem's family and familyItems
  const family = {
    ...item?._family,
    familyItems: item?.familyItems,
  };

  React.useEffect(() => {
    if (!mounted && modal.visible && modal.modalType === 'BUILD_ITEM_OPTIONS') {
      setMounted(true);
    }
  }, [mounted, modal]);

  const id = build?._id;
  const itemId = item?._item?._id;
  const itemName = item?._item?.name ? item?._item?.name : family?.name;
  const make = item?._item?._make?.name
    ? item?._item?._make?.name
    : family?.familyItems?.length > 0
    ? family?.familyItems[0]?._make?.name
    : '';
  const categoryName = item?._item?._category?.name
    ? item?._item?._category?.name
    : family?.familyItems?.length > 0
    ? family?.familyItems[0]?._category?.name
    : '';
  const status = item?._item?.status ? item?._item?.status : 'family';

  const handleDeleteItem = (buildItemId) => {
    window.confirm(
      'Are you sure you want to remove this item from your build?'
    ) && deleteBuildItem(id, buildItemId);
  };

  const handleVariantSelect = (variantId, toggle) => {
    if (toggle) {
      // remove variant from build item
      // deleteBuildItemVariant(id, itemId, variantId);
      deleteBuildItemVariant(id, buildItemId, variantId);
    } else {
      // addBuildItemVariant(id, itemId, variantId);
      addBuildItemVariant(id, buildItemId, variantId);
    }
    return;
  };

  const handleItemSelect = (itemId, toggle) => {
    if (toggle) {
      // remove item from build
      deleteBuildItemItem(id, buildItemId, itemId);
    } else {
      // add item to build
      addBuildItemItem(id, buildItemId, itemId);
    }
    return;
  };

  const renderPercentComplete = () => {
    const completed =
      status !== 'family'
        ? // has a variant been selected for each variantType?
          (item?._variant?.filter((variant) => variant.isComplete)?.length ||
            0) + 7
        : status === 'family'
        ? (item?._variant?.filter((variant) => variant.isComplete)?.length ||
            0) + 7
        : 0.7;
    let total =
      item?._item && item?._variant?.length > 0
        ? item?._variant?.filter((variant) =>
            // if item is selected, count distinct variantTypes iin variants
            item?._item?.variants?.some(
              (availableVariant) =>
                availableVariant.variantType === variant.variantType
            )
          ).length + 7
        : // total number of variantTypes in familyItems
          family?.familyItems?.reduce((acc, familyItem) => {
            const { variants } = familyItem;
            const variantTypes = variants
              ?.map((variant) => variant.variantType)
              .filter(
                (variantType, index, self) =>
                  self.indexOf(variantType) === index
              );
            return acc + variantTypes?.length;
          }, 0) + 7;
    // if item is selected, add 1 to total for each variantType
    if (!item?._item) {
      total = total + 7;
    }
    let percentage = Math.round((completed / total) * 100);
    const isComplete = percentage === 100;
    // if percentage is 0 set to 70 so that the circle is visible
    return {
      percentage,
      isComplete,
    };
  };

  const renderItems = () => {
    if (!family?.familyItems || family?.familyItems?.length === 0) {
      return null;
    }

    return (
      <div className="flex flex-col w-full mt-4 gap-3 font-medium">
        <div>Select your model</div>
        <div className="flex flex-row flex-wrap gap-2 mb-2">
          {family?.familyItems?.map((familyItem, index) => {
            const { name, _id: familyItemId, variants } = familyItem;
            const toggle = itemId === familyItemId;

            // set isDisabled to true if any variant in buildItem _variant array is not available in the familyItem, ignore items with isComplete === false
            const isDisabled = item?._variant?.some((variant) => {
              if (!variant.isComplete) {
                return false;
              }

              return item?._variant?.some((variant) => {
                // if variant is not complete, ignore it
                if (!variant.isComplete) {
                  return false;
                }

                return !variants?.some(
                  (availableVariant) =>
                    // if variant is not complete, ignore it
                    availableVariant.variant === variant.variant
                );
              });
            });

            return (
              <button
                key={index}
                className={`badge badge-md cursor-pointer whitespace-nowrap overflow-ellipsis ${
                  toggle ? `badge-primary` : `badge-accent `
                } ${
                  loader || isDisabled
                    ? `opacity-50 hover:cursor-not-allowed`
                    : ``
                }`}
                disabled={loader || isDisabled}
                onClick={() => handleItemSelect(familyItemId, toggle)}
              >
                <div>{name}</div>
              </button>
            );
          })}
          {
            // if all familyItems are disabled, render a message to the user to deselect options
            family?.familyItems?.every((familyItem) => {
              const { variants } = familyItem;
              // set isDisabled to true if any variant in buildItem _variant array is not available in the familyItem
              const isDisabled = item?._variant?.some((variant) => {
                if (!variant.isComplete) {
                  return false;
                }
                return !variants?.some(
                  (availableVariant) =>
                    availableVariant.variant === variant.variant
                );
              });
              return isDisabled;
            }) && (
              <div className="text-sm text-gray-500">
                Selected options do not match any available models.
              </div>
            )
          }
        </div>
      </div>
    );
  };

  const renderVariantOptions = () => {
    // for each variantType in item._variants,
    // render a selectable badge of options from
    // _item.variants.map(variant => variant[variantType])
    // if an item is not selected, but a family is selected, aggregate all variants from familyItems if they are available in any familyItem
    if (item?._family) {
      // group all variants by variantType accross all familyItems and remove duplicates and null values
      const variants = item?.familyItems
        ?.map((familyItem) => familyItem?.variants)
        .filter((variant) => variant !== null)
        .flat();

      const variantTypes = variants
        ?.map((variant) => variant?.variantType)
        .filter(
          (variantType, index, self) => self.indexOf(variantType) === index
        );
      const uniqueVariants = variantTypes?.map((variantType) => {
        const variantsByType = variants?.filter(
          (variant) => variant?.variantType === variantType
        );
        const uniqueVariants = variantsByType
          .map((variant) => variant)
          .filter(
            (variant, index, self) =>
              self.findIndex((v) => v.variant === variant.variant) === index
          );
        return {
          variantType,
          variants: uniqueVariants,
        };
      });

      // if no variants are available, return null
      if (uniqueVariants?.length === 0) {
        return null;
      }

      // render variants by variantType and if variant is selected
      return (
        <>
          <div className="mt-4 mb-4 font-medium">Select options</div>
          <div className="flex flex-col w-full">
            {uniqueVariants?.map((variant, index) => {
              const { variantType, variants } = variant;
              const selectedVariant = item?._variant?.find(
                (v) => v.variantType === variantType
              );
              return (
                <div className="flex flex-col" key={index}>
                  <div className="text-sm font-normal mb-2">{variantType}</div>
                  <div className="flex flex-row flex-wrap gap-2 mb-2">
                    {variants
                      .filter(
                        (availableVariant) =>
                          availableVariant.variantType === variantType
                      )
                      .map((availableVariant, index) => {
                        const { variant, _id: variantId } = availableVariant;
                        const toggle = variant === selectedVariant?.variant;

                        // disable variant if _item && familyItems defined, but variant is not available in familyItems matching _item
                        const isDisabled =
                          item?._item &&
                          item?.familyItems &&
                          !item?.familyItems
                            ?.filter(
                              (familyItem) => familyItem._id === itemId
                            )[0]
                            ?.variants?.some(
                              (availableVariant) =>
                                availableVariant.variant === variant
                            );

                        return (
                          <button
                            key={index}
                            className={`badge badge-md cursor-pointer whitespace-nowrap overflow-ellipsis ${
                              toggle ? `badge-primary` : `badge-accent `
                            } ${
                              loader || isDisabled
                                ? `opacity-50 hover:cursor-not-allowed`
                                : ``
                            }`}
                            disabled={loader || isDisabled}
                            onClick={() =>
                              handleVariantSelect(variantId, toggle)
                            }
                          >
                            <div>{variant}</div>
                          </button>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      );
    }

    if (
      !item?._variant ||
      item?._variant?.length === 0 ||
      !item?._item?.variants ||
      item?._item?.variants?.length === 0
    ) {
      return null;
    }

    return (
      <div className="flex flex-col w-full">
        {item._variant.map((variant, index) => {
          const selectedVariant = variant.variant;

          return (
            <div className="flex flex-col" key={index}>
              <div className="text-sm font-semibold mb-2">
                {variant.variantType}
              </div>
              <div className="flex flex-row flex-wrap gap-2 mb-2">
                {item._item.variants
                  .filter(
                    (availableVariant) =>
                      availableVariant.variantType === variant.variantType
                  )
                  .map((availableVariant, index) => {
                    const { variant, _id: variantId } = availableVariant;
                    const toggle = variant === selectedVariant;
                    return (
                      <button
                        key={index}
                        className={`badge badge-md cursor-pointer whitespace-nowrap overflow-ellipsis ${
                          toggle ? `badge-primary` : `badge-accent `
                        } ${loader ? `opacity-50 cursor-not-allowed` : ``}`}
                        disabled={loader}
                        onClick={() => handleVariantSelect(variantId, toggle)}
                      >
                        <div>{variant}</div>
                      </button>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderContent = () => {
    const { percentage, isComplete } = renderPercentComplete();

    return (
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-center mb-4">
          <div className=" flex flex-col">
            <div className="text-xl font-semibold">
              {make} {itemName}
            </div>
            <div className="text-sm text-gray-500">{categoryName}</div>
          </div>
          <button
            onClick={() => hideModal()}
            className="text-sm text-gray-500 hover:text-gray-700"
          >
            <XMarkIcon className="h-8 w-8" />
          </button>
        </div>
        {loader ? (
          <progress className="progress w-full mb-1 progress-secondary"></progress>
        ) : !isComplete ? (
          <progress
            className={`progress w-full mb-1 progress-warning`}
            value={percentage}
            max="100"
          ></progress>
        ) : null}
        {renderItems()}
        {renderVariantOptions()}
        <div className="divider"></div>
        <div className="flex flex-row justify-between items-center mt-4">
          <div
            onClick={() => handleDeleteItem(buildItemId)}
            className="btn text-error btn-ghost btn-xs w-fit gap-1 -ml-2"
          >
            <TrashIcon className="h-4 w-4" />
            Remove Item
          </div>
          <div
            onClick={() =>
              showModal('SUPPORT', {
                comment: `My suggested change for my ${itemName} is:`,
              })
            }
            className="btn btn-ghost btn-xs w-fit gap-1"
          >
            Suggest Edit
          </div>
        </div>
      </div>
    );
  };

  if (!modal.visible || modal.modalType !== 'BUILD_ITEM_OPTIONS') {
    return null;
  }

  return <Modal onDismiss={() => hideModal()} content={renderContent()} />;
};

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    loader: state?.loader?.form,
  };
};

export default connect(mapStateToProps, {
  showModal,
  hideModal,
  addBuildItemVariant,
  deleteBuildItemVariant,
  deleteBuildItem,
  addBuildItemItem,
  deleteBuildItemItem,
})(BuildItemOptions);

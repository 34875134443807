import _ from 'lodash';
import React from 'react';
import Modal from '../../Modal';

import { connect } from 'react-redux';

import AthleteLine from '../../athlete/line';

import { XMarkIcon } from '@heroicons/react/24/solid';
import { UserMinusIcon } from '@heroicons/react/24/outline';

import {
  followAthlete,
  unfollowAthlete,
  removeProfileFromBuild,
  showModal,
  hideModal,
} from '../../../actions';

const ParticipantModal = ({
  modal,
  hideModal,
  buildProfiles = [],
  buildName,
  myProfile,
  followAthlete,
  unfollowAthlete,
  removeProfileFromBuild,
  athlete,
}) => {
  const handleRemove = (profileId) => {
    // are you sure you want to remove this profile from the build?
    const confirm = window.confirm(
      'Are you sure you want to remove yourself from this build?'
    );

    if (!confirm) {
      return;
    }
    removeProfileFromBuild(modal?.property?.buildId, profileId);
  };

  const handleFollow = (profileId) => {
    followAthlete(profileId);
  };

  const handleUnfollow = (profileId) => {
    unfollowAthlete(profileId);
  };

  const renderFollowButton = (profileId) => {
    // if the profileId is the same as the current user, don't show the follow button
    if (profileId === myProfile) {
      return null;
    }

    // does the current user follow this profile?
    const isFollowing = athlete?._following?.includes(profileId);

    return (
      <div
        onClick={() =>
          isFollowing ? handleUnfollow(profileId) : handleFollow(profileId)
        }
        className={`btn btn-sm ${
          isFollowing ? 'btn-ghost bg-base-200' : 'btn-brand'
        }`}
      >
        {isFollowing ? 'Following' : 'Follow'}
      </div>
    );
  };

  const renderParticipants = () => {
    if (!_.isEmpty(buildProfiles)) {
      return buildProfiles.map(
        ({ _profile, profileType, status, services = [], creator }, index) => {
          // add profileType and services to an array
          const profileParticipation = [
            profileType
              ? { _id: _profile?._id, label: profileType, value: profileType }
              : [],
            ...services,
          ];

          const renderProfileService = () => {
            if (profileParticipation?.length === 0) {
              return null;
            }

            return (
              <div className="join" key={index}>
                {creator && !profileType && !services.length
                  ? renderFollowButton(_profile?._id)
                  : null}
                {profileParticipation?.length > 0
                  ? profileParticipation?.map((participation, index) => {
                      if (!participation?._id) {
                        return null;
                      }
                      // is this participant me?
                      if (participation?._id === myProfile) {
                        return (
                          <div
                            key={index}
                            onClick={() => handleRemove(myProfile)}
                            className="btn btn-ghost btn-sm btn-square join-item hover:cursor-pointer"
                          >
                            <UserMinusIcon className="h-5 w-5" />
                          </div>
                        );
                      }

                      return renderFollowButton(participation?._id);
                    })
                  : null}
              </div>
            );
          };

          return (
            <div
              key={index}
              className="flex flex-wrap gap-2 sm:gap-0 sm:flex-row sm:justify-between items-center border-b border-gray-200 py-2"
            >
              <div
                className="flex flex-row justify-between items-center gap-2"
                onClick={() => status !== 'hidden' && hideModal()}
              >
                <AthleteLine
                  athlete={_profile}
                  withServices
                  noLink={
                    status === 'hidden' || _profile?.status === 'hidden'
                      ? true
                      : false
                  }
                />
              </div>
              <div className="flex flex-row flex-grow items-center justify-end text-sm gap-1">
                {renderProfileService()}
                <span></span>
              </div>
            </div>
          );
        }
      );
    } else {
      return (
        <div className="text-sm text-secondary mb-4">
          <div className="mb-2">No participants.</div>
        </div>
      );
    }
  };

  const renderContent = () => {
    return (
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-center mb-2">
          <div className="text-xl font-semibold">{buildName}</div>
          <button
            onClick={() => hideModal()}
            className="text-sm text-secondary hover:text-gray-700"
          >
            <XMarkIcon className="h-8 w-8" />
          </button>
        </div>
        <div className="text-sm text-secondary mb-1">Build Profiles</div>
        {renderParticipants()}
      </div>
    );
  };

  if (!modal.visible || modal.modalType !== 'VIEW_BUILD_PARTICIPANTS') {
    return null;
  }

  return <Modal onDismiss={() => hideModal()} content={renderContent()} />;
};

const mapStateToProps = (state, ownProps) => {
  const athlete =
    state.auth.profile &&
    state.auth.athlete &&
    state.auth.profile._id === state.auth.athlete &&
    state.auth.profile;

  // get buildId from modal prop
  const buildId = state?.modal?.property?.buildId || null;

  const buildName =
    state?.builds?.builds?.[buildId]?.name ||
    state?.search?.builds?.paginatedBuilds?.[buildId]?.name ||
    null;

  // get buildProfiles from buildId and search
  const buildProfiles = state?.builds?.builds?.[buildId]?.profiles || [];
  const buildSearchProfiles =
    state?.search?.builds?.paginatedBuilds?.[buildId]?.profiles || [];
  // const buildProfilesCombined = [...buildProfiles, ...buildSearchProfiles];
  // remove duplicates
  const buildProfilesCombined = [
    ...buildProfiles,
    ...buildSearchProfiles,
  ].filter(
    (profile, index, self) =>
      index === self.findIndex((p) => p._profile._id === profile._profile._id)
  );

  // get creator from build and search
  const creator =
    state?.builds?.builds?.[buildId]?._athlete ||
    state?.search?.builds?.paginatedBuilds?.[buildId]?._athlete ||
    null;

  // if creator is not in the buildProfilesCombined, add them as the creator or if they are, set creator to true
  const athleteProfile =
    creator &&
    !buildProfilesCombined.find(
      (profile) => profile._profile._id === creator?._id
    )
      ? {
          _profile: creator,
          profileType: '',
          services: [],
          creator: true,
          status: creator?.status,
        }
      : {
          ...buildProfilesCombined.find(
            (profile) => profile?._profile?._id === creator?._id
          ),
          creator: true,
        };
  const revisedBuildProfiles = buildProfilesCombined?.filter(
    (profile) => profile._profile._id !== creator?._id
  );
  const assembledProfiles = buildProfilesCombined
    ? [athleteProfile, ...revisedBuildProfiles]
    : [athleteProfile];

  return {
    modal: state.modal,
    searchResults: state.search?.builds?.paginatedBuilds,
    buildProfiles: assembledProfiles,
    buildName,
    loader: state?.loader?.search,
    athlete,
    myProfile: state.auth?.athlete,
  };
};

export default connect(mapStateToProps, {
  showModal,
  hideModal,
  followAthlete,
  unfollowAthlete,
  removeProfileFromBuild,
})(ParticipantModal);

import _ from 'lodash';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStrava } from '@fortawesome/free-brands-svg-icons';

import { showModal } from '../../../actions';

import { connect } from 'react-redux';
import { ChevronDownIcon, ArrowUpRightIcon } from '@heroicons/react/24/outline';

const ActivityCard = ({ activity, isOwner, showModal, onTitleClick, expanded }) => {

    const setOpenAddActivityMenu = () => {
        showModal('ADD_ACTIVITY');
    }

    if (_.isEmpty(activity)) {
        return (
            <>
            {isOwner && (
                <div className="flex flex-row gap-2 items-center mb-7 mt-6">
                    <div className="flex flex-row items-center gap-1 cursor-pointer">
                        <span className="btn btn-primary btn-sm" onClick={setOpenAddActivityMenu}>
                            <FontAwesomeIcon 
                                icon={faStrava} className="mr-1" 
                                />
                            Add Activity
                        </span>
                    </div>
                </div>
            )}
            </>
        );
    }

    const convertMetersToMiles = (meters) => {
        return (meters * 0.000621371192).toFixed(2);
    }

    const convertSecondsToHours = (seconds) => {
        // convert seconds to an hour and minutes format
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);

        return `${hours>=1 ? `${hours}h` : ``} ${minutes}m`;
    }

    const convertMetersToFeet = (meters) => {
        return (meters * 3.28084).toFixed(0);
    }

    // convert date to readable format
    const convertDate = (date) => {
        // convert 2023-07-02T16:10:10.000Z to Month Name, Day, Year at Time format
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
        
    }

    return (
        <>
        {activity.map(activity => {

            const { id, name, distance, moving_time, total_elevation_gain, start_date_local } = activity;

            // link to strava activity
            const stravaLink = `https://www.strava.com/activities/${id}`;

            return (
                <div key={activity.id} className="grid grid-cols-1 gap-3 w-full mt-6">
                    <div className="flex gap-x-1 items-center cursor-pointer justify-between" onClick={onTitleClick}>
                        <div className="flex flex-row items-center">
                            <h2>Strava</h2>
                            <FontAwesomeIcon
                                icon={faStrava} className="w-6 h-6"
                            />
                        </div>
                        <div className="flex flex-row items-center">
                            <ChevronDownIcon className={`h-7 w-7 transform ease-in duration-200 ${expanded ? `rotate-0` : `rotate-180`}`} />
                        </div>
                    </div>
                    {expanded && <div className="text-sm grid grid-cols-1 space-y-4 divide-y dark:divide-gray-800">
                        <div className="-mb-2">
                            <a href={stravaLink} target="_blank" rel="noreferrer" className="hover:text-gray-500 items-center flex">
                                {name}
                                <ArrowUpRightIcon className="inline-block w-5 h-5 ml-2" />
                            </a>
                        </div>
                        <div className="flex h-full gap-x-1 items-center">
                            {convertDate(start_date_local)}
                        </div>
                        <div className="flex h-full gap-x-1 items-center">
                            <div>Distance: {convertMetersToMiles(distance)} miles</div>
                        </div>
                        <div className="flex h-full gap-x-1 items-center">
                            <div>Elevation: {convertMetersToFeet(total_elevation_gain)} feet</div>
                        </div>
                        <div className="flex h-full gap-x-1 items-center">
                            <div>Time: {convertSecondsToHours(moving_time)}</div>
                        </div>
                    </div>}
                </div>
            );
        })}
        {isOwner && expanded && (
            <div className="flex flex-row items-center gap-1 cursor-pointer justify-end mb-8 ">
                <span className="btn btn-ghost btn-xs text-gray-400" onClick={setOpenAddActivityMenu}>
                    Change Activity
                </span>
            </div>
        )}
    </>
);
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
    }
}

export default connect(
    mapStateToProps,
    { showModal }
)(ActivityCard);
import { ADD_NOTICE, HIDE_NOTICE, DELETE_NOTICE } from './types';

export const addNotice = (notice) => {
  return {
    type: ADD_NOTICE,
    payload: notice,
  };
};

export const hideNotice = () => {
  return {
    type: HIDE_NOTICE,
  };
};

export const deleteNotice = () => {
  return {
    type: DELETE_NOTICE,
  };
};

import React from 'react';
import { createPortal } from 'react-dom';

const Veil = ({ children }) => {
  return createPortal(
    <div className="fixed top-0 left-0 w-screen h-full bg-black bg-opacity-50 z-50">
      {children}
    </div>,
    document.querySelector('#veil')
  );
};

export default Veil;

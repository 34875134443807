import React from 'react';

import { connect } from 'react-redux';
import { disconnectStrava } from '../../../actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStrava } from '@fortawesome/free-brands-svg-icons';

const ConnectStrava = ({ athlete, auth, disconnectStrava, className }) => {
  // does this athlete have a user with an identity field of true?
  const hasIdentity = () => {
    if (!athlete.users) {
      return false;
    }
    return athlete.users.some((user) => user.identity);
  };

  const handleStravaDisconnect = async () => {
    window.confirm(
      'Are you sure you want to disconnect your Strava account?'
    ) && (await disconnectStrava());
  };

  const renderStravaButton = () => {
    if (hasIdentity()) {
      if (auth?.stravaId) {
        return (
          <div className="flex flex-col items-center justify-center gap-2 pt-1 mr-1">
            <div className="flex flex-row items-center justify-center gap-2">
              <FontAwesomeIcon icon={faStrava} className="text-2xl" />
              <div className="flex flex-col">
                <span className="text-sm text-gray-500">Strava Connected</span>
                <div
                  className="text-xs text-gray-300 hover:underline cursor-pointer"
                  onClick={handleStravaDisconnect}
                >
                  Disconnect
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <a
              className={`btn btn-secondary ${className}`}
              href={`/auth/strava/connect`}
            >
              <FontAwesomeIcon icon={faStrava} className="text-2xl pr-1" />
              Connect Strava
            </a>
          </div>
        );
      }
    }
  };

  return <div>{renderStravaButton()}</div>;
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { disconnectStrava })(ConnectStrava);

import React from 'react';
import { Form, Field } from 'react-final-form';

import { connect } from 'react-redux';

import { postSupport, hideModal } from '../../../actions';

import customRichTextarea from '../../forms/CustomRichTextarea';

const ContactForm = ({ postSupport, hideModal, initComment }) => {
  const onSubmit = async (values) => {
    const supportData = {
      message: values.message,
      location: window.location.href,
      userAgent: navigator.userAgent,
    };

    try {
      await postSupport(supportData);
      // reset form
      values.message = '';
      hideModal();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="flex flex-col w-full">
      <Form
        initialValues={{ message: initComment || '' }}
        onSubmit={onSubmit}
        validate={(formValues) => {
          const errors = {};
          if (!formValues.message) {
            errors.message = 'Required';
          }
          return errors;
        }}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col w-full mt-2">
              <div className="flex flex-col w-full">
                <Field
                  name="message"
                  component={customRichTextarea}
                  placeholder="Describe issue, question, or suggestion here."
                  className="h-40 gap-4 my-4"
                  autoFocus={true}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={submitting || pristine}
                >
                  Send
                </button>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    initComment: state.modal?.property?.comment,
  };
};

export default connect(mapStateToProps, { postSupport, hideModal })(
  ContactForm
);

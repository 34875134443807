import React from 'react';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Form, Field } from 'react-final-form';

import ListItems from '../../lists/listItems';
import CustomInput from '../../forms/CustomInput';
import CustomTextArea from '../../forms/CustomTextarea';

import FormPage from '../../ui/FormPage';

import {
  postList,
  getList,
  deleteList,
  deleteListItem,
} from '../../../actions';

const ListEdit = (props) => {
  // set id state to id from url
  const { listId = '_new_' } = useParams();

  const { lists } = props;
  if (!lists || !lists[listId]) {
    if (listId && listId !== '_new_' && listId !== 'undefined') {
      props.getList(listId);
    }
  }

  const onSubmit = async (formValues) => {
    return await props.postList(formValues);
  };

  const handleDelete = async (listId) => {
    window.confirm('Are you sure you want to delete this list?') &&
      (await props.deleteList(listId));
  };

  function handleDeleteItem(listItemId) {
    window.confirm('Are you sure you want to delete this item?') &&
      props.deleteListItem(listId, listItemId);
  }

  const initialValues = lists[listId] ? lists[listId] : {};

  return (
    <FormPage>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={(formValues) => {
          const errors = {};

          if (!formValues.name) {
            errors.name = 'Please enter a name for your list';
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} className="ui form error">
            <div className="flex flex-row pl-0 pr-4 sm:mt-4 items-center justify-between sm:px-0 sm:mb-4">
              <div className="flex flex-row justify-start px-5 sm:px-1 sm:mt-1">
                <h1>{listId && listId !== '_new_' ? 'Edit' : 'Add'} List</h1>
              </div>
            </div>
            <div className="flex flex-col px-5 sm:px-0 md:flex-row md:col-span-2 gap-4 mt-2 justify-center">
              {listId && listId !== '_new_' && (
                <div className="flex flex-col md:w-1/2">
                  <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2">
                    <ListItems
                      listId={listId}
                      lists={lists}
                      handleDeleteItem={handleDeleteItem}
                      athleteId={props.auth.athlete}
                    />
                  </div>
                </div>
              )}
              <div className="flex flex-col md:w-1/2 md:-mt-4">
                <div className="flex flex-col my-4">
                  <Field
                    name="name"
                    component={CustomInput}
                    type="text"
                    placeholder="My Wishlist"
                    label="Name for your list"
                    autoFocus={listId === '_new_' ? true : false}
                  />
                </div>
                <div className="flex flex-col my-4">
                  <Field
                    name="description"
                    component={CustomTextArea}
                    type="text"
                    placeholder="Description"
                    label="Description"
                  />
                </div>
                <div className="flex flex-col my-4">
                  <button
                    className={`btn btn-primary w-full ${
                      submitting ? 'loading' : ''
                    }`}
                    type="submit"
                    disabled={submitting || pristine}
                  >
                    {submitting ? 'Saving...' : 'Save'}
                  </button>
                  <Field name="_id" component={CustomInput} type="hidden" />
                </div>
                {listId && listId !== '_new_' && (
                  <button
                    type="button"
                    className="btn btn-error btn-outline mt-2"
                    onClick={() => {
                      if (
                        window.confirm(
                          'Are you sure you want to delete this list?'
                        )
                      ) {
                        handleDelete(listId);
                      }
                    }}
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
          </form>
        )}
      />
    </FormPage>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    lists: state.lists,
  };
};

export default connect(mapStateToProps, {
  postList,
  getList,
  deleteList,
  deleteListItem,
})(ListEdit);

export const SET_LOADER = 'set_loader';
export const SET_LOADER_ELEMENT = 'set_loader_element';
export const UPDATE_SERVICEWORKER = 'UPDATE_SERVICEWORKER';

export const SET_INVITE_TOKEN = 'set_invite_token';
export const AUTH_USER = 'auth_user';
export const FETCH_USER = 'fetch_user';
export const SET_USER_ATHLETE = 'set_user_athlete';
export const FETCH_AUTH_ATHLETES = 'fetch_auth_athletes';
export const LOG_OUT = 'log_out';

export const ADD_NOTICE = 'add_notice';
export const HIDE_NOTICE = 'hide_Notice';
export const DELETE_NOTICE = 'delete_notice';

export const ADD_MODAL = 'add_modal';
export const HIDE_MODAL = 'hide_modal';
export const DELETE_MODAL = 'delete_modal';

export const GET_ATHLETE = 'get_athlete';
export const POST_ATHLETE = 'post_athlete';
export const DELETE_ATHLETE = 'delete_athlete';
export const DELETE_ATHLETE_IMAGE = 'delete_athlete_image';

export const GET_BUILDS = 'get_builds';
export const GET_BUILD = 'get_build';
export const POST_BUILD = 'post_build';
export const POST_BUILD_LIKE = 'post_build_like';
export const POST_SEARCH_BUILD_LIKE = 'post_search_build_like';
export const POST_BUILD_ITEM_LIKE = 'post_build_item_like';
export const POST_BUILD_ITEM = 'post_build_item';
export const DELETE_BUILD = 'delete_build';
export const DELETE_BUILD_IMAGE = 'delete_build_image';
export const CLEAR_BUILDS = 'clear_builds';
export const GET_RELATED_BUILDS = 'get_related_builds';

export const GET_BUILD_COMMENTS = 'get_comments';
export const POST_BUILD_COMMENT = 'post_comment';
export const DELETE_BUILD_COMMENT = 'delete_comment';

export const GET_CATEGORIES = 'get_categories';

export const GET_LISTS = 'get_lists';
export const GET_LIST = 'get_list';
export const POST_LIST = 'post_list';
export const DELETE_LIST = 'delete_list';
export const CLEAR_LISTS = 'clear_lists';

export const POST_LIST_ITEM = 'post_list_item';
export const DELETE_LIST_ITEM = 'delete_list_item';

export const ADD_BUILD_ITEM = 'add_build_item';
export const DELETE_BUILD_ITEM = 'delete_build_item';

export const POST_STRAVA_ACTIVITY_TO_BUILD = 'post_strava_activity_to_build';
export const DELETE_STRAVA_ACTIVITY_FROM_BUILD =
  'delete_strava_activity_from_build';

export const POST_PROFILE_TO_BUILD = 'post_profile_to_build';
export const DELETE_PROFILE_FROM_BUILD = 'delete_profile_from_build';

export const POST_ITEM = 'post_item';
export const GET_ITEM = 'get_item';
export const GET_ITEMS = 'get_items';
export const DELETE_ITEM = 'delete_item';
export const DELETE_ITEM_IMAGE = 'delete_item_image';
export const CLEAR_ITEMS = 'clear_items';

export const GET_POSTS = 'get_posts';
export const GET_POST = 'get_post';
export const CLEAR_POSTS = 'clear_posts';
export const POST_POST_COMMENT = 'post_post_comment';
export const DELETE_POST_COMMENT = 'delete_post_comment';

export const ITEMS_RESULTS = 'ITEMS_RESULTS';
export const MODEL_RESULTS = 'MODEL_RESULTS';
export const SEARCH_RESULTS = 'SEARCH_RESULTS';
export const SEARCH_ALIAS = 'SEARCH_ALIAS';
export const CLEAR_RESULTS = 'CLEAR_RESULTS';
export const MORE_SEARCH_RESULTS = 'MORE_SEARCH_RESULTS';
export const PROFILE_RESULTS = 'PROFILE_RESULTS';

export const DELETE_NOTIFICATIONS = 'delete_notifications';
export const FETCH_NOTIFICATIONS = 'fetch_notifications';
export const CLEAR_NOTIFICATIONS = 'clear_notifications';

export const FETCH_STRAVA_ACTIVITIES = 'fetch_strava_activities';

export const APE_FETCH_USERS = 'ape_fetch_users';
export const APE_UPDATE_USER = 'ape_update_user';
export const APE_DELETE_USER = 'ape_delete_user';

export const APE_FETCH_INVITES = 'ape_fetch_invites';
export const APE_ADD_INVITE = 'ape_add_invite';
export const APE_DELETE_INVITE = 'ape_delete_invite';

export const APE_FETCH_ITEMS = 'ape_fetch_items';
export const APE_DELETE_ITEM = 'ape_delete_item';

export const APE_FETCH_DISTRIBUTORS = 'ape_fetch_distributors';
export const APE_FETCH_DISTRIBUTOR = 'ape_fetch_distributor';
export const APE_DELETE_DISTRIBUTOR = 'ape_delete_distributor';
export const APE_UPDATE_DISTRIBUTOR = 'ape_update_distributor';

export const APE_FETCH_POSTS = 'ape_fetch_posts';
export const APE_DELETE_POST = 'ape_delete_post';
export const APE_UPDATE_POST = 'ape_update_post';
export const APE_REORDER_POSTS = 'ape_reorder_posts';

export const APE_FETCH_POST_COLLECTION = 'ape_fetch_post_collection';
export const APE_FETCH_MANY_POST_COLLECTIONS =
  'ape_fetch_many_post_collections';
export const APE_CLEAR_POST_COLLECTIONS = 'ape_clear_post_collections';
export const APE_DELETE_POST_COLLECTION = 'ape_delete_post_collection';
export const APE_UPDATE_POST_COLLECTION = 'ape_update_post_collection';

export const APE_FETCH_STATS = 'ape_fetch_stats';
export const APE_FETCH_ANALYTICS = 'ape_fetch_analytics';

export const CLEAR_TYPE_RESULTS = 'clear_type_results';

export const FETCH_PROFILE_STATS = 'fetch_profile_stats';
export const FETCH_PROFILE_LOCATIONS = 'fetch_profile_locations';

import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';

import Loader from '../Loader';
import Splash from './splash';
import Waitlist from './waitlist';
import NewUser from './newUser';
import StravaAddEmail from '../auth/strava/email';

class LandingPage extends React.Component {
  render() {
    if (this.props.loader && this.props.loader.page) {
      return <Loader />;
    }
    if (
      this.props.auth &&
      this.props.auth.name &&
      // ensure users with no profile currently set, but with access to other profiles can login
      ((this.props.auth.profile && this.props.auth.profile._id) ||
        this.props.auth.athlete)
    ) {
      return history.push('/feed');
    } else if (this.props?.auth?.permissions?.user) {
      return <NewUser profile={this.props.auth} />;
    } else if (this.props?.auth?.name) {
      return (
        <Waitlist
          profile={this.props.auth}
          stravaId={this.props.auth.stravaId}
        />
      );
    } else if (this.props.auth && this.props.auth.stravaId) {
      return <StravaAddEmail />;
    }

    return <Splash />;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    athlete: state.athlete,
    loader: state.loader,
  };
}

export default connect(mapStateToProps)(LandingPage);

import React from 'react';

import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';
import { getList } from '../../../actions';

import ListItems from '../listItems';
import AthleteLine from '../../athlete/line';
import ReadMore from '../../ui/ReadMore';

import Helmet from '../../ui/Helmet';
import RecordEvent from '../../analytics/pageview';

import APArrowLeftIcon from '../../ui/ArrowLeftIcon';

const ListView = (props) => {
  const { listId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const handleBackButton = () => {
    const { state } = location;
    const skipEdit = state ? state.skipEdit : false;
    if (skipEdit) {
      navigate(-3);
    } else {
      navigate(-1);
    }
  };

  // onmount state
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true);
      props.getList(listId);
    }
  }, [mounted, props, listId]);

  const { lists } = props;

  function renderBackButton() {
    const { state } = location;
    const backable = state ? state.backable : false;

    return (
      <>
        {backable && (
          <Link
            className="btn btn-square btn-ghost"
            onClick={() => handleBackButton()}
          >
            <APArrowLeftIcon />
          </Link>
        )}
      </>
    );
  }

  function renderEditButton() {
    // if current athlete is not the list owner, don't show edit button
    if (props?.auth?.athlete !== lists[listId]?._athlete?._id) {
      return <div></div>;
    }
    return (
      <Link
        className="btn btn-square btn-ghost sm:left-0 relative"
        to={`/list/${listId}/edit`}
        state={{ backable: true }}
      >
        Edit
      </Link>
    );
  }

  function renderListTitle(listId) {
    if (!lists || !lists[listId]) {
      return <>Loading...</>;
    }

    const { name, description, _athlete } = props.lists[listId];
    // number of items in list
    const numItems = Object.keys(props.lists[listId].items).length;

    return (
      <div className="flex flex-col gap-2 sm:text-center sm:items-center mb-2 mt-3">
        <h1>{name}</h1>
        <ReadMore>{description}</ReadMore>
        <p className="text-sm text-gray-500">{numItems} items</p>
        <AthleteLine athlete={_athlete} />
        <RecordEvent
          hitType="pageview"
          page={window.location.pathname}
          title={`${_athlete.name}'s List - ${name}`}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col mx-4 sm:mx-0">
      <Helmet
        title={`${lists[listId]?.name} | list by ${lists[listId]?._athlete?.name} on Active Projects`}
        description={`View all items in ${lists[listId]?.name} by ${lists[listId]?._athlete?.name} on Active Projects`}
        keywords={`list, view, items`}
        url={`${process.env.REACT_APP_URL}/list/${listId}/`}
      />
      <div className="py-1 sm:py-4 flex-row sm:flex-col">
        <div className="flex sm:-my-1 sm:ml-0 m-0 w-full justify-between">
          <div className="flex -ml-1 ">{renderBackButton()}</div>
          <div className="hidden sm:flex sm:flex-row">
            {renderListTitle(listId)}
          </div>
          {renderEditButton()}
        </div>
        <div className="flex sm:hidden">{renderListTitle(listId)}</div>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 sm:px-14 items-end">
        <ListItems
          lists={lists}
          listId={listId}
          athleteId={props.auth.athlete}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    lists: state.lists,
  };
};

export default connect(mapStateToProps, { getList })(ListView);

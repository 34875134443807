import React from 'react';

import { useParams } from 'react-router-dom';

import { connect } from 'react-redux';

import { setInviteToken, getAuthAthletes, switchAthlete } from '../../../actions';

import Login from '../login';
import Loader from '../../Loader';

const AcceptInvite = (
    {
        isSignedIn, 
        // auth, 
        athlete, 
        // athleteList,
        setInviteToken,
        inviteTokenSet,
        // getAuthAthletes, 
        // switchAthlete
    }) => {

    const [mounted, setMounted] = React.useState(false);

    const { inviteToken } = useParams();

    // React.useEffect(() => {
    //     // on mount and upon sign-in verification, get athletes
    //     if (isSignedIn && !mounted && !athleteList) {
    //         setMounted(true);
    //         isSignedIn && getAuthAthletes();
    //     }
    // }, [getAuthAthletes, mounted, athleteList, isSignedIn]);

    React.useEffect(() => {
        // on mount, set invite token
        if (!mounted && inviteToken !== inviteTokenSet) {
            setMounted(true);
            setInviteToken(inviteToken);
        }
    }, [setInviteToken, inviteTokenSet, inviteToken, mounted]);

    const renderAcceptInvite = () => {

        // 1. if currently logged in, prompt to switch to add user to athlete and switch to athlete
        if (isSignedIn) {
            return (
                <div className='flex flex-col justify-between items-center gap-2'>
                    <div className="text-base font-semibold">Switch to {athlete.name}</div>
                </div>
            );
        }

        // 2. if not logged in, prompt to login using Google, Strava, or email and add user to athlete
        return (
            <div className='flex flex-col justify-between items-center gap-4'>
                <div className="text-base font-semibold max-w-xs text-center">To accept the invite, login using your Gmail, Strava, or email address.</div>
            </div>
        );

    }

    if (!mounted || !inviteTokenSet) {
        return <Loader type="inline" />;
    }

    return (
        <Login 
            inviteToken={inviteTokenSet}
        >
            <div className="flex flex-col justify-center items-center gap-2">
                <h1 className="text-2xl font-bold">Welcome to Active Projects</h1>
                <p>You are invited to join.</p>
                {renderAcceptInvite()}
            </div>
        </Login>
    );
}

const mapStateToProps = (state) => {

    // sort athletes by name
    const athletes = state?.auth?.athleteList && Object.values(state.auth.athleteList).sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        } else {
            return 1;
        }
    });
    

    const isSignedIn = state.auth?.userId;

    return { 
        auth: state.auth,
        isSignedIn,
        athleteList: athletes,
        athlete: state.athlete,
        inviteTokenSet: state.auth.inviteToken
    };
}

export default connect(mapStateToProps, { 
    setInviteToken,
    getAuthAthletes, 
    switchAthlete
 })(AcceptInvite);
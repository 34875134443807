import axios from 'axios';
import { FETCH_PROFILE_STATS, FETCH_PROFILE_LOCATIONS } from '../types';

import { setLoaderElement } from '../loader';
import { addNotice } from '../notices';
import { hideModal } from '../modal';

export const fetchProfileStats = () => async (dispatch) => {
  dispatch(setLoaderElement('stats', true));
  try {
    const res = await axios.get(`/api/profile/stats/`);
    dispatch({ type: FETCH_PROFILE_STATS, payload: res.data });
  } catch (error) {
    console.error(error);
  }
  dispatch(setLoaderElement('stats', false));
};

export const fetchProfileLocations = () => async (dispatch) => {
  dispatch(setLoaderElement('locations', true));
  try {
    const res = await axios.get(`/api/profile/locations/`);
    dispatch({ type: FETCH_PROFILE_LOCATIONS, payload: res.data });
  } catch (error) {
    console.error(error);
  }
  dispatch(setLoaderElement('locations', false));
};

export const updateProfileLocation = (location) => async (dispatch) => {
  dispatch(setLoaderElement('locations', true));
  try {
    const res = await axios.post(`/api/profile/locations/`, location);
    dispatch({ type: FETCH_PROFILE_LOCATIONS, payload: res.data });
    dispatch(hideModal());
    dispatch(
      addNotice({
        notice: 'Location updated',
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (error) {
    dispatch(
      addNotice({
        notice: 'Error updating location',
        type: 'error',
        time: new Date(),
      })
    );
    console.error(error);
  }
  dispatch(setLoaderElement('locations', false));
};

export const deleteProfileLocation = (location) => async (dispatch) => {
  dispatch(setLoaderElement('locations', true));
  try {
    const res = await axios.delete(`/api/profile/locations/${location}`);
    dispatch({ type: FETCH_PROFILE_LOCATIONS, payload: res.data });
  } catch (error) {
    dispatch(
      addNotice({
        notice: 'Error deleting location',
        type: 'error',
        time: new Date(),
      })
    );
    console.error(error);
  }
  dispatch(setLoaderElement('locations', false));
};

import React, { useState, useEffect } from 'react';
import { HeartIcon } from '@heroicons/react/24/solid';

const Heartable = ({ children, action, liked, rest }) => {
  const [hearts, setHearts] = useState([]);
  const [running, setRunning] = useState(false);
  const [like, setLike] = useState('');

  const handleDoubleClick = (e) => {
    if (running) return;
    const heart = {
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
      id: Math.random(),
    };
    setHearts([heart]);
    action();
    setRunning(true);
    setLike(liked);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setHearts([]);
    }, 2000);

    return () => {
      clearTimeout(timer);
      setRunning(false);
    };
  }, [hearts]);

  return (
    <div onDoubleClick={handleDoubleClick} {...rest}>
      {hearts.map((heart) => (
        <Heart
          key={heart.id}
          style={{ top: heart.y - 12, left: heart.x + 12 }}
          liked={like}
        />
      ))}
      {children}
    </div>
  );
};

const Heart = ({ style, liked }) => {
  const className = liked
    ? 'h-12 w-12 text-red-600 animate-heart-out'
    : 'h-12 w-12 text-red-600 animate-heart-up';

  return (
    <div className="absolute z-50 -translate-x-8" style={style}>
      <HeartIcon className={className} />
    </div>
  );
};

export default Heartable;

import _ from 'lodash';

import {
  GET_POSTS,
  GET_POST,
  CLEAR_POSTS,
  POST_POST_COMMENT,
  DELETE_POST_COMMENT,
} from '../actions/types';

const INITIAL_STATE = {
  posts: [],
  totalPages: 0,
};

const postsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_POSTS:
      return {
        posts: {
          ...state.posts,
          ..._.mapKeys(action.payload.paginatedPosts, '_id'),
        },
        totalPages: action.payload.totalPages,
        page: action.payload.page,
      };
    case GET_POST:
      const comments = state.posts[action.payload._id]
        ? state.posts[action.payload._id]?.comments
        : [];
      return {
        ...state,
        posts: {
          ...state.posts,
          [action.payload._id]: {
            ...state.posts[action.payload._id],
            ...action.payload,
            comments,
          },
        },
      };

    case CLEAR_POSTS:
      return {
        posts: [],
        totalPages: 0,
      };
    case POST_POST_COMMENT:
      return {
        ...state,
        posts: {
          ...state.posts,
          [action.payload._id]: {
            ...state.posts[action.payload._id],
            comments: action.payload.comments,
          },
        },
      };
    case DELETE_POST_COMMENT:
      return {
        ...state,
        posts: {
          ...state.posts,
          [action.payload._id]: {
            ...state.posts[action.payload._id],
            comments: action.payload.comments,
          },
        },
      };
    default:
      return state;
  }
};

export default postsReducer;

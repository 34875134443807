import React from 'react';

import { Form, Field } from 'react-final-form';

import { useParams, useNavigate } from 'react-router-dom';

import CustomSelectCreate from '../../../forms/CustomSelectCreate';

import { connect } from 'react-redux';
import { searchItem, clearSearch, addBuildItem } from '../../../../actions';

const AddItem = (props) => {
  let navigate = useNavigate();

  const onSubmit = (formValues) => {
    // do nothing
  };

  // get build id from url
  const { id } = useParams();

  const searchItem = (searchTerm) => {
    props.searchItem(searchTerm, 'q');
  };

  const onSelect = async (option, form) => {
    if (option.__isNew__) {
      // navigate to create item page with backable state
      return navigate(`/build/${id}/item/edit/?name=${option.value}`, {
        state: { backable: true },
      });
    }

    const itemId = option.value;
    const categoryId = option.categoryId;

    // add item to build
    await props.addBuildItem(id, itemId, categoryId);
    // set item state to empty
    props.clearSearch('q');
  };

  const renderForm = () => {
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={{
          q: '',
        }}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-control">
              <Field
                name="q"
                component={CustomSelectCreate}
                type="text"
                label=""
                options={props.items}
                defaultOptions={props.items}
                placeholder={props.placeholder}
                className="w-full"
                searchAction={searchItem}
                onChange={onSelect}
                isCreatable={true}
                onFocus={props.onFocus}
                onClick={props.onBlur}
                onBlur={props.onBlur}
                onSubmit={props.onBlur}
              />
            </div>
          </form>
        )}
      />
    );
  };

  return (
    <div className="flex flex-col -z-1">
      {renderForm()}
      {/* create div that is 20px below input */}
      <div
        className="-translate-y-24 sm:translate-y-24"
        ref={props.refProp}
      ></div>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const getOptions = (item) => {
    if (!item?.length === 0) {
      return [
        {
          value: '',
          label: '',
        },
      ];
    }

    // create options of items organized by _category name
    const options = item.reduce((acc, item) => {
      const category = item?._category?.name;
      const categoryId = item?._category?._id;
      const family = item?._family?.name;
      const make = item?._make?.name;

      const familyLabel = family ? `${make} ${family} ${category}` : null;
      const familyValue = family ? item?._family?._id : null;

      // if item does not have category in name, add it
      const label = `${make} ${item?.name} ${
        typeof item?.name === 'string' && item?.name?.includes(category)
          ? ''
          : category
      }`;
      const value = item?._id;

      // get categoryParent
      const categoryParent = item?._category?.parentCategory?.name;

      const termMatch = `${category} ${categoryParent} ${make} ${family} ${item?.name}`;

      // if categoryParent exists, add it to label
      const categoryLabel = categoryParent
        ? `${category} ${categoryParent}`
        : category;

      // if category does not exist in accumulator, add it
      if (!acc[category]) {
        acc[category] = {
          label: categoryLabel,
          value: categoryId,
          options: [],
        };
      }

      // if family exists, add it to options of category
      if (family) {
        acc[category].options.push({
          label: familyLabel,
          value: familyValue,
          isFamily: true,
          termMatch,
        });
      }

      // add item to options of category
      acc[category].options.push({
        label,
        value,
        isFamily: false,
        termMatch,
      });

      return acc;
    }, {});

    // remove options with duplicate values and append count of duplicates to label
    Object.keys(options).forEach((key) => {
      const optionsArr = options[key].options;
      const uniqueOptions = optionsArr.reduce((acc, option) => {
        const index = acc.findIndex((item) => item.value === option.value);
        if (index === -1) {
          acc.push({
            ...option,
            count: 1,
            categoryId: options[key].value,
          });
        } else {
          acc[index].count += 1;
        }
        return acc;
      }, []);
      options[key].options = uniqueOptions;
    });

    // update label to include count
    Object.keys(options).forEach((key) => {
      const optionsArr = options[key].options;
      optionsArr.forEach((option) => {
        if (option.count > 1) {
          option.label = `${option.label} (${option.count} items)`;
        }
      });
    });

    // sort options by category, family, label
    Object.keys(options).forEach((key) => {
      const optionsArr = options[key].options;
      optionsArr.sort((a, b) => {
        if (a.isFamily && !b.isFamily) {
          return -1;
        }
        if (!a.isFamily && b.isFamily) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    });

    return Object.values(options);
  };

  const items =
    state.search && state.search.item && state.search.item.length > 0
      ? getOptions(state.search.item)
      : [];

  return {
    items,
  };
}

export default connect(mapStateToProps, {
  searchItem,
  clearSearch,
  addBuildItem,
})(AddItem);

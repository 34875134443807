import React from 'react';

import Identity from '../identity';

import RecordEvent from '../analytics/pageview';

import Helmet from '../ui/Helmet';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import { fetchCompaniesWithMakes } from '../../actions';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';

import Loading from '../Loader';

const Directory = ({ fetchCompaniesWithMakes, profiles }) => {
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true);
      fetchCompaniesWithMakes();
    }
  }, [mounted, fetchCompaniesWithMakes]);

  // render company profiles
  const renderProfiles = () => {
    if (!profiles || !profiles.length) {
      return <Loading />;
    }

    return profiles.map((profile) => {
      const alias = profile?.alias;
      return (
        <div key={profile._id}>
          <Link to={`${alias ? `/${alias}/` : `/athlete/${profile._id}/`}`}>
            <h2>{profile.name}</h2>
          </Link>
        </div>
      );
    });
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center p-5 gap-4 dark:text-neutral bg-gradient-to-b from-yellow-300 to-yellow-200">
      <Helmet
        title="Active Projects - Company Directory"
        description="List of cycling brands with products on Active Projects"
        url={`${process.env.REACT_APP_URL}/directory`}
      />
      <Identity noDark />
      <Link to="/" className="btn btn-ghost btn-square">
        <ArrowLeftIcon className="h-6 w-6" />
      </Link>
      <div className="flex flex-row gap-3 items-center">
        <h1>Company Directory</h1>
      </div>
      {/* max height 80% vh and overflowscroll */}
      <div className="max-h-[80vh] overflow-y-scroll flex flex-col items-center gap-2 w-full">
        {renderProfiles()}
      </div>
      <RecordEvent
        hitType="pageview"
        page={window.location.pathname}
        title="Active Projects - Company Directory"
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    profiles: state?.search?.athletes,
  };
}

export default connect(mapStateToProps, { fetchCompaniesWithMakes })(Directory);

import React from 'react';
import { Link } from 'react-router-dom';

// import trash heroicon
import { XMarkIcon } from '@heroicons/react/24/solid';

import BuildImages from '../image';

const buildListCard = ({
  build,
  deleteButton,
  deleteHandler,
  imageOnly,
  customClass = '',
}) => {
  if (!build || !build.images) {
    return null;
  }

  const { _id, name } = build;

  const renderDeleteButton = () => {
    if (!deleteButton) {
      return null;
    }
    return (
      <div className="mt-1 h-full flex flex-col justify-end mb-4">
        <button
          className="btn btn-circle btn-xs bg-slate-50 text-gray-500 z-30"
          onClick={(e) => {
            // prevent button submit
            e.preventDefault();
            deleteHandler();
          }}
        >
          <XMarkIcon width={18} height={18} />
        </button>
      </div>
    );
  };

  if (imageOnly) {
    return (
      <div className={`card bg-base-100 ${customClass}`}>
        <BuildImages build={build} noCarousel />
      </div>
    );
  }

  return (
    <div className={`card bg-base-100 w-full`}>
      <Link to={`/build/${_id}`} key={_id} state={{ backable: true }}>
        <BuildImages build={build} noCarousel />
        <div className="mx-0 px-0 space-y-0">
          <div className="text-sm py-2 min-h-[4em] items-start">{name}</div>
        </div>
      </Link>
      {renderDeleteButton()}
    </div>
  );
};

export default buildListCard;

import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Page from '../ui/Page';
import Header from '../header';
import Loader from '../Loader';

import ListView from './listView';
import ListEdit from './listEdit';

import { connect } from 'react-redux';

class ListRouter extends React.Component {

    render() {

        if (this.props.loader && this.props.loader.page) {
            return <Loader />;
        }

        return (
            <>
            <Page className="flex h-screen">
                <Header />
                <Routes basename="/list">
                    <Route path="/:listId" element={<ListView />} />
                    <Route path="/:listId/edit" element={<ListEdit />} />
                    <Route path="/edit" element={<ListEdit />} />
                </Routes>
            </Page>
            </>
        );
    }
}

function mapStateToProps(state) {

    const athlete = (state.auth && state.auth.profile) ? state.auth.profile : {
        name: state.auth.name,
        email: state.auth.email,
        id: '_new_'
    };

    return {
        auth: state.auth,
        athlete: athlete,
        loader: state.loader,
    }
}

export default connect(mapStateToProps, null)(ListRouter);
import React, { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { createPortal } from 'react-dom';

const Loader = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  // fade in animation
  const fadeClasses = `transition-opacity duration-1000 ${
    !isVisible ? 'opacity-0' : 'opacity-100'
  } }`;

  // spinning loader
  const loader = (
    <TailSpin
      color="#00BFFF"
      height={50}
      width={50}
      timeout={3000} //3 secs
    />
  );

  // full page loading element with a spinner centered in the middle of the screen
  if (props.type === 'full') {
    return createPortal(
      <div className="h-screen flex items-center justify-center p-5">
        <div className={fadeClasses}>{loader}</div>
      </div>,
      document.querySelector('#loader')
    );
  }

  // if type is inline, loading element with a spinner inline with the content
  if (props.type === 'inline') {
    const size = props.size || '64';
    return (
      <div className={`flex items-center justify-center w-${size}`}>
        <div className={fadeClasses}>{loader}</div>
      </div>
    );
  }

  // if type is inline, loading element with a spinner inline with the content
  if (props.type === 'placeholder') {
    const height = props.height || 'h-4';
    const width = props.width || 'w-40';
    const align = props.align || 'items-left';
    return (
      <div
        className={`flex flex-col w-full w-height animate-pulse py-2 ${align}`}
      >
        <div className={`bg-gray-200 rounded ${height} ${width}`}></div>
      </div>
    );
  }

  if (props.type === 'placeholderParagraph') {
    const height = props.height || 'h-2';
    return (
      <div
        className={`grid grid-flow-row w-full w-height animate-pulse py-2 grid-cols-3 gap-3`}
      >
        <div className={`bg-gray-200 rounded col-span-2 ${height}`}></div>
        <div className={`bg-gray-200 rounded col-span-3 ${height}`}></div>
        <div className={`bg-gray-200 rounded col-span-2 ${height}`}></div>
      </div>
    );
  }

  if (props.type === 'placeholderList') {
    const height = props.height || 'h-1';
    return (
      <div
        className={`grid w-full w-height animate-pulse py-2 grid-cols-3 gap-7`}
      >
        <div className={`bg-gray-200 rounded col-span-3 ${height}`}></div>
        <div className={`bg-gray-200 rounded col-span-2 ${height}`}></div>
        <div className={`bg-gray-200 rounded col-span-3 ${height}`}></div>
      </div>
    );
  }

  if (props.type === 'table') {
    const height = props.height || 'h-4';
    return (
      <div
        className={`grid w-full w-height animate-pulse py-1 grid-cols-4 gap-3 mt-1 ${props.className}`}
      >
        <div className={`bg-gray-300 mx-2 rounded col-span-1 ${height}`}></div>
        <div className={`bg-gray-300 mx-2 rounded col-span-1 ${height}`}></div>
        <div className={`bg-gray-300 mx-2 rounded col-span-1 ${height}`}></div>
        <div className={`bg-gray-300 mx-2 rounded col-span-1 ${height}`}></div>
        <div
          className={`bg-gray-200 mt-3 mx-2 rounded col-span-1 ${height}`}
        ></div>
        <div
          className={`bg-gray-200 mt-3 mx-2 rounded col-span-1 ${height}`}
        ></div>
        <div
          className={`bg-gray-200 mt-3 mx-2 rounded col-span-1 ${height}`}
        ></div>
        <div
          className={`bg-gray-200 mt-3 mx-2 rounded col-span-1 ${height}`}
        ></div>
        <div className={`bg-gray-200 rounded col-span-4 h-0.5`}></div>
        <div className={`bg-gray-200 mx-2 rounded col-span-2 ${height}`}></div>
        <div className={`bg-gray-200 mx-2 rounded col-span-2 ${height}`}></div>
        <div className={`bg-gray-200 rounded col-span-4 h-0.5`}></div>
        <div className={`bg-gray-200 mx-2 rounded col-span-1 ${height}`}></div>
        <div className={`bg-gray-200 mx-2 rounded col-span-1 ${height}`}></div>
        <div className={`bg-gray-200 mx-2 rounded col-span-1 ${height}`}></div>
        <div className={`bg-gray-200 mx-2 rounded col-span-1 ${height}`}></div>
        <div className={`bg-gray-200 rounded col-span-4 h-0.5`}></div>
        <div className={`bg-gray-200 mx-2 rounded col-span-1 ${height}`}></div>
        <div className={`bg-gray-200 mx-2 rounded col-span-1 ${height}`}></div>
        <div className={`bg-gray-200 mx-2 rounded col-span-1 ${height}`}></div>
        <div className={`bg-gray-200 mx-2 rounded col-span-1 ${height}`}></div>
        <div className={`bg-gray-200 rounded col-span-4 h-0.5`}></div>
        <div className={`bg-gray-200 mx-2 rounded col-span-2 ${height}`}></div>
        <div className={`bg-gray-200 mx-2 rounded col-span-2 ${height}`}></div>
        <div className={`bg-gray-200 rounded col-span-4 h-0.5`}></div>
      </div>
    );
  }

  // image loading element with a spinner centered in the middle of the image
  if (props.type === 'image') {
    // aspect ratio of 1:1
    return (
      <div
        className={`${props.className} bg-gray-200 dark:bg-gray-800 items-center justify-center aspect-square shadow-inner skeleton rounded-none`}
      ></div>
    );
  }

  if (props.type === 'builds') {
    // aspect ratio of 1:1
    return (
      <div
        className={`${props.className} items-center justify-center flex flex-col`}
      >
        {loader}
      </div>
    );
  }

  if (props.type === 'items') {
    return (
      <div
        className={`${props.className} items-center justify-center flex flex-col`}
      >
        {loader}
      </div>
    );
  }

  // loading element with a spinner centered in the middle of the screen
  return (
    <div className="h-screen flex items-center justify-center p-5">
      <div className={fadeClasses}>{loader}</div>
    </div>
  );
};

export default Loader;

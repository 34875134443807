import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { clarity } from 'clarity-js';

const Analytics = ({isAdmin}) => {

    if (process.env.NODE_ENV !== 'production' || isAdmin) {
        return null;
    }

    // Google Analytics
    ReactGA.initialize('G-N0YHYL1TEQ');

    // Clarity
    clarity.start({
        projectId: 'jrj2oq57vb',
        upload: 'https://m.clarity.ms/collect',
        track: true,
        content: true
    });

    return null;
}

const mapStateToProps = (state) => {
    const isAdmin = state?.auth?.permissions?.admin;
    return {
        isAdmin
    };
};

export default connect(mapStateToProps)(Analytics);
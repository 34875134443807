// circle svg with transparent background
const Circle = ({className}) => (
    <svg 
        className={className}
        viewBox="0 0 20 20" 
        fill="none" 
        strokeLinecap="round" 
        strokeLinejoin="round">
        <circle cx="10" cy="10" r="8"></circle>
    </svg>
);

export default Circle;
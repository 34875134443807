import React from 'react';

import { connect } from 'react-redux';
import {
  apeFetchItems,
  apeDeleteItem,
  apeExportItems,
  showModal,
} from '../../../actions';

import AthleteLine from '../../athlete/line';
import HistoryModal from './history';

import Loader from '../../Loader';

import {
  EllipsisVerticalIcon,
  ClockIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';

import timeFromNow from '../../../utils/timeFrom';

const ItemsAdmin = ({
  apeFetchItems,
  apeDeleteItem,
  apeExportItems,
  items,
  loading,
  showModal,
}) => {
  const [mounted, setMounted] = React.useState(null);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [exporting, setExporting] = React.useState(false);

  React.useEffect(() => {
    // on mount, get the athlete
    if (!mounted) {
      setMounted(true);
      apeFetchItems({ status: '' });
    }
  }, [apeFetchItems, mounted]);

  const handleDeleteItem = (item) => {
    window.confirm(`Are you sure you want to delete ${item?.name}`) &&
      apeDeleteItem(item._id);
  };

  const handleShowModal = (item) => {
    setSelectedItem(item);
    showModal('ITEM_HISTORY');
  };

  const handleExportItems = async (e) => {
    if (exporting) {
      return;
    }

    setExporting(true);
    e.target.innerText = 'Exporting...';
    await apeExportItems();
    // enable the button
    setExporting(false);
    e.target.innerText = 'Export to TSV';
  };

  const renderItemPageMenu = () => {
    return (
      <div className="flex flex-col items-center">
        <div className="dropdown dropdown-bottom dropdown-end hover:bg-gray-200 rounded-lg">
          <label tabIndex={0} className="cursor-pointer ">
            <EllipsisVerticalIcon className="h-5 w-5" />
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-48"
          >
            <li>
              <div onClick={(e) => handleExportItems(e)}>Export to TSV</div>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderItems = () => {
    if (loading?.items) {
      return <Loader type="table" className="mt-5" />;
    }

    // sort by status
    if (items) {
      items.sort((a, b) => {
        if (a.updatedAt && !b.updatedAt) {
          return 1;
        } else if (!a.updatedAt && b.updatedAt) {
          return -1;
        } else {
          return 0;
        }
      });
    }

    const renderMenu = (item) => {
      return (
        <div className="flex flex-col items-center">
          <div className="dropdown dropdown-bottom dropdown-end hover:bg-gray-200 rounded-lg">
            <label tabIndex={0} className="cursor-pointer ">
              <EllipsisVerticalIcon className="h-5 w-5" />
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-32"
            >
              <li>
                <div
                  onClick={() => handleShowModal(item)}
                  className="cursor-pointer"
                >
                  Review
                </div>
              </li>
              <li>
                <div
                  onClick={() => handleDeleteItem(item)}
                  className="cursor-pointer"
                >
                  Delete
                </div>
              </li>
            </ul>
          </div>
        </div>
      );
    };

    // render items in a table
    if (items) {
      return (
        <table className="w-full table-fixed table-compact md:table-auto my-4  text-sm">
          <thead>
            <tr className="text-left">
              <th className="table-cell">Name</th>
              <th className="table-cell">Category</th>
              <th className="hidden md:table-cell">Suggested By</th>
              <th className="table-cell">Status</th>
              <th className="w-4 table-cell"></th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => {
              return (
                <tr key={item._id} className="border-b border-gray-200 py-2">
                  <td className="table-cell truncate hover:whitespace-normal">
                    {item?._make?.name
                      ? `${item._make.name} ${item.name}`
                      : item.name}
                  </td>
                  <td className="table-cell">{item._category?.name}</td>
                  <td className="hidden md:table-cell">
                    {item?.history?.length ? (
                      <AthleteLine athlete={item.history[0]._athlete} />
                    ) : (
                      'N/A'
                    )}
                  </td>
                  <td className="table-cell truncate">
                    <div className="flex flex-row gap-1 text-ellipsis">
                      {item.status === 'pending' ? (
                        <ClockIcon className="h-5 w-5 text-yellow-400" />
                      ) : (
                        <CheckCircleIcon className="h-5 w-5 text-green-400" />
                      )}
                      <span className="capitalize">{item.status}</span>
                      <span className="sm:flex hidden">
                        {item.updatedAt ? timeFromNow(item.updatedAt) : 'N/A'}
                      </span>
                    </div>
                  </td>
                  <td className="w-4 table-cell">{renderMenu(item)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-row w-full">
        <div className="flex-grow items-center text-center">
          <h1>Unverified Item Management</h1>
        </div>
        <div className="flex flex-row items-end">{renderItemPageMenu()}</div>
      </div>
      {renderItems()}
      <HistoryModal item={selectedItem} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    items: state.ape.items,
    loading: state.loader?.elements,
  };
};

export default connect(mapStateToProps, {
  apeFetchItems,
  apeDeleteItem,
  apeExportItems,
  showModal,
})(ItemsAdmin);

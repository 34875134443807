import React from 'react';
import Modal from '../../../../Modal';

import { connect } from 'react-redux';

import { Form, Field } from 'react-final-form';
import CustomInput from '../../../../forms/CustomInput';

import {
  showModal,
  hideModal,
  addNotice,
  updateProfileLocation,
} from '../../../../../actions';

// check heroicon
import { XMarkIcon } from '@heroicons/react/24/outline';

import AutocompleteInput from '../../../../forms/PlacesAutoCompleteInput';

const UpsertLocationModal = ({
  modal,
  locationId,
  locations,
  hideModal,
  updateProfileLocation,
}) => {
  const handleUpsertLocation = async (formValues) => {
    const priorLatLng = formValues.latLng;
    // convert to string
    if (!formValues.latlng) {
      formValues.latlng = `${priorLatLng.lat},${priorLatLng.lng}`;
    }

    const submitData = {
      ...formValues,
      _id: locationId,
    };
    return updateProfileLocation(submitData);
  };

  const renderForm = () => {
    const initialValues = locations?.length
      ? locations.find((location) => location._id === locationId)
      : {
          name: '',
          address: '',
          city: '',
          region: '',
          postalCode: '',
          phone: '',
          email: '',
        };

    const priorCoordinates = initialValues?.geo?.coordinates;
    const priorLatLng = priorCoordinates
      ? `${priorCoordinates[1]},${priorCoordinates[0]}`
      : '';

    return (
      <Form
        onSubmit={handleUpsertLocation}
        initialValues={initialValues}
        initialValuesEqual={() => true} // prevent reinitializing form on every render
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = 'A name is required';
          }
          if (!values.address) {
            errors.address = 'An address is required';
          }
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col justify-between items-center w-full gap-2">
              <Field
                name="name"
                component={CustomInput}
                placeholder="Steve's Eastside Cycle"
                label="Location name"
                className="w-full"
              />
              <Field
                name="address"
                component={AutocompleteInput}
                placeholder="123 Main St."
                label="Address"
                className="w-full"
                subscription={{ value: true }}
                onLatLngChange={(latlng) => {
                  form.change('latlng', latlng);
                }}
                latlngProp={priorLatLng}
                // defaultValue={initialValues.address}
              />
              <Field
                name="latlng"
                component={CustomInput}
                className="hidden"
                // get value from AutocompleteInput
              />
              <Field
                name="phone"
                component={CustomInput}
                placeholder="555-555-5555"
                label="Phone"
                className="w-full"
              />
              <Field
                name="email"
                component={CustomInput}
                placeholder="public email"
                label="Email"
                className="w-full"
              />
              <div className="flex flex-row justify-between items-center w-full gap-2">
                <button
                  className="btn btn-ghost btn-sm"
                  onClick={() => hideModal()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={submitting}
                  className="btn btn-primary btn-sm"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        )}
      />
    );
  };

  const renderContent = (props) => {
    return (
      <div className="flex flex-col">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <div className="text-xl font-bold">Add/Update Location</div>
            </div>
            <div className="flex flex-row justify-end">
              <button
                className="btn btn-square btn-ghost btn-sm"
                onClick={() => hideModal()}
              >
                <XMarkIcon className="h-6 w-6 text-gray-500" />
              </button>
            </div>
          </div>
          <div className="flex flex-col mt-4">
            <div className="flex flex-col mt-2">{renderForm()}</div>
          </div>
        </div>
      </div>
    );
  };

  if (!modal.visible || modal.modalType !== 'UPSERT_LOCATION') {
    return null;
  }

  return <Modal content={renderContent()} onDismiss={() => hideModal()} />;
};

const mapStateToProps = (state) => {
  const alias = state.auth.profile.alias;

  return {
    locations: state.athlete?.[alias]?.locations,
    modal: state.modal,
  };
};

export default connect(mapStateToProps, {
  updateProfileLocation,
  showModal,
  hideModal,
  addNotice,
})(UpsertLocationModal);

import _ from 'lodash';
import React from 'react';
import Modal from '../../Modal';

import Loader from '../../Loader';

import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';

import AthleteLine from '../../athlete/line';
import SearchInput from '../../forms/SearchInput';

import {
  showModal,
  hideModal,
  searchProfiles,
  postProfileToBuild,
  removeProfileFromBuild,
  removeFromBuildProfile,
  followAthlete,
  unfollowAthlete,
  clearSearch,
} from '../../../actions';
import { XMarkIcon } from '@heroicons/react/24/solid';
import {
  ChevronDownIcon,
  CheckIcon,
  UserMinusIcon,
} from '@heroicons/react/24/outline';
import Circle from '../../ui/Circle';

const AddOthers = ({
  modal,
  hideModal,
  searchResults,
  searchProfiles,
  clearSearch,
  postProfileToBuild,
  removeProfileFromBuild,
  buildId,
  buildName,
  buildProfiles = [],
  myProfile,
  athlete,
  followAthlete,
  unfollowAthlete,
  loader,
}) => {
  const [mounted, setMounted] = React.useState(false);
  const [term, setTerm] = React.useState('');

  React.useEffect(() => {
    // on mount and upon sign-in verification, get athletes
    if (!mounted && term && modal.visible && modal.modalType === 'ADD_OTHERS') {
      setMounted(true);
      setTerm(term);
    }
  }, [searchProfiles, mounted, term, modal]);

  const renderSearchError = () => {
    if (searchResults?.error && term?.length > 0) {
      return (
        <div className="flex flex-col text-gray-500 -translate-y-8 w-11/12 items-center animate-fade-in absolute">
          <div className="mb-2 bg-base-100 w-fit text-sm p-2">
            No results, try again?
          </div>
        </div>
      );
    }
  };

  const handleFollow = (profileId) => {
    followAthlete(profileId);
  };

  const handleUnfollow = (profileId) => {
    unfollowAthlete(profileId);
  };

  const renderFollowButton = (profileId) => {
    // if the profileId is the same as the current user, don't show the follow button
    if (profileId === myProfile) {
      return null;
    }

    // does the current user follow this profile?
    const isFollowing = athlete?._following?.includes(profileId);

    return (
      <div
        onClick={() =>
          isFollowing ? handleUnfollow(profileId) : handleFollow(profileId)
        }
        className={`btn btn-sm ${
          isFollowing ? 'btn-ghost bg-base-200' : 'btn-outline'
        }`}
      >
        {isFollowing ? 'Following' : 'Follow'}
      </div>
    );
  };

  const handleAddOthers = async (profileId, service) => {
    const profile = searchResults.find((profile) => profile._id === profileId);

    await postProfileToBuild(buildId, profile, service);

    // clear search
    setTerm('');
  };

  const handleTrashOthers = async (profileId) => {
    const profile = buildProfiles.find(
      (profile) => profile._profile._id === profileId
    );

    // is this the creator?
    const isCreator = profile.creator;

    // are you sure?
    const confirm = !isCreator
      ? window.confirm(
          `Are you sure you want to remove ${profile._profile.name} from this build?`
        )
      : true;
    if (confirm) {
      await removeProfileFromBuild(buildId, profile._profile._id);
    }

    // clear search
    setTerm('');
  };

  const handleClear = async () => {
    setTerm('');
  };

  const searchAction = async (term) => {
    setTerm(term);
    return await searchProfiles(term);
  };

  const onSubmit = async (formValues) => {
    setTerm(formValues.term);
    if (!formValues.term) {
      return await clearSearch({ type: 'profiles' });
    }
    return await searchProfiles(formValues.term);
  };

  const renderForm = () => {
    // does the build already have an event or is athlete an event
    const hasEvent =
      buildProfiles.find((profile) => profile.profileType === 'event') ||
      athlete?.profileType === 'event';

    return (
      <Form
        onSubmit={onSubmit}
        initialValues={{ term }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-row items-center justify-center mb-4">
              <Field
                name="term"
                component={SearchInput}
                type="text"
                placeholder={`Add ${
                  hasEvent ? 'athlete' : 'event'
                } & others...`}
                handleClick={handleSubmit}
                searchAction={searchAction}
                handleClear={handleClear}
                autoFocus
              />
            </div>
          </form>
        )}
      />
    );
  };

  const renderSearchResults = () => {
    if (!_.isEmpty(searchResults) && !searchResults?.error) {
      return searchResults.map((profile, index) => {
        // check if porfile is already added to buildProfiles on _profile._id
        const isCreator = buildProfiles.find(
          (buildProfile) =>
            buildProfile._profile._id === profile._id && buildProfile.creator
        );

        // check if the profiles in the search results are already added to the build and if so, check the service and profileType
        const isAddedService = buildProfiles.find(
          (buildProfile) =>
            buildProfile._profile._id === profile._id &&
            buildProfile.services.find(
              (service) => service._id === profile.services[0]?._id
            )
        );
        const isAddedProfileType = buildProfiles.find(
          (buildProfile) =>
            buildProfile._profile._id === profile._id &&
            buildProfile.profileType === profile.profileType
        );

        return (
          <div
            key={index}
            className="grid grid-cols-2 justify-between items-center border-b border-gray-200 py-2"
          >
            <div className="flex flex-initial items-center gap-2">
              {isCreator && (
                <span className="-ml-1 z-10 fixed">
                  <Circle className="h-11 w-11 stroke-2 stroke-yellow-500 drop-shadow-lg" />
                </span>
              )}
              <AthleteLine athlete={profile} withServices />
            </div>
            {profile?.services?.length === 0 ? (
              <div className="flex flex-grow items-center justify-self-end">
                {!isAddedProfileType ? (
                  <button
                    onClick={() => handleAddOthers(profile._id)}
                    className="btn btn-primary btn-xs sm:btn-sm"
                  >
                    Add as {profile?.profileType}
                  </button>
                ) : (
                  <button
                    onClick={() => handleTrashOthers(profile._id)}
                    className="btn btn-primary btn-xs sm:btn-sm"
                  >
                    <CheckIcon className="h-5 w-5 stroke-2" />
                    {profile?.profileType}
                  </button>
                )}
              </div>
            ) : (
              <div className="join flex flex-grow justify-self-end">
                {!isAddedProfileType ? (
                  <button
                    onClick={() => handleAddOthers(profile._id)}
                    className="btn btn-primary btn-xs sm:btn-sm join-item"
                  >
                    Add as {profile?.profileType}
                  </button>
                ) : (
                  <button
                    onClick={() => handleTrashOthers(profile._id)}
                    className="btn btn-primary btn-xs sm:btn-sm join-item"
                  >
                    <CheckIcon className="h-5 w-5 stroke-2" />
                    {profile?.profileType}
                  </button>
                )}
                <div className="indicator">
                  {isAddedService && (
                    <span className="indicator-item badge badge-warning">
                      <CheckIcon className="h-3 w-3 stroke-2" />
                    </span>
                  )}
                  <div className="dropdown dropdown-end">
                    <div
                      tabIndex={0}
                      role="button"
                      className="btn btn-primary btn-xs sm:btn-sm join-item"
                    >
                      <ChevronDownIcon className="h-5 w-5 -ml-2 -mr-1" />
                    </div>
                    <ul
                      tabIndex={0}
                      className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                    >
                      {profile?.services?.map((service, index) => {
                        if (!service?._id) {
                          return null;
                        }
                        // check if service is already added to buildProfiles on _profile._id
                        const isAdded = buildProfiles.find(
                          (buildProfile) =>
                            buildProfile._profile._id === profile._id &&
                            buildProfile.services.find(
                              (service) =>
                                service._id === profile.services[index]?._id
                            )
                        );
                        return (
                          <li key={index}>
                            {isAdded ? (
                              <div
                                onClick={() => handleTrashOthers(profile._id)}
                              >
                                <CheckIcon className="h-5 w-5 stroke-2" />
                                {service.label}
                              </div>
                            ) : (
                              <div
                                onClick={() =>
                                  handleAddOthers(profile._id, service)
                                }
                              >
                                {service.label}
                              </div>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      });
    } else if (!searchResults?.error || !loader) {
      return (
        <div className="text-sm text-gray-500 mb-4">
          <div className="mb-2">No results.</div>
        </div>
      );
    } else {
      return <Loader type="placeholderList" />;
    }
  };

  const renderParticipants = () => {
    if (!_.isEmpty(buildProfiles)) {
      return buildProfiles.map(
        ({ _profile, profileType, services = [], creator }, index) => {
          // add profileType and services to an array
          const profileParticipation = [
            profileType
              ? { _id: _profile?._id, label: profileType, value: profileType }
              : [],
            ...services,
          ];

          // const renderProfileService = () => {
          //   return (
          //     <div className="gap-2 flex flex-row">
          //       {profileParticipation?.length > 0
          //         ? profileParticipation?.map((participation, index) => {
          //             if (!participation?._id) {
          //               return null;
          //             }
          //             // if only one participation, remove profile from build label
          //             const label = participation?.label || participation;
          //             return (
          //               <span
          //                 key={index}
          //                 className="badge badge-ghost badge-sm lowercase"
          //               >
          //                 {label}
          //               </span>
          //             );
          //           })
          //         : null}
          //     </div>
          //   );
          // };

          const renderProfileOptions = () => {
            return (
              <div className="gap-2 flex flex-row">
                {profileParticipation?.length > 0
                  ? profileParticipation?.map((participation, index) => {
                      if (!participation?._id) {
                        return null;
                      }
                      return (
                        <div
                          key={index}
                          className="flex flex-row items-center gap-2"
                        >
                          {renderFollowButton(participation?._id)}
                          <button
                            onClick={() => handleTrashOthers(_profile._id)}
                            className="btn btn-ghost btn-square btn-sm"
                          >
                            <div className="flex flex-row items-center justify-center">
                              <UserMinusIcon className="h-5 w-5" />
                            </div>
                          </button>
                        </div>
                      );
                    })
                  : null}
              </div>
            );
          };

          return (
            <div
              key={index}
              className="flex flex-wrap gap-2 sm:gap-0 sm:flex-row sm:justify-between items-center border-b border-gray-200 py-2"
            >
              <div className="flex flex-col">
                <div className="flex flex-row justify-between items-center gap-2">
                  {creator && (
                    <span className="-ml-1 z-10 fixed">
                      <Circle className="h-11 w-11 stroke-1 stroke-yellow-500 drop-shadow-lg" />
                    </span>
                  )}
                  <AthleteLine athlete={_profile} withServices />
                </div>
              </div>
              <div className="flex flex-row flex-grow items-center justify-end text-sm gap-1">
                {renderProfileOptions()}
              </div>
            </div>
          );
        }
      );
    } else {
      return (
        <div className="text-sm text-gray-500 mb-4">
          <div className="mb-2">No participants.</div>
        </div>
      );
    }
  };

  const renderContent = () => {
    return (
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-center mb-2">
          <div className="text-xl font-semibold">{buildName}</div>
          <button
            onClick={() => hideModal()}
            className="text-sm text-gray-500 hover:text-gray-700"
          >
            <XMarkIcon className="h-8 w-8" />
          </button>
        </div>
        {!_.isEmpty(buildProfiles) ? (
          <div className="text-sm text-gray-500 mb-1">Build Profiles</div>
        ) : (
          <div className="text-sm text-gray-500 mb-1">
            Find event and project participants to add to this build.
          </div>
        )}
        <div className="mt-4">
          {renderForm()}
          {renderSearchError()}
        </div>
        {searchResults?.length > 0 && term?.length > 0
          ? renderSearchResults()
          : renderParticipants()}
      </div>
    );
  };

  if (!modal.visible || modal.modalType !== 'ADD_OTHERS') {
    return null;
  }

  return <Modal onDismiss={() => hideModal()} content={renderContent()} />;
};

const mapStateToProps = (state, ownProps) => {
  const athlete =
    state.auth.profile &&
    state.auth.athlete &&
    state.auth.profile._id === state.auth.athlete &&
    state.auth.profile;

  // if athlete is not in the buildProfiles, add them as the creator or if they are, set creator to true
  const athleteProfile =
    athlete &&
    !ownProps?.buildProfiles?.find(
      (profile) => profile._profile._id === athlete._id
    )
      ? {
          _profile: athlete,
          profileType: '',
          services: [],
          creator: true,
        }
      : {
          ...ownProps?.buildProfiles?.find(
            (profile) => profile?._profile?._id === athlete?._id
          ),
          creator: true,
        };
  const revisedBuildProfiles = ownProps?.buildProfiles?.filter(
    (profile) => profile._profile._id !== athlete?._id
  );
  const buildProfiles = ownProps?.buildProfiles
    ? [athleteProfile, ...revisedBuildProfiles]
    : [athleteProfile];

  const buildName =
    state?.builds?.builds?.[ownProps?.buildId]?.name ||
    state?.search?.builds?.paginatedBuilds?.[ownProps?.buildId]?.name ||
    null;

  return {
    modal: state.modal,
    searchResults: state.search?.profiles,
    buildProfiles,
    buildName,
    athlete,
    myProfile: state?.auth?.athlete,
    loader: state?.loader?.search,
  };
};

export default connect(mapStateToProps, {
  showModal,
  hideModal,
  searchProfiles,
  clearSearch,
  postProfileToBuild,
  removeProfileFromBuild,
  removeFromBuildProfile,
  followAthlete,
  unfollowAthlete,
})(AddOthers);

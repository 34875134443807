import React from 'react';

import { connect } from 'react-redux';
import {
  apeFetchUsers,
  apeActivateUser,
  apeDeactivateUser,
  apeDeleteUser,
} from '../../../actions';

import AthleteLine from '../../athlete/line';

import Loader from '../../Loader';

import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

import timeFromNow from '../../../utils/timeFrom';

const UserAdmin = ({
  apeFetchUsers,
  apeActivateUser,
  apeDeactivateUser,
  apeDeleteUser,
  users,
  loading,
}) => {
  const [mounted, setMounted] = React.useState(null);

  React.useEffect(() => {
    // on mount, get the athlete
    if (!mounted) {
      setMounted(true);
      apeFetchUsers();
    }
  }, [apeFetchUsers, mounted]);

  const toggleUser = (user) => {
    if (user.permissions.user) {
      window.confirm(
        `Are you sure you want to deactivate ${
          user?.displayName || user?.email
        }?`
      ) && apeDeactivateUser(user._id);
    } else {
      window.confirm(
        `Are you sure you want to activate ${user?.displayName || user?.email}?`
      ) && apeActivateUser(user._id);
    }
  };

  const handleDeleteUser = (user) => {
    window.confirm(
      `Are you sure you want to delete ${user?.displayName || user?.email}?`
    ) && apeDeleteUser(user._id);
  };

  const renderUsers = () => {
    if (loading?.users) {
      return <Loader type="table" className="mt-5" />;
    }

    // sort by status and then by lastLogin
    if (users) {
      users.sort((a, b) => {
        if (a.permissions.user && !b.permissions.user) {
          return -1;
        } else if (!a.permissions.user && b.permissions.user) {
          return 1;
        } else if (a.permissions.user && b.permissions.user) {
          if (a.lastLogin && b.lastLogin) {
            return new Date(b.lastLogin) - new Date(a.lastLogin);
          } else if (a.lastLogin && !b.lastLogin) {
            return -1;
          } else if (!a.lastLogin && b.lastLogin) {
            return 1;
          }
        }
        return 0;
      });
    }

    const renderMenu = (user) => {
      if (user?.permissions?.admin) {
        return null;
      }
      return (
        <div className="flex flex-col items-center">
          <div className="dropdown dropdown-bottom dropdown-end hover:bg-gray-200 rounded-lg">
            <label tabIndex={0} className="cursor-pointer ">
              <EllipsisVerticalIcon className="h-5 w-5" />
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-32"
            >
              <li>
                <div
                  onClick={() => toggleUser(user)}
                  className="cursor-pointer"
                >
                  {user.permissions.user ? 'Deactivate' : 'Activate'}
                </div>
              </li>
              {!user.permissions.user ? (
                <li>
                  <div
                    onClick={() => handleDeleteUser(user)}
                    className="cursor-pointer"
                  >
                    Delete
                  </div>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      );
    };

    // render users in a table
    if (users) {
      return (
        <table className="w-full table-fixed table-compact md:table-auto my-4 text-sm">
          <thead>
            <tr className="text-left">
              <th className="hidden md:table-cell">Name</th>
              <th className="table-cell">Email</th>
              {/* <th className='hidden md:table-cell'>Invite Credits</th> */}
              <th className="hidden md:table-cell">Type</th>
              <th className="table-cell">Status</th>
              <th className="w-4 table-cell"></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              return (
                <tr key={user._id} className="border-b border-gray-200 py-2">
                  <td className="hidden md:table-cell">
                    {user?._identity ? (
                      <AthleteLine athlete={user._identity} />
                    ) : (
                      user?.displayName
                    )}
                  </td>
                  <td className="table-cell truncate">{user.email}</td>
                  {/* <td className='hidden md:table-cell'>{user?.inviteCredits?.length || 0}</td> */}
                  <td className="hidden md:table-cell">
                    {user.permissions.admin ? 'Admin' : 'User'}
                  </td>
                  <td className="table-cell">
                    {!user.permissions.user ? (
                      `Waitlisted ${timeFromNow(user.createdAt)}`
                    ) : user?._athlete ? (
                      <>
                        <AthleteLine athlete={user._athlete} noAvatar={true} />
                        {user?.lastLogin ? (
                          <span className="text-xs text-gray-500 dark:text-gray-200 line-clamp-1 -mt-1">
                            Last login {timeFromNow(user.lastLogin)}
                          </span>
                        ) : null}
                      </>
                    ) : (
                      'Pending Profile'
                    )}
                  </td>
                  <td className="w-4 table-cell">{renderMenu(user)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-col items-center">
      <h1>User Management</h1>
      {renderUsers()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.ape.users,
    loading: state.loader?.elements,
  };
};

export default connect(mapStateToProps, {
  apeFetchUsers,
  apeActivateUser,
  apeDeactivateUser,
  apeDeleteUser,
})(UserAdmin);

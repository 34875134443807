import React, { useState, useEffect } from 'react';

const GuideTooltip = ({ children, message, placement = 'tooltip-bottom' }) => {
  const [showTooltip, setShowTooltip] = useState(true);

  // Check local storage when component mounts
  useEffect(() => {
    const hideTooltip = localStorage.getItem(message);
    if (hideTooltip) {
      setShowTooltip(false);
    }
  }, [message]);

  const handleHideTooltip = () => {
    // Update state and local storage when tooltip is hidden
    setShowTooltip(false);
    localStorage.setItem(message, 'true');
    console.log('Tooltip hidden');
  };

  return (
    <div
      className={`tooltip ${
        showTooltip ? `tooltip-open sm:tooltip-open! before:animate-pulse` : ''
      } ${placement} w-full text-left before:text-md before:font-semibold before:shadow-lg tooltip-warning`}
      data-tip={message}
      onClick={handleHideTooltip}
    >
      {children}
    </div>
  );
};

export default GuideTooltip;

import './ape.css';
import React from 'react';

import { Route, Routes, Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from '../header';

import UserAdmin from './users';
import InviteAdmin from './invites';
import ItemsAdmin from './items';
import PostsAdmin from './posts';
import ApeStats from './stats';

import NotFound from '../404';

import Helmet from '../ui/Helmet';
import Ape from '../ui/Ape';
import Loader from '../Loader';
import Distributors from './distributors';

const ApeRouter = ({ isAdmin, loader }) => {
  // on mount, get the athlete
  // const [mounted, setMounted] = React.useState(null);

  const { pathname } = useLocation();

  if (loader && loader.page) {
    return <Loader />;
  }
  if (!isAdmin) {
    return <NotFound />;
  }

  const renderMenu = () => {
    // current route
    const location = pathname.split('/')[2];

    // menu to toggle between user and invite management
    return (
      <div className="flex flex-row gap-4 py-4 justify-center mt-6">
        <div className="flex flex-row items-center justify-center">
          <Link
            to="/ape-admin/stats"
            className={`text-base ${
              location === 'stats' &&
              `font-semibold underline underline-offset-8`
            }`}
          >
            Stats
          </Link>
        </div>
        <div className="flex flex-row items-center justify-center">
          <Link
            to="/ape-admin/posts"
            className={`text-base ${
              location === 'posts' &&
              `font-semibold underline underline-offset-8`
            }`}
          >
            Posts
          </Link>
        </div>
        <div className="flex flex-row items-center justify-center">
          <Link
            to="/ape-admin/users"
            className={`text-base ${
              location === 'users' &&
              `font-semibold underline underline-offset-8`
            }`}
          >
            Users
          </Link>
        </div>
        <div className="flex flex-row items-center justify-center">
          <Link
            to="/ape-admin/invites"
            className={`text-base ${
              location === 'invites' &&
              `font-semibold underline underline-offset-8`
            }`}
          >
            Invites
          </Link>
        </div>
        <div className="flex flex-row items-center justify-center">
          <Link
            to="/ape-admin/items"
            className={`text-base ${
              location === 'items' &&
              `font-semibold underline underline-offset-8`
            }`}
          >
            Items
          </Link>
        </div>
        <div className="flex flex-row items-center justify-center">
          <Link
            to="/ape-admin/distributors"
            className={`text-base ${
              location === 'distributors' &&
              `font-semibold underline underline-offset-8`
            }`}
          >
            Distributors
          </Link>
        </div>
      </div>
    );
  };

  return (
    <Ape>
      <Helmet title="APE Admin" />
      <Header />
      <div className="px-5">
        {renderMenu()}
        <Routes basename="/ape-admin">
          <Route path="/users/*" element={<UserAdmin />} />
          <Route path="/invites/*" element={<InviteAdmin />} />
          <Route path="/items/*" element={<ItemsAdmin />} />
          <Route path="/posts/*" element={<PostsAdmin />} />
          <Route path="/stats" element={<ApeStats />} />
          <Route path="/distributors/*" element={<Distributors />} />
        </Routes>
      </div>
    </Ape>
  );
};

function mapStateToProps(state) {
  const isSignedIn = state.auth?.userId;
  const isAdmin = state?.auth?.permissions?.admin;

  return {
    athlete: state.athlete,
    auth: state.auth,
    isSignedIn,
    isAdmin,
    loader: state.loader,
  };
}

export default connect(mapStateToProps, {})(ApeRouter);

import React from 'react';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { getBuilds } from '../../../../actions';

import Masonry from 'react-masonry-css';

import BuildCard from '../../../builds/card';
import ProfileMenu from '../menu';

import Helmet from '../../../ui/Helmet';
import RecordEvent from '../../../analytics/pageview';

import Glassup from '../../../landing/glassup';
import Loader from '../../../Loader';

const AthleteBuilds = ({
  athleteId,
  getBuilds,
  loading,
  buildsType,
  builds,
  lists,
  myAthlete,
  totalPages,
  athlete,
}) => {
  const id = athleteId;

  const [mounted, setMounted] = React.useState(false);
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    if (!mounted && !loading && buildsType !== id) {
      setMounted(true);
      getBuilds(id);
    }
  }, [mounted, id, loading, buildsType, getBuilds]);

  // get the next page of builds if the user scrolls to the bottom of the page
  React.useEffect(() => {
    function handleScroll() {
      if (
        window.innerHeight +
          document.getElementById('main-content').scrollTop +
          400 <
        document.getElementById('scroll-surface').offsetHeight
      )
        return;

      // if we're on the last page or more results loading, don't bug the server
      if (page === totalPages || loading || totalPages === 0) return;

      getBuilds(id, page + 1);
      // increment page
      setPage(page + 1);
    }
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, [page, setPage, totalPages, loading, getBuilds, id]);

  const renderBuilds = () => {
    if (!builds || buildsType !== id) {
      return null;
    }

    if (myAthlete && builds?.length === 0) {
      return renderFirstBuild();
    } else if (!builds?.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center">
          No builds yet.
        </div>
      );
    }

    return (
      <>
        <Glassup>
          <Masonry
            breakpointCols={{ 640: 2, default: 3 }}
            className="masonry-grid gap-4 md:gap-8 lg:gap-8 px-4 md:px-0"
            columnClassName="masonry-grid_column space-y-3 sm:space-y-4 md:space-y-6"
          >
            {builds.map((build) => {
              return (
                <BuildCard
                  key={build._id}
                  build={build}
                  lists={lists}
                  profile
                  noAvatar
                  noCarousel
                  noReaction
                />
              );
            })}
            {builds?.length % 3 !== 1 && <div></div>}
          </Masonry>
        </Glassup>
      </>
    );
  };

  const renderFirstBuild = () => {
    return (
      <div className="flex justify-center items-center h-[45vh] px-5">
        <div>
          <h1>Now let's load up your bike</h1>
          <h2>
            On Active Projects you register your bike by creating a build.
          </h2>
          <p>
            Builds are a collection of components that make up your bike. You
            can create as many builds as you like.
          </p>
          <Link to="/build/edit" className="btn btn-primary my-4">
            Add Build
          </Link>
        </div>
      </div>
    );
  };

  // get athlete type and convert to title case
  const athleteType =
    athlete?.profileType !== 'athlete'
      ? `a ${athlete?.profileType}`
      : 'a cyclist';

  return (
    <>
      <Helmet
        title={`${athlete?.name}, ${athleteType} on Active Projects`}
        description={`View ${athlete?.name}'s builds, lists, and items on Active Projects`}
        image={athlete?.avatar}
        url={`${process.env.REACT_APP_URL}/${athlete?.alias}/`}
      />
      <RecordEvent
        hitType="pageview"
        page={window.location.pathname}
        title={`Profile - ${athlete?.name} - builds`}
      />
      <ProfileMenu myAthlete={myAthlete} athlete={athlete} />
      {renderBuilds()}
      {loading && <Loader type="builds" />}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const athleteId = ownProps.athleteId;

  const builds =
    state.builds && state.builds.builds
      ? Object.values(state.builds.builds)
      : null;

  const athlete = ownProps?.athlete;
  // get makes from athlete
  const makes = athlete?.makes || [];
  const makeIds = makes?.map((make) => make?.value);

  // only show builds for the current athlete in build profiles OR makes in build items on _item._make._id
  const athleteBuilds = builds
    ? builds.filter(
        (build) =>
          build?._athlete?._id === athleteId ||
          build?.profiles?.some(
            (profile) => profile?._profile?._id === athleteId
          ) ||
          build?.items?.some((item) =>
            makeIds.includes(item?._item?._make?._id)
          )
      )
    : null;

  const buildsType = state.builds && state.builds.type;
  const loading = state.loader && state.loader.builds;

  const totalPages =
    state.builds && state.builds.totalPages ? state.builds.totalPages : 0;

  return {
    auth: state.auth,
    builds: athleteBuilds,
    buildsType,
    totalPages,
    loading,
  };
};

export default connect(mapStateToProps, { getBuilds })(AthleteBuilds);

import _ from 'lodash';
import {
  GET_ITEM,
  GET_ITEMS,
  DELETE_ITEM,
  DELETE_ITEM_IMAGE,
  CLEAR_ITEMS,
} from '../actions/types';

const INITIAL_STATE = {
  items: [],
  make: '',
  filters: {
    inBuilds: false,
    forSale: false,
    inBrands: false,
  },
  totalPages: 0,
};

const itemReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ITEMS:
      return {
        ...state,
        items: {
          ...state.items,
          ..._.mapKeys(action.payload.items, '_id'),
        },
        make: action.payload.make,
        filters: action.payload.filters,
        totalPages: action.payload.totalPages,
      };
    case GET_ITEM:
      if (action.payload?.type === 'more') {
        // add additional builds to the builds array
        return {
          ...state,
          items: {
            ...state.items,
            [action.payload._id]: {
              ...state.items[action.payload._id],
              builds: [
                ...state.items[action.payload._id].builds,
                ...action.payload.builds,
              ],
              totalPages: action.payload.totalPages,
            },
          },
        };
      }
      return {
        ...state,
        items: { ...state.items, [action.payload._id]: action.payload },
      };
    case DELETE_ITEM:
      return _.omit(state.items, action.payload);
    case DELETE_ITEM_IMAGE:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: {
            ...state.items[action.payload.id],
            // remove the image from the images array
            images: _.remove(
              // iterate over the images array
              state.items[action.payload.id]?.images,
              (image) => image.imageFile !== action.payload.imageFile
            ),
          },
        },
      };
    case CLEAR_ITEMS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default itemReducer;

import { 
    AUTH_USER,
    FETCH_USER, 
    LOG_OUT,
    SET_USER_ATHLETE,
    FETCH_AUTH_ATHLETES,
    SET_INVITE_TOKEN
} from '../actions/types';

const INITIAL_STATE = {
    userId: null,
    stravaId: null,
    name: null,
    athlete: null,
    profile: null,
    permissions: null,
    inviteToken: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTH_USER:
            return { 
                ...state, 
                userId: action.payload?._id,
                stravaId: action.payload?.stravaId,
                name: action.payload?.displayName,
                email: action.payload?.email,
                athlete: action.payload?._athlete,
                permissions: action.payload?.permissions
            };
        case LOG_OUT:
            return {
                ...state,
                userId: null,
                stravaId: null,
                name: null,
                email: null,
                athlete: null,
                permissions: null,
                inviteToken: null,
            }
        case FETCH_USER:
            return {
                ...state,
                userId: action.payload?._id,
                stravaId: action.payload?.stravaId,
                name: action.payload?.displayName,
                email: action.payload?.email,
                athlete: action.payload?._athlete,
                permissions: action.payload?.permissions
            }
        case SET_USER_ATHLETE:
            return {
                ...state,
                athlete: action.payload._id,
                profile: action.payload
            }
        case FETCH_AUTH_ATHLETES:
            return {
                ...state,
                athleteList: action.payload
            }
        case SET_INVITE_TOKEN:
            return {
                ...state,
                inviteToken: action.payload
            }
        default:
            return state;
    }
};

export default authReducer;
import { ArrowLeftIcon } from '@heroicons/react/24/solid';

const APArrowLeftIcon = () => {
  return (
    <ArrowLeftIcon
      className="h-7 w-7"
      stroke="currentColor"
      strokeWidth="0.5"
    />
  );
};

export default APArrowLeftIcon;

import React from 'react';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { getLists } from '../../../../../actions';

import Helmet from '../../../../ui/Helmet';
import RecordEvent from '../../../../analytics/pageview';

import Glassup from '../../../../landing/glassup';
import ListCards from '../../../../lists/listCards';

const ListsView = (props) => {
  // const { id } = useParams();
  const id = props.athleteId;

  // onmount state
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true);
      props.getLists(id);
    }
  }, [mounted, props, id]);

  function renderFirstList() {
    if (!props.lists || !props.lists.length) {
      return (
        <div className="flex justify-center items-center h-[45vh] px-5">
          <div>
            <h1>What are lists?</h1>
            <h2>
              A list is a collection of components that you can group together.
            </h2>
            <p>
              You can make all sorts of lists and we'll help you get started
              with a wish list and a favorites list.
            </p>
            <Link to="/list/edit" className="btn btn-primary my-4">
              Add List
            </Link>
          </div>
        </div>
      );
    }
  }

  function renderLists() {
    if (!props.lists || !props.lists.length) {
      return;
    }

    return (
      <>
        <Glassup>
          <ListCards lists={props.lists} athleteId={id} />
        </Glassup>
      </>
    );
  }

  if (props.myAthlete && !props.lists?.length) {
    return renderFirstList(props);
  } else if (!props.lists?.length) {
    return (
      <div className="flex flex-col items-center justify-center">
        No lists yet.
      </div>
    );
  }

  return (
    <div>
      <Helmet
        title={`${props?.athlete?.name}'s lists on Active Projects`}
        description={`View all of ${props?.athlete?.name}'s lists on Active Projects`}
        url={`${process.env.REACT_APP_URL}/${props?.athlete?.alias}/lists/`}
      />
      {renderLists(props)}
      <RecordEvent
        hitType="pageview"
        page={window.location.pathname}
        title={`Profile - ${props?.athlete?.name} - lists`}
      />
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const { athleteId } = ownProps;

  // get lists for this athlete
  const lists = Object.values(state.lists).filter(
    (list) => list._athlete._id === athleteId
  );

  const newList = lists.map((list) => ({
    ...list,
    itemCount: list.items.length,
  }));

  // keep 4 items per list
  newList.forEach((list) => {
    if (list.items.length > 4) {
      list.items = list.items.slice(0, 4);
    }
  });

  return {
    auth: state.auth,
    lists: newList,
  };
}

export default connect(mapStateToProps, { getLists })(ListsView);

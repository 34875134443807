import React from 'react';

import { connect } from 'react-redux';
import { Route, Routes, Link, useLocation } from 'react-router-dom';

import NotFound from '../../404';

import Locations from './locations';
import Stats from './stats';

import Header from '../../header';
import Page from '../../ui/Page';

const DashRouter = ({ isAdmin, isIdentity, isCompany, profile }) => {
  const { pathname } = useLocation();

  if (!isCompany && !isAdmin) {
    return <NotFound />;
  }

  const renderMenu = () => {
    const location = pathname.split('/')[3] || null;

    return (
      <div className="flex flex-row gap-4 justify-center mt-6">
        <div className="flex flex-row items-center justify-center">
          <Link
            to="/profile/dash"
            className={`text-base ${
              !location && `font-semibold underline underline-offset-8`
            }`}
          >
            Stats
          </Link>
        </div>
        <div className="flex flex-row items-center justify-center">
          <Link
            to="/profile/dash/locations/"
            className={`text-base ${
              location === 'locations' &&
              `font-semibold underline underline-offset-8`
            }`}
          >
            Locations
          </Link>
        </div>
        <div className="flex flex-row items-center justify-center">
          <Link to="/profile/edit" className={`text-base`}>
            Edit
          </Link>
        </div>
      </div>
    );
  };

  return (
    <Page className="flex h-screen">
      <Header />
      <div className="px-5">
        <div className="flex flex-col items-center justify-center py-4 ">
          <h1>{profile.name}</h1>
          {renderMenu()}
        </div>
        <Routes basename="/profile/dash">
          <Route path="/" element={<Stats />} />
          <Route path="/locations" element={<Locations />} />
        </Routes>
      </div>
      <script
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap`}
      ></script>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.auth.profile,
    isAdmin: state.auth.isAdmin,
    isIdentity: state.auth.isIdentity,
    isCompany: state.auth.profile?.profileType === 'company',
    loader: state.loader,
  };
};

export default connect(mapStateToProps)(DashRouter);

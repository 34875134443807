import React from 'react';

import { Form, Field } from 'react-final-form';

import CustomInput from '../forms/CustomInput';

import {
  EllipsisVerticalIcon,
  PaperAirplaneIcon,
  HeartIcon,
} from '@heroicons/react/24/outline';

import timeFromNow from '../../utils/timeFrom';
import ProfileImage from '../athlete/image';

// function to render text and convert all urls into links with the domain name as the text
const renderText = (text) => {
  // if no text return null
  if (!text) {
    return null;
  }

  // regex to find urls
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  // split text into array of strings and urls
  const textArray = text.split(urlRegex);
  // map over array and return text or link
  return textArray.map((item, index) => {
    if (item.match(urlRegex)) {
      // get domain name excluding www
      const domain = item
        .replace('https://', '')
        .replace('http://', '')
        .replace('www.', '')
        .split(/[/?#]/)[0];
      return (
        <a
          key={index}
          href={item}
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-500 hover:underline"
        >
          {domain}
        </a>
      );
    }
    return item;
  });
};

const Comments = ({
  comments,
  isSignedIn,
  postAction,
  deleteAction,
  athlete,
}) => {
  const [replyTo, setReplyTo] = React.useState(null);

  const onSubmit = async (formValues, form) => {
    await postAction(formValues);
    // clear form
    form.reset();
  };

  const onReply = (commentId) => {
    // set replyTo to commentId
    setReplyTo(commentId);
    // set focus to comment input
    document.getElementById('comment').focus();
  };

  const renderComments = () => {
    if (!comments) {
      return <div>No comments</div>;
    }

    return comments.map((comment) => {
      if (!comment) {
        return null;
      }

      const {
        _id,
        _athlete,
        comment: text,
        createdAt,
        _replies,
        _likes,
      } = comment;

      return (
        <div key={_id} className="mb-0">
          <div className="chat chat-start">
            <div className="chat-image avatar">
              <ProfileImage athlete={_athlete} className="w-10 h-10" />
            </div>
            <div className="chat-header gap-2">
              <span className="mr-2">{_athlete?.name}</span>
            </div>
            <div
              className={`chat-bubble items-center justify-between flex flex-row whitespace-normal ${
                text?.length > 20 ? `text-sm` : ``
              }`}
            >
              <div>{renderText(text)}</div>
              <div className="dropdown dropdown-bottom dropdown-end translate-x-9 z-20">
                <label
                  tabIndex={0}
                  className="m-0 p-0 self-center cursor-pointer"
                >
                  <EllipsisVerticalIcon className="h-4 w-4" />
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-32"
                >
                  {isSignedIn && athlete?._id === _athlete?._id && (
                    <li>
                      <button onClick={() => deleteAction(_id)}>Delete</button>
                    </li>
                  )}
                  <li>
                    <button onClick={() => console.log(_id)}>Report</button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-row gap-0 chat-footer items-center opacity-60">
              <div>
                <button className="btn btn-ghost btn-xs btn-square gap-1 hidden">
                  {_likes?.length > 0 && (
                    <span className="badge badge-success badge-ghost badge-xs">
                      {_likes.length}
                    </span>
                  )}
                  <span>
                    <HeartIcon className="h-4 w-4 pt-0.5" />
                  </span>
                </button>
              </div>
              <div>
                <button
                  className="btn btn-ghost btn-xs gap-1"
                  onClick={() => onReply(_id)}
                >
                  {_replies?.length > 0 && (
                    <span className="badge badge-primary badge-ghost badge-xs">
                      {_replies.length}
                    </span>
                  )}
                  <span className="lowercase">Reply</span>
                </button>
              </div>
              <time className="text-xs opacity-50 badge badge-ghost">
                {timeFromNow(createdAt)}
              </time>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderForm = () => {
    if (isSignedIn) {
      return (
        <Form
          initialValues={{ replyTo: replyTo, comment: '' }}
          onSubmit={onSubmit}
          // reset form after submit
          onSubmitSuccess={(formValues, form, callback) => {
            form.values.comment = '';
          }}
          validate={(values) => {
            const errors = {};
            if (!values?.comment?.trim().length) {
              errors.comment = '';
            }

            if (values?.comment?.trim().length < 5) {
              // do we need to show a message to the user?
              errors.comment = '';
            }
            return errors;
          }}
          validateOnBlur={false}
          validateOnChange={false}
          render={({ handleSubmit, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-2 relative">
                <div className="flex flex-row gap-2 items-start mb-1">
                  <ProfileImage athlete={athlete} className="w-11 h-11 mt" />
                  <Field
                    type="text"
                    name="comment"
                    className="rounded-full text-sm"
                    placeholder="Add a comment"
                    component={CustomInput}
                  />
                  <Field type="hidden" name="replyTo" component="input" />
                  <button
                    type="submit"
                    disabled={
                      submitting ||
                      values.comment?.trim().length < 5 ||
                      !values.comment?.trim().length
                    }
                    className="btn btn-brand bg-brand text-secondary hover:bg-brand hover:text-base-content btn-square disabled:cursor-not-allowed disabled:text-gray-300 disabled:hover:text-gray-300 disabled:btn-ghost disabled:outline-none"
                  >
                    <PaperAirplaneIcon className="h-7 w-7" />
                  </button>
                </div>
              </div>
            </form>
          )}
        />
      );
    }
  };

  return (
    <div className="flex flex-col gap-3">
      {renderComments()}
      {renderForm()}
    </div>
  );
};

export default Comments;

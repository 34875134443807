import React from 'react';
import Modal from '../../../Modal';

import { connect } from 'react-redux';

import { Form, Field } from 'react-final-form';
import CustomInput from '../../../forms/CustomInput';
import CustomToggle from '../../../forms/CustomToggle';
import CustomSelectCreate from '../../../forms/CustomSelectCreate';
import validateEmail from '../../../../utils/validateEmail';

import {
  showModal,
  hideModal,
  addNotice,
  searchProfiles,
  apeGenerateInvite,
} from '../../../../actions';

// check heroicon
import { XMarkIcon } from '@heroicons/react/24/outline';

const AddInviteModal = ({
  isSignedIn,
  athlete: currentAthlete,
  modal,
  searchProfiles,
  athleteOptions,
  apeGenerateInvite,
  hideModal,
  addNotice,
}) => {
  // const [mounted, setMounted] = React.useState(false);
  // const [showForm, setShowForm] = React.useState(false);

  const handlePostInvite = async ({
    name,
    email,
    toggleProfileFields,
    permission,
    identity,
    profileId,
    toggleInviteFrom,
    inviteFromId,
  }) => {
    const postData = {
      name,
      email,
      profileId: toggleProfileFields ? profileId.value : '',
      permission,
      identity: toggleProfileFields ? identity : false,
      inviteFromId: toggleInviteFrom ? inviteFromId : '',
    };

    try {
      await apeGenerateInvite(postData);
      addNotice({ notice: 'Invite sent', type: 'success', time: new Date() });
      hideModal();
    } catch (err) {
      addNotice({
        notice: 'Error sending invite',
        type: 'alert',
        time: new Date(),
      });
    }
  };

  function handleAthleteSearch(searchTerm) {
    searchProfiles(searchTerm);
  }

  const renderInviteForm = () => {
    const initialValues = {
      email: '',
      name: '',
      toggleProfileFields: false,
      permission: false,
      identity: false,
      profileId: '',
      toggleInviteFrom: false,
      inviteFromId: currentAthlete._id,
    };
    return (
      <Form
        onSubmit={handlePostInvite}
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};
          if (values.email && !validateEmail(values.email)) {
            errors.email = 'Invalid email address';
          }
          if (!values.name) {
            errors.name = 'A name is required';
          }
          if (values.toggleProfileFields && !values.profileId) {
            errors.profileId = 'A profile is required';
          }
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col justify-between items-center w-full gap-2">
              <Field
                name="email"
                component={CustomInput}
                placeholder="hello@activeprojects.co"
                label="Email address for invite (optional)"
                className="w-full"
              />
              <Field
                name="name"
                component={CustomInput}
                placeholder="Justice Doit"
                label="Invitee's name"
                className="w-full"
              />
              <div className="flex flex-row items-center w-full justify-between">
                <Field
                  name="toggleProfileFields"
                  component={CustomToggle}
                  type="checkbox"
                  label="Add to existing profile"
                />
              </div>
              {values.toggleProfileFields && (
                <>
                  <div className="flex flex-row items-start w-full">
                    <Field
                      name="profileId"
                      component={CustomSelectCreate}
                      options={athleteOptions}
                      searchAction={handleAthleteSearch}
                      // deactivate create option for make if user is not admin
                      isCreatable={false}
                      placeholder="Search profiles"
                      label="Select profile"
                      className="w-full"
                    />
                  </div>
                  <div className="flex flex-row items-center w-full justify-between">
                    <Field
                      name="identity"
                      component={CustomToggle}
                      type="checkbox"
                      label="Make new user the primary identity"
                    />
                  </div>
                </>
              )}
              <div className="flex flex-row items-center w-full justify-between">
                <Field
                  name="toggleInviteFrom"
                  component={CustomToggle}
                  type="checkbox"
                  label={
                    values.toggleInviteFrom
                      ? `Invite to come from you, ${currentAthlete?.name}`
                      : 'Invite to come from Active Projects'
                  }
                />
              </div>
              <div className="flex flex-row justify-between items-center w-full gap-2">
                <button
                  className="btn btn-ghost btn-sm"
                  onClick={() => hideModal()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={submitting}
                  className="btn btn-primary btn-sm"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        )}
      />
    );
  };

  const renderContent = (props) => {
    return (
      <div className="flex flex-col">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <div className="text-xl font-bold">Generate Invitation</div>
            </div>
            <div className="flex flex-row justify-end">
              <button
                className="btn btn-square btn-ghost btn-sm"
                onClick={() => hideModal()}
              >
                <XMarkIcon className="h-6 w-6 text-gray-500" />
              </button>
            </div>
          </div>
          <div className="flex flex-col mt-4">
            <div className="flex flex-col mt-2">{renderInviteForm()}</div>
          </div>
        </div>
      </div>
    );
  };

  if (!modal.visible || modal.modalType !== 'APE_ADD_INVITE') {
    return null;
  }

  return <Modal content={renderContent()} onDismiss={() => hideModal()} />;
};

const mapStateToProps = (state) => {
  const getVanillaOptions = (options) => {
    return options.map((option) => {
      return {
        value: option._id,
        label: option.name?.length ? option.name : option.label,
      };
    });
  };

  const athleteOptions = state.search.profiles?.length
    ? getVanillaOptions(state.search.profiles)
    : [];

  const isSignedIn = state.auth?.userId;

  return {
    isSignedIn,
    athlete: state.auth.profile,
    athleteOptions,
    modal: state.modal,
  };
};

export default connect(mapStateToProps, {
  apeGenerateInvite,
  searchProfiles,
  showModal,
  hideModal,
  addNotice,
})(AddInviteModal);

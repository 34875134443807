import React from 'react';

const Identity = ({ noDark }) => {
  // if noDark is true, then the identity component will not have a dark mode
  if (noDark) {
    return (
      <div className="flex flex-row items-center space-x-2 group cursor-pointer identity">
        {/* Wrap div in an outlined circle */}
        <div className="flex flex-row items-center justify-center w-10 h-10 rounded-full border-2 border-slate-900 sm:ml-1 group-hover:animate-spin bg-white">
          <div className="text-2xl text-slate-900">A</div>
        </div>
        <div className="flex flex-row items-center justify-center w-10 h-10 rounded-full border-2 border-slate-900 group-hover:animate-spin bg-white">
          <div className="text-2xl text-slate-900">P</div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-row items-center space-x-2 group cursor-pointer identity">
      {/* Wrap div in an outlined circle */}
      <div className="flex flex-row items-center justify-center w-10 h-10 rounded-full border-2 border-slate-900 dark:border-slate-100 sm:ml-1 group-hover:animate-spin bg-white dark:bg-black ">
        <div className="text-2xl text-slate-900 dark:text-white">A</div>
      </div>
      <div className="flex flex-row items-center justify-center w-10 h-10 rounded-full border-2 border-slate-900 dark:border-slate-100 group-hover:animate-spin bg-white dark:bg-black">
        <div className="text-2xl text-slate-900 dark:text-white">P</div>
      </div>
    </div>
  );
};

export default Identity;

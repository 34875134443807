import React from 'react';

const Page = ({ children }) => {
  return (
    <div id="scroll-surface" className="py-5 sm:px-10 lg:pt-5 space-y-2 mb-14">
      {children}
    </div>
  );
};

export default Page;

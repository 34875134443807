import React from 'react';

import ListsView from './listsView';
import ListView from './listView';
import ProfileMenu from '../menu';

import { Routes, Route } from 'react-router-dom';

import { connect } from 'react-redux';

class AthleteLists extends React.Component {
  render() {
    return (
      <div>
        <ProfileMenu
          myAthlete={this.props.myAthlete}
          currentTab="lists"
          athlete={this.props.athlete}
        />
        <Routes basename="/athlete/:id/lists">
          <Route
            path="/"
            element={
              <ListsView
                athleteId={this.props.athleteId}
                myAthlete={this.props.myAthlete}
                athlete={this.props.athlete}
              />
            }
          />
          <Route
            path="/:listId"
            element={
              <ListView
                athleteId={this.props.athleteId}
                myAthlete={this.props.myAthlete}
                athlete={this.props.athlete}
              />
            }
          />
        </Routes>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lists: state.lists,
  };
}

export default connect(mapStateToProps, null)(AthleteLists);

import _ from 'lodash';
import {
  ITEMS_RESULTS,
  POST_SEARCH_BUILD_LIKE,
  MODEL_RESULTS,
  SEARCH_RESULTS,
  MORE_SEARCH_RESULTS,
  CLEAR_RESULTS,
  SEARCH_ALIAS,
  CLEAR_TYPE_RESULTS,
  PROFILE_RESULTS,
} from '../actions/types';

const INITIAL_STATE = {
  builds: {
    paginatedBuilds: {},
    totalBuilds: 0,
    totalPages: 0,
    page: 1,
  },
  items: {
    paginatedItems: {},
    totalItems: 0,
    totalPages: 0,
    page: 1,
  },
  lists: {
    paginatedLists: {},
    totalLists: 0,
    totalPages: 0,
    page: 1,
  },
  athletes: [],
  alias: '',
  term: '',
};

const searchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_RESULTS:
      // return { ...state, ...action.payload };
      return {
        ...state,
        term: action.payload.term,
        builds: {
          paginatedBuilds: {
            ...state.builds.paginatedBuilds,
            ..._.mapKeys(action.payload.builds.paginatedBuilds, '_id'),
          },
          totalBuilds: action.payload.builds.totalBuilds,
          totalPages: action.payload.builds.totalPages,
          page: action.payload.page,
        },
        items: {
          paginatedItems: {
            ...state.items.paginatedItems,
            ..._.mapKeys(action.payload.items.paginatedItems, '_id'),
          },
          totalItems: action.payload.items.totalItems,
          totalPages: action.payload.items.totalPages,
          page: action.payload.page,
        },
        athletes: [
          // return all athletes
          ...action.payload.athletes,
        ],
        lists: {
          paginatedLists: {
            ...state.lists.paginatedLists,
            ..._.mapKeys(action.payload.lists.paginatedLists, '_id'),
          },
          totalLists: action.payload.lists.totalLists,
          totalPages: action.payload.lists.totalPages,
          page: action.payload.page,
        },
        // events: [
        //     // return where profileType === 'event'
        //     ..._.filter(action.payload.athletes, athlete => athlete.profileType === 'event')
        // ]
      };
    case MORE_SEARCH_RESULTS:
      // append to existing results based on type
      switch (action.payload.type) {
        case 'builds':
          return {
            ...state,
            builds: {
              paginatedBuilds: {
                ...state.builds.paginatedBuilds,
                ..._.mapKeys(action.payload.builds.paginatedBuilds, '_id'),
              },
              totalBuilds: action.payload.builds.totalBuilds,
              totalPages: action.payload.builds.totalPages,
              page: action.payload.page,
            },
          };
        case 'items':
          return {
            ...state,
            items: {
              paginatedItems: {
                ...state.items.paginatedItems,
                ..._.mapKeys(action.payload.items.paginatedItems, '_id'),
              },
              totalItems: action.payload.items.totalItems,
              totalPages: action.payload.items.totalPages,
              page: action.payload.page,
            },
          };
        case 'lists':
          return {
            ...state,
            lists: {
              paginatedLists: {
                ...state.lists.paginatedLists,
                ..._.mapKeys(action.payload.lists.paginatedLists, '_id'),
              },
              totalLists: action.payload.lists.totalLists,
              totalPages: action.payload.lists.totalPages,
              page: action.payload.page,
            },
          };
        default:
          return state;
      }
    case ITEMS_RESULTS:
      // if action.payload.makes exists, add to existing makes
      // if (state?.makes?.length > 0 && action.payload.makes?.length > 0) {
      //   const mergedMakes = {
      //     ...state.makes,
      //     ...action.payload.makes,
      //   };

      //   console.log('mergedMakes', mergedMakes);

      //   return { ...state, ...action.payload, makes: mergedMakes };
      // }

      return { ...state, ...action.payload };
    case MODEL_RESULTS:
      return { ...state, ...action.payload };
    case CLEAR_TYPE_RESULTS:
      return { ...state, [action.payload]: {} };
    case CLEAR_RESULTS:
      return INITIAL_STATE;
    case POST_SEARCH_BUILD_LIKE:
      return {
        ...state,
        builds: [
          ..._.map(state.builds, (build) => {
            if (build._id === action.payload._id) {
              return { ...build, _likes: action.payload._likes };
            }
            return build;
          }),
        ],
      };
    case SEARCH_ALIAS:
      return { ...state, alias: action.payload };
    case PROFILE_RESULTS:
      return { ...state, athletes: action.payload };
    default:
      return state;
  }
};

export default searchReducer;

import axios from 'axios';
import history from '../history';

import {
  SET_INVITE_TOKEN,
  AUTH_USER,
  FETCH_USER,
  SET_USER_ATHLETE,
  FETCH_AUTH_ATHLETES,
  LOG_OUT,
  DELETE_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
  FETCH_NOTIFICATIONS,
  GET_ATHLETE,
  POST_ATHLETE,
  DELETE_ATHLETE_IMAGE,
  GET_BUILDS,
  GET_BUILD,
  GET_RELATED_BUILDS,
  POST_BUILD,
  POST_BUILD_LIKE,
  POST_SEARCH_BUILD_LIKE,
  POST_BUILD_ITEM_LIKE,
  DELETE_BUILD,
  DELETE_BUILD_IMAGE,
  CLEAR_BUILDS,
  GET_LISTS,
  GET_LIST,
  POST_LIST,
  DELETE_LIST,
  POST_LIST_ITEM,
  CLEAR_LISTS,
  GET_ITEM,
  DELETE_ITEM,
  CLEAR_ITEMS,
  PROFILE_RESULTS,
  POST_ITEM,
  DELETE_ITEM_IMAGE,
  POST_BUILD_ITEM,
  DELETE_BUILD_ITEM,
  GET_BUILD_COMMENTS,
  POST_BUILD_COMMENT,
  DELETE_BUILD_COMMENT,
  FETCH_STRAVA_ACTIVITIES,
  POST_STRAVA_ACTIVITY_TO_BUILD,
  POST_PROFILE_TO_BUILD,
  DELETE_PROFILE_FROM_BUILD,
  GET_ITEMS,
  APE_FETCH_USERS,
  APE_FETCH_INVITES,
  APE_UPDATE_USER,
  APE_ADD_INVITE,
  APE_DELETE_INVITE,
  APE_DELETE_USER,
  APE_FETCH_ITEMS,
  APE_DELETE_ITEM,
  APE_FETCH_STATS,
  APE_FETCH_ANALYTICS,
  CLEAR_TYPE_RESULTS,
  GET_POSTS,
  GET_POST,
  CLEAR_POSTS,
  POST_POST_COMMENT,
  DELETE_POST_COMMENT,
} from './types';

import { setLoader, setLoaderElement } from './loader';
import { showModal, hideModal, deleteModal } from './modal';
import { addNotice, hideNotice, deleteNotice } from './notices';

import {
  apeUpdatePost,
  apeDeletePost,
  apeFetchManyPostCollections,
  apeFetchPostCollection,
  apeReorderPosts,
  apeUpdatePostCollection,
  apeFetchPosts,
  apeDuplicatePostCollection,
  apeDeletePostCollection,
  apeSendTestEmail,
} from './ape/posts';

import {
  apeFetchDistributors,
  apeFetchDistributor,
  apeDeleteDistributor,
  apeUpsertDistributor,
} from './ape/distributors';

import {
  search,
  searchMake,
  searchDistributor,
  searchFamily,
  searchItemProperty,
  searchVariant,
  searchItem,
  searchSubjects,
  searchCollections,
  searchAlias,
  searchAthlete,
  searchProfiles,
  searchUsers,
  clearSearch,
  getCategories,
  searchLocations,
} from './search';

import {
  fetchProfileStats,
  fetchProfileLocations,
  updateProfileLocation,
  deleteProfileLocation,
} from './profile/company';

export { setLoader, setLoaderElement };
export { showModal, hideModal, deleteModal };
export { addNotice, hideNotice, deleteNotice };

export {
  apeUpdatePost,
  apeDeletePost,
  apeFetchManyPostCollections,
  apeFetchPostCollection,
  apeReorderPosts,
  apeUpdatePostCollection,
  apeFetchPosts,
  apeDuplicatePostCollection,
  apeDeletePostCollection,
  apeSendTestEmail,
};

export {
  apeFetchDistributors,
  apeFetchDistributor,
  apeDeleteDistributor,
  apeUpsertDistributor,
};

export {
  search,
  searchMake,
  searchDistributor,
  searchFamily,
  searchItemProperty,
  searchVariant,
  searchItem,
  searchSubjects,
  searchCollections,
  searchAlias,
  searchAthlete,
  searchProfiles,
  searchUsers,
  clearSearch,
  getCategories,
  searchLocations,
};

export {
  fetchProfileStats,
  fetchProfileLocations,
  updateProfileLocation,
  deleteProfileLocation,
};

export const updateServiceworker = () => {
  console.log('update service worker');
  return {
    type: 'UPDATE_SERVICEWORKER',
  };
};

export const authUser = () => async (dispatch) => {
  const response = await axios.post('/api/auth');

  dispatch({ type: AUTH_USER, payload: response.data });
  history.push('/');
};

export const setInviteToken = (token) => async (dispatch) => {
  try {
    const response = await axios.post('/api/invite', { inviteToken: token });
    dispatch({ type: SET_INVITE_TOKEN, payload: response.data.inviteToken });
  } catch (err) {
    console.log(err);
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
    history.push('/');
  }
};

export const loginEmail = (formValues) => async (dispatch) => {
  const res = await axios.post('/auth/email', formValues);

  if (res && res.data && res.data.message) {
    dispatch(hideModal());
    history.push('/login/email_sent');
  } else {
    dispatch(
      addNotice({
        notice: 'Error logging in',
        type: 'negative',
        time: new Date(),
      })
    );
  }
};

export const addToWaitlist = (formValues) => async (dispatch) => {
  try {
    await axios.post('/api/waitlist', { formValues });
    dispatch(
      addNotice({
        notice: "You've been added to the waitlist",
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (err) {
    const notice = 'Error adding to waitlist: ' + err.response.data.message;
    // if error message is "active user", redirect to login
    if (err.response.data?.user) {
      history.push('/login');
    }
    dispatch(addNotice({ notice, type: 'negative', time: new Date() }));
  }
};

export const fetchUser = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/current_user');
    if (response?.data?.message) {
      const type = response.data?.type || 'info';
      dispatch(
        addNotice({
          notice: response.data.message?.text,
          type,
          time: new Date(),
        })
      );
    }
    if (!response?.data?.email && response.data?.stravaId) {
      dispatch({
        type: AUTH_USER,
        payload: { permissions: 'guest', stravaId: response.data.stravaId },
      });
      dispatch(setLoader('page', false));
      return;
    } else if (!response.data || !response.data.email) {
      dispatch({
        type: AUTH_USER,
        payload: { permissions: 'guest' },
      });
      dispatch(setLoader('page', false));
      return;
    }
    if (!response.data?.permissions?.user) {
      if (response.data?.email) {
        history.push('/login/no_account');
      } else {
        history.push('/');
      }
    }
    dispatch({ type: FETCH_USER, payload: response.data });
    dispatch(setLoader('page', false));
  } catch (err) {
    // only show error if user is not on root page
    if (history.location.pathname !== '/') {
      dispatch(
        addNotice({
          notice: err.response.data.message,
          type: 'error',
          time: new Date(),
        })
      );
    }
    dispatch(setLoader('page', false));
  }
};

export const updateUser = (formValues) => async (dispatch) => {
  const response = await axios.post('/api/update_user', { formValues });
  dispatch({ type: FETCH_USER, payload: response.data });
  dispatch(
    addNotice({ notice: 'Profile updated', type: 'positive', time: new Date() })
  );
};

export const addStravaEmail = (formValues) => async (dispatch) => {
  try {
    const response = await axios.post('/auth/strava/email', { formValues });
    dispatch({ type: FETCH_USER, payload: response.data });
    dispatch(
      addNotice({
        notice: 'Strava account connected',
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (err) {
    const notice = 'Error adding Strava email: ' + err.response.data.message;
    dispatch(addNotice({ notice, type: 'negative', time: new Date() }));
  }
};

export const disconnectStrava = () => async (dispatch) => {
  try {
    const response = await axios.get('/auth/strava/disconnect');
    dispatch({ type: FETCH_USER, payload: response.data });
    dispatch(
      addNotice({
        notice: 'Strava account disconnected',
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (err) {
    const notice = 'Error disconnecting Strava: ' + err.response.data.message;
    dispatch(addNotice({ notice, type: 'negative', time: new Date() }));
  }
};

export const logOut = () => async (dispatch) => {
  await axios.get('/api/logout');
  dispatch({ type: LOG_OUT });
  history.push('/');
};

export const getProfile = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/profile');
    dispatch({ type: SET_USER_ATHLETE, payload: response.data });
    // if user is in /login* and step is "setup_athlete", redirect to /profile
    if (
      history.location.pathname.includes('/login') &&
      response.data.step === 'setup_athlete'
    ) {
      history.push('/');
    }
  } catch (err) {
    if (history.location.pathname !== '/') {
      const notice = 'Error getting profile: ' + err.response.data.error;
      dispatch({
        type: SET_USER_ATHLETE,
        payload: { error: notice, status: 404 },
      });

      dispatch(addNotice({ notice, type: 'negative', time: new Date() }));
    }
  }
};

export const getAthlete = (alias) => async (dispatch) => {
  // banned words for alias
  const bannedWords = ['admin', 'profile', 'login', 'logout', 'signup'];

  if (bannedWords.includes(alias)) {
    return;
  }

  try {
    const response = await axios.get(`/api/athletes/${alias}`);
    dispatch({ type: GET_ATHLETE, payload: response.data });
  } catch (err) {
    const notice = 'Error getting athlete: ' + err.response.data.error;
    dispatch({ type: GET_ATHLETE, payload: { error: notice, status: 404 } });
    dispatch(addNotice({ notice, type: 'negative', time: new Date() }));
  }
};

export const postAthlete = (formValues) => async (dispatch) => {
  const images = formValues.profileImages;
  const id = formValues._id || formValues.id;

  // upload profile image
  if (
    images &&
    images.length > 0 &&
    images.filter((image) => image.croppedImage).length > 0 &&
    id === '_new_'
  ) {
    const preResponse = await axios.post('/api/athletes', {
      ...formValues,
      newAcct: '_new_',
    });

    // get id of new build
    const newId = preResponse.data._id;

    const imageTypes = images.map((image) => image.type);
    const paramType =
      process.env.REACT_APP_AWS_S3_PREFIX === 'dev' ? 'dev-athlete' : 'athlete';

    const uploadConfig = await axios.get('/api/upload', {
      params: {
        imageTypes,
        acceptType: ['image/jpeg', 'image/png'],
        type: paramType,
        id: newId,
      },
    });

    await uploadConfig.data.map(async (image, index) => {
      await axios.put(image.url, images[index], {
        headers: {
          'Content-Type': images[index].type,
        },
      });
    });

    const profileImages = uploadConfig.data.map((image) => {
      return { imageFile: image.key };
    });

    formValues = { ...formValues, _id: newId, profileImages };
  } else if (
    id !== null &&
    images &&
    images.length > 0 &&
    images.filter((image) => image.croppedImage).length > 0
  ) {
    // if images exist and id exists, upload new images to s3
    // get images without imageFile property
    const newImages = images.filter((image) => image.croppedImage);
    const existingImages = images.filter(
      (image) => image.imageFile && !image.croppedImage
    );

    const imageTypes = newImages.map((image) => image.type);
    const paramType =
      process.env.REACT_APP_AWS_S3_PREFIX === 'dev' ? 'dev-athlete' : 'athlete';

    const uploadConfig = await axios.get('/api/upload', {
      params: {
        imageTypes,
        acceptType: ['image/jpeg', 'image/png'],
        type: paramType,
        id,
      },
    });

    await uploadConfig.data.map(async (image, index) => {
      await axios.put(image.url, newImages[index], {
        headers: {
          'Content-Type': newImages[index].type,
        },
      });
    });

    const profileImages = uploadConfig.data.map((image) => {
      return { imageFile: image.key };
    });

    // add existing images to buildImages
    const updatedImages = [...profileImages, ...existingImages];

    formValues = { ...formValues, _id: id, profileImages: updatedImages };
  }

  const response = await axios.post('/api/athletes', formValues);
  dispatch({ type: POST_ATHLETE, payload: response.data });
  dispatch({ type: SET_USER_ATHLETE, payload: response.data });

  // update user
  const user = await axios.get('/api/current_user');
  if (!user.data || !user.data.email) {
    dispatch({
      type: AUTH_USER,
      payload: { permission: 'guest' },
    });
    dispatch(setLoader('page', false));
    return;
  }

  setTimeout(() => {
    dispatch({ type: POST_ATHLETE, payload: response.data });
    dispatch(
      addNotice({ notice: 'Profile saved', type: 'success', time: new Date() })
    );

    const profileLink = response?.data?.alias
      ? `/${response.data.alias}`
      : `/athlete/${response?.data?._id}`;
    history.push(profileLink);
  }, 1500);
};

export const postNewAthlete = (formValues) => async (dispatch) => {
  try {
    const response = await axios.post('/api/athletes', formValues);
    dispatch({ type: POST_ATHLETE, payload: response.data });
    dispatch({ type: SET_USER_ATHLETE, payload: response.data });
    dispatch({ type: CLEAR_BUILDS });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.data.message,
        type: 'negative',
        time: new Date(),
      })
    );
    return;
  }
  dispatch(getAuthAthletes());
  dispatch(
    addNotice({
      notice: 'Profile generated',
      type: 'success',
      time: new Date(),
    })
  );
  history.push('/profile/edit');
};

export const switchAthlete = (id) => async (dispatch) => {
  dispatch(setLoader('page', true));
  let profileLink = '/feed';
  try {
    const response = await axios.get(`/api/athletes/switch/${id}`);
    dispatch({ type: CLEAR_BUILDS });
    dispatch({ type: CLEAR_LISTS });
    dispatch({ type: GET_ATHLETE, payload: response.data });
    dispatch({ type: SET_USER_ATHLETE, payload: response.data });
    dispatch(
      addNotice({
        notice: 'Profile switched',
        type: 'positive',
        time: new Date(),
      })
    );
    profileLink = response.data?.alias
      ? `/${response.data.alias}`
      : `/athlete/${response.data._id}`;
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.data.message,
        type: 'negative',
        time: new Date(),
      })
    );
    return;
  }
  dispatch(fetchUser());
  dispatch(getBuilds());
  dispatch(getLists());
  dispatch(getNotifications());
  dispatch(setLoader('page', false));
  // get alias for athlete
  history.push(profileLink);
};

export const getAuthAthletes = () => async (dispatch) => {
  const response = await axios.get('/api/auth_athletes');
  dispatch({ type: FETCH_AUTH_ATHLETES, payload: response.data });
};

export const postBuild = (formValues) => async (dispatch) => {
  dispatch(setLoader('form', true));

  const { images, _id } = formValues;
  let id = _id || null;

  if (images && images.length > 0 && images[0].size > 0 && id === null) {
    const preResponse = await axios.post('/api/builds', { formValues });

    // get id of new build
    id = preResponse.data._id;

    const imageTypes = images.map((image) => image.type);
    const paramType =
      process.env.REACT_APP_AWS_S3_PREFIX === 'dev' ? 'dev-build' : 'build';

    const uploadConfig = await axios.get('/api/upload', {
      params: {
        imageTypes,
        acceptType: ['image/jpeg', 'image/png'],
        type: paramType,
        id,
      },
    });

    await uploadConfig.data.map(async (image, index) => {
      await axios.put(image.url, images[index], {
        headers: {
          'Content-Type': images[index].type,
        },
      });
    });

    const buildImages = uploadConfig.data.map((image) => {
      return { imageFile: image.key };
    });

    formValues = { ...formValues, _id: id, images: buildImages };
  } else if (
    id !== null &&
    images &&
    images.length > 0 &&
    images.filter((image) => image.croppedImage).length > 0
  ) {
    // if images exist and id exists, upload new images to s3
    // get images without imageFile property
    console.log('images', images);

    const newImages = images.filter((image) => image.croppedImage);
    const existingImages = images.filter(
      (image) => image.imageFile && !image.croppedImage
    );

    const imageTypes = newImages.map((image) => image.type);
    const paramType =
      process.env.REACT_APP_AWS_S3_PREFIX === 'dev' ? 'dev-build' : 'build';

    const uploadConfig = await axios.get('/api/upload', {
      params: {
        imageTypes,
        acceptType: ['image/jpeg', 'image/png'],
        type: paramType,
        id,
      },
    });

    await uploadConfig.data.map(async (image, index) => {
      await axios.put(image.url, newImages[index], {
        headers: {
          'Content-Type': newImages[index].type,
        },
      });
    });

    const buildImages = uploadConfig.data.map((image) => {
      return { imageFile: image.key };
    });

    // add existing images to buildImages
    const updatedImages = [...buildImages, ...existingImages];

    formValues = { ...formValues, _id: id, images: updatedImages };
  }

  const response = await axios.post('/api/builds', { id, formValues });

  dispatch({ type: POST_BUILD, payload: response.data });

  // dispatch after five seconds
  setTimeout(() => {
    dispatch(setLoader('form', false));
    dispatch(
      addNotice({ notice: 'Build saved', type: 'positive', time: new Date() })
    );
    return history.push(`/build/${response.data._id}`);
  }, 1500);
};

export const getFeaturedBuilds =
  ({ page = 1, params = {} }) =>
  async (dispatch) => {
    dispatch(setLoader('builds', true));
    page === 1 && dispatch({ type: CLEAR_BUILDS });
    const response = await axios.get('/api/builds/featured', {
      params: {
        page,
        ...params,
      },
    });
    // if page is 1, clear builds
    if (page === 1) {
      dispatch({ type: CLEAR_BUILDS });
    }
    dispatch({
      type: GET_BUILDS,
      payload: { ...response.data, type: 'featured', params },
    });
    dispatch(setLoader('builds', false));
  };

export const getFollowedBuilds =
  ({ page = 1, params = {} }) =>
  async (dispatch) => {
    dispatch(setLoader('builds', true));
    page === 1 && dispatch({ type: CLEAR_BUILDS });
    const response = await axios.get('/api/builds/following', {
      params: {
        page,
        ...params,
      },
    });
    // if page is 1, clear builds
    if (page === 1) {
      dispatch({ type: CLEAR_BUILDS });
    }
    dispatch({
      type: GET_BUILDS,
      payload: { ...response.data, type: 'following', params },
    });
    dispatch(setLoader('builds', false));
  };

export const getLatestBuilds =
  ({ page = 1, params = {} }) =>
  async (dispatch) => {
    dispatch(setLoader('builds', true));
    page === 1 && dispatch({ type: CLEAR_BUILDS });
    const response = await axios.get('/api/builds/latest', {
      params: {
        page,
        ...params,
      },
    });
    // if page is 1, clear builds
    if (page === 1) {
      dispatch({ type: CLEAR_BUILDS });
    }
    dispatch({
      type: GET_BUILDS,
      payload: { ...response.data, type: 'latest', params },
    });
    dispatch(setLoader('builds', false));
  };

export const fetchDiscoverFeed =
  (page = 1) =>
  async (dispatch) => {
    dispatch(setLoader('posts', true));
    page === 1 && dispatch({ type: CLEAR_POSTS });
    const response = await axios.get('/api/posts/discover', {
      params: {
        page,
      },
    });

    dispatch({
      type: GET_POSTS,
      payload: { ...response.data, type: 'all' },
    });

    dispatch(setLoader('posts', false));
  };

export const fetchPost = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/posts/${id}`);
    dispatch({ type: GET_POST, payload: response.data });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.error,
        type: 'negative',
        time: new Date(),
      })
    );
    history.push('/feed');
  }
};

export const postPostComment = (formValues) => async (dispatch) => {
  try {
    const response = await axios.post('/api/posts/comments', formValues);
    dispatch({ type: POST_POST_COMMENT, payload: response.data });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.error,
        type: 'negative',
        time: new Date(),
      })
    );
  }
};

export const deletePostComment = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/posts/comments/${id}`);
    dispatch({ type: DELETE_POST_COMMENT, payload: id });
    dispatch(
      addNotice({
        notice: 'Comment deleted',
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.error,
        type: 'negative',
        time: new Date(),
      })
    );
  }
};

export const getBuilds =
  (athleteId = null, page = 1) =>
  async (dispatch) => {
    try {
      dispatch(setLoader('builds', true));
      const response = await axios.get(`/api/builds/`, {
        params: {
          athleteId,
          page,
        },
      });
      // if page is 1, clear builds
      if (page === 1) {
        dispatch({ type: CLEAR_BUILDS });
      }
      dispatch({
        type: GET_BUILDS,
        payload: { ...response.data, type: athleteId },
      });
      dispatch(setLoader('builds', false));
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.error,
          type: 'negative',
          time: new Date(),
        })
      );
      dispatch(setLoader('builds', false));
      history.push('/feed');
    }
  };

export const getBuild = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/builds/${id}`);
    dispatch({ type: GET_BUILD, payload: response.data });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.error,
        type: 'negative',
        time: new Date(),
      })
    );
    history.push('/feed');
  }
};

export const deleteBuild = (id) => async (dispatch) => {
  await axios.delete(`/api/builds/${id}`);
  dispatch({ type: DELETE_BUILD, payload: id });
  dispatch(
    addNotice({ notice: 'Build deleted', type: 'positive', time: new Date() })
  );
  history.push('/feed');
};

export const duplicateBuild = (id) => async (dispatch) => {
  const response = await axios.post(`/api/builds/${id}/duplicate`);
  dispatch({ type: POST_BUILD, payload: response.data });
  dispatch(
    addNotice({
      notice: 'Build duplicated',
      type: 'positive',
      time: new Date(),
    })
  );
  history.push(`/build/${response.data._id}`);
};

export const getRelatedBuilds =
  (id, page = 1) =>
  async (dispatch) => {
    dispatch(setLoader('builds', true));
    try {
      const response = await axios.get(`/api/builds/${id}/related`, {
        params: {
          page,
        },
      });
      if (page === 1) {
        dispatch({ type: GET_RELATED_BUILDS, payload: response.data });
      } else {
        dispatch({
          type: GET_RELATED_BUILDS,
          payload: { ...response.data, type: 'more' },
        });
      }
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.error,
          type: 'negative',
          time: new Date(),
        })
      );
    }
    dispatch(setLoader('builds', false));
  };

export const deleteBuildImage = (id, imageFile) => async (dispatch) => {
  await axios.delete(`/api/builds/${id}/images/`, { data: { imageFile } });
  dispatch({ type: DELETE_BUILD_IMAGE, payload: { id, imageFile } });
  dispatch(
    addNotice({ notice: 'Image deleted', type: 'positive', time: new Date() })
  );
};

export const deleteItemImage = (id, imageFile) => async (dispatch) => {
  console.log('delete item image', id, imageFile);
  await axios.delete(`/api/items/${id}/images/`, { data: { imageFile } });
  dispatch({ type: DELETE_ITEM_IMAGE, payload: { id, imageFile } });
  dispatch(
    addNotice({ notice: 'Image deleted', type: 'positive', time: new Date() })
  );
};

export const deleteProfileImage = (imageFile) => async (dispatch) => {
  await axios.delete(`/api/athletes/images/`, { data: { imageFile } });
  dispatch({ type: DELETE_ATHLETE_IMAGE, payload: imageFile });
  dispatch(
    addNotice({ notice: 'Image deleted', type: 'positive', time: new Date() })
  );
};

export const postList = (formValues) => async (dispatch) => {
  try {
    const response = await axios.post('/api/lists', formValues);
    dispatch({ type: POST_LIST, payload: response.data });
    dispatch(
      addNotice({ notice: 'List saved', type: 'positive', time: new Date() })
    );
    history.push(`/list/${response.data._id}`);
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.error,
        type: 'negative',
        time: new Date(),
      })
    );
  }
};

export const getLists =
  (athleteId = null) =>
  async (dispatch) => {
    try {
      const response = await axios.get('/api/lists', {
        params: {
          athleteId,
        },
      });
      dispatch({ type: GET_LISTS, payload: response.data });
    } catch (err) {
      if (history.location.pathname !== '/') {
        dispatch(
          addNotice({
            notice: err.response.data.error,
            type: 'negative',
            time: new Date(),
          })
        );
      }
    }
  };

export const getList = (id) => async (dispatch) => {
  const response = await axios.get(`/api/lists/${id}`);
  if (response.error) {
    dispatch(
      addNotice({ notice: response.error, type: 'negative', time: new Date() })
    );
  } else {
    dispatch({ type: GET_LIST, payload: response.data });
  }
};

export const deleteList = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/lists/${id}`);

    dispatch({ type: DELETE_LIST, payload: id });
    dispatch(
      addNotice({ notice: 'List deleted', type: 'positive', time: new Date() })
    );
    history.push(`/`);
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.error,
        type: 'negative',
        time: new Date(),
      })
    );
  }
};

export const getMakeItems =
  (make, page = 1, filters = {}) =>
  async (dispatch) => {
    // get filters option from filters object
    const { inBuilds, forSale, inBrand } = filters;

    try {
      dispatch(setLoader('items', true));
      // if page is 1, clear items
      if (page === 1) {
        dispatch({ type: CLEAR_ITEMS });
      }
      const response = await axios.get(`/api/items/make/${make}`, {
        params: {
          page,
          inBuilds,
          forSale,
          inBrand,
        },
      });
      dispatch({
        type: GET_ITEMS,
        payload: {
          items: response.data?.items,
          make,
          filters,
          totalPages: response.data?.totalPages,
        },
      });
      dispatch(setLoader('items', false));
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.error,
          type: 'negative',
          time: new Date(),
        })
      );
      dispatch(setLoader('items', false));
      history.back();
    }
  };

export const postItem = (formValues) => async (dispatch) => {
  dispatch(setLoader('form', true));

  const images = formValues.images;
  const id = formValues._id;

  // if images exist, upload to s3
  if (images && images.length > 0 && images[0].size > 0 && id === undefined) {
    const preResponse = await axios.post('/api/items', formValues);

    // get id of new item
    const newId = preResponse.data._id;

    const imageTypes = formValues.images.map((image) => image.type);
    const paramType =
      process.env.REACT_APP_AWS_S3_PREFIX === 'dev' ? 'dev-item' : 'item';

    const uploadConfig = await axios.get('/api/upload', {
      params: {
        imageTypes,
        acceptType: ['image/jpeg', 'image/png'],
        type: paramType,
        id: newId,
      },
    });

    await uploadConfig.data.map(async (image, index) => {
      await axios.put(image.url, images[index], {
        headers: {
          'Content-Type': images[index].type,
        },
      });
    });

    const itemImages = uploadConfig.data.map((image) => {
      return { imageFile: image.key };
    });

    formValues = { ...formValues, images: itemImages, _id: newId };
  } else if (
    id !== null &&
    images &&
    images.length > 0 &&
    images.filter((image) => image.croppedImage).length > 0
  ) {
    const newImages = images.filter((image) => image.croppedImage);
    const existingImages = images.filter(
      (image) => image.imageFile && !image.croppedImage
    );

    const imageTypes = newImages.map((image) => image.type);
    const paramType =
      process.env.REACT_APP_AWS_S3_PREFIX === 'dev' ? 'dev-item' : 'item';

    const uploadConfig = await axios.get('/api/upload', {
      params: {
        imageTypes,
        acceptType: ['image/jpeg', 'image/png'],
        type: paramType,
        id,
      },
    });

    await uploadConfig.data.map(async (image, index) => {
      await axios.put(image.url, newImages[index], {
        headers: {
          'Content-Type': newImages[index].type,
        },
      });
    });

    const itemImages = uploadConfig.data.map((image) => {
      return { imageFile: image.key };
    });

    const allImages = [...itemImages, ...existingImages];

    formValues = { ...formValues, images: allImages };
  }

  const response = await axios.post('/api/items', formValues);
  dispatch({ type: POST_ITEM, payload: response.data });
  dispatch(setLoader('form', false));
  dispatch(
    addNotice({ notice: 'Item saved', type: 'positive', time: new Date() })
  );
  // if listId exists, redirect to list
  if (formValues.listId) {
    return history.push(`/list/${formValues.listId}`);
  } else if (formValues.buildId) {
    // update build with new item
    dispatch({
      type: POST_BUILD_ITEM,
      payload: {
        _id: formValues.buildId,
        item: response.data,
      },
    });

    return history.push(`/build/${formValues.buildId}`);
  } else if (formValues._id) {
    return history.push(`/item/${response.data._id}`);
  } else {
    history.back();
  }
};

export const addListItem = (listId, itemId) => async (dispatch) => {
  try {
    const response = await axios.post(`/api/lists/${listId}/items/${itemId}`);
    dispatch({ type: POST_LIST_ITEM, payload: response.data.list });
    dispatch(
      addNotice({
        notice: response.data.message,
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.error,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const addListBuildItem = (listId, buildId) => async (dispatch) => {
  try {
    const response = await axios.post(`/api/lists/${listId}/builds/${buildId}`);
    dispatch({ type: POST_LIST_ITEM, payload: response.data.list });
    dispatch(
      addNotice({
        notice: response.data.message,
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.error,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const deleteListItem = (listId, listItemId) => async (dispatch) => {
  const response = await axios.delete(
    `/api/lists/${listId}/list_item/${listItemId}`
  );
  dispatch({ type: GET_LIST, payload: response.data });
  dispatch(
    addNotice({
      notice: 'Item removed from list',
      type: 'positive',
      time: new Date(),
    })
  );
};

export const addBuildItem =
  (buildId, itemId, categoryId) => async (dispatch) => {
    try {
      const response = await axios.post(
        `/api/builds/${buildId}/items/${itemId}`,
        {
          categoryId,
        }
      );
      dispatch({ type: POST_BUILD_ITEM, payload: response.data });
      dispatch(
        addNotice({
          notice: 'Item added to build',
          type: 'positive',
          time: new Date(),
        })
      );
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.error,
          type: 'error',
          time: new Date(),
        })
      );
    }
  };

export const deleteBuildItem = (buildId, itemId) => async (dispatch) => {
  try {
    await axios.delete(`/api/builds/${buildId}/items/${itemId}`);
    dispatch({ type: DELETE_BUILD_ITEM, payload: { id: buildId, itemId } });
    dispatch(
      addNotice({
        notice: 'Item removed from build',
        type: 'positive',
        time: new Date(),
      })
    );
    dispatch(hideModal());
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.error,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const addBuildItemVariant =
  (buildId, buildItemId, variantId) => async (dispatch) => {
    dispatch(setLoader('form', true));
    try {
      const response = await axios.post(
        `/api/builds/${buildId}/items/${buildItemId}/variants/${variantId}`
      );
      dispatch({ type: POST_BUILD_ITEM, payload: response.data });
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.error,
          type: 'error',
          time: new Date(),
        })
      );
    }
    dispatch(setLoader('form', false));
  };

export const deleteBuildItemVariant =
  (buildId, buildItemId, variantId) => async (dispatch) => {
    dispatch(setLoader('form', true));
    try {
      const response = await axios.delete(
        `/api/builds/${buildId}/items/${buildItemId}/variants/${variantId}`
      );
      dispatch({ type: POST_BUILD_ITEM, payload: response.data });
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.error,
          type: 'error',
          time: new Date(),
        })
      );
    }
    dispatch(setLoader('form', false));
  };

export const addBuildItemItem =
  (buildId, buildItemId, itemId) => async (dispatch) => {
    dispatch(setLoader('form', true));
    try {
      const response = await axios.post(
        `/api/builds/${buildId}/buildItem/${buildItemId}/item/${itemId}`
      );
      dispatch({ type: POST_BUILD_ITEM, payload: response.data });
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.error,
          type: 'error',
          time: new Date(),
        })
      );
    }
    dispatch(setLoader('form', false));
  };

export const deleteBuildItemItem =
  (buildId, buildItemId, itemId) => async (dispatch) => {
    dispatch(setLoader('form', true));
    try {
      const response = await axios.delete(
        `/api/builds/${buildId}/buildItem/${buildItemId}/item/${itemId}`
      );
      dispatch({ type: POST_BUILD_ITEM, payload: response.data });
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.error,
          type: 'error',
          time: new Date(),
        })
      );
    }
    dispatch(setLoader('form', false));
  };

export const getItem =
  (id, withBuilds = false, page = 1) =>
  async (dispatch) => {
    try {
      const response = await axios.get(`/api/items/${id}`, {
        // pass query string to backend to determine if builds should be populated
        params: {
          withBuilds,
          page,
        },
      });
      if (page === 1) {
        dispatch({ type: GET_ITEM, payload: response.data });
      } else {
        dispatch({
          type: GET_ITEM,
          payload: { ...response.data, type: 'more' },
        });
      }
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.message,
          type: 'error',
          time: new Date(),
        })
      );
      // history.push('/404');
    }
  };

export const deleteItem = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/items/${id}`);
    dispatch({ type: DELETE_ITEM, payload: id });
    dispatch(
      addNotice({ notice: 'Item deleted', type: 'positive', time: new Date() })
    );
    // return to previous page if history exists
    history.back();
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const followAthlete = (id) => async (dispatch) => {
  try {
    const response = await axios.post(`/api/athletes/${id}/follow`);
    dispatch({ type: SET_USER_ATHLETE, payload: response.data });
    dispatch(
      addNotice({
        notice: 'Followed',
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.error,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const unfollowAthlete = (id) => async (dispatch) => {
  try {
    const response = await axios.post(`/api/athletes/${id}/unfollow`);
    dispatch({ type: SET_USER_ATHLETE, payload: response.data });
    dispatch(
      addNotice({
        notice: 'Unfollowed',
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.error,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const likeBuild = (id) => async (dispatch) => {
  dispatch(setLoader('like', true));
  try {
    const response = await axios.post(`/api/like/build/${id}`);
    dispatch({ type: POST_BUILD_LIKE, payload: response.data });
    dispatch({ type: POST_SEARCH_BUILD_LIKE, payload: response.data });
    // if response.data._likes includes user id, then user has liked the build
    if (response.data._likes.includes(response.data._user)) {
      dispatch(
        addNotice({ notice: 'Build liked', type: 'positive', time: new Date() })
      );
    }
    axios.post(`/api/notices/build/like`, { _build: id });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.error,
        type: 'error',
        time: new Date(),
      })
    );
  }
  dispatch(setLoader('like', false));
};

export const likeBuildItem = (buildId, itemId) => async (dispatch) => {
  dispatch(setLoader('like', true));
  try {
    const response = await axios.post(
      `/api/like/build/${buildId}/item/${itemId}`
    );
    dispatch({
      type: POST_BUILD_ITEM_LIKE,
      payload: { ...response.data, itemId },
    });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.error,
        type: 'error',
        time: new Date(),
      })
    );
  }
  dispatch(setLoader('like', false));
};

export const getBuildComments = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/builds/${id}/comments`);
    dispatch({ type: GET_BUILD_COMMENTS, payload: response.data });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const postBuildComment = (id, comment) => async (dispatch) => {
  try {
    const response = await axios.post(`/api/builds/${id}/comments`, {
      comment,
    });
    dispatch({ type: POST_BUILD_COMMENT, payload: response.data });
    dispatch(
      addNotice({
        notice: 'Comment posted',
        type: 'positive',
        time: new Date(),
      })
    );
    // axios.post(`/api/notices/build/comment`, { _build: id });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const deleteBuildComment = (id, commentId) => async (dispatch) => {
  try {
    await axios.delete(`/api/builds/${id}/comments/${commentId}`);
    dispatch({
      type: DELETE_BUILD_COMMENT,
      payload: { buildId: id, commentId },
    });
    dispatch(
      addNotice({
        notice: 'Comment deleted',
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const getNotifications = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/notices');
    dispatch({ type: FETCH_NOTIFICATIONS, payload: response.data });
  } catch (err) {
    if (history.location.pathname !== '/') {
      dispatch(
        addNotice({
          notice: err.response.data.message,
          type: 'error',
          time: new Date(),
        })
      );
    }
  }

  // get notifications every six hours
  setInterval(async () => {
    try {
      const response = await axios.get('/api/notices');
      dispatch({ type: FETCH_NOTIFICATIONS, payload: response.data });
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.message,
          type: 'error',
          time: new Date(),
        })
      );
    }
  }, 21600000);
};

export const deleteNotifications = () => async (dispatch) => {
  try {
    await axios.post(`/api/notices/delete`);
    dispatch({ type: DELETE_NOTIFICATIONS });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const clearNotifications = () => async (dispatch) => {
  try {
    await axios.post(`/api/notices/seen`);
    dispatch({ type: CLEAR_NOTIFICATIONS });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const getStravaActivities = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/strava/activities');
    dispatch({ type: FETCH_STRAVA_ACTIVITIES, payload: response.data });
  } catch (err) {
    dispatch({
      type: FETCH_STRAVA_ACTIVITIES,
      payload: { error: err.response.data.message },
    });
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const postStravaActivityToBuild =
  (buildId, activity) => async (dispatch) => {
    try {
      const response = await axios.post(`/api/builds/${buildId}/strava`, {
        activity,
      });
      dispatch({ type: POST_STRAVA_ACTIVITY_TO_BUILD, payload: response.data });
      dispatch(hideModal());
      dispatch(
        addNotice({
          notice: 'Strava activity added to build',
          type: 'positive',
          time: new Date(),
        })
      );
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.message,
          type: 'error',
          time: new Date(),
        })
      );
    }
  };

export const postProfileToBuild =
  (buildId, profile, service) => async (dispatch) => {
    try {
      const response = await axios.post(`/api/builds/${buildId}/profile`, {
        profileId: profile._id,
        service: service?._id,
      });
      dispatch({
        type: POST_PROFILE_TO_BUILD,
        payload: {
          buildId,
          profileId: profile._id,
          profile: response.data,
        },
      });
      // dispatch(hideModal());
      dispatch(
        addNotice({
          notice: 'Profile added to build',
          type: 'positive',
          time: new Date(),
        })
      );
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.error,
          type: 'error',
          time: new Date(),
        })
      );
    }
  };

export const removeFromBuildProfile =
  (buildId, profile, participation) => async (dispatch) => {
    try {
      const response = await axios.post(
        `/api/builds/${buildId}/profile/${profile._id}`,
        { participation }
      );
      dispatch({
        type: POST_PROFILE_TO_BUILD,
        payload: {
          buildId,
          profileId: profile._id,
          profile: response.data,
        },
      });
      dispatch(
        addNotice({
          notice: 'Build profile updated',
          type: 'positive',
          time: new Date(),
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        addNotice({
          notice: err.response?.data?.error,
          type: 'error',
          time: new Date(),
        })
      );
    }
  };

export const removeProfileFromBuild =
  (buildId, profileId) => async (dispatch) => {
    try {
      await axios.delete(`/api/builds/${buildId}/profile/${profileId}`);
      dispatch({
        type: DELETE_PROFILE_FROM_BUILD,
        payload: { buildId, profileId },
      });
      dispatch(
        addNotice({
          notice: 'Profile removed from build',
          type: 'positive',
          time: new Date(),
        })
      );
    } catch (err) {
      console.log(err.response.data.error);
      dispatch(
        addNotice({
          notice: err.response.data.error,
          type: 'error',
          time: new Date(),
        })
      );
    }
  };

export const fetchCompaniesWithMakes = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/search/company-profiles');
    dispatch({
      type: PROFILE_RESULTS,
      payload: response.data.athletes,
    });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.error,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const apeFetchUsers = () => async (dispatch) => {
  dispatch(setLoaderElement('users', true));
  try {
    const response = await axios.get(`/api/ape/users`);
    dispatch({ type: APE_FETCH_USERS, payload: response.data });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
  dispatch(setLoaderElement('users', false));
};

export const apeFetchInvites = () => async (dispatch) => {
  dispatch(setLoaderElement('invites', true));
  try {
    const response = await axios.get(`/api/ape/invites`);
    dispatch({ type: APE_FETCH_INVITES, payload: response.data });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
  dispatch(setLoaderElement('invites', false));
};

export const apeGenerateInvite =
  ({
    name,
    email,
    inviteFromId = null,
    permission,
    profileId = null,
    identity,
  }) =>
  async (dispatch) => {
    try {
      const response = await axios.post(`/api/ape/invites/generate`, {
        name,
        email,
        inviteFromId,
        profileId,
        permission,
        identity,
      });
      dispatch({ type: APE_ADD_INVITE, payload: response.data.invite });
      dispatch(
        addNotice({
          notice: response.data.message,
          type: 'positive',
          time: new Date(),
        })
      );
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.message,
          type: 'error',
          time: new Date(),
        })
      );
    }
  };

export const apeDeleteInvite = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(`/api/ape/invites/${id}`);
    dispatch({ type: APE_DELETE_INVITE, payload: id });
    dispatch(
      addNotice({
        notice: response.data.message,
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (err) {
    console.log(err);
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const apeActivateUser = (id) => async (dispatch) => {
  try {
    const response = await axios.post(`/api/ape/users/activate`, { id });
    dispatch({ type: APE_UPDATE_USER, payload: response.data.user });
    dispatch(
      addNotice({
        notice: response.data.message,
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const apeDeactivateUser = (id) => async (dispatch) => {
  try {
    const response = await axios.post(`/api/ape/users/deactivate`, { id });
    dispatch({ type: APE_UPDATE_USER, payload: response.data.user });
    dispatch(
      addNotice({
        notice: response.data.message,
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const apeDeleteUser = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(`/api/ape/users/${id}`);
    dispatch({ type: APE_DELETE_USER, payload: id });
    dispatch(
      addNotice({
        notice: response.data.message,
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.error,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const postSupport = (formValues) => async (dispatch) => {
  try {
    const response = await axios.post(`/api/athlete/support`, formValues);
    dispatch(
      addNotice({
        notice: response.data.message,
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.error,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const apeFetchItems =
  (params = { status: 'pending' }) =>
  async (dispatch) => {
    dispatch(setLoaderElement('items', true));
    try {
      const response = await axios.get(`/api/ape/items`, { params });
      dispatch({ type: APE_FETCH_ITEMS, payload: response.data });
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.message,
          type: 'error',
          time: new Date(),
        })
      );
    }
    dispatch(setLoaderElement('items', false));
  };

export const apeDeleteItem = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(`/api/ape/items/${id}`);
    dispatch({ type: APE_DELETE_ITEM, payload: id });
    dispatch(
      addNotice({
        notice: response.data.message,
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (err) {
    console.log(err);
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const clearResultsOfType = (type) => async (dispatch) => {
  dispatch({ type: CLEAR_TYPE_RESULTS, payload: type });
};

export const apeExportItems = (status) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/ape/items/export`, {
      params: { status },
    });
    // open response in new window
    dispatch(
      addNotice({
        notice: 'Items exported. Prepare to receive thy file.',
        type: 'positive',
        time: new Date(),
      })
    );
    // await a second to allow notice to render
    setTimeout(() => {
      const blob = new Blob([response.data], { type: 'text/tsv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      const filename =
        response.headers['content-disposition'].split('filename=')[1];
      link.href = url;
      link.download = filename; // or any other filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, 1000);
  } catch (err) {
    console.log(err);
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const apeFetchStats = () => async (dispatch) => {
  dispatch(setLoaderElement('stats', true));
  try {
    const response = await axios.get(`/api/ape/stats`);
    dispatch({ type: APE_FETCH_STATS, payload: response.data });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
  dispatch(setLoaderElement('stats', false));
};

export const apeFetchAnalytics = () => async (dispatch) => {
  dispatch(setLoaderElement('analytics', true));
  try {
    const response = await axios.get(`/api/ape/analytics`);
    dispatch({ type: APE_FETCH_ANALYTICS, payload: response.data });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
  dispatch(setLoaderElement('analytics', false));
};

export const addAdminToAthlete = (id) => async (dispatch) => {
  try {
    const response = await axios.post(`/api/athlete/${id}/add_admin`);
    dispatch({ type: GET_ATHLETE, payload: response.data });
    dispatch(getAuthAthletes());
    dispatch(
      addNotice({
        notice: 'Added to your list of profiles',
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const removeAdminFromAthlete =
  (id, skip = false) =>
  async (dispatch) => {
    try {
      const response = await axios.post(`/api/athlete/${id}/remove_admin`);
      !skip && dispatch({ type: GET_ATHLETE, payload: response.data });
      dispatch(getAuthAthletes());
      dispatch(
        addNotice({
          notice: 'Removed form your list of profiles',
          type: 'positive',
          time: new Date(),
        })
      );
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.message,
          type: 'error',
          time: new Date(),
        })
      );
    }
  };

export const deleteAthlete = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(`/api/athlete/${id}`);
    dispatch(
      addNotice({
        notice: "Profile deleted. It's gone.",
        type: 'positive',
        time: new Date(),
      })
    );
    dispatch(switchAthlete(response?.data?._id));
    dispatch(getAuthAthletes());
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

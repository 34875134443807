import React from 'react';

import ItemCard from '../../items/card';
import BuildListCard from '../../builds/listCard';

import Loader from '../../Loader';

const ListItems = ({ lists, listId, handleDeleteItem, athleteId }) => {
  if (listId === '_new_') {
    return null;
  }

  if (!lists || !lists[listId]) {
    return <Loader type="inline" />;
  }

  const { items } = lists[listId];

  if (!items || items.length === 0) {
    return (
      <div
        key={listId}
        className="w-full items-center text-center justify-center col-span-4"
      >
        Nothing saved yet
      </div>
    );
  }

  return items.map((item) => {
    const { _id, _item, _build } = item;

    if (_item) {
      return (
        <ItemCard
          key={_id}
          item={_item}
          deleteButton={
            handleDeleteItem && lists[listId]._athlete._id === athleteId
          }
          deleteHandler={handleDeleteItem && (() => handleDeleteItem(_id))}
        />
      );
    }

    if (_build) {
      return (
        <BuildListCard
          key={_id}
          build={_build}
          deleteButton={
            handleDeleteItem && lists[listId]._athlete._id === athleteId
          }
          deleteHandler={handleDeleteItem && (() => handleDeleteItem(_id))}
        />
      );
    }

    return null;
  });
};

export default ListItems;

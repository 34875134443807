import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const libraries = ['places'];
const PlaceComponent = ({
  className: customClass,
  label,
  meta,
  placeholder,
  defaultValue,
  input,
  onLatLngChange,
  locationType,
  latlng: latlngProp,
}) => {
  const inputRef = useRef();

  const [latlng, setLatlng] = useState(latlngProp);
  const [address, setAddress] = useState(input.value);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = address;
    }
  }, [address]);

  // on latlng change, run onLatLngChange function
  useEffect(() => {
    if (onLatLngChange) {
      onLatLngChange(latlng);
    }
  }, [latlng, onLatLngChange]);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (loadError) {
    console.log('Error loading Google Maps API');
    return null;
  }

  function renderError({ error, touched }, label) {
    if (touched && error) {
      return (
        <>
          <div
            className={`absolute right-4 flex items-center text-red-500 ${
              label ? `top-8` : `top-2`
            }`}
          >
            <ExclamationTriangleIcon className="h-8 w-8 mr-1" />
          </div>
          <div className="text-white bg-red-400 w-fit flex-nowrap py-2 px-4 mt-2 rounded whitespace-nowrap">
            {error}
          </div>
        </>
      );
    }
  }

  const handlePlaceChanged = async () => {
    // const [place] = await inputRef.current.getPlaces();
    const place = inputRef.current.getPlace();
    if (place) {
      setAddress(place.formatted_address);
      setLatlng(
        `${place.geometry.location.lat()},${place.geometry.location.lng()}`
      );
      await input.onChange(place.formatted_address);
      if (onLatLngChange) {
        onLatLngChange(latlng);
      }
    }
  };

  const handleBlur = () => {
    // clear input if no place selected
    // if latlng is null or latlng is "0,0"
    if (!latlng || latlng === '0,0') {
      setAddress('');
      setLatlng(null);
      input.onChange('');
    }
  };

  const className = `${customClass} input input-bordered w-full form-control ${
    meta.error && meta.touched ? 'input-error' : ''
  }`;

  const divClassName = `relative w-full`;
  return (
    isLoaded && (
      <div className={divClassName}>
        <label htmlFor="location">{label}</label>
        <Autocomplete
          onLoad={(ref) => (inputRef.current = ref)}
          onPlaceChanged={handlePlaceChanged}
          // set locationType to 'cities' to restrict search to cities only
          types={locationType ? ['(cities)'] : []}
          fields={['address_components', 'formatted_address', 'geometry']}
        >
          <input
            {...input}
            name={input.name}
            type="text"
            className={className}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={(e) => {
              // console.log(e.target.value);
              setAddress(e.target.value);
              input.onChange(e.target.value);
            }}
            onBlur={handleBlur}
          />
        </Autocomplete>

        {renderError(meta, label)}
      </div>
    )
  );
};

export default PlaceComponent;

import React from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Identity from '../identity';
import SearchBar from '../search/bar';

import SwitchAthlete from '../profile/switch';
import ProfileImage from '../athlete/image';

import Notifications from '../notifications';

import { Bars2Icon } from '@heroicons/react/24/solid';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

const Header = (props) => {
  const renderAddMenu = () => {
    return (
      <div className="dropdown dropdown-bottom dropdown-end hidden sm:flex">
        <label tabIndex={0} className="m-0 p-0 self-center cursor-pointer">
          <PlusCircleIcon className="h-10 w-10" />
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content z-[1] menu p-2 shadow bg-base-200 rounded-box w-32"
        >
          <li>
            <Link to="/build/edit" state={{ backable: true }}>
              Build
            </Link>
          </li>
          <li>
            <Link to="/list/edit" state={{ backable: true }}>
              List
            </Link>
          </li>
        </ul>
      </div>
    );
  };

  const profileLink = props?.athlete?.alias
    ? `/${props.athlete.alias}`
    : `/athlete/${props?.athlete?._id}`;
  const hasProfile = props.hasProfile;

  if (!hasProfile) {
    return null;
  }

  return (
    <>
      <div className="flex flex-row justify-between gap-8 lg:gap-x-24 items-center mx-5 sm:mx-0">
        <div className="flex-none">
          <Link to="/feed/">
            <Identity />
          </Link>
        </div>
        <div className="flex sm:flex-1 invisible sm:visible">
          <SearchBar location="header" />
        </div>
        <div className="flex flex-none gap-5 items-center">
          {props.isSignedIn ? (
            <>
              <Notifications className="dropdown-bottom dropdown-end hidden sm:flex" />
              {renderAddMenu()}
              <Link to={profileLink} className="hidden sm:flex">
                <ProfileImage
                  athlete={props.athlete}
                  className="w-10 h-10 cursor-pointer m-0 p-0 self-center"
                />
              </Link>
              <div tabIndex={0} className="">
                <label htmlFor="app-drawer">
                  <Bars2Icon className="w-9 h-9 self-center cursor-pointer" />
                </label>
              </div>
            </>
          ) : (
            <Link to="/login" className="btn btn-primary">
              Sign in
            </Link>
          )}
        </div>
        <SwitchAthlete />
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const athlete =
    state.auth && state.auth.profile
      ? state.auth.profile
      : {
          name: state.auth.name,
          email: state.auth.email,
          id: '_new_',
        };

  const isSignedIn = state.auth?.userId;
  const hasProfile = state.auth?.profile?.step !== 'setup_athlete';

  return {
    auth: state.auth,
    isSignedIn,
    athlete,
    hasProfile,
  };
};

export default connect(mapStateToProps, {})(Header);

import React from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const GlassUp = ({ auth, children, loading, isApe, required }) => {
  // if logged in, return null
  if ((auth && auth.userId) || loading || isApe) {
    return children;
  }

  // div with class glass that covers the screen
  return (
    <div className="relative w-full h-fit max-h-[90vh] overflow-hidden min-h-56">
      <div className="absolute backdrop-blur backdrop-brightness-100 z-50 w-full flex flex-1 justify-center items-center h-full bg-gradient-to-b from-transparent to-white dark:to-black dark:text-black">
        <div className="flex flex-col glass p-4 rounded-md md:max-w-lg w-[80vw] gap-2 bg-yellow-300 hover:bg-yellow-300 dark:bg-yellow-300 dark:hover:bg-yellow-300">
          <h1 className="identity text-3xl">Welcome to Active Projects</h1>
          <p>
            We're currently in closed Beta. We welcome you to request to join.
            If you love cycling, you're going to want to check this out.
          </p>
          <div className="flex flex-row gap-6 mt-1">
            <Link className="my-1 w-fit btn btn-secondary btn-outline" to="/">
              Request Access
            </Link>
            <Link
              className="my-1 w-fit underline underline-offset-8 text-sm mt-4 backdrop-blur-sm"
              to="/login"
            >
              Sign in
            </Link>
          </div>
        </div>
      </div>
      <div className="relative w-full h-full overflow-hidden p-6 sm:py-10">
        {children}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const required = ownProps?.required || false;

  const isApeProfile =
    !required && Object.values(state?.athlete)?.find((a) => a?.alias === 'ape');
  const isApeBuild =
    !required &&
    Object.values(state?.builds?.builds)?.find(
      (b) => b?._athlete?.alias === 'ape'
    );

  return {
    auth: state.auth,
    loading: state?.loader?.page,
    // is key of athlete state in redux store "athlete"
    isApe: isApeProfile || isApeBuild,
  };
};

export default connect(mapStateToProps)(GlassUp);

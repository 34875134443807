import './forms.css';
import React, { useEffect, useState, useRef } from 'react';

// import heroicon of back arrow
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

import { TailSpin } from 'react-loader-spinner';

// input is the destructured version of formProps.input

function renderError({ error, touched }) {
  if (touched && error) {
    return (
      <>
        <div className="absolute top-8 right-4 flex items-center text-red-500">
          <ExclamationTriangleIcon className="h-8 w-8 mr-1" />
        </div>
        <div className="text-white bg-red-400 w-fit flex-nowrap py-2 px-4 mt-2 rounded whitespace-nowrap">
          {error}
        </div>
      </>
    );
  }
}

const SearchInput = ({
  input,
  label,
  meta,
  placeholder,
  disabled,
  handleClick,
  searchAction,
  autoFocus,
  handleClear,
}) => {
  const inputRef = useRef();

  const [term, setTerm] = useState(inputRef.current);
  const [loading, setLoading] = useState(false);
  const [activeInput, setActiveInput] = useState(false);

  // spinning loader
  const loader = (
    <TailSpin
      color="#00BFFF"
      height={24}
      width={24}
      timeout={4000} //4 secs
    />
  );

  const className = `input input-ghost border-0 border-b-2 border-black dark:border-white dark:focus:border-white px-0 focus:border-black focus:outline-0 w-full rounded-none placeholder:text-gray-500 ${
    meta.error && meta.touched ? 'input-error' : ''
  }`;
  const divClassName = `relative w-full`;

  const searchIcon = (
    <div
      onClick={handleClick}
      className="absolute top-3 right-0 flex text-gray-500 cursor-pointer input-primary"
    >
      {loading ? loader : <MagnifyingGlassIcon className="h-6 w-6" />}
    </div>
  );

  useEffect(() => {
    inputRef.current = input.value;
    const search = () => searchAction(inputRef.current);
    setLoading(true);
    if (!activeInput) {
      setLoading(false);
      return;
    }
    if (input.value && input.value.length > 3 && term !== input.value) {
      const timeoutId = setTimeout(() => {
        // if term is not in options, search
        if (
          inputRef.current &&
          inputRef.current.length > 2 &&
          term !== inputRef.current
        ) {
          setTerm(inputRef.current);
          search();
        }
        setLoading(false);
      }, 1000);
      // react will run the return when component is re-reendered.
      return () => {
        setLoading(false);
        clearTimeout(timeoutId);
      };
      // if term is empty, clear options
    } else if (input.value.length === 0 && handleClear) {
      handleClear();
      setTerm(inputRef.current);
      setLoading(false);
      return () => {
        setLoading(false);
      };
    } else {
      setTerm(inputRef.current);
      setLoading(false);
    }
  }, [term, input, searchAction, activeInput, handleClear]);

  return (
    <div className={divClassName}>
      <label htmlFor={input.name}>{label}</label>
      <input
        // onChange={formProps.input.onChange}
        // value={formProps.value}
        // is the same as
        {...input}
        id={input.name}
        autoComplete="off"
        placeholder={placeholder}
        disabled={disabled}
        className={className}
        autoFocus={autoFocus}
        onFocus={() => setActiveInput(true)}
        onBlur={() => setActiveInput(false)}
      />
      {searchIcon}
      {renderError(meta)}
    </div>
  );
};

export default SearchInput;

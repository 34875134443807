import React from 'react';

import { Route, Routes } from 'react-router-dom';

import Edit from './edit';
import Collections from './collections';

const PostsAdminRouter = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <Routes>
        <Route path="/" element={<Collections />} />
        <Route path="/edit" element={<Edit />} />
        <Route path="/edit/:id" element={<Edit />} />
        <Route path="/:collectionType" element={<Collections />} />
      </Routes>
    </div>
  );
};

export default PostsAdminRouter;

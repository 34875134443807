import React from 'react';

import { useParams } from 'react-router-dom';

import EditItem from '../../../items/edit';

const BuildItemEdit = ({ children }) => {

    const { buildId } = useParams() || {};
    const { itemId } = useParams() || {};

    return (
        <div className="flex flex-col items-start justify-start sm:flex-1">
            <EditItem itemId={itemId} buildId={buildId} />
        </div>
    );
}

export default BuildItemEdit;
import './forms.css';
import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
// input is the destructured version of formProps.input

// import heroicon of back arrow
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

function renderError({ error, touched }) {
  if (touched && error) {
    return (
      <>
        <div className="absolute top-8 right-4 flex items-center text-red-500">
          <ExclamationTriangleIcon className="h-8 w-8 mr-1" />
        </div>
        <div className="text-white bg-red-400 w-fit flex-nowrap py-2 px-4 mt-2 rounded whitespace-nowrap">
          {error}
        </div>
      </>
    );
  }
}

function renderOptions(options) {
  return options.map((option) => {
    return (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    );
  });
}

const renderDropdown = ({
  input,
  label,
  meta,
  placeholder,
  options,
  disabled,
  tooltip,
  onChange,
}) => {
  const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
  return (
    <div className={className}>
      <label className="text-sm font-normal text-secondary">{label}</label>
      {tooltip ? (
        <div className="tooltip" data-tip={tooltip}>
          <InformationCircleIcon className="h-4 w-4 text-base-300 ml-1" />
        </div>
      ) : null}
      <select
        className="select select-bordered w-full"
        // onChange={formProps.input.onChange}
        // value={formProps.value}
        // is the same as
        {...input}
        placeholder={placeholder}
        autoComplete="off"
        disabled={disabled}
      >
        {renderOptions(options)}
      </select>
      {renderError(meta)}
    </div>
  );
};

export default renderDropdown;

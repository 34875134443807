import React from 'react';
import { createPortal } from 'react-dom';

const Modal = (props) => {
  return createPortal(
    <div onClick={props.onDismiss} className="modal modal-open z-[31]">
      <div className="modal-box" onClick={(e) => e.stopPropagation()}>
        <h2>{props.title}</h2>
        <div>{props.content}</div>
        {props.actions && (
          <div className="modal-action">
            <div className="actions">{props.actions}</div>
          </div>
        )}
      </div>
    </div>,
    document.querySelector('#modal')
  );
};

export default Modal;

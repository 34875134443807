import axios from 'axios';

import {
  APE_DELETE_POST,
  APE_DELETE_POST_COLLECTION,
  APE_FETCH_MANY_POST_COLLECTIONS,
  APE_FETCH_POST_COLLECTION,
  APE_REORDER_POSTS,
  APE_UPDATE_POST,
  APE_UPDATE_POST_COLLECTION,
  APE_CLEAR_POST_COLLECTIONS,
} from '../types';

import { addNotice } from '../notices';
import { hideModal } from '../modal';
import { setLoaderElement } from '../loader';

export const apeUpdatePost = (values) => async (dispatch) => {
  const postCollectionId = values?.postCollectionId;
  const images = values?.images;
  const id = values?._id;

  // if images exist, upload to s3
  if (images && images.length > 0 && images[0].size > 0 && id === '_isNew_') {
    try {
      const preResponse = await axios.put(`/api/ape/posts/`, values);
      const newId = preResponse.data.post._id;

      const imageTypes = images.map((image) => image.type);
      const paramType =
        process.env.REACT_APP_AWS_S3_PREFIX === 'dev' ? 'dev-post' : 'post';

      const uploadConfig = await axios.get('/api/upload', {
        params: {
          imageTypes,
          acceptType: ['image/jpeg', 'image/png'],
          type: paramType,
          id: newId,
        },
      });

      await uploadConfig.data.map(async (image, index) => {
        await axios.put(image.url, images[index], {
          headers: {
            'Content-Type': images[index].type,
          },
        });
      });

      const postImages = uploadConfig.data.map((image) => {
        return { imageFile: image.key };
      });

      values = { ...values, images: postImages, _id: newId };
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.message,
          type: 'error',
          time: new Date(),
        })
      );
    }
  } else if (
    id !== '_isNew_' &&
    images?.length > 0 &&
    images.filter((image) => image.croppedImage).length > 0
  ) {
    try {
      const newImages = images.filter((image) => image.croppedImage);
      const existingImages = images.filter(
        (image) => image.imageFile && !image.croppedImage
      );

      const imageTypes = newImages.map((image) => image.type);
      const paramType =
        process.env.REACT_APP_AWS_S3_PREFIX === 'dev' ? 'dev-post' : 'post';

      const uploadConfig = await axios.get('/api/upload', {
        params: {
          imageTypes,
          acceptType: ['image/jpeg', 'image/png'],
          type: paramType,
          id,
        },
      });

      await uploadConfig.data.map(async (image, index) => {
        await axios.put(image.url, newImages[index], {
          headers: {
            'Content-Type': newImages[index].type,
          },
        });
      });

      const postImages = uploadConfig.data.map((image) => {
        return { imageFile: image.key };
      });

      const allImages = [...postImages, ...existingImages];

      values = { ...values, images: allImages };
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.message,
          type: 'error',
          time: new Date(),
        })
      );
    }
  }

  try {
    const response = await axios.put(`/api/ape/posts/`, values);
    dispatch({
      type: APE_UPDATE_POST,
      payload: { post: response.data.post, postCollectionId },
    });
    dispatch(
      addNotice({
        notice: response.data.message,
        type: 'positive',
        time: new Date(),
      })
    );
    dispatch(hideModal());
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response?.data?.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const apeReorderPosts =
  ({ postCollectionId, posts }) =>
  async (dispatch) => {
    dispatch(setLoaderElement('sorting', true));
    try {
      // only submit _id and order from posts
      const orderedPosts = posts.map((post) => {
        return { _id: post._id, order: post.order };
      });

      const response = await axios.put(
        `/api/ape/postsCollection/${postCollectionId}/posts/reorder`,
        {
          posts: orderedPosts,
        }
      );
      dispatch({ type: APE_REORDER_POSTS, payload: response });
      dispatch(
        addNotice({
          notice: 'Saved',
          type: 'positive',
          time: new Date(),
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        addNotice({
          notice: err?.response?.data?.message,
          type: 'error',
          time: new Date(),
        })
      );
    }
    dispatch(setLoaderElement('sorting', false));
  };

export const apeDeletePost =
  ({ postId, postCollectionId }) =>
  async (dispatch) => {
    try {
      const response = await axios.delete(`/api/ape/posts/${postId}`);
      dispatch({
        type: APE_DELETE_POST,
        payload: { postId, postCollectionId },
      });
      dispatch(
        addNotice({
          notice: response.data.message,
          type: 'positive',
          time: new Date(),
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        addNotice({
          notice: err.response.data.message,
          type: 'error',
          time: new Date(),
        })
      );
    }
  };

export const apeFetchPosts = (id) => async (dispatch) => {
  dispatch(setLoaderElement('posts', true));
  try {
    const response = await axios.get(`/api/ape/postsCollection/${id}/posts`);
    dispatch({ type: APE_FETCH_POST_COLLECTION, payload: response.data });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
  dispatch(setLoaderElement('posts', false));
};

export const apeFetchManyPostCollections =
  (type = null, page = 1) =>
  async (dispatch) => {
    dispatch(setLoaderElement('postCollection', true));
    dispatch({ type: APE_CLEAR_POST_COLLECTIONS });
    try {
      const response = await axios.get(`/api/ape/postsCollection`, {
        params: { type, page },
      });
      dispatch({
        type: APE_FETCH_MANY_POST_COLLECTIONS,
        payload: response.data,
      });
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.message,
          type: 'error',
          time: new Date(),
        })
      );
    }
    dispatch(setLoaderElement('postCollection', false));
  };

export const apeFetchPostCollection = (id) => async (dispatch) => {
  dispatch(setLoaderElement('postCollection', true));
  try {
    const response = await axios.get(`/api/ape/postsCollection/${id}/posts`);
    dispatch({ type: APE_FETCH_POST_COLLECTION, payload: response.data });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
  dispatch(setLoaderElement('postCollection', false));
};

// duplicate postCollection
export const apeDuplicatePostCollection = (id) => async (dispatch) => {
  dispatch(setLoaderElement('postCollection', true));
  try {
    const response = await axios.post(
      `/api/ape/postsCollection/${id}/duplicate`
    );
    dispatch({
      type: APE_FETCH_POST_COLLECTION,
      payload: response.data.postCollection,
    });
    dispatch(
      addNotice({
        notice: 'Post collection duplicated including posts',
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
  dispatch(setLoaderElement('postCollection', false));
};

export const apeDeletePostCollection = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(`/api/ape/postsCollection/${id}`);
    dispatch({ type: APE_DELETE_POST_COLLECTION, payload: id });
    dispatch(
      addNotice({
        notice: response.data.message,
        type: 'positive',
        time: new Date(),
      })
    );
  } catch (err) {
    console.log(err);
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const apeUpdatePostCollection = (values) => async (dispatch) => {
  try {
    const response = await axios.put(`/api/ape/postsCollection`, values);
    dispatch({
      type: APE_UPDATE_POST_COLLECTION,
      payload: response.data.postCollection,
    });
    dispatch(
      addNotice({
        notice: response.data.message,
        type: 'positive',
        time: new Date(),
      })
    );
    dispatch(hideModal());
  } catch (err) {
    console.log(err);
    dispatch(
      addNotice({
        notice: err?.response?.data?.err,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const apeSendTestEmail =
  ({ postCollectionId, userId }) =>
  async (dispatch) => {
    try {
      const response = await axios.get(
        `/api/ape/email/postsCollection/sendTest`,
        {
          params: { postCollectionId, userId },
        }
      );
      dispatch(
        addNotice({
          notice: response.data.message,
          type: 'positive',
          time: new Date(),
        })
      );
    } catch (err) {
      dispatch(
        addNotice({
          notice: err.response.data.error,
          type: 'error',
          time: new Date(),
        })
      );
    }
  };

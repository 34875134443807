import React from 'react';

import ProfileLine from '../../athlete/line';
import BuildImages from '../image';
import Heart from '../../reactions/heart';
import Bookmark from '../../reactions/bookmark';

import { TagIcon } from '@heroicons/react/24/solid';

const BuildCard = ({
  build,
  lists,
  profile,
  noCarousel,
  noReactions,
  classProp,
  noAvatar,
  location,
}) => {
  if (!build) {
    return null;
  }

  const profiles = build?.profiles;

  const badge = build?.forSale ? <TagIcon className="w-6 h-6" /> : '';
  const badgeClass = build?.forSale
    ? 'bg-transparent text-brand text-base-100 border-0 translate-x-1 translate-y-1 drop-shadow-lg'
    : '';

  return (
    // <div className={`card bg-base-100 w-full sm:w-[10em] md:w-[12.5em] lg:w-[15em] xl:w-[21em] 2xl:w-[28em] h-fit ${!profile && `mb-4 lg:mb-8`}`}>
    <div
      className={`card resize-none bg-base-100 w-full h-fit ${
        !profile && `mb-4 lg:mb-8`
      }`}
    >
      <BuildImages
        build={build}
        addBuildLink
        noCarousel={noCarousel}
        badge={badge}
        badgeClass={badgeClass}
      />
      <div className="card-body mx-0 px-0 my-0 py-0 space-y-0">
        {!profile && (
          <div className="flex flex-row justify-between inset-0 gap-1 px-1 sm:px-0">
            <div className="grid grid-cols-1 gap-2 items-center truncate overflow-hidden">
              <ProfileLine
                athlete={build._athlete}
                profiles={profiles}
                buildId={build?._id}
                noAvatar={noAvatar}
                location={location}
              />
            </div>
            {noReactions ? (
              ''
            ) : (
              <div className="flex flex-row">
                <div className="xl:flex flex-row items-center sm:hidden w-16">
                  <Heart build={build} />
                  <Bookmark build={build} lists={lists} />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BuildCard;

import React from 'react';
import { Link } from 'react-router-dom';

import ItemImages from '../image';
import Bookmark from '../../reactions/bookmark';

// import trash heroicon
import { XMarkIcon } from '@heroicons/react/24/solid';

const ItemCard = ({
  item,
  deleteButton,
  deleteHandler,
  imageOnly,
  customClass = '',
  lists,
  withBookmark,
  family,
  onClickHandler,
  labelBuilds,
}) => {
  if (!item) {
    return null;
  }

  // const { REACT_APP_AWS_S3 } = process.env;
  const { _id, name } = item;
  const makeName = item && item._make && item._make.name;

  // get build count and copy to buildBadge
  const buildCount = labelBuilds ? item?.buildCount : 0;
  const buildBadge =
    labelBuilds &&
    buildCount > 0 &&
    `in ${buildCount} ${buildCount === 1 ? 'build' : 'builds'}`;

  const renderDeleteButton = () => {
    if (!deleteButton) {
      return null;
    }
    return (
      <div className="mt-1 h-full flex flex-col justify-end mb-4">
        <button
          className="btn btn-circle btn-xs bg-slate-50 text-gray-500 z-30"
          onClick={(e) => {
            // prevent button submit
            e.preventDefault();
            deleteHandler();
          }}
        >
          <XMarkIcon width={18} height={18} />
        </button>
      </div>
    );
  };

  if (imageOnly) {
    // return <>{renderImagesOnly}</>;
    return (
      <ItemImages
        item={item}
        noCarousel={true}
        customClass={customClass}
        leftBadge={buildBadge}
      />
    );
  }

  if (family) {
    const itemCount = item?.familyItems?.length;
    const familyName = String(item?._family?.name || '');
    // add category name to family name, if name doesn't already include it item?._family?.name;
    let categoryName =
      typeof familyName === 'string' &&
      !familyName?.includes(item?._category?.name) &&
      typeof name === 'string' &&
      !name?.includes(item?._category?.name) &&
      item?._category?.name
        ? item?._category?.name
        : '';

    // get the first item in the family with an image, if none, then use the first item
    const firstItem =
      item?.familyItems?.find((i) => i?.images?.length > 0) ||
      item?.familyItems[0];

    const imageBadge = itemCount > 0 && `${itemCount} items`;

    const itemName = familyName ? `${familyName} ${categoryName}` : name;

    return (
      <div className={`card bg-base-100 w-full gap-2 ${customClass}`}>
        <div onClick={onClickHandler} className="hover:cursor-pointer">
          <ItemImages
            item={firstItem}
            stack
            badge={imageBadge}
            leftBadge={buildBadge}
          />
        </div>
        <div className="mx-0 px-0">
          <div className="flex items-center justify-between">
            <div className="text-sm line-clamp-1 flex flex-col  leading-tight">
              <span className="font-bold pr-1">{makeName}</span>
              <span className="line-clamp-1 ">{itemName}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // add category name to family name, if name doesn't already include it item?._family?.name;
  // even partial matches will be included
  const categoryName =
    typeof name === 'string' &&
    !name?.includes(item?._category?.name) &&
    item?._category?.name;

  // if category parent is 'Frame', add 'Frame' to the name
  const parentCategory = item?._category?._parent?.name;
  const isFrame = parentCategory === 'Frame';
  const frameName = isFrame ? 'Frame' : '';

  return (
    <div className={`card bg-base-100 pb-4 w-full gap-2 ${customClass}`}>
      <Link to={`/item/${_id}`} key={_id} state={{ backable: true }}>
        <ItemImages item={item} noCarousel={true} leftBadge={buildBadge} />
      </Link>
      <div className="mx-0 px-0">
        <div className="flex items-center justify-between">
          <div className="text-sm flex flex-col leading-tight">
            <span className="font-medium pr-1">{makeName}</span>
            <span className="line-clamp-1 ">
              {name} {categoryName} {frameName}
            </span>
          </div>
          {withBookmark && lists && (
            <div className="sm:flex items-center space-x-1 mt-2 sm:mt-0 hidden">
              <Bookmark item={item} lists={lists} />
            </div>
          )}
        </div>
      </div>
      {renderDeleteButton()}
    </div>
  );
};

export default ItemCard;

import _ from 'lodash';
import {
  APE_FETCH_USERS,
  APE_FETCH_INVITES,
  APE_UPDATE_USER,
  APE_ADD_INVITE,
  APE_DELETE_INVITE,
  APE_DELETE_USER,
  APE_FETCH_ITEMS,
  APE_DELETE_ITEM,
  APE_DELETE_POST,
  APE_UPDATE_POST,
  APE_REORDER_POSTS,
  APE_FETCH_POST_COLLECTION,
  APE_FETCH_MANY_POST_COLLECTIONS,
  APE_CLEAR_POST_COLLECTIONS,
  APE_DELETE_POST_COLLECTION,
  APE_UPDATE_POST_COLLECTION,
  APE_FETCH_STATS,
  APE_FETCH_ANALYTICS,
  APE_FETCH_DISTRIBUTOR,
  APE_FETCH_DISTRIBUTORS,
  APE_DELETE_DISTRIBUTOR,
  APE_UPDATE_DISTRIBUTOR,
} from '../actions/types';

const INITIAL_STATE = {};

const apeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APE_FETCH_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case APE_FETCH_INVITES:
      return {
        ...state,
        invites: action.payload,
      };
    case APE_UPDATE_USER:
      return {
        ...state,
        users: state.users.map((user) => {
          if (user._id === action.payload._id) {
            return action.payload;
          }
          return user;
        }),
      };
    case APE_DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
      };
    case APE_ADD_INVITE:
      return {
        ...state,
        invites: [...state.invites, action.payload],
      };
    case APE_DELETE_INVITE:
      return {
        ...state,
        invites: state.invites.filter(
          (invite) => invite._id !== action.payload
        ),
      };
    case APE_FETCH_ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    case APE_DELETE_ITEM:
      return {
        ...state,
        items: state.items.filter((item) => item._id !== action.payload),
      };

    case APE_DELETE_POST:
      // find the postCollection that contains the post and remove the post
      const postCollectionId = action.payload.postCollectionId;

      return {
        ...state,
        // posts: _.omit(state.posts, action.payload),
        postCollections: {
          ...state.postCollections,
          [postCollectionId]: {
            ...state.postCollections[postCollectionId],
            posts: state.postCollections[postCollectionId].posts.filter(
              (post) => post._id !== action.payload.postId
            ),
          },
        },
      };
    case APE_UPDATE_POST:
      // add or update the post in the postCollection
      return {
        ...state,
        postCollections: {
          ...state.postCollections,
          [action.payload.postCollectionId]: {
            ...state.postCollections[action.payload.postCollectionId],
            posts: state.postCollections[
              action.payload.postCollectionId
            ].posts.find((post) => post._id === action.payload.post._id)
              ? state.postCollections[
                  action.payload.postCollectionId
                ].posts.map((post) => {
                  if (post._id === action.payload.post._id) {
                    return action.payload.post;
                  }
                  return post;
                })
              : [
                  ...state.postCollections[action.payload.postCollectionId]
                    .posts,
                  action.payload.post,
                ],
          },
        },
      };
    case APE_REORDER_POSTS:
      // update the order of posts, but keep the posts object intact
      return {
        ...state,
        posts: {
          ..._.mapKeys(action.payload, '_id'),
        },
      };
    case APE_FETCH_POST_COLLECTION:
      return {
        ...state,
        postCollections: {
          ...state.postCollections,
          [action.payload._id]: action.payload,
        },
      };
    case APE_FETCH_MANY_POST_COLLECTIONS:
      return {
        ...state,
        postCollections: {
          ...state.postCollections,
          ..._.mapKeys(action.payload, '_id'),
        },
      };
    case APE_CLEAR_POST_COLLECTIONS:
      return {
        ...state,
        postCollections: {},
      };
    case APE_DELETE_POST_COLLECTION:
      return {
        ...state,
        postCollections: _.omit(state.postCollections, action.payload),
      };
    case APE_UPDATE_POST_COLLECTION:
      return {
        ...state,
        postCollections: {
          ...state.postCollections,
          [action.payload._id]: action.payload,
        },
      };
    case APE_FETCH_STATS:
      return {
        ...state,
        stats: action.payload,
      };
    case APE_FETCH_ANALYTICS:
      return {
        ...state,
        analytics: action.payload,
      };
    case APE_FETCH_DISTRIBUTOR:
      return {
        ...state,
        distributors: {
          ...state.distributors,
          [action.payload._id]: action.payload,
        },
      };

    case APE_FETCH_DISTRIBUTORS:
      return {
        ...state,
        distributors: _.mapKeys(action.payload, '_id'),
      };
    case APE_DELETE_DISTRIBUTOR:
      return {
        ...state,
        // find by id and remove
        distributors: _.omit(state.distributors, action.payload._id),
      };
    case APE_UPDATE_DISTRIBUTOR:
      return {
        ...state,
        distributors: {
          ...state.distributors,
          [action.payload._id]: action.payload,
        },
      };

    default:
      return state;
  }
};

export default apeReducer;

import React from 'react';

import { Link } from 'react-router-dom';

const BuildsFilter = ({ shortcuts = [], setShortcuts }) => {
  if (!shortcuts || shortcuts.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-row justify-between items-center">
      <div className="flex flex-row space-x-2 w-full overflow-x-scroll pb-4 px-5 sm:px-0">
        {Array.isArray(shortcuts) &&
          shortcuts.map((shortcut, index) => (
            <Link
              key={index}
              // remove shortcut.value if active
              to={shortcut.active ? '/feed' : `/${shortcut.value}`}
              className={`btn btn-sm ${shortcut.active ? 'btn-primary' : ''}`}
            >
              {shortcut.label}
            </Link>
          ))}
      </div>
    </div>
  );
};

export default BuildsFilter;

import { 
    DELETE_NOTIFICATIONS,
    FETCH_NOTIFICATIONS,
    CLEAR_NOTIFICATIONS
} from '../actions/types';

const INITIAL_STATE = {
    notices: [],
    count: 0
}

const notificationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DELETE_NOTIFICATIONS:
            return { ...state, notices: null, count: 0 };
        case FETCH_NOTIFICATIONS:
            return { ...state, 
                notices: action.payload, 
                count: action.payload.filter(n => !n.seen).length
            };
        case CLEAR_NOTIFICATIONS:
            // for each notice, set seen to true
            return { ...state, notices: state.notices.map(n => ({ ...n, seen: true })), count: 0 };
        default:
            return state;
    }
};

export default notificationsReducer;
import React from 'react';
import './forms.css';

// import heroicon of back arrow
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

// input is the destructured version of formProps.input

function renderError({ error, touched }, label) {
  if (touched && error) {
    return (
      <>
        <div
          className={`absolute right-4 flex items-center text-red-500 ${
            label ? `top-8` : `top-2`
          }`}
        >
          <ExclamationTriangleIcon className="h-8 w-8 mr-1" />
        </div>
        <div className="text-white bg-red-400 w-fit flex-nowrap py-2 px-4 mt-2 rounded whitespace-nowrap">
          {error}
        </div>
      </>
    );
  }
}

const renderInput = ({
  input,
  label,
  meta,
  placeholder,
  disabled,
  size = 'input',
  autoFocus = false,
  className: customClass,
  onInput,
}) => {
  const className = `${customClass} ${size} input-bordered w-full  ${
    meta.error && meta.touched ? 'input-error' : ''
  }`;
  const divClassName = `relative w-full`;
  return (
    <div className={divClassName}>
      <label htmlFor={input.name}>{label}</label>
      <input
        // onChange={formProps.input.onChange}
        // value={formProps.value}
        // is the same as
        {...input}
        id={input.name}
        autoComplete="off"
        placeholder={placeholder}
        disabled={disabled}
        className={`transition-all duration-200 ease-in-out ${className}`}
        autoFocus={autoFocus}
        onInput={onInput}
      />
      {renderError(meta, label)}
    </div>
  );
};

export default renderInput;

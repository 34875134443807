import React from 'react';

import { Link } from 'react-router-dom';

const ProfileMenu = ({ currentTab, athlete }) => {
  if (!athlete) return null;

  const { _id, alias, profileType } = athlete;

  const baseUrl = !alias ? `/athlete/${_id}` : `/${alias}`;

  return (
    <div>
      <div className="tabs flex justify-center my-6">
        <Link
          to={`${baseUrl}/`}
          className={`${!currentTab && `tab-active font-bold`} tab text-xl`}
        >
          Builds
        </Link>
        <Link
          to={`${baseUrl}/lists/`}
          className={`${
            currentTab === 'lists' && `tab-active font-bold`
          } tab text-xl`}
        >
          Lists
        </Link>
        {profileType === 'company' && (
          <Link
            to={`${baseUrl}/items/`}
            className={`${
              currentTab === 'items' && `tab-active font-bold`
            } tab text-xl`}
          >
            Items
          </Link>
        )}
      </div>
    </div>
  );
};

export default ProfileMenu;

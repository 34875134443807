import './forms.css';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Field } from 'react-final-form';
import MultiSelect from 'react-select';
// import heroicon of back arrow
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

const controlStyles = {
  base: 'border rounded-lg bg-white hover:cursor-pointer',
  focus: 'border-primary-600 ring-1 ring-primary-500 h-12',
  nonFocus: 'border-gray-300 hover:border-gray-400',
};
const placeholderStyles = 'text-sm text-gray-400 pl-1 py-0.5';
const selectInputStyles = 'pl-1 py-1.5';
const valueContainerStyles = 'p-1 gap-1';
const singleValueStyles = 'leading-7 ml-1';
const multiValueStyles =
  'bg-gray-100 dark:bg-gray-800 rounded items-center py-0.5 pl-2 pr-1 gap-1.5';
const multiValueLabelStyles = 'leading-6 py-0.5';
const multiValueRemoveStyles =
  'border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md';
const indicatorsContainerStyles = 'p-1 gap-1';
const clearIndicatorStyles =
  'text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800';
const indicatorSeparatorStyles = 'bg-gray-300';
const dropdownIndicatorStyles =
  'p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black';
const menuStyles = 'p-1 mt-2 border border-gray-200 bg-white rounded-lg';
const groupHeadingStyles = 'ml-3 mt-2 mb-1 text-gray-500 text-sm';
const optionStyles = {
  base: 'hover:cursor-pointer px-3 py-2 rounded',
  focus: 'bg-gray-100 active:bg-gray-200',
  selected: "after:content-['✔'] after:ml-2 after:text-green-500 text-gray-500",
};
const noOptionsMessageStyles =
  'text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm';
const loadingMessageStyle = 'py-2 text-inherit';

// customer filter function to match any word in the string
const filterOption = (option, rawInput) => {
  const words = rawInput.split(' ');
  return words.reduce(
    (acc, cur) => acc && option.label.toLowerCase().includes(cur.toLowerCase()),
    true
  );
};

const orderOptions = (values) => {
  // if single value, return it
  if (!Array.isArray(values)) return values;

  return (
    values &&
    values
      .filter((v) => v.isFixed)
      .concat(values.filter((v) => !v.isFixed))
      // sort by isFixed first, then by label
      .sort((a, b) => (a.isFixed === b.isFixed ? 0 : a.isFixed ? -1 : 1))
  );
};

const ReactSelectAdapter = ({
  input,
  value,
  onInputChange,
  defaultOptions,
  options,
  isLoading,
  onFocus,
  onChange,
  isClearable,
  isMulti = true,
  ...rest
}) => (
  <MultiSelect
    {...input}
    {...rest}
    key={input.name}
    // value={value} -- don't use this, it will cause the select to be uncontrolled
    searchable
    filterOption={filterOption}
    isClearable={false}
    isLoading={isLoading}
    isMulti={isMulti}
    options={options}
    defaultValue={options}
    defaultOptions={options}
    onInputChange={onInputChange}
    classNamePrefix="react-select"
    unstyled={true}
    onFocus={onFocus}
    onChange={onChange}
    styles={{
      input: (base) => ({
        ...base,
        'input:focus': {
          boxShadow: 'none',
        },
      }),
      // On mobile, the label will truncate automatically, so we want to
      // override that behaviour.
      multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
      },
      multiValueLabel: (base, state) => {
        return state.data.isFixed
          ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
          : base;
      },
      multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
      },
      control: (base) => ({
        ...base,
        transition: 'none',
        height: 'auto',
      }),
    }}
    classNames={{
      control: ({ isFocused }) =>
        clsx(
          isFocused ? controlStyles.focus : controlStyles.nonFocus,
          controlStyles.base
        ),
      placeholder: () => placeholderStyles,
      input: () => selectInputStyles,
      valueContainer: () => valueContainerStyles,
      singleValue: () => singleValueStyles,
      multiValue: () => multiValueStyles,
      multiValueLabel: () => multiValueLabelStyles,
      multiValueRemove: () => multiValueRemoveStyles,
      indicatorsContainer: () => indicatorsContainerStyles,
      clearIndicator: () => clearIndicatorStyles,
      indicatorSeparator: () => indicatorSeparatorStyles,
      dropdownIndicator: () => dropdownIndicatorStyles,
      menu: () => menuStyles,
      groupHeading: () => groupHeadingStyles,
      option: ({ isFocused, isSelected }) =>
        clsx(
          isFocused && optionStyles.focus,
          isSelected && optionStyles.selected,
          optionStyles.base
        ),
      noOptionsMessage: () => noOptionsMessageStyles,
      loadingMessage: () => loadingMessageStyle,
    }}
  />
);

// input is the destructured version of formProps.input
function renderError({ error, touched }) {
  if (touched && error) {
    return (
      <>
        <div className="absolute top-8 right-4 flex items-center text-red-500">
          <ExclamationTriangleIcon className="h-8 w-8 mr-8" />
        </div>
        <div className="text-white bg-red-400 w-fit flex-nowrap py-2 px-4 mt-2 rounded whitespace-nowrap">
          {error}
        </div>
      </>
    );
  }
}

// only set state if the value is different from the current state
const handleTermChange = (input, term, setTerm) => {
  if (input !== term) {
    setTerm(input);
  }
};
const RenderCustomSelect = ({
  input,
  label,
  meta,
  options = [],
  searchAction,
  placeholder,
  onFocus,
  minOptions = 0,
  isMulti = true,
  onChange,
}) => {
  const [term, setTerm] = useState('');
  const [value, setValue] = useState(orderOptions([...options]));
  const [inputValue, setInputValue] = useState(value);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    // search term related to input item, if searchAction is provided
    const search = () => searchAction && searchAction(term, input.name);

    // debounce search
    if (term && term.length > 3 && !options.length && !inputValue === term) {
      search();
      setInputValue(term);
      input.onChange(term);
      setLoading(false);
    } else {
      const timeoutId = setTimeout(() => {
        // if term is not in options, search
        if (term && inputValue !== term) {
          search();
          setInputValue(term);
        }
        setLoading(false);
      }, 1000);
      // react will run the return when component is re-reendered.
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [term, input, options, searchAction, inputValue]);

  const onChangeHandle = (value, { action, removedValue }) => {
    switch (action) {
      case 'remove-value':
        if (removedValue.isFixed) return;
        break;
      case 'pop-value':
        if (removedValue.isFixed) return;
        if (value?.length === minOptions && minOptions > 0)
          value[0].isFixed = true; // <-- add the isFixed element
        break;
      case 'set-value':
        if (value?.length === minOptions && minOptions > 0)
          value[0].isFixed = true; // <-- add the isFixed element
        break;
      case 'clear':
        value = options.filter((v) => !v.isFixed);
        break;
      default:
        break;
    }
    value = orderOptions(value);
    setValue(orderOptions(value));
    // push the value to redux form
    setValue(value);
    setInputValue(value);
    input.onChange(value);

    // if onChange is provided, call it
    if (onChange) onChange(value);
  };

  const className = `${meta.error && meta.touched ? 'input-error' : ''}`;
  return (
    <div className="relative">
      <label>{label}</label>
      <Field
        {...input}
        autoComplete="off"
        // value={value} //-- shouldn't be used on react-select
        component={ReactSelectAdapter}
        options={options}
        isLoading={loading}
        onInputChange={(input) => handleTermChange(input, term, setTerm)}
        className={className}
        placeholder={placeholder}
        onChange={onChangeHandle}
        isMulti={isMulti}
        onFocus={() => onFocus && onFocus()}
      />
      {renderError(meta)}
    </div>
  );
};

export default RenderCustomSelect;

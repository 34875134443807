import _ from 'lodash';
import {
  GET_BUILD,
  GET_BUILDS,
  POST_BUILD,
  POST_BUILD_ITEM,
  POST_BUILD_LIKE,
  POST_BUILD_ITEM_LIKE,
  GET_RELATED_BUILDS,
  DELETE_BUILD,
  DELETE_BUILD_IMAGE,
  DELETE_BUILD_ITEM,
  CLEAR_BUILDS,
  GET_BUILD_COMMENTS,
  POST_BUILD_COMMENT,
  DELETE_BUILD_COMMENT,
  POST_STRAVA_ACTIVITY_TO_BUILD,
  POST_PROFILE_TO_BUILD,
  DELETE_PROFILE_FROM_BUILD,
} from '../actions/types';

const INITIAL_STATE = {
  builds: [],
  totalPages: 0,
  type: '',
  params: {},
};

const buildReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BUILDS:
      return {
        builds: { ...state.builds, ..._.mapKeys(action.payload.builds, '_id') },
        totalPages: action.payload.totalPages,
        type: action.payload.type,
        params: action.payload?.params,
      };
    case GET_BUILD:
      const comments = state.builds[action.payload._id]
        ? state.builds[action.payload._id]?.comments
        : [];
      return {
        ...state,
        builds: {
          ...state.builds,
          [action.payload._id]: {
            ...state.builds[action.payload._id],
            ...action.payload,
            comments,
          },
        },
      };
    case POST_BUILD_ITEM:
      // update the build in the builds object
      return {
        ...state,
        builds: {
          ...state.builds,
          [action.payload._id]: {
            ...state.builds[action.payload._id],
            items: action.payload.items,
          },
        },
      };
    case GET_RELATED_BUILDS:
      // add to the builds object
      if (action.payload?.type === 'more') {
        // add additional builds to the builds array
        return {
          ...state,
          builds: {
            ...state.builds,
            [action.payload.buildId]: {
              ...state.builds[action.payload.buildId],
              relatedBuilds: [
                ...state.builds[action.payload.buildId].relatedBuilds,
                ...action.payload.relatedBuilds,
              ],
              totalRelatedBuildsPages: action.payload.totalRelatedBuildsPages,
            },
          },
        };
      }
      return {
        ...state,
        builds: {
          ...state.builds,
          [action.payload.buildId]: {
            ...state.builds[action.payload.buildId],
            relatedBuilds: action.payload.relatedBuilds,
            totalRelatedBuildsPages: action.payload.totalRelatedBuildsPages,
          },
        },
      };
    case POST_BUILD:
      return {
        ...state,
        builds: {
          ...state.builds,
          [action.payload._id]: {
            ...state.builds[action.payload._id],
            ...action.payload,
          },
        },
      };
    case POST_BUILD_LIKE:
      // update _likes of build in the builds object
      return {
        ...state,
        builds: {
          ...state.builds,
          [action.payload._id]: {
            ...state.builds[action.payload._id],
            _likes: action.payload._likes,
          },
        },
      };
    case POST_BUILD_ITEM_LIKE:
      // update _likes of build in the builds object
      return {
        ...state,
        builds: {
          ...state.builds,
          [action.payload._id]: {
            ...state.builds[action.payload._id],
            items: _.map(state.builds[action.payload._id].items, (item) => {
              if (item._id === action.payload.itemId) {
                return {
                  ...item,
                  _likes: action.payload.items.find(
                    (item) => item._id === action.payload.itemId
                  )._likes,
                };
              } else {
                return item;
              }
            }),
          },
        },
      };
    case POST_STRAVA_ACTIVITY_TO_BUILD:
      // update the build in the builds object
      return {
        ...state,
        builds: {
          ...state.builds,
          [action.payload._id]: {
            ...state.builds[action.payload._id],
            activity: action.payload.activity,
          },
        },
      };
    case DELETE_BUILD:
      return _.omit(state.builds, action.payload);
    case DELETE_BUILD_ITEM:
      return {
        ...state,
        builds: {
          ...state.builds,
          [action.payload.id]: {
            ...state.builds[action.payload.id],
            // remove the item from the items array
            items: _.remove(
              // iterate over the items array
              state.builds[action.payload.id].items,
              (item) => item._id !== action.payload.itemId
            ),
          },
        },
      };
    case POST_PROFILE_TO_BUILD:
      // update the build in the builds object
      return {
        ...state,
        builds: {
          ...state.builds,
          [action.payload.buildId]: {
            ...state.builds[action.payload.buildId],
            profiles: [
              // add or replace the profile in the profiles array
              ..._.remove(
                state.builds[action.payload.buildId].profiles,
                (profile) => profile._id !== action.payload.profile._id
              ),
              action.payload.profile,
            ],
          },
        },
      };
    case DELETE_PROFILE_FROM_BUILD:
      return {
        ...state,
        builds: {
          ...state.builds,
          [action.payload.buildId]: {
            ...state.builds[action.payload.buildId],
            // remove the event from the profiles array
            profiles: _.remove(
              // iterate over the profiles array
              state.builds[action.payload.buildId].profiles,
              (profile) => profile._profile._id !== action.payload.profileId
            ),
          },
        },
      };
    case DELETE_BUILD_IMAGE:
      return {
        ...state,
        builds: {
          ...state.builds,
          [action.payload.id]: {
            ...state.builds[action.payload.id],
            // remove the image from the images array
            images: _.remove(
              // iterate over the images array
              state.builds[action.payload.id].images,
              (image) => image.imageFile !== action.payload.imageFile
            ),
          },
        },
      };
    case GET_BUILD_COMMENTS:
      return {
        ...state,
        builds: {
          ...state.builds,
          [action.payload.buildId]: {
            ...state.builds[action.payload.buildId],
            comments: action.payload.comments,
          },
        },
      };
    case POST_BUILD_COMMENT:
      return {
        ...state,
        builds: {
          ...state.builds,
          [action.payload.buildId]: {
            ...state.builds[action.payload.buildId],
            comments: action.payload.comments,
          },
        },
      };
    case DELETE_BUILD_COMMENT:
      return {
        ...state,
        builds: {
          ...state.builds,
          [action.payload.buildId]: {
            ...state.builds[action.payload.buildId],
            comments: _.remove(
              state.builds[action.payload.buildId].comments,
              (comment) => comment._id !== action.payload.commentId
            ),
          },
        },
      };
    case CLEAR_BUILDS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default buildReducer;

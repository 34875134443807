import { useEffect, useState } from "react";
import history from "../../history";

export default function ScrollToTop() {

  const [scrollLocation, setScrollLocation] = useState(null);
  useEffect(() => {
    const unlisten = history.listen((prop) => {
      // save current scroll position
      const el = document.getElementById('main-content');
      el.scrollTop >0 && setScrollLocation(el.scrollTop);
      if (prop.action === 'PUSH') {
        const el = document.getElementById('main-content');
        el.scrollTo(0,0);
      } else {
        // // scroll to saved position after a short delay
        setTimeout(() => {
          const el = document.getElementById('main-content');
          el.scrollTo(0,scrollLocation);
        }, 100);
      }
    })
    return () => unlisten()
  }, [scrollLocation]);

  return (null)
}


// modal component to render items in a family

import React from 'react';
import Modal from '../../Modal';

import { connect } from 'react-redux';

import { showModal, hideModal } from '../../../actions';

import { XMarkIcon } from '@heroicons/react/24/solid';

import { Link } from 'react-router-dom';

import ItemImages from '../image';

const FamilyItemsModal = ({ modal, hideModal, family, loader }) => {
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    if (!mounted && modal.visible && modal.modalType === 'FAMILY_ITEMS') {
      setMounted(true);
    }
  }, [mounted, modal]);

  const familyName = family?.name || '';
  const familyItems = family?.familyItems || [];
  // get make from first item in family
  const make = familyItems?.[0]?._make?.name || '';
  const categoryName =
    familyItems?.length > 0 ? familyItems[0]?._category?.name : '';

  // sort family items by name
  familyItems.sort((a, b) => a.name.localeCompare(b.name));

  const renderImage = (images, item) => {
    if (!images || !images[0]) {
      // render default image using heroicons
      return (
        <div className="hidden sm:flex">
          <div className="flex flex-col items-center justify-center aspect-square bg-gray-100 w-14 h-14"></div>
        </div>
      );
    }

    return <ItemImages item={item} noCarousel />;
  };

  // for each family item, render item and link to item page
  const renderFamilyItems = () => {
    return familyItems?.map((item) => {
      const { name, images, _id: itemId, _category } = item;

      const buildCount =
        item?.builds?.length > 0 && item?.builds[0]?.count
          ? item?.builds[0].count
          : item?.buildCount;
      const labelBuilds = buildCount > 0 && (
        <span className="text-xs badge badge-ghost outline-none bg-brand text-base-100">
          in {buildCount} {buildCount === 1 ? 'build' : 'builds'}
        </span>
      );

      // render category name, if different from family category
      const categoryName =
        _category?.name !== familyItems[0]?._category?.name
          ? _category?.name
          : '';

      return (
        <div
          key={item._id}
          className="flex flex-row w-full items-center justify-between border-b border-gray-200 py-2"
        >
          <div className="flex items-center gap-4">
            <div className="w-14 shrink-0 aspect-square">
              {renderImage(images, item)}
            </div>
            <div className="text-sm font-medium flex-col flex-wrap gap-1 flex">
              <div>{name}</div>
              <div className="text-gray-500 gap-2">
                {labelBuilds}
                {categoryName}
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <Link
              to={`/item/${itemId}`}
              className="btn btn-accent btn-sm"
              onClick={() => hideModal()}
            >
              View
            </Link>
          </div>
        </div>
      );
    });
  };

  if (!modal.visible || modal.modalType !== 'FAMILY_ITEMS') {
    return null;
  }

  return (
    <Modal
      onDismiss={() => hideModal()}
      content={
        <div className="flex flex-col">
          <div className="flex flex-row justify-between items-center mb-4">
            <div className="flex flex-col">
              <div className="text-xl font-semibold">
                {make} {familyName} Family
              </div>
              <div className="text-sm text-gray-500">{categoryName}</div>
            </div>
            <button
              onClick={() => hideModal()}
              className="text-sm text-gray-500 hover:text-gray-700"
            >
              <XMarkIcon className="h-8 w-8" />
            </button>
          </div>

          <div className="flex items-start w-full">
            <div className="mt-0 text-left w-full">
              <div className="mt-2">
                <div className="mt-1 text-sm">{renderFamilyItems()}</div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

function mapStateToProps(state) {
  return {
    modal: state.modal,
    build: state.build,
    loader: state.loader,
  };
}

export default connect(mapStateToProps, { showModal, hideModal })(
  FamilyItemsModal
);

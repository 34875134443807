import React from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import ProfileImage from '../athlete/image';
import Notifications from '../notifications';

import { HomeIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

const Dock = ({ auth, isSignedIn }) => {
  if (!isSignedIn || !auth?.profile) {
    return null;
  }

  const setSearchFocus = () => {
    const searchInput = document.getElementById('search');
    searchInput.focus();
  };

  const renderAddMenu = () => {
    return (
      <div className="dropdown dropdown-top dropdown-start sm:invisible visible">
        <label tabIndex={0} className="m-0 p-0 self-center cursor-pointer">
          <PlusCircleIcon className="h-10 w-10" />
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content z-[1] menu p-2 shadow bg-base-200 rounded-box w-32"
        >
          <li>
            <Link to="/build/edit" state={{ backable: true }}>
              Build
            </Link>
          </li>
          <li>
            <Link to="/list/edit" state={{ backable: true }}>
              List
            </Link>
          </li>
        </ul>
      </div>
    );
  };

  const profileLink = auth?.profile?.alias
    ? `/${auth.profile.alias}`
    : `/athlete/${auth?.profile?._id}`;

  return (
    <div className="btm-nav sm:invisible shadow bg-base-100 z-20 bottom-0">
      <Link to={'/feed'}>
        <HomeIcon className="w-8 h-8" />
      </Link>
      <Link to="/search" className="btn-circle">
        <MagnifyingGlassIcon className="w-8 h-8" onClick={setSearchFocus} />
      </Link>
      <div className="flex-grow">{isSignedIn && renderAddMenu()}</div>
      <div className="flex-grow top-1">
        {isSignedIn && <Notifications className="dropdown-top dropdown-end" />}
      </div>
      <Link to={profileLink} className="btn-circle">
        <ProfileImage
          athlete={auth.profile}
          className="w-8 h-8 cursor-pointer m-0 p-0 self-center"
        />
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => {
  const isSignedIn = state?.auth?.userId;

  return {
    auth: state.auth,
    loader: state.loader,
    isSignedIn,
  };
};

export default connect(mapStateToProps, null)(Dock);

import React from 'react';

import ProfileImage from '../image';

import ReadMore from '../../ui/ReadMore';

import { connect } from 'react-redux';

import {
  followAthlete,
  unfollowAthlete,
  addAdminToAthlete,
  removeAdminFromAthlete,
} from '../../../actions';

import parseLinks from '../../../utils/parseLinks';
import Loader from '../../Loader';

import {
  InformationCircleIcon,
  UserPlusIcon,
  UserMinusIcon,
} from '@heroicons/react/24/outline';

import { CheckBadgeIcon } from '@heroicons/react/24/solid';

class AthleteHeader extends React.Component {
  renderVerified = () => {
    if (
      this.props.athlete?.status !== 'verified' &&
      this.props.athlete?.profileType !== 'athlete'
    ) {
      return (
        <div className="flex flex-row justify-center items-center">
          <div className="tooltip" data-tip="Unverified">
            <InformationCircleIcon className="h-6 w-6 text-accent" />
          </div>
        </div>
      );
    } else if (
      this.props.athlete?.status === 'verified' &&
      this.props.athlete?.profileType !== 'athlete'
    ) {
      return (
        <div className="flex flex-row justify-center items-center">
          <div className="tooltip" data-tip="Verified">
            <CheckBadgeIcon className="h-6 w-6 text-brand" />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    const {
      name,
      bio,
      location,
      _following,
      followers,
      url,
      services,
      status,
      profileType,
    } = this.props.athlete;

    // get followers count
    const followerCount = followers ? followers.length : 0;

    // get following count
    const following = _following ? _following.length : 0;

    // handle follow button
    const follow =
      this.props.auth &&
      this.props.auth.profile &&
      this.props.auth.profile._following &&
      this.props.auth.profile._following.includes(this.props.athlete._id);

    // opacity 20% if not verified
    const opacity =
      status !== 'verified' && profileType !== 'athlete'
        ? 'opacity-40 grayscale'
        : '';

    // handle follow button click
    const handleFollow = () => {
      if (follow) {
        this.props.unfollowAthlete(this.props.athlete._id);
      } else {
        this.props.followAthlete(this.props.athlete._id);
      }
    };

    const renderServices = () => {
      let serviceList = '';
      if (services && services.length > 0) {
        serviceList = services.map((service) => {
          if (!service.label)
            return (
              <Loader
                key={service._id}
                type="placeholder"
                align="items-center"
              />
            );
          return (
            <div key={service._id} className="text-sm">
              {service.label}
            </div>
          );
        });
      } else {
        serviceList = <></>;
      }

      return (
        <div className="flex flex-row justify-center items-center gap-2 mb-2">
          {serviceList}
        </div>
      );
    };

    // render follow button if not current user
    const followButton =
      this.props.auth?.profile &&
      this.props.auth.profile._id !== this.props.athlete._id ? (
        <div className="flex flex-row justify-center items-center">
          <button
            className={`btn btn-md ${
              !follow ? `btn-brand` : `btn-ghost bg-base-200`
            }`}
            onClick={handleFollow}
          >
            {!follow ? 'Follow' : 'Following'}
          </button>
        </div>
      ) : null;

    // handle admin button click
    const handleAdmin = () => {
      if (this.props.isMyAthlete) {
        this.props.removeAdminFromAthlete(this.props.athlete._id);
      } else {
        this.props.addAdminToAthlete(this.props.athlete._id);
      }
    };

    // render admin button if current user is admin
    const toggleAdminButton =
      this.props.isAdmin &&
      this.props.auth?.profile &&
      this.props.auth.profile._id !== this.props.athlete._id ? (
        <div className="flex flex-row justify-center items-center">
          <button
            className={`btn btn-md ${
              this.props.isMyAthlete ? `btn-ghost bg-base-200` : ``
            }`}
            onClick={handleAdmin}
          >
            {this.props.isMyAthlete ? (
              <UserMinusIcon className="h-5 w-5" />
            ) : (
              <UserPlusIcon className="h-5 w-5" />
            )}
            {this.props.isMyAthlete
              ? 'Remove from Profiles'
              : 'Add to Profiles'}
          </button>
        </div>
      ) : null;

    return (
      <>
        <div className="grid gap-4 items-center">
          <div className="relative flex flex-col col-span-1 mt-2 gap-5 items-center">
            <div className="flex flex-row justify-center items-center min-w-fit">
              <ProfileImage
                athlete={this.props.athlete}
                className="w-20 h-20"
              />
            </div>
            <div className="text-center max-w-md -mt-2 flex flex-col">
              <div className="flex flex-row justify-center items-center gap-2">
                <h1 className={`text-lg ${opacity}`}>{name}</h1>
                {this.renderVerified()}
              </div>
              {renderServices()}
              <h2 className="mb-2 text-sm">{location}</h2>
              <ReadMore>{bio}</ReadMore>
              {url && <p>{parseLinks(url)}</p>}
              <div className="flex flex-row justify-center items-center gap-4 text-gray-500 text-sm">
                {following > 0 && <p>{following} following</p>}
                {followerCount > 1 ? (
                  <p>{followerCount} followers</p>
                ) : followerCount === 1 ? (
                  <p>{followerCount} follower</p>
                ) : null}
              </div>
            </div>
            <div className="flex flex-row justify-center items-center gap-4">
              {followButton}
              {toggleAdminButton}
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const isAdmin = state?.auth?.permissions?.admin;
  const isMyAthlete =
    state?.auth?.athleteList?.filter(
      (athlete) => athlete._id === ownProps.athlete._id
    ).length > 0
      ? true
      : false;

  return {
    auth: ownProps.auth,
    athlete: ownProps.athlete,
    isAdmin,
    isMyAthlete,
  };
}

export default connect(mapStateToProps, {
  followAthlete,
  unfollowAthlete,
  addAdminToAthlete,
  removeAdminFromAthlete,
})(AthleteHeader);

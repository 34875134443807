import { UPDATE_SERVICEWORKER } from '../actions/types';

const INITIAL_STATE = {
  serviceWorkerUpdated: false,
};

const swReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SERVICEWORKER:
      return {
        ...state,
        serviceWorkerUpdated: true,
      };
    default:
      return state;
  }
};

export default swReducer;

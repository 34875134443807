import _ from 'lodash';
import { 
    GET_LISTS,
    GET_LIST,
    POST_LIST,
    DELETE_LIST,
    POST_LIST_ITEM,
    CLEAR_LISTS
} from '../actions/types';

const INITIAL_STATE = {};

const listReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_LISTS:
            return { ..._.mapKeys(action.payload, '_id') };
        case GET_LIST:
            return { 
                ...state, [action.payload._id]: action.payload
            };
        case POST_LIST:
            return {
                ...state, [action.payload._id]: action.payload
            }
        case POST_LIST_ITEM:
            return {
                ...state, [action.payload._id]: {
                    // ...state[action.payload._id],
                    // items: action.payload.items
                    ...state[action.payload._id],
                    name: action.payload.name,
                    _id: action.payload._id,
                    items: action.payload.items,
                    listType: action.payload.listType
                }
            }
        case DELETE_LIST:
            return _.omit(state.lists, action.payload);
        case CLEAR_LISTS:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default listReducer;
import _ from 'lodash';
import React from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { showModal } from '../../../actions';

import ProfileImage from '../../profile/image';
import Loader from '../../Loader';

import { CheckBadgeIcon } from '@heroicons/react/24/solid';

const renderVerified = (athlete) => {
  if (athlete?.status === 'verified' && athlete?.profileType !== 'athlete') {
    return (
      <span className="inline-flex items-baseline -mt-1">
        <CheckBadgeIcon className="h-5 w-5 translate-y-1 self-end text-brand" />
      </span>
    );
  } else {
    return null;
  }
};

const AthleteLine = ({
  showModal,
  athlete,
  buildId,
  profiles = [],
  noLink,
  textSize = 'text-sm',
  type,
  withServices,
  noAvatar,
  location,
}) => {
  const athleteName = athlete && athlete.name ? athlete.name : '';
  const athleteId = athlete && athlete._id ? athlete._id : '';
  const athleteType = athlete?.profileType
    ? `${athlete.profileType}`
    : type
    ? `${type}`
    : '';
  const athleteStatus = athlete?.status ? `${athlete.status}` : '';
  const hide = athlete?.feedHideTitle && location === 'feed';

  if (!athleteId || hide) {
    return null;
  }

  let revisedProfiles =
    profiles?.length > 0 && !_.isEmpty(profiles)
      ? profiles.map((profile) => {
          return {
            ...profile?._profile,
            profileType: profile?.profileType,
            service: profile?.service,
          };
        })
      : [];

  // remove athlete from profiles
  revisedProfiles = revisedProfiles.filter(
    (profile) => profile._id !== athleteId
  );

  const profileImages = !_.isEmpty(revisedProfiles)
    ? [athlete, ...revisedProfiles]
    : [athlete];
  const colabName = !_.isEmpty(revisedProfiles) ? (
    revisedProfiles.length > 1 ? (
      <>
        <span className="font-medium">{revisedProfiles[0].name}</span>{' '}
        {renderVerified(revisedProfiles[0])} and{' '}
        <span className="font-medium">{revisedProfiles.length - 1} more</span>
      </>
    ) : (
      <span className="font-medium">
        {revisedProfiles[0]?.name} {renderVerified(revisedProfiles[0])}
      </span>
    )
  ) : (
    ''
  );

  if (withServices) {
    const serviceList =
      athlete?.services?.map((service) => {
        return (
          <span key={service._id} className="text-xs mr-2 whitespace-nowrap">
            {service.label}
          </span>
        );
      }) || [];

    // if no serviceList, show athleteType
    const renderAthleteType = !serviceList.length ? (
      <span className="text-xs mr-2">{_.capitalize(athleteType)}</span>
    ) : null;

    const aliasSlug = athlete?.alias
      ? `/${athlete.alias}`
      : `/athlete/${athleteId}`;

    return (
      <Link
        to={aliasSlug}
        className="flex flex-row gap-1 items-center truncate"
      >
        {!noAvatar && (
          <ProfileImage profiles={profileImages} className="w-7 h-7" />
        )}
        {athleteName ? (
          <div className={`${textSize} flex flex-col`} key={athleteId}>
            <span className="font-medium">{athleteName}</span>
            <span className="whitespace-normal flex-wrap flex w-full">
              {renderAthleteType}
              {serviceList}
            </span>
          </div>
        ) : (
          <div className="w-40">
            <Loader type="placeholder" height="h-4" />
          </div>
        )}
      </Link>
    );
  }

  // if link or multiple profiles, render as div
  if (!noLink && !_.isEmpty(revisedProfiles) && buildId) {
    return (
      <div
        onClick={() =>
          showModal('VIEW_BUILD_PARTICIPANTS', {
            buildId,
          })
        }
        className="flex flex-row gap-1 items-center truncate inset-0 cursor-pointer"
      >
        {!noAvatar && (
          <ProfileImage
            athlete={athlete}
            className="w-7 h-7"
            profiles={profileImages}
          />
        )}
        {athleteName ? (
          <div className={`${textSize} flex flex-col truncate -space-y-1`}>
            <span className="font-medium truncate overflow-ellipsis">
              {athleteName} {renderVerified(athlete)}
            </span>
            {colabName && (
              <span className="whitespace-nowrap truncate overflow-ellipsis">
                x {colabName}
              </span>
            )}
          </div>
        ) : (
          <div className="w-40">
            <Loader type="placeholder" height="h-4" />
          </div>
        )}
      </div>
    );
  } else if (
    noLink ||
    !_.isEmpty(revisedProfiles) ||
    athleteStatus === 'hidden'
  ) {
    return (
      <div className="flex flex-row gap-1 items-center truncate inset-0">
        {!noAvatar && (
          <ProfileImage
            athlete={athlete}
            className="w-7 h-7"
            profiles={profileImages}
          />
        )}
        {athleteName ? (
          <div className={`${textSize} flex flex-col truncate -space-y-1`}>
            <span className="font-medium truncate overflow-ellipsis">
              {athleteName} {renderVerified(athlete)}
            </span>
            {colabName && (
              <span className="whitespace-nowrap truncate overflow-ellipsis">
                x {colabName}
              </span>
            )}
          </div>
        ) : (
          <div className="w-40">
            <Loader type="placeholder" height="h-4" />
          </div>
        )}
      </div>
    );
  }

  // does user have an alias?
  const aliasSlug = athlete?.alias
    ? `/${athlete.alias}`
    : `/athlete/${athleteId}`;

  return (
    <Link to={aliasSlug} className="flex flex-row gap-1 items-center">
      {!noAvatar && (
        <ProfileImage profiles={profileImages} className="w-7 h-7" />
      )}
      {athleteName ? (
        <div
          className={`${textSize} font-medium overflow-ellipsis truncate w-full`}
        >
          {athleteName} {renderVerified(athlete)}
        </div>
      ) : (
        <div className="w-40">
          <Loader type="placeholder" height="h-4" />
        </div>
      )}
    </Link>
  );
};

export default connect(null, {
  showModal,
})(AthleteLine);

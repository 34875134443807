import './forms.css';
import React from 'react';
// input is the destructured version of formProps.input

function renderError({ error, touched }) {
  if (touched && error) {
    return (
      <div className="tooltip tooltip-open tooltip-error" data-tip="error">
        {error}
      </div>
    );
  }
}

const renderTextarea = ({
  input,
  label,
  meta,
  className: customClass,
  placeholder,
  autoFocus,
}) => {
  const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
  return (
    <div className={className}>
      <label htmlFor={input.name}>{label}</label>
      <textarea
        // onChange={formProps.input.onChange}
        // value={formProps.value}
        // is the same as
        {...input}
        id={input.name}
        autoComplete="off"
        className={`textarea textarea-bordered h-24 w-full ${customClass}`}
        placeholder={placeholder}
        autoFocus={autoFocus}
      />
      {renderError(meta)}
    </div>
  );
};

export default renderTextarea;

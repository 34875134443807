import React from 'react';
import Modal from '../Modal';

import { connect } from 'react-redux';

import { showModal, hideModal } from '../../actions';

import ContactForm from './contact';
import PrivacyPolicy from './disclosures';

import { XMarkIcon } from '@heroicons/react/24/outline';

const SupportModal = (
    {
        isSignedIn,
        athlete: currentAthlete, 
        modal, 
        hideModal
    }) => {

    const [page, setPage] = React.useState(1);

    const renderPage = () => {
        switch (page) {
            case 1:
                return <ContactForm setPage={setPage} />;
            case 2:
                return <PrivacyPolicy setPage={setPage} />;
            default:
                return <ContactForm setPage={setPage} />;
        }
    }

    const renderMenu = () => {
        return (
            <div className="flex flex-row justify-center items-center w-full gap-4 text-sm my-2">
                <div 
                    onClick={() => setPage(1)}
                    className={`items-center cursor-pointer ${page === 1 ? 'underline-offset-8 underline' : 'text-gray-500'}`}>
                    Contact Us
                </div>
                <div
                    onClick={() => setPage(2)} 
                    className={`items-center cursor-pointer ${page === 2 ? 'underline-offset-8 underline' : 'text-gray-500'}`}>
                    Privacy Policy
                </div>
            </div>
        );
    }

    const renderContent = () => {
        return (
            <div className="flex flex-col w-full">
                <div className="flex flex-col w-full">
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-col">
                            <div className="text-xl font-bold">Support</div>
                        </div>
                        <div className="flex flex-row justify-end">
                            <button className="btn btn-square btn-ghost btn-sm" onClick={() => hideModal()}>
                                <XMarkIcon className="h-6 w-6 text-gray-500" />
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col mt-2 w-full">
                        {renderMenu()}
                        {renderPage()}
                    </div>
                </div>
            </div>
        );
    }

    if (!modal.visible || modal.modalType !== 'SUPPORT') {
        return null;
    }

    return (
        <Modal
            content={renderContent()}
            onDismiss={() => hideModal()}
        />
    );

}

const mapStateToProps = (state) => {

    const isSignedIn  = state.auth?.userId;

    return {
        isSignedIn,
        athlete: state.auth.profile,
        modal: state.modal
    }
}

export default connect(mapStateToProps, 
    { showModal, hideModal })
    (SupportModal);
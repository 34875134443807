import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col w-full mt-4">
      <h1>Privacy Policy</h1>
      <p>
        This is our privacy policy. We take your privacy seriously and are
        committed to protecting your personal information.
      </p>
      <h2>MS Clarity</h2>
      <p>
        We partner with Microsoft Clarity and Microsoft Advertising to capture
        how you use and interact with our website through behavioral metrics,
        heatmaps, and session replay to improve and market our
        products/services. Website usage data is captured using first and
        third-party cookies and other tracking technologies to determine the
        popularity of products/services and online activity. Additionally, we
        use this information for site optimization, fraud/security purposes, and
        advertising. For more information about how Microsoft collects and uses
        your data, visit the Microsoft Privacy Statement.
      </p>
      <h2>Google Analytics</h2>
      <p>
        We also use Google Analytics to track website traffic and gather
        information about user demographics and interests. This helps us analyze
        and improve our website's performance and content.
      </p>
      <p>
        By using our website, you consent to the collection and use of your
        information as described in this privacy policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;

import React from 'react';

import { connect } from 'react-redux';

import { showModal } from '../../actions';

const Banner = ({ loggedIn, showModal, notice }) => {
  // if user is not logged in, don't show banner
  if (!loggedIn) {
    return <></>;
  }

  if (notice) {
    return (
      <>
        <div
          className={`mt-0 text-sm font-medium w-screen h-fit bg-error flex flex-row absolute z-30 p-1 justify-center text-white `}
        >
          <span className="hidden sm:flex">{notice}</span>
          <span className="sm:hidden">{notice}</span>
        </div>
        <div className="h-6"></div>
      </>
    );
  }

  return (
    <>
      <div className="mt-0 text-sm font-medium w-screen h-fit bg-banner flex flex-row absolute z-30 p-1 justify-center">
        <span className="hidden sm:flex">
          Thank you for participating in Private Beta.
        </span>
        <span className="sm:hidden">Private Beta.</span>
        <span
          className="ml-2 underline underline-offset-4 cursor-pointer"
          onClick={() =>
            showModal('SUPPORT', {
              comment: ``,
            })
          }
        >
          Submit feedback.
        </span>
      </div>
      <div className="h-6"></div>
    </>
  );
};

const mapStateToProps = (state) => {
  // is user logged in?
  // if not, don't show banner
  if (!state?.auth?.userId && !state?.auth?.permissions) {
    return {
      loggedIn: false,
    };
  } else {
    return {
      loggedIn: true,
    };
  }
};

export default connect(mapStateToProps, { showModal })(Banner);

import React from 'react';
// import { Link } from 'react-router-dom';
import Waitlist from '../../landing/waitlist';

const NoAccount = () => {
  return (
    <>
      <Waitlist />
    </>
  );
};

export default NoAccount;
